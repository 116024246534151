import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from 'react-bootstrap';

interface DeletedAlertProps {
	deleted?: boolean;
}

export function DeletedAlert({ deleted }: DeletedAlertProps) {
	if (!deleted) {
		return <></>;
	}

	return (
		<Alert variant="danger" className="text-center">
			<FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
			This record has been deleted.
			<FontAwesomeIcon icon={faExclamationTriangle} className="ms-2" />
		</Alert>
	);
}
