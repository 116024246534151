/* eslint-disable no-restricted-globals */
// stories/:id
import {
	faBracketsCurly,
	faExternalLinkAlt,
	faFileXml,
	faNewspaper,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertToDate } from '@newstex/core/date';
import { UserError } from '@newstex/core/errors';
import { Content } from '@newstex/types/content';
import { Story } from '@newstex/types/story';
import { marked } from 'marked';
import { useEffect, useState } from 'react';
import {
	Alert,
	Button,
	Card,
	Col,
	Container,
	Row,
	Tab,
	Tabs,
} from 'react-bootstrap';
import { JsonView, allExpanded, defaultStyles } from 'react-json-view-lite';
import ReactQuill from 'react-quill';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import 'react-json-view-lite/dist/index.css';
import { toast } from 'react-toastify';
// @ts-ignore
import XMLViewer from 'react-xml-viewer';
import { AIScoreDebugger } from '~/components/ai-score-debugger';
import { CategoryDebugger } from '~/components/category-debugger';
import { DownloadButton } from '~/components/download-button';
import Icon from '~/components/Icon';
import LoadingSpinner from '~/components/LoadingSpinner';
import { ParentBreadcrumb } from '~/components/parent-breadcrumb';
import { PropertyDisplayValue } from '~/components/property-display-value';
import { RefreshButton } from '~/components/refresh-button';
import { RequirePermissions, hasPermission } from '~/components/require-permissions';
import type { SVGIconsName } from '~/components/SvgIcons';
import { useAPI } from '~/providers/api-provider';
import { useConfirm } from '~/providers/confirm';
import { useUserInfo } from '~/providers/user-info';
import 'react-quill/dist/quill.snow.css';

function StoryLink({ story }: { story?: Story }) {
	if (!story?.permalink) {
		return <></>;
	}
	return (
		<div className="link text-muted p-1 small">
			<Link
				to={story.permalink}
				className="link-secondary"
				target="_blank"
			>
				{story.permalink}
				&nbsp;<FontAwesomeIcon icon={faExternalLinkAlt} />
			</Link>
		</div>
	);
}

const LOADING_XML = '<?xml version="1.0" encoding="UTF-8"?><Loading />';

function CategoryScoreWidget({
	score, label, icon,
}: { score: number; label: string, icon: SVGIconsName }) {
	const color = score > 0 ? 'danger' : 'success';
	return (
		<Col xs={6} md={2}>
			<Card>
				<Card.Body className="bg-transparent border-0">
					<Card.Title className={`fw-normal text-${color}`}>
						{String(score)}
						<div className="float-end">
							<Icon icon={icon} />
						</div>
					</Card.Title>
					<div className="subtitle text-sm text-muted mb-0">{label}</div>
					{score > 10 ? <b>MATCHED</b> : null}
				</Card.Body>
			</Card>
		</Col>
	);
}

export function StoryCategoryAlerts({ story }: { story?: Story }) {
	if (!story?.category_scores) {
		return <></>;
	}
	return (
		<Row className="mb-4">
			<CategoryScoreWidget
				score={story.category_scores?.SPAM || 0}
				label="Spam"
				icon="bin-1"
			/>
			<CategoryScoreWidget
				score={story.category_scores?.CONTROV || 0}
				label="Controversial"
				icon="heartbeat-1"
			/>
			<CategoryScoreWidget
				score={story.category_scores?.PRHYPE || 0}
				label="PR Hype"
				icon="paper-plane-1"
			/>
			<CategoryScoreWidget
				score={story.category_scores?.GUESTPOST || 0}
				label="Guest Post"
				icon="man-1"
			/>
		</Row>
	);
}

export function StoryPage() {
	const params = useParams();
	const api = useAPI();
	const userInfo = useUserInfo();
	const [story, setStory] = useState<Story>();
	const [storyXHTML, setStoryXHTML] = useState<string>();
	const [storyXML, setStoryXML] = useState<string>();
	const [storyHTML, setStoryHTML] = useState<string>();
	const [searchParams, setSearchParams] = useSearchParams();
	const [visibleTab, setVisibleTab] = useState<string | null>(searchParams.get('tab') || 'content');
	const [pub, setPub] = useState<Content>();
	const [editMode, setEditMode] = useState(false);
	const [loading, setLoading] = useState(true);
	const { confirmDialog } = useConfirm();

	const removeStory = async () => {
		setLoading(true);
		try {
			const resp = await api.fetchWithAuthRaw(`resources/Story/${params.id}/remove`, {
				method: 'POST',
				body: JSON.stringify({
					version: story?.version,
				}),
			});
			if (resp.ok) {
				setStory(await resp.json());
			} else {
				console.error('Remove story failed', resp);
			}
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	const fetchData = async (refresh = false) => {
		setLoading(true);
		const resp = await api.fetchWithAuth(
			`resources/Story/${params.id}`,
			refresh ? { cache: 'reload' } : undefined,
		);
		setStory(resp.items?.[0]);
		if (resp.items?.[0]) {
			const pubResp = await api.fetchWithAuth(
				`resources/Publication/${resp.items[0].publication}`,
				refresh ? { cache: 'reload' } : undefined,
			);
			setPub(pubResp.items?.[0]);
			try {
				const storyHTMLResp = await api.fetchWithAuthRaw(
					`resources/Story/${params.id}/content.html`,
					refresh ? { cache: 'reload' } : undefined,
				);
				if (storyHTMLResp.ok) {
					setStoryHTML(await storyHTMLResp.text());
				}
			} catch (err) {
				if (err instanceof UserError) {
					toast.error(`Error loading story HTML: ${err.statusCode || 500}`);
					console.warn('Error loading story HTML', err);
					setStoryHTML(`<h1>${err.message}</h1>`);
				} else {
					console.error('Error loading story HTML', err);
					toast.error(`Unknown error loading story HTML: ${(err as any).statusCode || 500}`);
					setStoryHTML('<h1>Error Loading Story HTML</h1>');
				}
			}
		}
		setLoading(false);
	};

	const convertToFullText = async () => {
		setLoading(true);
		try {
			const resp = await api.fetchWithAuthRaw(`resources/Story/${params.id}/convertToFullText`, {
				method: 'POST',
				body: JSON.stringify({
					version: story?.version,
				}),
			});
			if (resp.ok) {
				fetchData(true);
			}
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	const handleContentChange = async (value: string) => {
		if (
			typeof value === 'string'
			&& (value.trim().includes('**') || value.trim().includes('###'))
		) {
			// Look for a `Headline:` in the first few lines, and if found use it as
			// the story headline
			let cleanValue = value.replace(/<\/?(p|br)>/g, '\r\n');
			const lines = cleanValue.split('\r\n');
			console.log(lines.slice(0, 10));
			const headlineLine = lines.slice(0, 10).find((line) => line.trim().match(/^[#*]*\s*Headline:\s*(.+?)\s*[#*]*$/));
			if (headlineLine) {
				const headline = headlineLine.replace(/^[#*]*\s*Headline:\s*/, '').replace(/\s*[#*]*$/, '').trim();
				cleanValue = cleanValue.replace(headlineLine, '').trim();
			}
			setStoryHTML(await marked(cleanValue));
		} else {
			setStoryHTML(value);
		}
	};

	const handleSaveContent = async () => {
		setLoading(true);
		try {
			const resp = await api.fetchWithAuthRaw('resources/Story', {
				method: 'POST',
				body: JSON.stringify({
					...story,
					html_content: storyHTML,
					version: story?.version ? story.version + 1 : 1,
				}),
			});
			if (resp.ok) {
				const updatedStory = await resp.json();
				setStory(updatedStory);
				toast.success('Story content updated successfully');
				setEditMode(false);
			} else {
				console.error('Update story content failed', resp);
				toast.error('Failed to update story content');
			}
		} catch (err) {
			console.error(err);
			toast.error('Error updating story content');
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, [params]);

	return (
		<Container fluid>
			<div className="title-wrapper pt-30">
				<div className="row align-items-center">
					<div className="col-md-6">
						<div className="title">
							<h2>
								{story?.headline}
								{story?.categories?.includes('SPAM') ? <span className="badge bg-danger ms-2">SPAM</span> : null}
								{story?.categories?.includes('PRHYPE') ? <span className="badge bg-danger ms-2">PR Hype</span> : null}
								{story?.categories?.includes('GUESTPOST') ? <span className="badge bg-danger ms-2">Guest Post</span> : null}
							</h2>
							<h6 className="text-muted">
								{story?.__id__}
							</h6>
							<StoryLink story={story} />
						</div>
					</div>
					<div className="col-md-6">
						<div className="breadcrumb-wrapper">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<ParentBreadcrumb obj={pub?.$id} />
									<li className="breadcrumb-item active">
										<a>{story?.__id__}</a>
									</li>
								</ol>
							</nav>
							<div className="text-end">
								<span className="ps-1">
									<DownloadButton size="sm" options={[
										{
											icon: faFileXml,
											name: 'StoryXML',
											path: `resources/Story/${params.id}/story.xml`,
											filename: `${story?.__id__}.xml`,
										},
										{
											icon: faNewspaper,
											name: 'NewsML',
											path: `resources/Story/${params.id}/story.newsml`,
											filename: `${story?.__id__}.newsml`,
										},
										{
											icon: faBracketsCurly,
											name: 'StoryJSON',
											path: `resources/Story/${params.id}/story.json`,
											filename: `${story?.__id__}.json`,
										},
									]} />
								</span>
								<span className="ps-2">
									<RefreshButton size="sm" refreshHandler={() => {
										return fetchData(true);
									}} />
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			{story?.status === 'Removed' && (
				<Row className="align-items-center">
					<Alert key="story-removed" variant="danger">
						<center>
							<h3>STORY REMOVED</h3>
							{story.excerpt}
						</center>
					</Alert>
				</Row>
			)}
			<hr />

			<Row>
				{story?.category_scores ? <StoryCategoryAlerts story={story} /> : null}
			</Row>
			<Row>
				<div className="col-12">
					<Card className="story">
						<Card.Header>
							<Container fluid>
								<Row>
									<Col xs={12} md={6} xl={3}>
										<dl className="properties">
											<dt>Published</dt>
											<dd>{story?.date ? convertToDate(story.date).format('MMM Do YYYY, h:mma') : ''}</dd>

											<dt>Received</dt>
											<dd>{story?.received_at ? convertToDate(story.received_at).format('MMM Do YYYY, h:mma') : ''}</dd>

											<dt>Version</dt>
											<dd><PropertyDisplayValue propName="version" propValue={story?.version} /></dd>

											<dt>Stats</dt>
											<dd><PropertyDisplayValue propName="stats" propValue={story?.stats} /></dd>
										</dl>
									</Col>
									<Col xs={12} md={6} xl={3}>
										<dl className="properties">
											<dt>Publication</dt>
											<dd><PropertyDisplayValue propName="publication" propValue={pub} /></dd>

											<dt>Source</dt>
											<dd><PropertyDisplayValue propName="source" propValue={story?.source} /></dd>

											<dt>Language</dt>
											<dd><PropertyDisplayValue propName="language" propValue={story?.language} /></dd>

											<dt>External ID</dt>
											<dd><PropertyDisplayValue propName="external_id" propValue={story?.external_id} /></dd>
										</dl>
									</Col>
									<Col xs={12} md={6} xl={3}>
										<dl className="properties">
											<dt>Categories</dt>
											<dd><PropertyDisplayValue propName="categories" propValue={story?.categories} /></dd>

											<RequirePermissions permissions={['admin']}>
												<dt>Products</dt>
												<dd><PropertyDisplayValue propName="products" propValue={story?.products} /></dd>
											</RequirePermissions>
										</dl>
									</Col>
									<Col xs={12} md={6} xl={3}>
										<dl className="properties">
											<dt>Provider Categories</dt>
											<dd><PropertyDisplayValue propName="provider_categories" propValue={story?.provider_categories} /></dd>
										</dl>
									</Col>
								</Row>
							</Container>
						</Card.Header>
						<Card.Body>
							<Tabs defaultActiveKey={searchParams.get('tab') || 'content'} id="uncontrolled-tab-example" className="mb-3" onSelect={(e) => {
								setVisibleTab(e);
								// Clear the "sort_by" parameter when switching tabs
								setSearchParams((p) => {
									p.delete('sort_by');
									if (e) {
										p.set('tab', e);
									}
									return p;
								});
								if (story?.__id__ && e === 'xhtml' && !storyXHTML) {
									api.fetchWithAuthRaw(`resources/Story/${params.id}/content.xhtml`).then(async (resp) => {
										if (resp.ok) {
											setStoryXHTML(`<?xml version="1.0" encoding="UTF-8"?><xhtml>${await resp.text()}</xhtml>`);
										}
									});
								}

								if (story?.__id__ && e === 'xml' && !storyXML) {
									api.fetchWithAuthRaw(`resources/Story/${params.id}/story.xml`).then(async (resp) => {
										if (resp.ok) {
											setStoryXML(`${await resp.text()}`);
										}
									});
								}
							}}>
								<Tab eventKey="content" title="Content">
									<LoadingSpinner loading={loading} />
									{!loading && storyHTML && story?.__id__?.startsWith('NTX-') && (
										<Button size="sm" className="float-end" variant="secondary" onClick={() => setEditMode(!editMode)}>
											{editMode ? 'Cancel' : 'Edit'}
										</Button>
									)}
									{editMode ? (
										<div className="mb-5">
											<ReactQuill
												theme="snow"
												value={storyHTML || ''}
												readOnly={loading}
												onChange={handleContentChange}
												style={{
													height: '500px',
													width: '100%',
													marginBottom: '50px',
												}}
											/>
											<Button size="sm" className="float-end" variant="success" onClick={handleSaveContent}>
												Save Content
											</Button>
										</div>
									) : (
										<iframe
											srcDoc={storyHTML}
											seamless
											width="100%"
											height="1500px"
											onLoad={(evt) => {
												try {
													const frame = evt.target as HTMLIFrameElement;
													if (frame.contentWindow?.document.body.scrollHeight) {
														frame.style.height = `${frame.contentWindow.document.body.scrollHeight + 50}px`;
													}
												} catch (e) {
													console.error(e);
												}
											}}
										/>
									)}
									<RequirePermissions permissions={['admin']}>
										{story?.status === 'Active' ? <>
											<Button variant="primary" onClick={async () => {
												if (await confirmDialog('Are you sure you want to re-process the full text?')) {
													convertToFullText();
												}
											}}>Re-Process Full Text</Button>
											&nbsp;
											<Button variant="danger" onClick={async () => {
												if (await confirmDialog('Are you sure you want to remove this story?')) {
													removeStory();
												}
											}}>Remove Story</Button>
										</> : <b>{story?.status}</b>}
									</RequirePermissions>
								</Tab>
								<Tab eventKey="xhtml" title="XHTML">
									<LoadingSpinner loading={loading} />
									<XMLViewer xml={storyXHTML || LOADING_XML} collapsible />
								</Tab>
								<Tab eventKey="xml" title="StoryXML">
									<LoadingSpinner loading={loading} />
									<XMLViewer xml={storyXML || LOADING_XML} collapsible />
								</Tab>
								<Tab eventKey="metadata" title="Metadata">
									<LoadingSpinner loading={loading} />
									<code>
										<JsonView data={story || {}} shouldExpandNode={allExpanded} style={defaultStyles} />
									</code>
								</Tab>
								{hasPermission(['admin']) && (
									<Tab eventKey="categoryDebug" title="Category Debugger">
										<LoadingSpinner loading={loading} />
										{ (visibleTab === 'categoryDebug' && story) ? <CategoryDebugger story={story} /> : <></> }
									</Tab>
								)}
								{hasPermission(['admin']) && (
									<Tab eventKey="aiQualification" title="AI Qualification">
										{ (visibleTab === 'aiQualification' && story) ? <AIScoreDebugger story={story} /> : <></> }
									</Tab>
								)}
							</Tabs>
						</Card.Body>
					</Card>
				</div>
			</Row>
		</Container>
	);
}
