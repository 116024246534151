import type { HubSpotCompany } from '@newstex/types/hubspot';

type NumericFields = {
	[K in keyof HubSpotCompany]: HubSpotCompany[K] extends number ? K : never
}[keyof HubSpotCompany];

export const HubSpotClientFieldNames: Record<string, NumericFields> = {
	ccc: 'ai_qualification_score__ccc',
	cla: 'ai_qualification_score__cla',
	industrydive: 'ai_qualification_score__industry_dive',
	lexisnexis: 'ai_qualification_score__lexisnexis',
	moodys: 'ai_qualification_score__moody_s',
	proquest: 'ai_qualification_score__proquest',
	refinitiv: 'ai_qualification_score__refinitiv',
	syndigate: 'ai_qualification_score__syndigate',
	thomsonwestlaw: 'ai_qualification_score__thomson_westlaw',
	thomson: 'ai_qualification_score__thomson_westlaw',
};

export function getHubSpotFieldName(client: string): NumericFields {
	const cleanedClient = client.replace(/[^a-z]/gi, '').toLowerCase();
	if (cleanedClient in HubSpotClientFieldNames) {
		return HubSpotClientFieldNames[cleanedClient];
	}
	return `ai_qualification_score__${cleanedClient}` as NumericFields;
}
