import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Content,
	Publication,
	Publisher,
	ReferenceString,
	Results,
	convertReferenceToString,
} from '@newstex/types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAPI } from '~/providers/api-provider';

export const ParentBreadcrumb: React.FC<{
	obj?: Partial<Content> | ReferenceString<Publisher>;
}> = (props) => {
	const [parent, setParent] = useState<Publisher | Publication | null | undefined>(undefined);
	const [loading, setLoading] = useState(false);
	const api = useAPI();

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			if (typeof props.obj === 'string') {
				const pubResp = await api.fetchWithAuth<Results<Publication>>(`resources/Publication/${props.obj}`);
				if (pubResp?.items?.[0]) {
					setParent(pubResp?.items?.[0]);
				} else {
					console.error('Failed to fetch parent for', props.obj);
					setParent({
						$type: 'Publication',
						$id: props.obj,
						name: '[DELETED]',
					});
				}
			} else if (props.obj?.parent) {
				const parentID = convertReferenceToString(props.obj.parent);
				const pubResp = await api.fetchWithAuth<Results<Publisher>>(`resources/Publisher/${parentID}`);
				if (pubResp?.items?.[0]) {
					setParent(pubResp.items[0]);
				} else {
					console.error('Failed to fetch parent for', props.obj);
					setParent({
						$type: 'Publisher',
						$id: parentID,
						name: '[DELETED]',
					});
				}
			} else {
				setParent(null);
			}
			setLoading(false);
		};

		fetchData();
	}, [props.obj]);

	return (
		<>
			{props.obj && (parent !== null || loading) && (
				<>
					{parent?.parent && <ParentBreadcrumb obj={parent} />}
					<li className="breadcrumb-item">
						{loading && <FontAwesomeIcon icon={faSpinner} spin />}
						{parent && <Link to={`/${parent.$type.toLowerCase()}s/${parent.$id}`}>
							{parent.name || '...'}
							{parent.newstex_id && (
								<>
									{' '}
									[{parent.newstex_id}]
								</>
							)}
						</Link>}
						{!parent && !loading && <span data-info={JSON.stringify(props.obj)}>...</span>}
					</li>
				</>
			)}
		</>
	);
};
