import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Category } from '@newstex/types/category';
import type { Results } from '@newstex/types/results';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAPI } from '~/providers/api-provider';

interface CategoryLinkProps {
	category?: string | Category;
}

export const CategoryLink: React.FC<CategoryLinkProps> = ({ category }) => {
	const [categoryData, setCategoryData] = useState<Category | null>(null);
	const api = useAPI();

	useEffect(() => {
		const fetchData = async () => {
			if (typeof category === 'string') {
				const resp = await api.fetchWithAuth<Results<Category>>(`resources/Category/${category}`);
				setCategoryData(resp.items?.[0]);
			}
		};

		if (typeof category === 'string') {
			fetchData();
		} else if (category && typeof category === 'object') {
			setCategoryData(category);
		}
	}, [category]);

	if (!category) {
		return null;
	}

	if (typeof category === 'object') {
		return (
			<Link to={`/categories/${category.code}`}>
				{category.name || category.code} [${category.code}]
			</Link>
		);
	}

	return (
		<Link to={`/categories/${category}`}>
			{categoryData ? (
				categoryData.name || category
			) : (
				<FontAwesomeIcon icon={faSpinner} spin />
			)} {categoryData?.code && <small className="text-muted"> [{categoryData.code}]</small>}
		</Link>
	);
};
