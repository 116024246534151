/* eslint-disable no-nested-ternary */
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NormalizedSearchResponse } from '@newstex/search/types';
import { Category } from '@newstex/types/category';
import {
	Content,
	Publication,
	Publisher,
	isContent,
	isPublication,
	isPublisher,
} from '@newstex/types/content';
import { HubSpotCompany } from '@newstex/types/hubspot';
import type React from 'react';
import {
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import type { SearchParams } from 'typesense/lib/Typesense/Documents';
import {
	Command,
	ModalFields,
	commands,
	isCommand,
} from '~/commands/index';
import { CategoryCard } from '~/components/category-card';
import { ContentCard } from '~/components/content-card';
import ModalDataEditor from '~/components/modal-data-editor';

import { useAIChat } from './ai-chat-provider';
import { useAI } from './ai-provider';
import { useAPI } from './api-provider';
import { useSearch } from './search';
import { useUserInfo } from './user-info';

interface QuickSearchResponseType {
	Publisher?: NormalizedSearchResponse<Publisher>;
	Publication?: NormalizedSearchResponse<Publication>;
	Category?: NormalizedSearchResponse<Category>;
	HubSpot?: NormalizedSearchResponse<HubSpotCompany>;
	Commands?: Command[];
	exactMatch?: Content | Category | Command;
}

interface CommandBarContextType {
	bind: (root: HTMLElement | null) => void;
	inputField: (inputField: HTMLElement | null) => void;
	input: string;
	setInput: (input: string) => void;
	setActive: (el: HTMLElement | null) => void;
	visible: boolean;
	show: () => void;
	hide: () => void;
	results: QuickSearchResponseType | null;
}

const CommandbarContext = createContext<CommandBarContextType | null>(null);

export function CommandBar(props: React.PropsWithChildren<{}>) {
	const userInfo = useUserInfo();
	const searchContext = useSearch();
	const navigate = useNavigate();
	const api = useAPI();
	const [visible, setVisible] = useState<boolean>(false);
	const [input, setInput] = useState<string>('');
	const [root, setRoot] = useState<HTMLElement | null>(null);
	const [inputField, setInputField] = useState<HTMLElement | null>(null);
	const [results, setResults] = useState<QuickSearchResponseType | null>(null);
	const [modalData, setModalData] = useState<ModalFields | null>(null);
	const ai = useAI();
	const aiChat = useAIChat();

	const control = {
		get root() {
			if (!root) throw new Error('Root not set');
			return root;
		},
		input() {
			return inputField;
		},
		actions() {
			return [...control.root.querySelectorAll("[data-element='action']")];
		},
		reset() {
			control.setActive(control.actions()[0]);
		},
		active() {
			return control.root.querySelector("[data-element='action'].active");
		},
		setActive(el?: Element) {
			if (!el) return;
			const current = control.active();
			if (current) current.classList.remove('active');
			el.classList.add('active');
			el.scrollIntoView({
				block: 'end',
			});
		},
		move(direction: number) {
			const current = control.active();
			const all = control.actions();
			if (!current) {
				control.setActive(all[0]);
				return;
			}
			const index = all.indexOf(current);
			const next = all[index + direction];
			control.setActive(next ?? all[direction === 1 ? 0 : all.length - 1]);
		},
		next() {
			return control.move(1);
		},
		back() {
			return control.move(-1);
		},

		show() {
			// No command bar for non-admin users
			if (!userInfo?.auth_groups?.includes('admin')) {
				return;
			}
			setVisible(true);
			const focusInterval = setInterval(() => {
				const inputControl = control.input();
				if (inputControl) {
					inputControl.focus();
					control.setActive(control.actions()[0]);
					clearInterval(focusInterval);
				}
			}, 50);
		},

		hide() {
			setVisible(false);
		},
		async quickSearch(q: string): Promise<QuickSearchResponseType | null> {
			if (!searchContext?.searchClient) {
				return null;
			}
			const params: Partial<SearchParams> = {
				per_page: 5,
			};

			/*
			if (q.length > 1 && q.length < 10 && !q.includes(' ')) {
				params.filter_by = `newstex_id:${q.toUpperCase()}`;
			}
			*/

			const queries: Parameters<typeof searchContext.searchClient.multiSearch>[0] = [
				{
					indexName: 'Publisher',
					query: q,
					sort_by: [
						// Sort by relevance first
						'_text_match:desc',
						// Then by status
						'_eval([ (status:Active):5, (status:Standby):4, (status:Pending):3, (status:Inactive):2, (status:Lead):0 ]):desc',
					].join(','),
					...params,
				},
				{
					indexName: 'Publication',
					query: q,
					sort_by: [
						// Sort by relevance first
						'_text_match:desc',
						// Then by status
						'_eval([ (status:Active):5, (status:Standby):4, (status:Pending):3, (status:Inactive):2, (status:Lead):0 ]):desc',
					].join(','),
					...params,
				},
				{
					indexName: 'HubSpot',
					query: q,
					...params,
				},
			];
			if (q.length > 1) {
				queries.push({
					indexName: 'Categories',
					query: q,
					...params,
				});
			}
			const resp = await searchContext.searchClient.multiSearch<[
				Content,
				HubSpotCompany,
				Category,
				any,
			]>(queries);
			const output: QuickSearchResponseType = {
				Publisher: resp[0] as any,
				Publication: resp[1] as any,
				HubSpot: resp[2] as any,
				Category: resp[3] as any,
				Commands: q.length > 0 ? commands.filter((cmd) => cmd.name.toLowerCase().includes(q.toLowerCase())) : commands,
			};
			// If no results were found, and the query is just a numeric sequence, try doing an hs_object_id lookup
			if (
				q.match(/^[0-9]{9,12}$/)
				&& output
				&& !output.Publisher?.hits?.length
				&& !output.Publication?.hits?.length
				&& !output.HubSpot?.hits?.length
				&& !output.Category?.hits?.length
			) {
				// TODO: Implement this with a fetch
				console.log('BACKUP LOOKUP', q);
			}

			// If there is a Publication record with a newstex_id that EXACTLY matches the query, show that
			if (output.Publication?.hits?.length) {
				const exactMatch = output.Publication.hits.find((hit) => hit.newstex_id === q);
				if (exactMatch) {
					output.exactMatch = exactMatch;
					return output;
				}
			}

			// If there is a Publisher record with a newstex_id that EXACTLY matches the query, show that
			if (output.Publisher?.hits?.length) {
				const exactMatch = output.Publisher.hits.find((hit) => hit.newstex_id === q);
				if (exactMatch) {
					output.exactMatch = exactMatch;
					return output;
				}
			}

			// If there is a Category record with a code that EXACTLY matches the query, show just that
			if (output.Category?.hits?.length) {
				const exactMatch = output.Category.hits.find((hit) => hit.code === q);
				if (exactMatch) {
					output.exactMatch = exactMatch;
					return output;
				}
			}
			return output;
		},
	};

	useEffect(() => {
		const observer = new MutationObserver(() => control.reset());
		// observer.observe(root?.querySelector('[data-element="results"]'), { childList: true });
		return () => observer.disconnect();
	}, [root]);

	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			// Only bind the cmd+k shortcut if the user is an admin
			if (userInfo?.auth_groups?.includes('admin')) {
				// Bind Cmd+K to the command bar
				if (e.key === 'k' && (e.getModifierState('Meta') || e.getModifierState('Control')) && !visible) {
					control.show();
					e.preventDefault();
					return;
				}

				// Bind Cmd+'/' to the AI Chat modal
				if (aiChat && e.key === '/' && (e.getModifierState('Meta') || e.getModifierState('Control')) && !visible && !aiChat.visible) {
					aiChat.show();
					e.preventDefault();
					return;
				}
			}

			if (!visible) return;
			if (e.key === 'ArrowDown') {
				control.next();
				e.preventDefault();
			}

			if (e.key === 'ArrowUp') {
				control.back();
				e.preventDefault();
			}

			if (e.key === 'Enter') {
				control.hide();
				e.preventDefault();
				if (results?.exactMatch) {
					let itemType = 'Content';
					let itemID: string | undefined;
					if (isPublisher(results.exactMatch)) {
						itemType = 'Publisher';
						itemID = results.exactMatch.$id;
					} else if (isPublication(results.exactMatch)) {
						itemType = 'Publication';
						itemID = results.exactMatch.$id;
					} else if (isContent(results.exactMatch)) {
						itemType = results.exactMatch.$type || 'Content';
						itemID = results.exactMatch.$id;
					} else if (isCommand(results.exactMatch)) {
						itemType = 'Command';
						itemID = results.exactMatch.id;
					} else {
						itemType = 'Categories';
						itemID = results.exactMatch.code;
					}

					if (itemID && itemType) {
						navigate(`/${itemType.toLowerCase()}s/${itemID}`, { replace: true });
					}
				} else {
					const itemID = control.active()?.getAttribute('data-id');
					if (itemID) {
						const itemType = control.active()?.getAttribute('data-type');
						if (itemType === 'Command') {
							commands.find((cmd) => cmd.id === itemID)?.handler({
								searchContext,
								setModalData,
								navigate,
								ai,
								aiChat,
							});
						} else if (itemID === 'LOGOUT') {
							navigate('/logout', { replace: true });
						} else if (itemID === 'SEARCH') {
							searchContext.setQuery?.(input);
						} else if (itemType) {
							navigate(`/${itemType.toLowerCase()}s/${itemID}`, { replace: true });
						}
					}
				}
			}

			if (e.key === 'Escape') {
				control.hide();
			}
		};

		window.addEventListener('keydown', handleKeyDown);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [control, navigate, searchContext, visible, input]);

	useEffect(() => {
		const handleClick = (e: HTMLElementEventMap['click']) => {
			const el = e.target as HTMLElement;
			if (el) {
				const closest = el.closest("[data-element='action']");
				const itemID = closest?.getAttribute('data-id');
				if (itemID) {
					const itemType = control.active()?.getAttribute('data-type');
					if (itemType === 'Command') {
						commands.find((cmd) => cmd.id === itemID)?.handler({
							searchContext,
							setModalData,
							navigate,
							ai,
							aiChat,
						});
					} else if (itemID === 'LOGOUT') {
						navigate('/logout', { replace: true });
					} else if (itemID === 'SEARCH') {
						searchContext.setQuery?.(input);
					} else if (itemType) {
						navigate(`/${itemType.toLowerCase()}s/${itemID}`, { replace: true });
					}
					control.hide();
				}
			}
		};

		const handleMouseMove = (e: HTMLElementEventMap['mousemove']) => {
			const el = e.target as HTMLElement;
			if (el) {
				const closest = el.closest("[data-element='action']");
				if (closest && !closest.classList.contains('active')) {
					control.setActive(closest);
				}
			}
		};

		root?.addEventListener('click', handleClick);
		root?.addEventListener('mousemove', handleMouseMove);
		return () => {
			root?.removeEventListener('click', handleClick);
			root?.removeEventListener('mousemove', handleMouseMove);
		};
	}, [control, input, navigate, root, searchContext]);

	useEffect(() => {
		const fetchData = async () => {
			const data = await control.quickSearch(input || '');
			setResults({
				...data,
				Commands: [
					...(data?.Commands || []),
				],
			});
		};

		if (userInfo?.auth_groups?.includes('admin')) {
			fetchData();
		}
	}, [input, userInfo?.auth_groups]);

	const cmd = {
		bind: setRoot,
		inputField: setInputField,
		input,
		setInput,
		setActive: (el: HTMLElement | null) => {
			if (el instanceof HTMLElement) {
				control.setActive(el);
			}
		},
		visible,
		show: control.show,
		hide: control.hide,
		results,
	};

	return (
		<CommandbarContext.Provider value={cmd}>
			{props.children}
			<ModalDataEditor
				modalData={modalData}
				setModalData={setModalData}
			/>
			{cmd.visible && (
				<Modal show={cmd.visible} onHide={cmd.hide} className="commandbar modal-xl">
					<Modal.Header>
						<Modal.Title>
							<InputGroup className="mb-3">
								<InputGroup.Text>
									<FontAwesomeIcon icon={faSearch} />
								</InputGroup.Text>
								<Form.Control
									placeholder="Search"
									aria-label="Search"
									aria-describedby="basic-addon1"
									autoFocus
									ref={cmd.inputField}
									onChange={(e) => cmd.setInput(e.currentTarget.value)}
								/>
							</InputGroup>
						</Modal.Title>
					</Modal.Header>
					{cmd?.results?.exactMatch ? (
						<Modal.Body className="exact-match">
							{isContent(cmd.results.exactMatch) ? (
								<ContentCard item={cmd.results.exactMatch} onClick={cmd.hide}/>
							) : isCommand(cmd.results.exactMatch) ? (
								<div>
									<h3>{cmd.results.exactMatch.name}</h3>
									<p>{cmd.results.exactMatch.description}</p>
								</div>
							) : <CategoryCard item={cmd.results.exactMatch} />}
						</Modal.Body>
					) : (
						<Modal.Body>
							<div className="results container" data-element="results" ref={cmd.bind}>
								{cmd?.results?.Commands?.length ? (
									<>
										<div className="row">
											<div className="col">
												<h3>Commands</h3>
												{cmd.results?.Commands.map((result) => (
													<div
														className="row item"
														data-element="action"
														data-type="Command"
														data-id={result.id}
														key={result.id}
													>
														<div>
															<span>{result.name}</span>
															{result.icon
																&& <span className="float-end">
																	<FontAwesomeIcon icon={result.icon} className="me-2" />
																</span>}
														</div>
														<div className="item-detail">
															{result.description}
														</div>
													</div>
												))}
											</div>
										</div>
										<hr/>
									</>
								) : null }
								{cmd?.results?.Category?.hits?.length ? (
									<>
										<div className="row">
											<div className="col">
												<h3>Categories</h3>
												{cmd.results?.Category.hits.map((result) => (
													<div
														className="row item"
														data-element="action"
														data-type="Category"
														data-id={result.code || result.id}
														key={result.code || result.id}
													>
														<div>
															<span>{result.name}</span>
															<small className="text-body-secondary float-end">
																{result.story_count ? `${result.story_count} stories` : null}
															</small>
														</div>
														<div className="item-detail">
															{result.description}
															<div className="text-body-secondary float-end newstex-id">
																{result.code}
															</div>
														</div>
													</div>
												))}
											</div>
										</div>
										<hr/>
									</>
								) : null}
								<div className="row">
									<div className="col">
										<h3>Publishers</h3>
										<div className="container">
											{cmd.results?.Publisher?.hits?.map((result) => (
												<div
													className="row item"
													data-element="action"
													data-type="Publisher"
													data-id={result.$id || result.id}
													key={result.$id || result.id}
												>
													<div>
														<span>{result.name}</span>
														<small className="text-body-secondary float-end">
															<div className={`status status-${result.status?.toLowerCase()}`}>
																{result.status}
															</div>
														</small>
													</div>
													<div className="item-detail">
														{result.url}
														<div className="text-body-secondary float-end newstex-id">
															{result.newstex_id}
														</div>
													</div>
												</div>
											))}
										</div>
									</div>
									<div className="col">
										<h3>Publications</h3>
										{cmd.results?.Publication?.hits?.map((result) => (
											<div
												className="row item"
												data-element="action"
												data-type="Publication"
												data-id={result.$id || result.id}
												key={result.$id || result.id}
											>
												<div>
													<span>{result.name}</span>
													<small className="text-body-secondary float-end">
														<div className={`status status-${result.status?.toLowerCase()}`}>
															{result.status}
														</div>
													</small>
												</div>
												<div className="item-detail">
													{result.url}
													<div className="text-body-secondary float-end newstex-id">
														{result.newstex_id}
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						</Modal.Body>
					)}
					<Modal.Footer>
						<span className="position-absolute start-0 p-2">
							<kbd>⌘ K</kbd>
						</span>
						<span>
							Navigate with
							<kbd>↑</kbd>
							<kbd>↓</kbd>
						</span>
						<span>
							Select using
							<kbd>Enter</kbd>
						</span>
					</Modal.Footer>
				</Modal>
			)}
		</CommandbarContext.Provider>
	);
}

export function useCommandBar() {
	const ctx = useContext(CommandbarContext);
	if (!ctx) throw new Error('No commandbar context');
	return ctx;
}
