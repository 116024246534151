import { QualificationScores } from '@newstex/ai/qualify';
import type { Story } from '@newstex/types/story';
import { useEffect, useState } from 'react';
import {
	Card,
	Col,
	Container,
	Row,
} from 'react-bootstrap';
import { useAI } from '~/providers/ai-provider';
import { useAPI } from '~/providers/api-provider';

import LoadingSpinner from './LoadingSpinner';

export function AIScoreDebugger({ story }: { story: Story }) {
	const api = useAPI();
	const { qualifyStory } = useAI();
	const [loading, setLoading] = useState<boolean>(false);
	const [scores, setScores] = useState<QualificationScores | null>(null);

	async function fetchScores() {
		if (story?.headline && story?.__id__) {
			setLoading(true);
			const sessionStorageValue = sessionStorage.getItem(`ai-score-debugger-${story.__id__}`);
			if (sessionStorageValue) {
				setScores(JSON.parse(sessionStorageValue));
				setLoading(false);
				return;
			}

			let textContent = story.text_content;
			if (!textContent) {
				const textResp = await api.fetchWithAuthRaw(`resources/Story/${story.__id__}/content.txt`);
				if (textResp.ok) {
					textContent = await textResp.text();
				}
			}

			if (textContent) {
				const resp = await qualifyStory({
					headline: story.headline || '',
					textContent,
				});
				console.log('Scores', resp);
				setScores(resp);
				sessionStorage.setItem(`ai-score-debugger-${story.__id__}`, JSON.stringify(resp));
			}
			setLoading(false);
		}
	}

	useEffect(() => {
		fetchScores();
	}, [story?.headline, story?.text_content]);

	console.log(scores);

	const getHeaderColor = (score: number) => {
		if (score < 1) return 'danger';
		if (score < 3) return 'warning';
		return 'success';
	};

	return <Container fluid>
		<LoadingSpinner loading={loading} />
		{!loading && scores && <>
			<h5>AI Scores</h5>
			<Row>
				{Object.entries(scores).map(([client, score]) => (
					<Col key={client} xs={12} md={6} lg={4}>
						<Card>
							<Card.Header className={getHeaderColor(score.score)}>
								<Card.Title>{client}: {score.score}</Card.Title>
							</Card.Header>
							<Card.Body>
								<div className="reason">{score.reason}</div>
							</Card.Body>
						</Card>
					</Col>
				))}
			</Row>
		</>}
	</Container>;
}
