import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Content, isPublication } from '@newstex/types/content';
import {
	Badge,
	Card,
	Col,
	Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { DeletedAlert } from './deleted-alert';
import { HubSpotLink } from './hubspot-link';
import { PropertyDisplayValue } from './property-display-value';
import { StatusBadge } from './status-badge';
import ViewStoriesButton from './view-stories-button';

interface ContentCardProps {
	item?: Content;
	hitCount?: number;
	onClick?: () => void;
}

export function ContentCard(props: ContentCardProps) {
	if (!props.item) {
		return <></>;
	}

	if (!props.item.$type) {
		console.trace('INVALID TYPE', props.item);
	}
	return (
		<Card className="company h-100">
			<DeletedAlert deleted={props.item?.deleted} />
			<Card.Body>
				<Card.Title>
					{props.item.$type
						&& <Link to={`/${props.item.$type.toLowerCase()}s/${props.item.$id}`} onClick={props.onClick}>
							{props.item.name} [{props.item.newstex_id}]
							{props.hitCount && (
								<>
									&nbsp;
									<span className="badge text-bg-secondary">{props.hitCount}</span>
									&nbsp;
								</>
							)}
						</Link>
					}
					<div className="float-end">
						<HubSpotLink item={props.item} size={28} />
					</div>
					<h6 className="text-muted mb-3">
						{props.item.$type}
						<div className="float-end">
							<StatusBadge status={props.item.status} />
						</div>
					</h6>
				</Card.Title>
				<div className="mb-3">
					<div>{props.item.description}</div>
					{props.item.url && (
						<a className="corporate-website" href={props.item.url} target="_blank">
							{props.item.url} <FontAwesomeIcon icon={faExternalLinkAlt} />
						</a>
					)}
					{props.item.last_post_date && (
						<Row>
							<Col className="property-name" md={4}>Last Post Date</Col>
							<Col className="property-value" md={8}>
								<PropertyDisplayValue propValue={props.item.last_post_date} propName="last_post_date" />
							</Col>
						</Row>
					)}
					{props.item.last_received_date && (
						<Row>
							<Col className="property-name" md={4}>Last Received</Col>
							<Col className="property-value" md={8}>
								<PropertyDisplayValue propValue={props.item.last_received_date} propName="last_received_date" />
							</Col>
						</Row>
					)}
				</div>
			</Card.Body>
			<Card.Footer>
				{isPublication(props.item) && props.item.product_status && (
					<div>
						{props.item?.products?.map((product) => (
							<Badge key={product} className="me-1" bg="success-subtle" text="dark">
								<PropertyDisplayValue propValue={product} propName="product" />
							</Badge>
						))}
					</div>
				)}
				<div className="float-end">
					{props.item.status === 'Active' && props.item.newstex_id && (
						<ViewStoriesButton content={props.item.newstex_id} size="sm" onClick={props.onClick} />
					)}
				</div>
			</Card.Footer>
		</Card>
	);
}
