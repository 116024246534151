import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { LeadData } from '~/providers/api-provider';

interface QualificationStatsState {
	// Stats
	totalItems: number;
	items: LeadData[];
	skippedItems: LeadData[];
	createdRecordCount: number;
	updatedRecordCount: number;

	// Actions
	addItem: (item: LeadData) => void;
	addSkippedItem: (item: LeadData) => void;
	incrementCreatedCount: () => void;
	incrementUpdatedCount: () => void;
	setTotalItems: (count: number) => void;
	reset: () => void;
}

const initialState = {
	totalItems: 0,
	items: [],
	skippedItems: [],
	createdRecordCount: 0,
	updatedRecordCount: 0,
};

export const useQualificationStatsStore = create<QualificationStatsState>()(
	persist(
		(set, get) => ({
			...initialState,

			addItem: (item) => set((state) => ({
				items: [...state.items, item],
			})),

			addSkippedItem: (item) => set((state) => ({
				skippedItems: [...state.skippedItems, item],
			})),

			incrementCreatedCount: () => set((state) => ({
				createdRecordCount: state.createdRecordCount + 1,
			})),

			incrementUpdatedCount: () => set((state) => ({
				updatedRecordCount: state.updatedRecordCount + 1,
			})),

			setTotalItems: (count) => set((state) => ({
				totalItems: (state.totalItems || 0) + count,
			})),

			reset: () => set(initialState),
		}),
		{
			name: 'qualification-stats-store',
			version: 1,
		},
	),
);
