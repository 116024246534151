import type { Story } from '@newstex/types';
import { z } from 'zod';

import { Agent } from './agent';

const QUALIFICATION_SCORE_SCHEMA = z.object({
	score: z.number().describe('The numeric qualification score for the client.'),
	reason: z.string().describe('The reason for the qualification score. Keep this short and concise.'),
});
export type QualificationScore = z.infer<typeof QUALIFICATION_SCORE_SCHEMA>;

const QUALIFICATION_RESULT_SCHEMA = z.object({
	scores: z.record(z.string(), QUALIFICATION_SCORE_SCHEMA).describe('A map of client IDs to their qualification scores'),
	matched_criteria: z.array(z.string()).describe('List of criteria that were matched. ONLY INCLUDE CRITERIA FROM THE SET CRITERIA.'),
	improvements: z.array(z.string()).describe('List of improvements that could be made to the publication to improve its qualification score.'),
});

export type QualificationResult = z.infer<typeof QUALIFICATION_RESULT_SCHEMA>;

export interface ContentMetadata {
	/**
	 * The URL of the content being qualified
	 */
	url: string;
	/**
	 * The name of the content source
	 */
	name: string;
	/**
	 * A description of the content source
	 */
	description?: string;
	/**
	 * Sample articles from the content source
	 */
	articles?: {
		title: string;
		author?: string;
		summary?: string;
		categories?: Record<string, number>;
	}[];
}

/**
 * Agent responsible for qualifying content against specified criteria
 */
export class QualificationAgent extends Agent {
	private criteria?: string;

	/**
	 * Set the qualification criteria for this agent
	 */
	public setCriteria(criteria: string): void {
		this.criteria = criteria;
	}

	/**
	 * Format content metadata into a prompt
	 */
	private formatContentPrompt(content: ContentMetadata): string[] {
		const prompt = [
			'Given the following publication, please provide qualification scores based on your criteria',
			`URL: ${content.url}`,
			`Name: ${content.name}`,
		];

		if (content.description) {
			prompt.push(`Description: ${content.description}`);
		}

		if (content.articles?.length) {
			prompt.push('List of sample articles below');
			prompt.push('');

			for (const article of content.articles.slice(0, 5)) {
				const articleData: string[] = [];
				articleData.push(`Headline: "${article.title}"`);

				if (article.author) {
					articleData.push(`By: "${article.author}"`);
				}

				if (article.categories) {
					articleData.push('Category Scores:');
					for (const [category, score] of Object.entries(article.categories)
						.sort((a, b) => b[1] - a[1])
						.slice(0, 5)
					) {
						articleData.push(`  - ${category}: ${score}`);
					}
				}

				if (article.summary) {
					articleData.push('_______________________');
					articleData.push(`"${article.summary.slice(0, 500)}"`);
					articleData.push('_______________________');
				}

				prompt.push(articleData.join('\n'));
			}
		}

		return prompt;
	}

	/**
	 * Qualify content against the set criteria
	 */
	public async qualifyContent(content: ContentMetadata): Promise<QualificationResult> {
		if (!this.criteria) {
			throw new Error('No criteria set. Call setCriteria() before qualifying content.');
		}

		const prompt = this.formatContentPrompt(content);

		return this.generateObject(
			QUALIFICATION_RESULT_SCHEMA,
			`You are a publication qualification system. You are given a publication and a criteria, and you need to provide scores and matching criteria,
			as well as suggestions for improvements to the publication.

			Criteria: ${this.criteria}
			_______________________
			ONLY RETURN SCORES FOR CLIENTS THAT ARE DEFINED IN YOUR CONTEXT. Do not respond with any other client scores.`,
			[{
				role: 'user',
				content: prompt.join('\n'),
			}],
		);
	}

	/**
	 * Qualify a publication by its ID
	 *
	 * @param publicationId The ID of the publication to qualify
	 * @param options Optional parameters for qualification
	 * @returns Qualification results
	 */
	public async qualifyPublicationById(
		publicationId: string,
		options: {
			/**
			 * Number of recent stories to analyze (default: 5)
			 */
			numStories?: number;
			/**
			 * Whether to include inactive stories (default: false)
			 */
			includeInactive?: boolean;
		} = {},
	): Promise<QualificationResult> {
		const {
			numStories = 5,
			includeInactive = false,
		} = options;

		// Load the publication
		const publication = await this.getPublication(publicationId);
		if (!publication) {
			throw new Error(`Publication not found: ${publicationId}`);
		}

		// Query recent stories
		const stories = await this.query<Story>({
			TableName: 'Story',
			IndexName: 'publication-received_at-index',
			KeyConditionExpression: '#publication = :publication',
			ExpressionAttributeNames: {
				'#publication': 'publication',
				...(includeInactive ? {} : { '#status': 'status' }),
			},
			ExpressionAttributeValues: {
				':publication': publicationId,
				...(includeInactive ? {} : { ':status': 'Active' }),
			},
			...(includeInactive ? {} : {
				FilterExpression: '#status = :status',
			}),
			ScanIndexForward: false, // Sort by received_at in descending order
			Limit: numStories,
		});

		// Format the content metadata
		const content: ContentMetadata = {
			url: publication.url,
			name: publication.name,
			description: publication.description,
			articles: stories.map((story) => ({
				title: story.headline,
				author: story.source,
				summary: story.excerpt,
				categories: story.category_scores,
			})),
		};

		// Qualify the content
		return this.qualifyContent(content);
	}
}
