import type { ContentProductStatus, Publication } from '@newstex/types/content';
import { Product } from '@newstex/types/product';
import { Results } from '@newstex/types/results';
import Case from 'case';
import React, { useEffect, useState } from 'react';
import {
	Badge,
	Card,
	Col,
	Row,
} from 'react-bootstrap';
import { useAPI } from '~/providers/api-provider';

const BG_STATUS_MAPPING = {
	Approved: 'success',
	Rejected: 'danger',
	Lead: 'info',
	'': 'primary',
} as const;

export const ProductStatusDisplay: React.FC<{ code: string, name?: string } & ContentProductStatus> = (props) => {
	const statusClassName = BG_STATUS_MAPPING[props.status || ''];
	return <Badge
		bg={`${statusClassName}-subtle`}
		text="dark"
		title={`${props.name || props.code}: ${props.status}`}
	>
		<strong>{props.name || props.code}</strong>
	</Badge>;
};
type ProductWithStatus = Product & { status: ContentProductStatus['status'] };

export const ProductStatusList: React.FC<{
	value: Publication['product_status'],
	inline?: boolean,
}> = (props) => {
	if (typeof props.value === 'string') {
		return <ProductStatusList value={JSON.parse(props.value)} inline={props.inline} />;
	}

	const [productGroups, setProductGroups] = useState<Record<string, ProductWithStatus[]>>({});
	const api = useAPI();
	useEffect(() => {
		const productList: Promise<ProductWithStatus>[] = [];
		for (const [code, status] of Object.entries(props.value || {})) {
			if (code.match(/^[0-9]{1,5}$/)) {
				console.log('Skipping product', {
					code,
					status,
					props,
				});
				continue;
			}
			productList.push(api.fetchWithAuth<Results<Product>>(`resources/Product/${code}`).then((product) => ({
				...product.items[0],
				status: status.status,
			})));
		}
		Promise.all(productList).then((results) => {
			const productsByGroup: Record<string, ProductWithStatus[]> = {};
			for (const product of results) {
				const group = product.group || 'default';
				// Ignore archived and deleted groups
				if (group.toLowerCase() === 'archived' || group.toLowerCase() === 'deleted') {
					continue;
				}

				if (!productsByGroup[group]) {
					productsByGroup[group] = [];
				}
				productsByGroup[group].push(product);
			}
			// Sort each group by "Approved" first
			for (const group of Object.keys(productsByGroup)) {
				productsByGroup[group].sort((statusA, statusB) => {
					// Sort Approved first
					if (statusA.status === 'Approved' && statusB.status !== 'Approved') {
						return -1;
					}

					if (statusB.status === 'Approved' && statusA.status !== 'Approved') {
						return 1;
					}

					return statusA.$id.localeCompare(statusB.$id);
				});
			}
			setProductGroups(productsByGroup);
		});
	}, [props.value]);

	if (props.inline) {
		return <Row>
			{Object.entries(productGroups)
				.sort(([groupA], [groupB]) => groupA.localeCompare(groupB))
				.map(([group, products]) => (
					<Col key={group}>
						<Card>
							<Card.Header style={{
								textAlign: 'center',
								margin: '0',
								padding: '0.5rem',
							}}>
								<b>{group === 'aci' ? 'ACI' : Case.title(group)}</b>
							</Card.Header>
							<Card.Body style={{
								textAlign: 'center',
								padding: '0.5rem',
							}}>
								<ul className={`list-inline group-${group}`} style={{
									marginBottom: '0',
								}}>
									{products.map((product) => (
										<li
											key={`status-${product.$id}`}
											className="list-inline-item"
										>
											<ProductStatusDisplay
												code={product.$id}
												name={product.name}
												status={product.status}
											/>
										</li>
									))}
								</ul>
							</Card.Body>
						</Card>
					</Col>
				))}
		</Row>;
	}

	return <ul className="list-group fs-6">
		{Object.entries(props.value || {}).map(([code, status]) => (
			<li
				key={`status-${code}`}
				className={`list-group-item list-group-item-${BG_STATUS_MAPPING[status.status || '']}`}
			>
				<b>{code}</b>:&nbsp;{status.status}
			</li>
		))}
	</ul>;
};
