import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type Story } from '@newstex/types/story';
import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { PropertyDisplayValue } from '../property-display-value';

export const StoryCard: React.FC<{
	item?: Story;
}> = ({
	item,
}) => {
	if (!item) {
		return <></>;
	}

	return (
		<Card className="story h-100">
			<Card.Body>
				<Card.Title className="">
					<div>
						<Link to={`/story/${item.__id__}`}>
							{item.headline}
						</Link>
						{item.categories?.includes('SPAM') ? <span className="badge bg-danger ms-2">SPAM</span> : null}
						{item.categories?.includes('PRHYPE') ? <span className="badge bg-danger ms-2">PR Hype</span> : null}
						{item.categories?.includes('GUESTPOST') ? <span className="badge bg-danger ms-2">Guest Post</span> : null}
					</div>
					<h6 className="text-muted">
						{item.__id__}
					</h6>
				</Card.Title>
				<div>
					<div className="dateline text-muted mb-2" style={{ fontStyle: 'italic' }}>
						<PropertyDisplayValue propName="date" propValue={item.date} />
						{item.source ? ` by ${item.source}` : null }
						&nbsp;&mdash;&nbsp;
						<PropertyDisplayValue propName="publication" propValue={item.publication} />
					</div>
					<div className="excerpt">
						{item.excerpt}...
					</div>
				</div>
			</Card.Body>
			<Card.Footer>
				{item.permalink && (
					<Link className="text-muted" to={item.permalink} target="_blank">
						{item.permalink} <FontAwesomeIcon icon={faExternalLinkAlt} />
					</Link>
				)}
			</Card.Footer>
		</Card>
	);
};
