import type { StatusChoice } from '@newstex/types/status';
import { Badge } from 'react-bootstrap';

const BG_STATUS_MAPPING: Record<StatusChoice, string> = {
	Active: 'success',
	Inactive: 'danger',
	Archived: 'danger',
	Removed: 'danger',
	Deleted: 'warning',
	Standby: 'warning',
	Lead: 'info',
};

export function StatusBadge(props: {
	indicator?: boolean,
	status?: StatusChoice,
	name?: string,
}) {
	if (props?.status) {
		const statusClassName = BG_STATUS_MAPPING[props.status || 'Lead'];
		return (
			<Badge bg={`${statusClassName}-subtle`} text="dark">
				<b>{props.name || props.status}</b>
				{props.indicator ? <span className={`indicator right text-${statusClassName}`} /> : <></>}
			</Badge>
		);
	}
	return <></>;
}
