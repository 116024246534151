export type SVGIconsName =
	'24h-assistance-1'
	| 'add-1'
	| 'add-folder-1'
	| 'ai-wand'
	| 'airplane-mode-1'
	| 'angle-brackets-1'
	| 'arrow-left-1'
	| 'arrow-right-1'
	| 'arrow-target-1'
	| 'attachment-1'
	| 'attachment-2'
	| 'backpack-1'
	| 'ballpoint-pen-1'
	| 'bank-cards-1'
	| 'beach-1'
	| 'bike-1'
	| 'bin-1'
	| 'bitcoin-1'
	| 'bookmark-1'
	| 'bookmark-ribbon-1'
	| 'british-pound-badge-1'
	| 'british-pound-sign-1'
	| 'browser-window-1'
	| 'calls-1'
	| 'camera-1'
	| 'camping-1'
	| 'cart-1'
	| 'cell-phone-signal-1'
	| 'chart-1'
	| 'chat-bubble-1'
	| 'chats-1'
	| 'checked-window-1'
	| 'checkmark-1'
	| 'close-1'
	| 'code-folder-1'
	| 'color-palette-1'
	| 'configuration-1'
	| 'credit-card-1'
	| 'css3-1'
	| 'cutlery-1'
	| 'deadline-1'
	| 'delivery-time-1'
	| 'delivery-truck-1'
	| 'design-1'
	| 'destination-map-1'
	| 'disable-1'
	| 'disable-2'
	| 'discount-1'
	| 'document-saved-1'
	| 'dollar-badge-1'
	| 'dollar-sign-1'
	| 'envelope-1'
	| 'excavator-1'
	| 'exit-1'
	| 'file-storage-1'
	| 'filter-data-1'
	| 'find-1'
	| 'first-aid-kit-1'
	| 'food-1'
	| 'gear-1'
	| 'giftbox-1'
	| 'glasses-1'
	| 'grid-1'
	| 'hamburger-1'
	| 'heartbeat-1'
	| 'helpline-24h-1'
	| 'hidden-file-1'
	| 'hospital-1'
	| 'hot-coffee-1'
	| 'html5-1'
	| 'imac-screen-1'
	| 'image-1'
	| 'images-1'
	| 'info-card'
	| 'iphone-1'
	| 'key-1'
	| 'keyboard-1'
	| 'label-tag-1'
	| 'landline-1'
	| 'laptop-screen-1'
	| 'list-details-1'
	| 'literature-1'
	| 'location-pin-1'
	| 'locomotive-1'
	| 'man-1'
	| 'map-marker-1'
	| 'map-pin-1'
	| 'mental-health-1'
	| 'mental-illness-1'
	| 'menu-1'
	| 'menu-hamburger-1'
	| 'message-bubble-1'
	| 'minus-1'
	| 'money-box-1'
	| 'more-1'
	| 'mountain-1'
	| 'movie-camera-1'
	| 'muffin-1'
	| 'multiple-windows-1'
	| 'new-1'
	| 'new-window-1'
	| 'new-window-2'
	| 'news-1'
	| 'numbers-1'
	| 'page-1'
	| 'paper-bag-1'
	| 'paper-plane-1'
	| 'paper-stack-1'
	| 'paperback-book-1'
	| 'pay-1'
	| 'pay-by-card-1'
	| 'pen-1'
	| 'pencil-1'
	| 'pie-chart-1'
	| 'pin-1'
	| 'play-button-1'
	| 'play-video-1'
	| 'portfolio-grid-1'
	| 'portfolio-grid-2'
	| 'property-1'
	| 'quality-1'
	| 'question-help-1'
	| 'reading-1'
	| 'real-estate-1'
	| 'retail-bag-1'
	| 'retro-camera-1'
	| 'road-1'
	| 'rotate-left-1'
	| 'rss-1'
	| 'sales-performance-up-1'
	| 'sales-up-1'
	| 'sand-clock-1'
	| 'sandwich-1'
	| 'save-1'
	| 'save-file-1'
	| 'scissor-1'
	| 'scooter-1'
	| 'screwdriver-1'
	| 'search-1'
	| 'security-1'
	| 'security-shield-1'
	| 'sedan-1'
	| 'settings-1'
	| 'settings-2'
	| 'share-1'
	| 'shield-security-1'
	| 'shopping-bag-1'
	| 'shopping-basket-1'
	| 'shopping-cart-1'
	| 'shutdown-1'
	| 'smartphone-1'
	| 'sorting-1'
	| 'speaker-call-1'
	| 'special-price-1'
	| 'speech-bubble-1'
	| 'speed-1'
	| 'speedometer-1'
	| 'stack-1'
	| 'star-medal-1'
	| 'statistic-1'
	| 'store-1'
	| 'strategy-1'
	| 'strawberry-1'
	| 'stream-video-1'
	| 'sun-1'
	| 'survey-1'
	| 'swimming-pool-1'
	| 'table-content-1'
	| 'tag-1'
	| 'target-1'
	| 'tea-cup-1'
	| 'tea-mug-1'
	| 'text-checked-1'
	| 'text-message-1'
	| 'time-1'
	| 'tractor-1'
	| 'tv-1'
	| 'unlocked-1'
	| 'us-dollar-1'
	| 'user-1'
	| 'vault-1'
	| 'view-1'
	| 'wheel-loader-1'
	| 'white-wine-1'
	| 'wireframe-1'
	| 'woman-1'
	| 'world-map-1'
	| 'x-shield'
	| 'zoom-1'
	| 'zoom-in-1'
	| 'zoom-out-1';

const SvgIcons = () => (
	<div style={{ position: 'absolute' }}>
		<svg width="0" height="0" className="hidden">
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="close-1">
				<title>Close</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M41.999 20.002l-22 22m22 0L20 20"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="checkmark-1">
				<title>Checkmark</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M16 33l11 11 21-22"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="rotate-left-1">
				<title>Rotate Left</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M10.168 34.947a26.016 26.016 0 1 1 7.45 15.432" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M2 23l8.168 11.947L20.986 25" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="portfolio-grid-1">
				<title>Portfolio Grid</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M2 2h12v12H2zm24 0h12v12H26zm24 0h12v12H50zM2 50h12v12H2z"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M26 50h12v12H26zm24 0h12v12H50z" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M2 25.988h12v12H2z" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M26 25.988h12v12H26zm24 0h12v12H50z" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="more-1">
				<title>More</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<circle data-name="layer3"
					cx="8" cy="32" r="6" fill="none" stroke="#202020" strokeMiterlimit="10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer2" cx="56" cy="32" r="6" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer1" cx="32" cy="32" r="6" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="speed-1">
				<title>Speed</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<circle data-name="layer2"
					cx="32" cy="32" r="30" fill="none" stroke="#202020" strokeMiterlimit="10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M8 32h6M32 8v5.999M50 32h6M45 20l4-4" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M15.183 16.544A.975.975 0 0 1 15 16a1 1 0 0 1 1.489-.873l18.748 11.821a5.999 5.999 0 1 1-8.153 8.486z"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="security-shield-1">
				<title>Security Shield</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M6 2h52v22.064A41.973 41.973 0 0 1 31.994 62 41.97 41.97 0 0 1 6 24.064z"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M22 27l9 9 15-16" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="x-shield">
				<title>Shield with X</title>
				<desc>A Security Shield with the close Icon instead of a check</desc>
				<path data-name="layer1"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M41.999 20.002l-22 22m22 0L20 20"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer2"
					d="M6 2h52v22.064A41.973 41.973 0 0 1 31.994 62 41.97 41.97 0 0 1 6 24.064z"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="food-1">
				<title>Food</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M50.5 37v23a2 2 0 0 1-2 2 2 2 0 0 1-2-2V37" fill="none" stroke="#202020"
					strokeLinecap="round" strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeLinecap="round"
					strokeLinejoin="round" d="M18.5 17V2m4 0v15m4-15h-12l-1 20c0 3.2 2.1 5.2 5 5.8V60a2 2 0 0 0 4 0V27.8c2.9-.6 5-2.6 5-5.8zm24 35V2a10 10 0 0 0-10 10v20a5 5 0 0 0 5 5z"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" id="hot-coffee-1">
				<title>Hot Coffee</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path
					strokeLinejoin="round" strokeLinecap="round" stroke="#202020" fill="none"
					d="M52 17.965v22.857c0 9.467-9.582 17.143-19.049 17.143h-1.9C21.58 57.965 12 50.289 12 40.822V17.965zM52 22h1.016A8.984 8.984 0 0 1 62 30.982a8.982 8.982 0 0 1-8.982 8.982H52"
					data-name="layer2" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path d="M32 13.998v-12m-12.002 12V8.002m24.004 5.996V8.002M62 60a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2 2 2 0 0 1 2-2h56a2 2 0 0 1 2 2z"
					strokeLinejoin="round" strokeLinecap="round" stroke="#202020"
					fill="none" data-name="layer1" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="tea-cup-1">
				<title>Tea Cup</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					d="M16 3v16m6 16V25a6 6 0 0 0-6-6 6 6 0 0 0-6 6v10z" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M48 3v54a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V3zm0 10a14 14 0 0 1 14 14v4a14 14 0 0 1-14 14"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M48 41a10 10 0 0 0 10-10v-4a10 10 0 0 0-10-10"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="muffin-1">
				<title>Muffin</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M10.5 36.5s2.6 20.7 2.8 23.1A2.4 2.4 0 0 0 16 62h31.9a2.4 2.4 0 0 0 2.6-2.4c.3-2.4 2.9-23.4 2.9-23.4M20 46l1.6 16M44 46l-1.6 16M32 46v16"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M32 2C18.3 2 6 16.7 6 28s11.3 10 16 10h24.1c4.8 0 11.7-2 11.9-10S50.5 2 32 2zM20 20l-2 2m14-2l-2 2m-2-10l-2 2m14-2l-2 2m6 6l-2 2"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" id="man-1">
				<title>Man</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path
					strokeMiterlimit="10" stroke="#202020" fill="none" d="M41.713 48.744c-1.968-1.476-1.71-3.069-1.71-4.244v-4m-16 1v3c0 1.285.425 3.083-2.194 4.67"
					data-name="layer2" strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path strokeMiterlimit="10" stroke="#202020"
					fill="none" d="M37.601 42.5a16.786 16.786 0 0 0 7.168-10.135c3.026-.037 4.234-2.931 4.234-6.937 0-1.994 0-4.928-3-4.928v-2m-29 0v2.006c-2-.006-3 2.994-3 4.988 0 4.006 1.391 7.006 4.436 7.006h.002a15.21 15.21 0 0 0 7.17 10m20.392-24v-2c0-10.4-7.373-14-14-14s-15 3.517-15 13.916V18.5c12 0 13-8 21-8 7.459 0 8 8 8 8z"
					data-name="layer2" strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path strokeMiterlimit="10" stroke="#202020"
					fill="none" d="M14.003 51.5c-12.61 2-12.001 11-12.001 11h30L21.809 49.17c-1.458.885-3.856 1.703-7.806 2.33zm36 0c-4.436-.74-6.91-1.72-8.29-2.756L32.002 62.5h30s0-9-12-11z"
					data-name="layer1" strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" id="woman-1">
				<title>Woman</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path
					strokeMiterlimit="10" stroke="#202020" fill="none" d="M32 2c-6.627 0-13.992 4-13.992 14.399V26c0 7.18 3.27 13.379 8 16.267m11.984 0c4.73-2.888 8-9.087 8-16.267v-9.601C45.992 6 38.627 2 32 2"
					data-name="layer2" strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path strokeMiterlimit="10" stroke="#202020"
					fill="none" d="M46 18C26 18 26 3.174 26 3.174S27.416 20 18 20m27.473 30.357C39.003 48.835 39 45.697 39 44v-2M28 62h8M25 42v2c0 1.684-.002 4.813-6.794 6.333M18.008 26S17 42 13 46c4 4 4.926 4.394 4.926 4.394"
					data-name="layer2" strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path strokeMiterlimit="10" stroke="#202020"
					fill="none" d="M45.992 26S47 42 51 46c-4 4-6.095 4.217-6.095 4.217" data-name="layer2"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path strokeMiterlimit="10" stroke="#202020"
					fill="none" d="M62 62a13.788 13.788 0 0 0-12.422-11 30.574 30.574 0 0 1-4.105-.643L36 62zM13.61 51A12.46 12.46 0 0 0 2 62h26l-9.794-11.667a34.259 34.259 0 0 1-4.597.667z"
					data-name="layer1" strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="user-1">
				<title>User</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					d="M46 26c0 6.1-3.4 11.5-7 14.8V44c0 2 1 5.1 11 7a15.5 15.5 0 0 1 12 11H2a13.4 13.4 0 0 1 11-11c10-1.8 12-5 12-7v-3.2c-3.6-3.3-7-8.6-7-14.8v-9.6C18 6 25.4 2 32 2s14 4 14 14.4z"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="quality-1">
				<title>Quality</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M19 38.9l-3 23 16-6 16 6-2.9-23M32 45.8v10.1"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer1" cx="32" cy="23.9" r="12" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M32 4.7l4.6-2.6 3.1 4.2 5.3-.5 1.1 5.2 5.1 1.6-1.1 5.2 3.9 3.6-3.1 4.3 2.1 4.8-4.5 2.7-.1 5.3-5.2.6-2.2 4.8-5-1.6-4 3.5-4-3.5-5 1.6-2.2-4.8-5.2-.6-.1-5.3-4.5-2.7 2.1-4.8-3.1-4.3 3.9-3.6-1.1-5.2 5.1-1.6L19 5.8l5.3.5 3.1-4.2L32 4.7z"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="reading-1">
				<title>Reading</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					d="M54 12h8v44H42c-6 0-10 2-10 3.8" fill="none" stroke="#202020" strokeMiterlimit="10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer2" d="M32 16c0-3.6 4-12 12.6-12H54v46H43.9C37.8 50 32 54.2 32 60"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer1" d="M32 16c0-2-4-4-14-4H2v44h20c6 0 10 2 10 3.8z"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="news-1">
				<title>News</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					d="M32 16C32 8 16 6 2 6v44c14 0 30 2 30 10z" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer2" d="M24 18.5a60.7 60.7 0 0 0-14-2.3M24 26a60.7 60.7 0 0 0-14-2.3M24 34a60.7 60.7 0 0 0-14-2.3m14 9.8a60.7 60.7 0 0 0-14-2.3m30-20.7a60.7 60.7 0 0 1 14-2.3M40 26a60.7 60.7 0 0 1 14-2.3M40 34a60.7 60.7 0 0 1 14-2.3m-14 9.8a60.7 60.7 0 0 1 14-2.3"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer1" d="M32 16C32 8 48 6 62 6v44c-14 0-30 2-30 10"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="sun-1">
				<title>Sun</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<circle data-name="layer2"
					cx="32" cy="32" r="14" fill="none" stroke="#202020" strokeMiterlimit="10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M32 2v8m0 44v8m30-30h-8m-44 0H2m8.8-21.2l5.6 5.6m31.2 31.2l5.6 5.6m0-42.4l-5.6 5.6M16.4 47.6l-5.6 5.6"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="camera-1">
				<title>Camera</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M59 17H46.6L40 9H24l-6.6 8H5a3 3 0 0 0-3 3v30a3 3 0 0 0 3 3h54a3 3 0 0 0 3-3V20a3 3 0 0 0-3-3z"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer1" cx="32" cy="33" r="14" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer1" cx="54" cy="25" r="1" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer1" cx="32" cy="33" r="6" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="retro-camera-1">
				<title>Retro Camera</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M5 16v-6h10v6"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M20 12v4H2v36h60V12H20zM2 22h30m0 0h30" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer1" cx="32" cy="34" r="12" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer1" cx="32" cy="34" r="4" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer1" d="M55 30a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2 2 2 0 0 1 2-2h2a2 2 0 0 1 2 2z"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="play-button-1">
				<title>Play Button</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<rect data-name="layer2"
					x="2" y="12" width="60" height="40" rx="10.4" ry="10.4" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></rect>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M24 22v20l20-10-20-10z" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="tv-1">
				<title>TV</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M20.7 22a12 12 0 0 1 22.6 0" fill="none" stroke="#202020" strokeMiterlimit="10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<rect data-name="layer1" x="2" y="22" width="60" height="40" rx="2"
					ry="2" fill="none" stroke="#202020" strokeMiterlimit="10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></rect>
				<path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M25.8 15.8L12.1 2.1m26.1 13.7L51.9 2.1" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer2" cx="54" cy="38" r="1" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer2" cx="54" cy="45" r="1" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer2" cx="54" cy="30" r="2" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<rect data-name="layer1" x="6" y="26" width="40" height="29"
					rx="3" ry="3" fill="none" stroke="#202020" strokeMiterlimit="10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></rect>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="time-1">
				<title>Time</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<circle data-name="layer2"
					cx="32" cy="32" r="30" fill="none" stroke="#202020" strokeLinecap="round"
					strokeMiterlimit="10" strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer1" fill="none" stroke="#202020" strokeLinecap="round"
					strokeMiterlimit="10" d="M32 10v23l13 7" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" id="deadline-1">
				<title>Deadline</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path
					strokeMiterlimit="10" strokeLinecap="round" stroke="#202020" fill="none"
					d="M29.375 35.625L16.293 18.707a1.007 1.007 0 0 1 1.414-1.414l16.918 13.082A3.739 3.739 0 0 1 30 36.254a3.914 3.914 0 0 1-.625-.629z"
					data-name="layer2" strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path strokeMiterlimit="10" strokeLinecap="round"
					stroke="#202020" fill="none" d="M10.787 10.787A30 30 0 1 0 32 2v13" data-name="layer1"
					strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="heartbeat-1">
				<title>Heartbeat</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M54.132 37.978c-7.756 12.073-22.055 20.058-22.055 20.058S18.218 50.34 10.33 39M7.9 35A23.91 23.91 0 0 1 5 24C5 5.04 28 5 32 19c4-14 27-14 27 4a25.548 25.548 0 0 1-2.644 11"
					fill="none" stroke="#202020" strokeLinecap="round" strokeMiterlimit="10"
					strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M2 35h14.76a.5.5 0 0 0 .39-.188l3.437-4.3a.5.5 0 0 1 .8.022l4.166 5.833a.5.5 0 0 0 .839-.039l6.1-10.459a.5.5 0 0 1 .894.062l6.2 15.049a.5.5 0 0 0 .91.033l3.371-6.741a.5.5 0 0 1 .442-.272H62"
					fill="none" stroke="#202020" strokeLinecap="round" strokeMiterlimit="10"
					strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="first-aid-kit-1">
				<title>First Aid Kit</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeLinecap="round" strokeMiterlimit="10"
					d="M43 33h-8v-8h-6v8h-8v6h8v8h6v-8h8v-6z" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<rect data-name="layer1" x="2" y="15" width="60" height="42" rx="6"
					ry="6" fill="none" stroke="#202020" strokeLinecap="round" strokeMiterlimit="10"
					strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></rect>
				<path data-name="layer1" d="M23 15v-4a4 4 0 0 1 4-4h10a4 4 0 0 1 4 4v4"
					fill="none" stroke="#202020" strokeLinecap="round" strokeMiterlimit="10"
					strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="hospital-1">
				<title>Hospital</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeLinecap="round" strokeMiterlimit="10"
					d="M6 56V16m52 0v40" strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeLinecap="round"
					strokeMiterlimit="10" d="M23 45h18v16H23z" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer2" fill="none" stroke="#202020" strokeLinecap="round"
					strokeMiterlimit="10" d="M23 61H2v-5h21m18 0h21v5H41"
					strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeLinecap="round"
					strokeMiterlimit="10" d="M32 45v16" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer2" fill="none" stroke="#202020" strokeLinecap="round"
					strokeMiterlimit="10" d="M22.756 16H2v-6h20.063m19.874 0H62v6H41.244M11 21h6v6h-6zm36 0h6v6h-6zM11 33h6v6h-6zm0 12h6v6h-6zm36 0h6v6h-6zM23 33h6v6h-6zm12 0h6v6h-6zm12 0h6v6h-6zM29 21.542V27h-6v-6h4.636m8.728 0H41v6h-6v-5.458"
					strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer1" cx="32" cy="12" r="10" fill="none" stroke="#202020"
					strokeLinecap="round" strokeMiterlimit="10" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer1" fill="none" stroke="#202020" strokeLinecap="round"
					strokeMiterlimit="10" d="M32 8v8m-4-4h8" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" id="map-pin-1">
				<title>Map Pin</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path
					strokeMiterlimit="10" stroke="#202020" fill="none" d="M32 2a20 20 0 0 0-20 20c0 18 20 39 20 39s20-21 20-39A20 20 0 0 0 32 2z"
					data-name="layer1" strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" id="pin-1">
				<title>Pin</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path
					strokeMiterlimit="10" stroke="#202020" fill="none" d="M32 2a20 20 0 0 0-20 20c0 18 20 39 20 39s20-21 20-39A20 20 0 0 0 32 2z"
					data-name="layer2" strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle strokeMiterlimit="10" stroke="#202020"
					fill="none" r="8" cy="22" cx="32" data-name="layer1" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
			</symbol>
			<symbol viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" id="map-marker-1">
				<title>Map Marker</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path d="M43.2 48.1l-7.2-4L18 52 2 44.1V4l16 7.9L36 3l18 8.9v20.3M36 44.1V3M18 52V11.9"
					strokeMiterlimit="10" stroke="#202020" fill="none" data-name="layer2"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path strokeMiterlimit="10" stroke="#202020"
					fill="none" d="M52 32a10 10 0 0 0-10 10c0 9 10 19 10 19s10-10 10-19a10 10 0 0 0-10-10z"
					data-name="layer1" strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle strokeMiterlimit="10" stroke="#202020"
					fill="none" r="3" cy="42" cx="52" data-name="layer1" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="cart-1">
				<title>Cart</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M2 6h10l10 40h32l8-24H16"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer1" cx="23" cy="54" r="4" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer1" cx="49" cy="54" r="4" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="shopping-cart-1">
				<title>Shopping Cart</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<circle data-name="layer2"
					cx="23" cy="56" r="4" fill="none" stroke="#202020" strokeMiterlimit="10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer2" cx="49" cy="56" r="4" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer1" d="M3 4h8l4 16h46l-8 24H23a4 4 0 0 0-4 4 4 4 0 0 0 4 4h32"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="paper-bag-1">
				<title>Paper Bag</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M8 22h48v40H8z"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M22 26V12A10 10 0 0 1 32 2a10 10 0 0 1 10 10v14"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="retail-bag-1">
				<title>Retail Bag</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M4 14h56v48H4zm2 0V2h52v12"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M6.2 2.1L16 8 6 14M57.8 2.1L48 8l10 6" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M42 24a10 10 0 0 1-20 0" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" id="dollar-sign-1">
				<title>Dollar Sign</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path
					strokeMiterlimit="10" stroke="#202020" fill="none" d="M39.889 24A8 8 0 0 0 32 17.334c-4.418 0-8 2.914-8 7.333s4 6.42 8 7.334c4 .9 8 2.917 8 7.334s-3.582 7.333-8 7.333a8 8 0 0 1-8-8"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path d="M32 17.334v-5.335m0 40.003v-5.335" strokeMiterlimit="10"
					stroke="#202020" fill="none" data-name="layer2" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle strokeMiterlimit="10" stroke="#202020"
					fill="none" r="30" cy="32" cx="32" data-name="layer1" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
			</symbol>
			<symbol viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" id="dollar-badge-1">
				<title>Dollar Badge</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path
					strokeMiterlimit="10" stroke="#202020" fill="none" d="M39.889 24A8 8 0 0 0 32 17.334c-4.418 0-8 2.914-8 7.333s4 6.42 8 7.334c4 .9 8 2.917 8 7.334s-3.582 7.333-8 7.333a8 8 0 0 1-8-8"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path d="M32 17.334v-5.335m0 40.003v-5.335m0-42.196l5.967-2.472 4.568 4.567 6.459.001 2.471 5.967 5.969 2.473v6.458L62 26.032l-2.471 5.967L62 37.967l-4.566 4.568v6.458l-5.969 2.472-2.471 5.967-6.459.001-4.568 4.568L32 59.529l-5.967 2.472-4.568-4.568-6.459-.001-2.471-5.967-5.969-2.472v-6.458L2 37.967l2.471-5.968L2 26.032l4.566-4.567v-6.458l5.969-2.473 2.471-5.967 6.459-.001 4.568-4.567L32 4.471z"
					strokeMiterlimit="10" stroke="#202020" fill="none" data-name="layer2"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="credit-card-1">
				<title>Credit Card</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M62 16a3 3 0 0 0-3-3H5a3 3 0 0 0-3 3v7h60zM2 23v25a3 3 0 0 0 3 3h54a3 3 0 0 0 3-3V23"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M10 31h20m18 0h6m-44 8h8" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="pay-by-card-1">
				<title>Pay by Card</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					d="M40 20l8.8 8.7C51.2 31.1 52 34.2 52 40c0 8.6-.1 22-.1 22M22 44a20 20 0 0 0 3.3 11.5C28.1 60 28 61.3 28 62"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer2" d="M29.8 44H14a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h24a2 2 0 0 1 2 2v31M30 2v23"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer1" d="M44.1 39.1L28.4 23.5a5 5 0 0 0-7 0 5 5 0 0 0 0 7l8.6 8.6s-2.6 8 6 14.9"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="bank-cards-1">
				<title>Bank Cards</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M10.3 29l-2.2-6.1a2.4 2.4 0 0 1 1.4-3.1L50.1 5.1a2.4 2.4 0 0 1 3.1 1.4l8.7 24a2.4 2.4 0 0 1-1.4 3.1L50 37.4m-39.9-9.2L55 11.8m-8.3 9.8l4.5-1.6"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<rect data-name="layer1" x="2" y="29" width="48" height="30" rx="2.4"
					ry="2.4" fill="none" stroke="#202020" strokeMiterlimit="10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></rect>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M10 43h14m12-6h6M10 51h2m8 0h2m8 0h2m8 0h2" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="money-box-1">
				<title>Money Box</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					d="M28.8 19.5a21.1 21.1 0 0 0-7.1 2.2c-7.9 4.2-9.2 11-9.2 16.3s2.3 13.1 10.8 20h7.3v-4l8.9.5a20.1 20.1 0 0 0 2.3 3.5h6.7v-6a47.9 47.9 0 0 0 6-5c2.9.3 5.9-2 7-8.7 0-1-.5-1.3-1-1.3a6.8 6.8 0 0 1-3-1c-.4-.5-1.6-4.7-3.8-8a11.7 11.7 0 0 1 4.1-5.2c-2.8-1.4-5.7-2.4-11.4-1.8-1.5-.3-4-.9-6.4-1.3"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<circle data-name="layer2" cx="34.5" cy="14" r="8" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></circle>
				<path data-name="layer1" d="M12.5 38c-2.9-.3-10-1.4-10 2s1.9 3.4 3.8 2.6 4.3-5.5-3.8-8.6m25.7-8.9a20.1 20.1 0 0 1 12.7-.1"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<circle data-name="layer1" cx="48.5" cy="34" r="1" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></circle>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="pay-1">
				<title>Pay</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M52 37h10v22H52zm0 18.2c-2 0-14.9 5.8-22 5.8-4.2 0-7.9-2.2-16-6-3.8-1.8-12-6.6-12-10s10.9 1.1 20.1 4"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<circle data-name="layer2" cx="34.1" cy="11" r="8" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></circle>
				<circle data-name="layer2" cx="20" cy="29" r="8" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></circle>
				<path data-name="layer1" d="M36.1 53h-10a4 4 0 0 1-4-4 4 4 0 0 1 4-4h4c12 0 8-6 22-6"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="delivery-truck-1">
				<title>Delivery Truck</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M38 19h14l6 12 4 4v8"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M2 43V7h36v36" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<circle data-name="layer1" cx="14" cy="51" r="6" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></circle>
				<circle data-name="layer1" cx="50" cy="51" r="6" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></circle>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M44 51H20M8 51H2v-8h60v8h-6" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="delivery-time-1">
				<title>Delivery Time</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M62 46v-5l-8-7h-8"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer2" cx="24" cy="54" r="4" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer2" cx="54" cy="54" r="4" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M50 54H28m-8 0h-4v-8h46v8h-4M24.5 24H46v22m-30 0V29.8M2 38h6m-2 8h2"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer1" cx="14" cy="18" r="12" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M14 12v8h6" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="giftbox-1">
				<title>Giftbox</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M56 34v28H8V34M4 22h56v12H4z"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M24 22v40m16-40v40M26.2 5A7.4 7.4 0 0 0 20 2a8 8 0 0 0-5.3 14c4.2 3.7 17.3 6 17.3 6 0-6-3.4-14.3-5.8-17zm11.6 0A7.4 7.4 0 0 1 44 2a8 8 0 0 1 5.3 14C45.1 19.7 32 22 32 22c0-6 3.4-14.3 5.8-17z"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="tag-1">
				<title>Tag</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M25.6 61L3 38.4 38.4 3l21.2 1.4L61 25.6 25.6 61z"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer1" cx="48" cy="16" r="4" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="pie-chart-1">
				<title>Pie Chart</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					d="M36 2v26h26A26 26 0 0 0 36 2z" fill="none" stroke="#202020" strokeMiterlimit="10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer2" d="M28 10a26 26 0 1 0 18.4 44.4L28 36z"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer1" d="M40 36l16.6 15.9A25.8 25.8 0 0 0 62 36z"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="sedan-1">
				<title>Sedan</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M48 28l-5.5-10a3.7 3.7 0 0 0-3.1-2H17.3a3.2 3.2 0 0 0-2.6 2l-5 10M58 42h4V31a3 3 0 0 0-3-3H5a3 3 0 0 0-3 3v11h4m12 0h28"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer1" cx="12" cy="42" r="6" fill="none" stroke="#202020"
					strokeLinecap="round" strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer1" cx="52" cy="42" r="6" fill="none"
					stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="document-saved-1">
				<title>Document Saved</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M10 2v60h44V18L38 2H10z"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M38 2v16h16" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M42.7 28.1l-14 14-7-7" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="calls-1">
				<title>Calls</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					d="M58.9 47l-10.4-6.8a4.8 4.8 0 0 0-6.5 1.3c-2.4 2.9-5.3 7.7-16.2-3.2S19.6 24.4 22.5 22a4.8 4.8 0 0 0 1.3-6.5L17 5.1c-.9-1.3-2.1-3.4-4.9-3S2 6.6 2 15.6s7.1 20 16.8 29.7S39.5 62 48.4 62s13.2-8 13.5-10-1.7-4.1-3-5z"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="speaker-call-1">
				<title>Speaker Call</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M50.4 49.3c1.1.8 2.9 1.8 2.6 4.2S49.1 62 41.5 62s-17-6-25.2-14.2S2 30.2 2 22.5 8.8 11.3 10.5 11s3.4 1.5 4.2 2.6l5.8 8.8a4.1 4.1 0 0 1-1.1 5.6c-2.5 2-6.6 4.5 2.8 13.8S34 47.1 36 44.6a4.1 4.1 0 0 1 5.6-1.1z"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M30 2a32 32 0 0 1 32 32M30 12a22 22 0 0 1 22 22M30 23a11 11 0 0 1 11 11"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="landline-1">
				<title>Landline</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M52 26.3V53a6 6 0 0 1-6 6H18a6 6 0 0 1-6-6V26.3" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<rect data-name="layer2" x="20" y="30" width="4" height="4" rx="1"
					ry="1" fill="none" stroke="#202020" strokeMiterlimit="10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></rect>
				<rect data-name="layer2" x="30" y="30" width="4" height="4" rx="1"
					ry="1" fill="none" stroke="#202020" strokeMiterlimit="10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></rect>
				<rect data-name="layer2" x="40" y="30" width="4" height="4" rx="1"
					ry="1" fill="none" stroke="#202020" strokeMiterlimit="10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></rect>
				<rect data-name="layer2" x="20" y="39" width="4" height="4" rx="1"
					ry="1" fill="none" stroke="#202020" strokeMiterlimit="10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></rect>
				<rect data-name="layer2" x="30" y="39" width="4" height="4" rx="1"
					ry="1" fill="none" stroke="#202020" strokeMiterlimit="10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></rect>
				<rect data-name="layer2" x="40" y="39" width="4" height="4" rx="1"
					ry="1" fill="none" stroke="#202020" strokeMiterlimit="10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></rect>
				<rect data-name="layer2" x="20" y="48" width="4" height="4" rx="1"
					ry="1" fill="none" stroke="#202020" strokeMiterlimit="10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></rect>
				<rect data-name="layer2" x="30" y="48" width="4" height="4" rx="1"
					ry="1" fill="none" stroke="#202020" strokeMiterlimit="10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></rect>
				<rect data-name="layer2" x="40" y="48" width="4" height="4" rx="1"
					ry="1" fill="none" stroke="#202020" strokeMiterlimit="10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></rect>
				<path data-name="layer1" d="M17.8 24.7a3.9 3.9 0 0 0 3-4.4c-.3-3-1.4-7.3 11.2-7.3s11.5 4.3 11.2 7.3a3.9 3.9 0 0 0 3 4.4l9.9 2c1.3.2 3.2.8 4.6-1.1a11.4 11.4 0 0 0-2-13.4C53.5 7.2 43.1 5 32 5S10.5 7.2 5.3 12.3a11.4 11.4 0 0 0-2 13.4c1.4 1.8 3.3 1.3 4.6 1.1z"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="smartphone-1">
				<title>Smartphone</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					d="M15 10h34M15 48h34" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<rect data-name="layer1" x="15" y="2" width="34" height="60" rx="4"
					ry="4" fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></rect>
				<circle data-name="layer1" cx="32" cy="55" r="2" fill="none" stroke="#202020"
					strokeLinecap="round" strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="chats-1">
				<title>Chats</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M48.5 36.5c.7.4 1.7 1.1 1.5 2.5a7.2 7.2 0 0 1-6.8 5c-4.5 0-10-3.5-14.8-8.4S20 25.3 20 20.8a7.2 7.2 0 0 1 5-6.8c1.4-.2 2 .9 2.5 1.5l3.4 5.2a2.4 2.4 0 0 1-.6 3.3c-1.5 1.2-2.9 1.6 2.6 7.1s5.9 4.1 7.1 2.6a2.4 2.4 0 0 1 3.3-.6z"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M34 2A28 28 0 0 0 9.3 43.2L3.2 60.8l17.5-6.1A28 28 0 1 0 34 2z"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="24h-assistance-1">
				<title>24h Assistance</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					d="M2 10h34V6a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v52a4 4 0 0 0 4 4h26a4 4 0 0 0 4-4V46m0 2H2"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer2" fill="none" stroke="#202020" strokeLinecap="round"
					strokeLinejoin="round" d="M54 18l-6 14h14m-4-6v12m-14 0H32l8.5-10v-.2a6 6 0 0 0-8.5-8.2"
					style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<circle data-name="layer1" cx="19" cy="55" r="1" fill="none" stroke="#202020"
					strokeLinecap="round" strokeLinejoin="round" style={{ stroke: 'var(--layer2, #202020)' }}></circle>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="share-1">
				<title>Share</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M20.9 36.5l22.2 11m0-31l-22.2 11"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer1" cx="12" cy="32" r="10" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer1" cx="52" cy="12" r="10" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer1" cx="52" cy="52" r="10" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="envelope-1">
				<title>Envelope</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M2 12l30 27.4L62 12"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M2 12h60v40H2z" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="paper-plane-1">
				<title>Paper Plane</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M26 38v21l10.3-14.6"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M3 29L59 5l-9 48-24-15-23-9zM59 5L26 38" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="chat-bubble-1">
				<title>Chat Bubble</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M5 59l18.8-6.9a37.1 37.1 0 0 0 8.2.9c16.6 0 30-10.7 30-24S48.6 5 32 5 2 15.7 2 29c0 6.7 3.5 12.8 9.1 17.2z"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer1" cx="20" cy="29" r="2" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer1" cx="32" cy="29" r="2" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer1" cx="44" cy="29" r="2" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="mental-illness-1">
				<title>Mental Illness</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<circle data-name="layer2"
					cx="30" cy="21" r="12" fill="none" stroke="#202020" strokeLinecap="round"
					strokeMiterlimit="10" strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer2" fill="none" stroke="#202020" strokeLinecap="round"
					strokeMiterlimit="10" d="M36 19h-4v-4h-4v4h-4v4h4v4h4v-4h4v-4z"
					strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M32.748 61.828a.249.249 0 0 0 .37-.107C33.853 60.01 37.775 51 39 51c2 0 5.938 2 9 2s2.488-7.083 3.445-9.973S53 38.445 53 37c3.828-.385 3.134-1.581 2.655-2.737S51 27.867 51 27c0-1 1-4.074 1-6 0-8.764-5-19-21.991-19C13.359 2 8 14.258 8 21s1.895 11.785 4 14 4 4.244 4 8a9.538 9.538 0 0 1-2 6z"
					fill="none" stroke="#202020" strokeLinecap="round" strokeMiterlimit="10"
					strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="mental-health-1">
				<title>Mental Health</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M32.748 61.828a.249.249 0 0 0 .37-.107C33.853 60.01 37.775 51 39 51c2 0 5.938 2 9 2s2.488-7.083 3.445-9.973S53 38.445 53 37c3.828-.385 3.134-1.581 2.655-2.737S51 27.867 51 27c0-1 1-4.074 1-6 0-8.764-5-19-21.991-19C13.359 2 8 14.258 8 21s1.895 11.785 4 14 4 4.244 4 8a9.538 9.538 0 0 1-2 6z"
					fill="none" stroke="#202020" strokeLinecap="round" strokeMiterlimit="10"
					strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M25 8c5.965-1.193 17.762-.476 20 10 .864 4.119 1.2 8.237-8 9s-13.288 1.22-14 3-1 6-3 6a1.789 1.789 0 0 1-2-2c0-1.856-1-4-2-6-.834-1.668-6-17 9-20z"
					fill="none" stroke="#202020" strokeLinecap="round" strokeMiterlimit="10"
					strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M34 22c2-2 6-2 6.834 1.386a2.636 2.636 0 0 1-1.7 3.35M31 21c-3 1-3.831 4.262-2 6m7.91-18.194c1.257 3.141.378 5.821-1.91 7.194m7.4-3.4c.658 3.362-.4 5.4-3.4 5.4M30.292 7.625A4.3 4.3 0 0 1 32 13m-2 4c-1-2-4.373-2.034-6 0m-2.156-8c3.407.458 6.023 3.093 5.133 6.5M22 16c-3-.813-2.953-4.229-2.752-5.719M19 19a2.558 2.558 0 0 0-4.422 0M25 20c-2 0-4 3-1 5m-1.488-2.847c-2.7-.407-6.491 1.158-6.778 5"
					fill="none" stroke="#202020" strokeLinecap="round" strokeMiterlimit="10"
					strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="arrow-target-1">
				<title>Arrow Target</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<circle data-name="layer2"
					cx="25" cy="29.6" r="23" fill="none" stroke="#202020" strokeMiterlimit="10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer2" cx="25" cy="29.6" r="14" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer1" cx="25" cy="29.6" r="4" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M12 48.6l-6 10m32-10l6 10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M25 29.7l29.6-17.1 2-7.2m-2 7.2L62 15" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="target-1">
				<title>Target</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<circle data-name="layer2"
					cx="32" cy="32" r="30" fill="none" stroke="#202020" strokeMiterlimit="10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer1" cx="32" cy="32" r="20" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer2" cx="32" cy="32" r="10" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer1" cx="32" cy="32" r="2" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="sales-up-1">
				<title>Sales Up</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M48 2h8v8"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M8 30l18-16 12 6L56 2m-4 60V22h8v40" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M36 62V42h8v20" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M20 62V36h8v26" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M4 62V50h8v12" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M62 62H2" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="statistic-1">
				<title>Statistic</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M11 2v60h44V18L39 2H11z"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M39 2v16h16" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M19 26v28h26" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M19 46l10-10 6 4.9L45 30" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="numbers-1">
				<title>Numbers</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M10 2v60h44V18L38 2H10z"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M38 2v16h16" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M48 54H16m18 0V26h-8v14m8-6h8v20m-16 0V40h-8v14" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="stream-video-1">
				<title>Stream Video</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<rect data-name="layer2"
					x="2" y="2" width="60" height="60" rx="10" ry="10" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></rect>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M24 22v20l20-10-20-10z" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="play-video-1">
				<title>Play Video</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M26 25.4v13.2L40 32l-14-6.6z"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M2 46v8h60v-8m0-28v-8H2v8m20-8v8m20-8v8M22 46v8m20-8v8"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M2 18h60v28H2z" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="movie-camera-1">
				<title>Movie Camera</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M48 36l14-6v22l-14-6"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<rect data-name="layer2" x="6" y="28" width="42" height="26" rx="2"
					ry="2" fill="none" stroke="#202020" strokeMiterlimit="10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></rect>
				<circle data-name="layer1" cx="12" cy="12" r="10" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer1" cx="36" cy="16" r="6" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="real-estate-1">
				<title>Real Estate</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					fill="none" stroke="#202020" strokeLinecap="round" strokeMiterlimit="10"
					d="M2 29L32 5l30 24" strokeLinejoin="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer2" fill="none" stroke="#202020" strokeLinecap="round"
					strokeMiterlimit="10" d="M23 62V40h18v22" strokeLinejoin="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeLinecap="round"
					strokeMiterlimit="10" d="M10 22.6V62h44V8h-9v7.445" strokeLinejoin="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="property-1">
				<title>Property</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeLinecap="round" strokeMiterlimit="10"
					d="M54 22.6V8h-9v7.445M40 62h14V29.769M10 30v32h14" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer2" cx="32" cy="29" r="5" fill="none" stroke="#202020"
					strokeLinecap="round" strokeMiterlimit="10" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer1" fill="none" stroke="#202020" strokeLinecap="round"
					strokeMiterlimit="10" d="M24 42h16v20H24zm8-37L2.436 28.651a.5.5 0 0 0-.036.749l3.287 3.287a.5.5 0 0 0 .668.035L32 12l25.65 20.718a.5.5 0 0 0 .668-.035l3.287-3.283a.5.5 0 0 0-.041-.744z"
					strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="page-1">
				<title>Page</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					d="M2 8h60v12H2zm0 12v36h60V20" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer2" fill="none" stroke="#202020" strokeLinecap="round"
					strokeLinejoin="round" d="M14 30h36m-36 8h36m-36 8h20"
					style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<circle data-name="layer1" cx="8" cy="14" r="1" fill="none" stroke="#202020"
					strokeLinecap="round" strokeLinejoin="round" style={{ stroke: 'var(--layer2, #202020)' }}></circle>
				<circle data-name="layer1" cx="15" cy="14" r="1" fill="none"
					stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></circle>
				<circle data-name="layer1" cx="22" cy="14" r="1" fill="none"
					stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></circle>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="list-details-1">
				<title>List Details</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					d="M2 8h60v12H2zm0 12v36h60V20" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer2" fill="none" stroke="#202020" strokeLinecap="round"
					strokeLinejoin="round" d="M26 30h24m-36 0h4m8 8h24m-36 0h4m8 8h24m-36 0h4"
					style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<circle data-name="layer1" cx="8" cy="14" r="1" fill="none" stroke="#202020"
					strokeLinecap="round" strokeLinejoin="round" style={{ stroke: 'var(--layer2, #202020)' }}></circle>
				<circle data-name="layer1" cx="15" cy="14" r="1" fill="none"
					stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></circle>
				<circle data-name="layer1" cx="22" cy="14" r="1" fill="none"
					stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></circle>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="sorting-1">
				<title>Sorting</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M8 6v44m24-11.999V58m0-52v20.001M56 14v44"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer1" cx="8" cy="56" r="6" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer1" cx="56" cy="8" r="6" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer1" cx="32" cy="32" r="6" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="special-price-1">
				<title>Special Price</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<circle data-name="layer2"
					cx="21" cy="23" r="5" fill="none" stroke="#202020" strokeMiterlimit="10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer2" cx="43" cy="41" r="5" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M46 18L18 46" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M32 4.5L38 2l4.5 4.6H49l2.5 5.9 5.9 2.5v6.5L62 26l-2.5 6 2.5 6-4.6 4.5V49l-5.9 2.5-2.5 5.9h-6.5L38 62l-6-2.5-6 2.5-4.5-4.6H15l-2.5-5.9L6.6 49v-6.5L2 38l2.5-6L2 26l4.6-4.5V15l5.9-2.5L15 6.6h6.5L26 2l6 2.5z"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="locomotive-1">
				<title>Locomotive</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					d="M54 16H42M11 46H2V32h6V12m19 34h-8m24 0h-8m-5-34v16h25.9s2.1 2 2.1 8.2a21.4 21.4 0 0 1-2 9.8h-5M34 12H2m50 4v12m-8-12v12"
					style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<circle data-name="layer2" cx="15" cy="49" r="5" fill="none" stroke="#202020"
					strokeLinecap="round" strokeLinejoin="round" style={{ stroke: 'var(--layer2, #202020)' }}></circle>
				<circle data-name="layer2" cx="31" cy="49" r="5" fill="none"
					stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></circle>
				<circle data-name="layer2" cx="47" cy="49" r="5" fill="none"
					stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></circle>
				<path data-name="layer1" fill="none" stroke="#202020" strokeLinecap="round"
					strokeLinejoin="round" d="M56 46l6 6M30 28H8" style={{ stroke: 'var(--layer2, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="excavator-1">
				<title>Excavator</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					d="M46.5 20L34 38H2V24h12l6 6h13.8" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer2" fill="none" stroke="#202020" strokeLinecap="round"
					strokeLinejoin="round" d="M42.5 17.9L36 24 24.5 12H14v12"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer2" cx="46" cy="16" r="4" fill="none" stroke="#202020"
					strokeLinecap="round" strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer2" fill="none" stroke="#202020" strokeLinecap="round"
					strokeLinejoin="round" d="M54.8 23.6l-6-4.8" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer1" cx="57" cy="25.6" r="3" fill="none"
					stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer1" d="M54.8 27.6L42 44h16c1.9 0 5.3-.6 4-4l-4.3-11.4M38 49a5 5 0 0 1-5 5H7a5 5 0 0 1-5-5 5 5 0 0 1 5-5h26a5 5 0 0 1 5 5z"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="tractor-1">
				<title>Tractor</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<circle data-name="layer2"
					cx="51" cy="51" r="7" fill="none" stroke="#202020" strokeLinecap="round"
					strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer2" cx="16" cy="46" r="12" fill="none"
					stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer1" d="M2 31.7A20 20 0 0 1 36 46M8 27.7V8h22l4 22"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeLinecap="round"
					strokeLinejoin="round" d="M27.9 30H62l-2.5 14H35.9M46 30V15.7a3.7 3.7 0 0 1 3.7-3.7h.3"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" id="wheel-loader-1">
				<title>Wheel Loader</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path d="M4 40.538V24.001l4-4.002h15.049l4.951 14h6v8h-.535"
					strokeLinejoin="round" strokeLinecap="round" stroke="#202020"
					fill="none" data-name="layer2" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path d="M10 19.999v6l18 8" strokeLinejoin="round"
					strokeLinecap="round" stroke="#202020" fill="none" data-name="layer2"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path strokeLinejoin="round" strokeLinecap="round"
					stroke="#202020" fill="none" d="M38 48h24s-18 .011-24-26m-4 22a3.999 3.999 0 0 1-4 4H6a4.002 4.002 0 0 1-4-4 4.001 4.001 0 0 1 4-4h24a3.999 3.999 0 0 1 4 4z"
					data-name="layer1" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path d="M38 34.999V22" strokeLinejoin="round"
					strokeLinecap="round" stroke="#202020" fill="none" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="literature-1">
				<title>Literature</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M19.1 12.6c-2.2-1-4.4-3.2-2.3-5.2s6.4-.9 8.7.2L48 16.8V54" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer2" d="M27.2 8.4c-2.2-1-4.4-3.2-2.3-5.2s6.4-.9 8.7.2L56 12.5V50"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M8 13.5v34.9c0 4 2.9 4.9 2.9 4.9L32 62V25.5l-20.7-8.6c-2.2-1-4.6-3.1-2.5-5.2s6.4-.9 8.7.2L40 21.1V58"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="paper-stack-1">
				<title>Paper Stack</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M2 19L32 5l30 14-30 14L2 19z"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M62 27L32 41 2 27" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M62 35L32 49 2 35" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M62 43L32 57 2 43" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="shield-security-1">
				<title>Shield Security</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M56 12A33 33 0 0 1 32 2 33 33 0 0 1 8 12S5.9 48 32 62c26.1-14 24-50 24-50z"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M45 24L31 38l-7-7" fill="none" stroke="#202020"
					strokeLinecap="round" strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="survey-1">
				<title>Survey</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M20 14h-4v40h32V14h-4M24 24h16M24 34h16M24 44h16"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M43.1 8H54a2 2 0 0 1 2 2v50a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2V10a2 2 0 0 1 2-2h10.9"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M39 6h-2.1a5 5 0 0 0-9.8 0H25a4.7 4.7 0 0 0-5 5v3h24v-3a4.8 4.8 0 0 0-5-5z"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="disable-1">
				<title>Disable</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M19 30V15A13 13 0 0 1 32 2a13 13 0 0 1 13 13v2a2 2 0 0 1-4 0v-2a9 9 0 1 0-18 0v15"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M10 30h44v32H10z" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M34 49a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2 2 2 0 0 1 2 2z"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="disable-2">
				<title>Disable</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M19 30V15A13 13 0 0 1 32 2a13 13 0 0 1 13 13v2a2 2 0 0 1-4 0v-2a9 9 0 1 0-18 0v15"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M10 30h44v32H10z" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M34 49a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2 2 2 0 0 1 2 2z"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="security-1">
				<title>Security</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M19 26.8V15A13 13 0 0 1 32 2a13 13 0 0 1 13 13v11.8" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer2" d="M23 24.1V15a9 9 0 0 1 18 0v9.1" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer1" cx="32" cy="42" r="20" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer1" d="M34 45a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2 2 2 0 0 1 2 2z"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="unlocked-1">
				<title>Unlocked</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M19 26.8V15A13 13 0 0 1 32 2a13 13 0 0 1 13 13" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer2" d="M23 24.1V15a9 9 0 0 1 18 0 2 2 0 0 0 4 0"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer1" cx="32" cy="42" r="20" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer1" d="M34 45a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2 2 2 0 0 1 2 2z"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="stack-1">
				<title>Stack</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer3"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M18.3 39.6L4.2 47 32 61.7 59.8 47l-14.1-7.4"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M18.5 24.6L4.2 32.1 32 46.8l27.8-14.7-14.3-7.5" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M32 2.3L4.2 17 32 31.7 59.8 17 32 2.3z" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="strategy-1">
				<title>Strategy</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M29.5 57A27.5 27.5 0 1 1 57 29.5m-27.5 16a16 16 0 1 1 16-16" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer2" cx="29.5" cy="29.5" r="4" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M62 62L37.9 37.9m17.3 1.6l-17.3-1.6 1.4 17.4" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="configuration-1">
				<title>Configuration</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M31.156 23.33l-4.683-4.683a3.964 3.964 0 0 1-1.1-3.349A11.737 11.737 0 0 0 13.739 2c-.338 0-.67.023-1 .051l4.406 6.973a1.3 1.3 0 0 1-.1 1.532l-2.4 2.875a1.3 1.3 0 0 1-.645.419l-3.2.913a1.3 1.3 0 0 1-1.49-.606L4.756 6.189a11.73 11.73 0 0 0 6.4 19 11.593 11.593 0 0 0 4.028.2 4.011 4.011 0 0 1 3.363 1.1l7.553 7.555m3.554 3.556l7.86 7.86a3.98 3.98 0 0 1 1.1 3.339 11.542 11.542 0 0 0-.094 1.466 11.658 11.658 0 0 0 13.509 11.588l-5-7.92a1.3 1.3 0 0 1 .1-1.532l2.4-2.875a1.3 1.3 0 0 1 .644-.419l3.2-.913a1.3 1.3 0 0 1 1.49.606l4.833 8.439a11.671 11.671 0 0 0 2.291-7.731A11.734 11.734 0 0 0 48.71 38.625a3.945 3.945 0 0 1-3.337-1.079l-5-5"
					fill="none" stroke="#202020" strokeLinecap="round" strokeMiterlimit="10"
					strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeLinecap="round"
					strokeMiterlimit="10" d="M27.96 32.186L12.285 47.861l-5.245.983-4.429 9.995 2 2 10.243-4.181 1.029-5.291 15.629-15.629"
					strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M49.027 4.078L35.451 17.655a7.474 7.474 0 0 0-2.1 4.152L26.8 26.355a2.7 2.7 0 0 0-.438 4.229l6.757 6.757a2.7 2.7 0 0 0 4.229-.438l4.493-6.477a7.477 7.477 0 0 0 4.271-2.116l13.571-13.576A7.535 7.535 0 0 0 49.027 4.078z"
					fill="none" stroke="#202020" strokeLinecap="round" strokeMiterlimit="10"
					strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="settings-1">
				<title>Settings</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M31.942 39.884l5.572 5.572a3.98 3.98 0 0 1 1.1 3.339 11.542 11.542 0 0 0-.094 1.466 11.658 11.658 0 0 0 13.509 11.592l-5-7.92a1.3 1.3 0 0 1 .1-1.532l2.4-2.875a1.3 1.3 0 0 1 .644-.419l3.2-.913a1.3 1.3 0 0 1 1.49.606l4.833 8.439a11.671 11.671 0 0 0 2.291-7.731A11.734 11.734 0 0 0 48.71 38.625a3.945 3.945 0 0 1-3.337-1.079L39.826 32m-7.884-7.884l-5.469-5.469a3.964 3.964 0 0 1-1.1-3.349A11.737 11.737 0 0 0 13.739 2c-.338 0-.67.023-1 .051l4.406 6.973a1.3 1.3 0 0 1-.1 1.532l-2.4 2.875a1.3 1.3 0 0 1-.645.419l-3.2.913a1.3 1.3 0 0 1-1.49-.606L4.756 6.189a11.73 11.73 0 0 0 6.4 19 11.593 11.593 0 0 0 4.028.2 4.011 4.011 0 0 1 3.363 1.1L24.058 32"
					fill="none" stroke="#202020" strokeLinecap="round" strokeMiterlimit="10"
					strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M61.949 12.739l-6.973 4.406a1.3 1.3 0 0 1-1.532-.1l-2.875-2.4A1.3 1.3 0 0 1 50.15 14l-.913-3.2a1.3 1.3 0 0 1 .606-1.49l7.967-4.563a11.738 11.738 0 0 0-19.2 10.428 4.011 4.011 0 0 1-1.1 3.363L18.544 37.514a3.98 3.98 0 0 1-3.339 1.1 11.542 11.542 0 0 0-1.466-.094A11.658 11.658 0 0 0 2.147 52.029l7.92-5a1.3 1.3 0 0 1 1.532.1l2.875 2.4a1.3 1.3 0 0 1 .419.644l.913 3.2a1.3 1.3 0 0 1-.606 1.49l-8.439 4.822a11.671 11.671 0 0 0 7.731 2.291A11.734 11.734 0 0 0 25.375 48.71a3.945 3.945 0 0 1 1.079-3.337l18.9-18.9a3.964 3.964 0 0 1 3.349-1.1A11.737 11.737 0 0 0 62 13.739c0-.339-.023-.669-.051-1z"
					fill="none" stroke="#202020" strokeLinecap="round" strokeMiterlimit="10"
					strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="vault-1">
				<title>Vault</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<rect data-name="layer1"
					x="2" y="2" width="60" height="60" rx="3" ry="3" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></rect>
				<path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M10 16v-6h44v44H10v-6m0-8V24" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<circle data-name="layer2" cx="34" cy="32" r="8" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></circle>
				<path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M34 20v4m0 16v4M22 32h4m16 0h4m-20.5-8.5l2.8 2.8m11.4 11.4l2.8 2.8m-17 0l2.8-2.8m11.4-11.4l2.8-2.8"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M8 16h4v8H8zm0 24h4v8H8z" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="add-1">
				<title>Add</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M32 16v32m16-16H16"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="minus-1">
				<title>Minus</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M48 32H16"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="arrow-right-1">
				<title>Arrow Right</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M50 31.998H14"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M36 18l14 14-14 14" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="arrow-left-1">
				<title>Arrow Left</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M14 31.998h36"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M28 18L14 32l14 14" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="menu-1">
				<title>Menu</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M14 18h36M14 32h36"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M14 46h36" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="menu-hamburger-1">
				<title>Menu Hamburger</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M2 14h60M2 32h36"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M2 50h60" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="cutlery-1">
				<title>Cutlery</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					d="M18.4 22.9L5.5 9.9m3.4-3.5l13 13m16.5 23.5L55.6 60a2.4 2.4 0 0 0 3.4-3.4L42.5 40m-8.7-8.7l-3.6-3.6c2-3 2.1-6.6-.7-9.3L12.4 3 2 13.3l15.5 17.2c2.7 2.7 6.3 2.7 9.3.7l3.6 3.6"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M28.2 37L8.3 56.9a2.4 2.4 0 0 0 0 3.5 2.4 2.4 0 0 0 3.5 0l19.8-20"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M28.2 37L58.4 6.7a12.2 12.2 0 0 1 0 17.3L41.1 41.3a6.1 6.1 0 0 1-8.7 0z"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="tea-mug-1">
				<title>Tea Mug</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M18 52S2 38 2 8h50s-.3 30-16 44" fill="none" stroke="#202020" strokeLinecap="round"
					strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer2" d="M51.6 14c5.9 0 10.4 1.6 10.4 6.1s-4.7 11.4-15.5 16.1"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M52 54a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2 2 2 0 0 1 2-2h46a2 2 0 0 1 2 2z"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="white-wine-1">
				<title>White Wine</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					d="M32 30v32m-10 0h20m4-52V2H18v8" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M46 10v6c0 4-2 14-14 14S18 20 18 16v-6z"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="sandwich-1">
				<title>Sandwich</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					d="M10 22.5L2 46l8 4m4 6a6 6 0 0 0 11.6 2.2 5 5 0 0 0 9.3-1.2 6 6 0 0 0 10.8-1M54 26.2a10 10 0 0 1 0 19.6"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M32 2C19.4 2 2 3.9 2 14s8 8.5 8 8.5V51a5 5 0 0 0 5 5h34a5 5 0 0 0 5-5V22.4s8-.3 8-8.4S54.3 2 32 2z"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer1" cx="19" cy="15" r="1" fill="none" stroke="#202020"
					strokeLinecap="round" strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer1" cx="27" cy="27" r="1" fill="none"
					stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer1" cx="44" cy="20" r="2" fill="none"
					stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer1" cx="42" cy="38" r="2" fill="none"
					stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer1" cx="25" cy="45" r="1" fill="none"
					stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="hamburger-1">
				<title>Hamburger</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					d="M58.9 34.4A5 5 0 0 1 62 39a5 5 0 0 1-5 5M7 44a5 5 0 0 1-5-5 5 5 0 0 1 2-4"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer2" d="M8 26c-2 0-9.6 5.4-4 9 3.3 1.9 6.7-2.8 8-2.8S18 36 24 36s9.1-4 12-4 5.1 4 8 4 4.8-2 8-2 9.2 1.9 10-2-6-6-6-6"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer1" d="M57 54l-.6 5a3.7 3.7 0 0 1-3.4 3H11a3.7 3.7 0 0 1-3.6-3l-.5-5"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer2" fill="none" stroke="#202020" strokeLinecap="round"
					strokeLinejoin="round" d="M32 44l16 8 8-8" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer1" d="M56 26a24 24 0 1 0-48 0h48z" fill="none"
					stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<circle data-name="layer1" cx="44" cy="12" r="1" fill="none" stroke="#202020"
					strokeLinecap="round" strokeLinejoin="round" style={{ stroke: 'var(--layer2, #202020)' }}></circle>
				<circle data-name="layer1" cx="38" cy="14" r="1" fill="none"
					stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></circle>
				<circle data-name="layer1" cx="32" cy="10" r="1" fill="none"
					stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></circle>
				<circle data-name="layer1" cx="28" cy="16" r="1" fill="none"
					stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></circle>
				<circle data-name="layer1" cx="22" cy="12" r="1" fill="none"
					stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></circle>
				<path data-name="layer1" d="M62 49a5 5 0 0 1-5 5H7a5 5 0 0 1-5-5 5 5 0 0 1 5-5h50a5 5 0 0 1 5 5z"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="strawberry-1">
				<title>Strawberry</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M14 4c4.6 1.3 12.1 5.8 18 12C37.8 9.9 43.2 5.7 50 4M32 2v14M20 26v4m4 6v4m16-4v4m-8-14v4m0 16v4m12-24v4"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M32 16c-7.4-3.3-24-5.5-24 12.8S26.5 62 31.9 62 56 46.5 56 28.2 38.7 13.3 32 16z"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="browser-window-1">
				<title>Browser Window</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					d="M2 20v36h60V20M2 8h60v12H2z" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer1" cx="8" cy="14" r="1" fill="none" stroke="#202020"
					strokeLinecap="round" strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer1" cx="15" cy="14" r="1" fill="none"
					stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer1" cx="22" cy="14" r="1" fill="none"
					stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="new-window-1">
				<title>New Window</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					d="M2 6h52v12H2zm0 12v32h30m22-22V18" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer2" cx="8" cy="12" r="1" fill="none" stroke="#202020"
					strokeLinecap="round" strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer2" cx="15" cy="12" r="1" fill="none"
					stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer2" cx="22" cy="12" r="1" fill="none"
					stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer1" cx="46" cy="42" r="16" fill="none"
					stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer1" fill="none" stroke="#202020" strokeLinecap="round"
					strokeLinejoin="round" d="M46 34v16m-8-8h16" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="checked-window-1">
				<title>Checked Window</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					d="M2 6h52v12H2zm0 12v32h30m22-22V18" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer2" cx="8" cy="12" r="1" fill="none" stroke="#202020"
					strokeLinecap="round" strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer2" cx="15" cy="12" r="1" fill="none"
					stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer2" cx="22" cy="12" r="1" fill="none"
					stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer1" cx="46" cy="42" r="16" fill="none"
					stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer1" fill="none" stroke="#202020" strokeLinecap="round"
					strokeLinejoin="round" d="M53 38l-8 9-5-5" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="imac-screen-1">
				<title>iMac Screen</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					d="M22 60h20M32 50v10m30-20V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v34"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M2 40v8a2 2 0 0 0 2 2h56a2 2 0 0 0 2-2v-8z"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="iphone-1">
				<title>Iphone</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M14 59a3 3 0 0 0 3 3h30a3 3 0 0 0 3-3v-9H14zM50 5a3 3 0 0 0-3-3H17a3 3 0 0 0-3 3v5h36zm0 45V10m-36 0v40"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer1" cx="32" cy="56" r="2" fill="none" stroke="#202020"
					strokeLinecap="round" strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="laptop-screen-1">
				<title>Laptop Screen</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					d="M6 45l-4 6v2h60v-2l-4-6" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeLinecap="round"
					strokeLinejoin="round" d="M6 11h52v34H6z" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="html5-1">
				<title>Html5</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					d="M6 2h52l-4 54-22 6-22-6L6 2z" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeLinecap="round"
					strokeLinejoin="round" d="M46 16H18l1 14h25.9L44 44l-12 4-11.7-4-.6-5.8"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="multiple-windows-1">
				<title>Multiple Windows</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer3"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					d="M10 20H2v12h8m-8 0v28h44v-8" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer2" fill="none" stroke="#202020" strokeLinecap="round"
					strokeLinejoin="round" d="M18 12h-8v12h8m-8 0v28h44v-8"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeLinecap="round"
					strokeLinejoin="round" d="M18 4h44v12H18zm0 12v28h44V16"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer1" cx="24" cy="10" r="1" fill="none" stroke="#202020"
					strokeLinecap="round" strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer1" cx="31" cy="10" r="1" fill="none"
					stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer1" cx="38" cy="10" r="1" fill="none"
					stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="bookmark-1">
				<title>Bookmark</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					d="M46 14h8v48H18a8 8 0 0 1-8-8V8" fill="none" stroke="#202020" strokeMiterlimit="10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M46 34l-7.9-6-8.1 6V10h16v24z" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer1" d="M30 14H16a6 6 0 0 1 0-12h34" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="images-1">
				<title>Images</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M10 46H2V2h44v8"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M18 54h-8V10h44v8" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M18 18h44v44H18zm0 34h44" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer1" cx="28" cy="28" r="4" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer1" d="M24 52c0-2.8.6-14 7.3-14s4.1 6 6.7 6 .6-16 8-16 10 21.8 10 24"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="image-1">
				<title>Image</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<circle data-name="layer2"
					cx="17" cy="15" r="5" fill="none" stroke="#202020" strokeMiterlimit="10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer2" d="M8 46c0-2.8 6.6-16 13.3-16s6.1 6 8.7 6 4.6-18 12-18 14 25.8 14 28"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M2 46h60M2 2h60v60H2z" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" id="location-pin-1">
				<title>Location Pin</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path
					strokeMiterlimit="10" stroke="#202020" fill="none" d="M32 62c0-17.1 16.3-25.2 17.8-39.7A18 18 0 1 0 14 20a18.1 18.1 0 0 0 .2 2.2C15.7 36.8 32 44.9 32 62z"
					data-name="layer2" strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle strokeMiterlimit="10" stroke="#202020"
					fill="none" r="6" cy="20" cx="32" data-name="layer1" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
			</symbol>
			<symbol viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" id="destination-map-1">
				<title>Destination Map</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path d="M43.1 17l9.9 9.9M43 27l9.9-9.9m-19.5 7.6a10 10 0 0 1 6.8-2.7M29.7 35.2a10 10 0 0 0 .5-2.9V32a10 10 0 0 1 .9-4.3M21.2 42a10 10 0 0 0 6.6-3.5M9.8 42h7.6M2 42h4"
					strokeMiterlimit="10" stroke="#202020" fill="none" data-name="layer2"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path d="M62 10v50l-22-6-16 6-22-6V4l22 6 16-6 22 6z"
					strokeMiterlimit="10" stroke="#202020" fill="none" data-name="layer1"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="zoom-in-1">
				<title>Zoom In</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<circle data-name="layer2"
					cx="24.2" cy="24.2" r="22.2" fill="none" stroke="#202020" strokeMiterlimit="10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M39.9 39.9L62 62" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M24.2 14.5v19.3m9.6-9.6H14.5" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="zoom-out-1">
				<title>Zoom Out</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<circle data-name="layer2"
					cx="24.2" cy="24.2" r="22.2" fill="none" stroke="#202020" strokeMiterlimit="10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M39.9 39.9L62 62" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M33.8 24.2H14.5" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="find-1">
				<title>Find</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<circle data-name="layer1"
					cx="24.2" cy="24.2" r="22.2" fill="none" stroke="#202020" strokeMiterlimit="10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M39.9 39.9L62 62" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="bin-1">
				<title>Bin</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M6 10h52m-36 0V5.9A3.9 3.9 0 0 1 25.9 2h12.2A3.9 3.9 0 0 1 42 5.9V10m10.5 0l-2.9 47.1a5 5 0 0 1-4.9 4.9H19.3a5 5 0 0 1-4.9-4.9L11.5 10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M32 18v36M22 18l2 36m18-36l-2 36" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" id="ballpoint-pen-1">
				<title>Ballpoint Pen</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path
					strokeMiterlimit="10" stroke="#202020" fill="none" d="M26.328 18.566L40.516 4.378a5.4 5.4 0 0 1 7.637 0l1.837 1.839"
					data-name="layer2" strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path strokeMiterlimit="10" stroke="#202020"
					fill="none" d="M44.847 26.922L59.67 12.1l.014-.013A5.5 5.5 0 0 0 51.9 4.3L37.067 19.141"
					data-name="layer2" strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path d="M44.847 26.922l-7.78-7.781-29.6 29.599-4.764 12.567 12.545-4.785 29.599-29.6zM7.467 48.74l7.781 7.782"
					strokeMiterlimit="10" stroke="#202020" fill="none" data-name="layer1"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="pen-1">
				<title>Pen</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M9.7 54.3l-7.6 7.6m15.1-10.8a6.5 6.5 0 0 0-4.3-4.3 6 6 0 0 1-5.6.9l-.9.7-.5.7a6.4 6.4 0 0 0-1 1.9c-1.8 4.2-2.8 10.9-2.8 10.9s6.8-.9 11-2.7a6.5 6.5 0 0 0 1.7-1l.7-.6.7-.9a6 6 0 0 1 1-5.6z"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M61.1 6.9l-36 40.4a6 6 0 1 1-9-8l.5-.5L57 2.8a2.8 2.8 0 0 1 4 4z"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="attachment-1">
				<title>Attachment</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					d="M54.5 29.3A16 16 0 0 0 31.9 6.7L8.3 30.2a2 2 0 0 0 2.9 2.8L34.7 9.5a12 12 0 0 1 17 17L22.5 55.7a8 8 0 1 1-11.3-11.3L37.5 18a4 4 0 1 1 5.7 5.7L22.5 44.4a2 2 0 0 0 2.8 2.8L46 26.5a8 8 0 1 0-11.3-11.3L8.3 41.5a12 12 0 0 0 17 17z"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="attachment-2">
				<title>Attachment</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					d="M24.6 45.1l17.3-17.3a6 6 0 0 0 0-8.5 6 6 0 0 0-8.5 0L7.6 45.1a10 10 0 0 0 0 14.1 10 10 0 0 0 14.1 0l33.5-33.4a14 14 0 0 0 0-19.8 14 14 0 0 0-19.8 0l-25 25"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="key-1">
				<title>Key</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					d="M56.7 7.3a18 18 0 0 0-28.7 21l-8 7.9V40h-6v6H8v6H4l-2 2v8h8l25.8-26A18 18 0 0 0 56.7 7.3zm-5.9 11.5a4 4 0 1 1 0-5.7 4 4 0 0 1 0 5.7z"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" id="design-1">
				<title>Design</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path d="M33.353 22.629l-19.77-19.77-10.761 10.76L22.51 33.461"
					strokeMiterlimit="10" stroke="#202020" fill="none" data-name="layer1"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path d="M18.301 53.703l36.603-36.561-8.026-8.026-36.602 36.562-6.383 14.408 14.408-6.383zm36.603-36.561l6.274-6.267-8.026-8.025-6.274 6.266"
					strokeMiterlimit="10" stroke="#202020" fill="none" data-name="layer2"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path d="M30.502 41.516L49.984 61.15 60.93 50.205 41.381 30.656m2.137 23.856l5.496-5.498M15.892 26.791l6.055-6.057M37.48 48.547l4.502-4.501M9.588 20.439l4.443-4.441"
					strokeMiterlimit="10" stroke="#202020" fill="none" data-name="layer1"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path d="M10.276 45.678l8.025 8.025" strokeMiterlimit="10"
					stroke="#202020" fill="none" data-name="layer2" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="shopping-basket-1">
				<title>Shopping Basket</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M6.1 27L12 57h40l6-30"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M26 7L8.1 27M38 7l18 20" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M2 27h60" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="shopping-bag-1">
				<title>Shopping Bag</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M56 62H8l2-44h44l2 44z"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M22 26V12A10 10 0 0 1 32 2a10 10 0 0 1 10 10v14"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="british-pound-sign-1">
				<title>British Pound Sign</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<circle data-name="layer2"
					cx="32" cy="32" r="30" fill="none" stroke="#202020" strokeMiterlimit="10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M19.6 46h21.3M20 32h21m1-10a8 8 0 0 0-16 0v17.6c0 3.2-1.1 6.4-6.4 6.4"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="british-pound-badge-1">
				<title>British Pound Badge</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M32 4.5L38 2l4.5 4.6H49l2.5 5.9 5.9 2.5v6.5L62 26l-2.5 6 2.5 6-4.6 4.5V49l-5.9 2.5-2.5 5.9h-6.5L38 62l-6-2.5-6 2.5-4.5-4.6H15l-2.5-5.9L6.6 49v-6.5L2 38l2.5-6L2 26l4.6-4.5V15l5.9-2.5L15 6.6h6.5L26 2l6 2.5z"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M19.6 46h21.3M19.6 32h21.3M42 22a8 8 0 0 0-16 0v17.6c0 3.2-1.1 6.4-6.4 6.4"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="new-1">
				<title>New</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<circle data-name="layer2"
					cx="32" cy="32" r="30" fill="none" stroke="#202020" strokeMiterlimit="10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M10 39V26l7.8 12h.2V25m16 1h-8v12h8m-2-6h-6m11-7l4.3 13h.5l3.5-10h1.2l3.3 10h.5L54 25"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="store-1">
				<title>Store</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M36 36h12v22H36z"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M56 30v28H8V30" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M16 36h14v12H16z" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M52.6 6H11.4L2 22.5a7.5 7.5 0 0 0 15 0 7.5 7.5 0 0 0 15 0 7.5 7.5 0 0 0 15 0 7.5 7.5 0 0 0 15 0zM2 22h60M32 6v16.5m-15 0L22 6m25 16.5L42 6"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="label-tag-1">
				<title>Label Tag</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M25.6 61L3 38.4 38.4 3l21.2 1.4L61 25.6 25.6 61z"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer2" cx="48" cy="15" r="4" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M31.3 21.4l11.3 11.3m-22.6 0l8.5 8.5M25.6 27l5.7 5.7"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" id="chart-1">
				<title>Chart</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path
					strokeMiterlimit="10" stroke="#202020" fill="none" d="M32 47C15.432 47 2 38.047 2 27v10c0 11.046 13.432 20 30 20a40.987 40.987 0 0 0 17-3.523v-10A41 41 0 0 1 32 47z"
					data-name="layer2" strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path strokeMiterlimit="10" stroke="#202020"
					fill="none" d="M62 27C62 15.955 48.568 7 32 7S2 15.955 2 27s13.432 20 30 20a41 41 0 0 0 17-3.523V43M32 27V7.008 27"
					data-name="layer2" strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path strokeMiterlimit="10" stroke="#202020"
					fill="none" d="M49 43.477v10C56.851 49.87 62 43.837 62 37V27c0 6.838-5.149 12.87-13 16.477z"
					data-name="layer1" strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path strokeLinejoin="round" stroke="#202020"
					fill="none" d="M49 43v.477C56.851 39.87 62 33.838 62 27H32l8 7.529z" data-name="layer1"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" id="bike-1">
				<title>Bike</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<circle
					strokeLinejoin="round" strokeLinecap="round" stroke="#202020" fill="none"
					r="10" cy="37.998" cx="12" data-name="layer2" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle strokeLinejoin="round" strokeLinecap="round"
					stroke="#202020" fill="none" r="10" cy="37.998" cx="52" data-name="layer2"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path d="M45.014 22L32.246 38H12.18l5.92-16"
					strokeLinejoin="round" strokeLinecap="round" stroke="#202020" fill="none"
					data-name="layer1" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path d="M32.246 38L18.1 22h26.914m-30.836-6.002h8"
					strokeLinejoin="round" strokeLinecap="round" stroke="#202020" fill="none"
					data-name="layer1" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path d="M36.178 14h8l8 23.998" strokeLinejoin="round"
					strokeLinecap="round" stroke="#202020" fill="none" data-name="layer1"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="scooter-1">
				<title>Scooter</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M22 44a8 8 0 1 1-16 0m52.7-2a8 8 0 1 1-15.5 0" fill="none" stroke="#202020"
					strokeLinecap="round" strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M38 10h4c1.1 0 4 1.2 4 4v18.2c0 3.2-2.9 10.5-12.1 10.5"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M46 32h6c9.7 0 10 10 10 10H38.5M16 30A14 14 0 0 0 2 44h32a14 14 0 0 0-14-14z"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M23.9 22H2c1 6.1 6.9 8 13.9 8h4a21.2 21.2 0 0 0 9.1-1.7c3.1-1.7 3.4-6.3-5.1-6.3zM51 15v6"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="airplane-mode-1">
				<title>Airplane Mode</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					d="M57.5 7.5a5 5 0 0 0-7.1 0L39.9 18.1l-25.6-3.3s-1.9-.3-2.7.5l-3 3a1.3 1.3 0 0 0-.4 1.4c.2.5 1.6 1.2 2 1.4L28 30l-7.2 7.2-.7.7-9.1-1.3a1.6 1.6 0 0 0-1.5.5l-2.2 2.2c-.3.3-.9 1 .4 1.6l11.6 4.8s4.2 10.3 4.8 11.6 1.3.7 1.6.4l2.2-2.2a1.6 1.6 0 0 0 .5-1.5l-1.3-9.1.7-.7 7.2-7.1 9 17.7c.2.5.9 1.9 1.4 2a1.3 1.3 0 0 0 1.4-.4l3-3c.8-.8.5-2.7.5-2.7l-3.4-25.6 10.6-10.6a5 5 0 0 0 0-7z"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="road-1">
				<title>Road</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M52.1 2C32.1 2 24 14.2 24 20s4 6.1 4 12c0 8.2-24 20-24 20M58 8c-7.5 0-14 4.2-14 10s14 10.2 14 26c0 14.1-10 18-10 18"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M42 10l-1.3.7m-4.4 41.4l-1.8 1.6m7.1-9.8a14 14 0 0 1-1.5 3.6m.9-11.8a16 16 0 0 1 .7 2.7m-5.5-11l1.8 2.7m-4.3-10.7a10.5 10.5 0 0 0 .3 3m4.1-10.2a13.2 13.2 0 0 0-2.4 2.1m-6.4 42.9L28 58"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" id="speedometer-1">
				<title>Speedometer</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<circle
					strokeLinejoin="round" strokeLinecap="round" stroke="#202020" fill="none"
					r="30" cy="32" cx="32" data-name="layer2" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path strokeLinejoin="round" strokeLinecap="round"
					stroke="#202020" fill="none" d="M10 32a22 22 0 1 1 44 0" data-name="layer2"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path strokeLinejoin="round" strokeLinecap="round"
					stroke="#202020" fill="none" d="M24 20l4.188 17.142a3.987 3.987 0 1 0 6.93-3.624C35.124 33.396 24 20 24 20"
					data-name="layer1" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" id="beach-1">
				<title>Beach</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path
					strokeLinejoin="round" strokeLinecap="round" stroke="#202020" fill="none"
					d="M7.998 61.99a26.781 26.781 0 0 1 19.111-8c12.12 0 20.89 8 20.89 8zm33.998-42c1.49 9.448 2.088 23.518-7.842 34.888"
					data-name="layer2" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path strokeLinejoin="round" strokeLinecap="round"
					stroke="#202020" fill="none" d="M33.998 33.99c.659-.96 6.727-11.74 7.828-13.697a.244.244 0 0 1 .4-.034l11.77 13.73A26.77 26.77 0 0 0 50.25 20.38a.085.085 0 0 1 .124-.114 54.868 54.868 0 0 0 9.624 5.724s.35-5.554-5.67-9.773a.145.145 0 0 1 .07-.265c4.316-.453 5.6-1.962 5.6-1.962s-5.453-4.627-11.688-2.13a.176.176 0 0 1-.246-.192c.679-3.285 1.934-7.678 1.934-7.678s-5.637 2.32-7.863 5.776a.277.277 0 0 1-.373.09c-5.584-3.144-9.764-1.865-9.764-1.865l5.728 5.728a.13.13 0 0 1-.101.224 11.836 11.836 0 0 0-11.629 6.047 37.693 37.693 0 0 1 9.648-.072.189.189 0 0 1 .107.317c-.91.983-3.909 5.02-1.753 13.755z"
					data-name="layer2" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle strokeLinejoin="round" strokeLinecap="round"
					stroke="#202020" fill="none" r="8" cy="27.995" cx="11.998" data-name="layer1"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="swimming-pool-1">
				<title>Swimming Pool</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					d="M48 25H20m28 16H20M10 10a5 5 0 0 1 5-5 5 5 0 0 1 5 5v41.3M38 10a5 5 0 0 1 5-5 5 5 0 0 1 5 5v41.2"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M18 51c-4 0-4 2-8 2s-4-2-8-2m32 0c-4 0-4 2-8 2s-4-2-8-2m32 0c-4 0-4 2-8 2s-4-2-8-2m28 1a7.8 7.8 0 0 1-4 1c-4 0-4-2-8-2m-32 8c-4 0-4 2-8 2s-4-2-8-2m32 0c-4 0-4 2-8 2s-4-2-8-2m32 0c-4 0-4 2-8 2s-4-2-8-2m28 1a7.7 7.7 0 0 1-4 1c-4 0-4-2-8-2"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="glasses-1">
				<title>Glasses</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M4.4 33.4L4 34S2.6 52 16 52s12-16 12-16m31.7-2.6l.4.6s1.4 18-12 18-12-16-12-16"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M26.1 18c0-4.7-3.1-7.6-6.6-5s-13 17.2-15 20.4M38.1 18c0-4.7 3.1-7.6 6.6-5s13 17.3 15 20.4"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M62 34.5S48 26 36 36h-8C16.1 26 2.1 34.5 2.1 34.5"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="backpack-1">
				<title>Backpack</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					d="M56 26v32a4 4 0 0 1-4 4H12a4 4 0 0 1-4-4V26" fill="none" stroke="#202020"
					strokeLinecap="round" strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M12 62H6a4 4 0 0 1-4-4V42a4 4 0 0 1 4-4h2m44 24h6a4 4 0 0 0 4-4V42a4 4 0 0 0-4-4h-2"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<rect data-name="layer1" x="8" y="10" width="48" height="19.98"
					rx="4" ry="4" fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></rect>
				<path data-name="layer1" d="M24 10a8 8 0 1 1 16 0M24.1 46h-8v-8h8v8zM48 46h-8v-8h8v8zm-27.9-8V10.2m0 35.8v4M44 38V10.2M44 46v4"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" id="camping-1">
				<title>Camping</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path d="M62 61.995V45.997L44.531 23.995H20.898L2 45.997v15.998h60z"
					strokeLinejoin="round" strokeLinecap="round" stroke="#202020"
					fill="none" data-name="layer2" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path d="M44.531 23.995L26.004 45.997 26 61.995m29.996.003L44.531 39.996 32.006 61.998m-6.002-16.001H2"
					strokeLinejoin="round" strokeLinecap="round" stroke="#202020"
					fill="none" data-name="layer2" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle strokeLinejoin="round" strokeLinecap="round"
					stroke="#202020" fill="none" r="8" cy="9.997" cx="10" data-name="layer1"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="mountain-1">
				<title>Mountain</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					d="M14.4 25L2.1 47h40L31.9 29m16.2 18h14l-7.8-13.7" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M24.1 27l7.9 2-6.8-12c-1.1-2-5.1-2-6.2 0l-4.5 8 5.6 4zm20.3-2.9L41 30.2l4.3 3 3-1.5 6 1.5-5.1-9.1c-.8-1.5-3.9-1.5-4.8 0z"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="helpline-24h-1">
				<title>Helpline 24h</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M52.3 48.8c1.2.8 3 1.9 2.7 4.3S51 62 43 62s-17.7-6.3-26.2-14.8S2 28.9 2 21 9 9.3 10.9 9s3.6 1.5 4.3 2.7l6 9.2a4.3 4.3 0 0 1-1.1 5.8c-2.6 2.1-6.8 4.6 2.9 14.3s12.3 5.4 14.3 2.9a4.3 4.3 0 0 1 5.8-1.1z"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M54 2l-6 14h14m-4-6v12m-14 0H32l8.5-10v-.2A6 6 0 0 0 32 3.6"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="text-checked-1">
				<title>Text Checked</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M2 8h60M2 24h60M2 40h24M2 56h24"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M35 45l8 9 16-16" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" id="exit-1">
				<title>Exit</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path d="M22 48h16V4H2v43l20 14V19L2 4"
					strokeMiterlimit="10" strokeLinecap="round" stroke="#202020"
					fill="none" data-name="layer2" strokeLinejoin="round"></path>
				<path d="M32 26h29M51 16l10 10-10 10" strokeMiterlimit="10"
					strokeLinecap="round" stroke="#202020" fill="none" data-name="layer1"
					strokeLinejoin="round"></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="question-help-1">
				<title>Question Help</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<circle data-name="layer2"
					cx="32" cy="32" r="30" fill="none" stroke="#202020" strokeMiterlimit="10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer1" d="M24 24.528C24 21.647 26.206 17 32.47 17S40 22.628 40 24.528s-.59 4.092-4.057 7.558C32.477 35.552 32 37.353 32 39v2"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer1" cx="32" cy="47" r="2" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="shutdown-1">
				<title>Shutdown</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M44.01 13.216a24 24 0 1 1-24.012-.004" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M32 8v24" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="search-1">
				<title>Search</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M41.54 37.285L61.122 56.88a3 3 0 0 1-4.244 4.242l-19.58-19.593" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer1" cx="24" cy="24" r="22" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer1" cx="24" cy="24" r="16" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="zoom-1">
				<title>Zoom</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M59.121 54.878L43.244 39.001a3 3 0 0 0-4.243 4.242l15.878 15.879a3 3 0 0 0 4.242-4.244zM39.056 39.055L35.02 35.02"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer1" cx="23" cy="23" r="17" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="gear-1">
				<title>Gear</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<circle data-name="layer2"
					cx="32" cy="32" r="14" fill="none" stroke="#202020" strokeLinecap="round"
					strokeMiterlimit="10" strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer1" d="M58.906 27a3.127 3.127 0 0 1-2.977-2.258 24.834 24.834 0 0 0-1.875-4.519 3.131 3.131 0 0 1 .505-3.71 3.1 3.1 0 0 0 0-4.376l-2.693-2.698a3.1 3.1 0 0 0-4.376 0 3.131 3.131 0 0 1-3.71.505 24.834 24.834 0 0 0-4.519-1.875A3.127 3.127 0 0 1 37 5.094 3.1 3.1 0 0 0 33.906 2h-3.812A3.1 3.1 0 0 0 27 5.094a3.127 3.127 0 0 1-2.258 2.977 24.834 24.834 0 0 0-4.519 1.875 3.131 3.131 0 0 1-3.71-.505 3.1 3.1 0 0 0-4.376 0l-2.695 2.7a3.1 3.1 0 0 0 0 4.376 3.131 3.131 0 0 1 .505 3.71 24.834 24.834 0 0 0-1.875 4.519A3.127 3.127 0 0 1 5.094 27 3.1 3.1 0 0 0 2 30.094v3.811A3.1 3.1 0 0 0 5.094 37a3.127 3.127 0 0 1 2.977 2.258 24.833 24.833 0 0 0 1.875 4.519 3.131 3.131 0 0 1-.505 3.71 3.1 3.1 0 0 0 0 4.376l2.7 2.7a3.1 3.1 0 0 0 4.376 0 3.131 3.131 0 0 1 3.71-.505 24.834 24.834 0 0 0 4.519 1.875A3.127 3.127 0 0 1 27 58.906 3.1 3.1 0 0 0 30.094 62h3.811A3.1 3.1 0 0 0 37 58.906a3.127 3.127 0 0 1 2.258-2.977 24.834 24.834 0 0 0 4.519-1.875 3.131 3.131 0 0 1 3.71.505 3.1 3.1 0 0 0 4.376 0l2.7-2.695a3.1 3.1 0 0 0 0-4.376 3.131 3.131 0 0 1-.505-3.71 24.833 24.833 0 0 0 1.875-4.519A3.127 3.127 0 0 1 58.906 37 3.1 3.1 0 0 0 62 33.906v-3.812A3.1 3.1 0 0 0 58.906 27z"
					fill="none" stroke="#202020" strokeLinecap="round" strokeMiterlimit="10"
					strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="settings-2">
				<title>Settings</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<circle data-name="layer2"
					cx="32.106" cy="32.035" r="14" fill="none" stroke="#202020" strokeLinecap="round"
					strokeMiterlimit="10" strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer1" d="M60.2 27.264l-4.908-1.27a23.845 23.845 0 0 0-2.534-6.086l2.606-4.426a2.4 2.4 0 0 0-.371-2.915l-3.411-3.411a2.4 2.4 0 0 0-2.915-.371l-4.423 2.6a23.848 23.848 0 0 0-6.1-2.54L36.842 3.8A2.4 2.4 0 0 0 34.518 2h-4.825a2.4 2.4 0 0 0-2.323 1.8l-1.3 5.028a23.843 23.843 0 0 0-6.141 2.544L15.408 8.71a2.4 2.4 0 0 0-2.915.371l-3.412 3.412a2.4 2.4 0 0 0-.371 2.915l2.662 4.52a23.848 23.848 0 0 0-2.512 6.027L3.8 27.264A2.4 2.4 0 0 0 2 29.588v4.825a2.4 2.4 0 0 0 1.8 2.323l5.02 1.3a23.846 23.846 0 0 0 2.571 6.21l-2.6 4.423a2.4 2.4 0 0 0 .371 2.915l3.412 3.412a2.4 2.4 0 0 0 2.915.371l4.426-2.607a23.845 23.845 0 0 0 6.2 2.565L27.37 60.2a2.4 2.4 0 0 0 2.323 1.8h4.825a2.4 2.4 0 0 0 2.324-1.8l1.267-4.9a23.85 23.85 0 0 0 6.154-2.563l4.329 2.549a2.4 2.4 0 0 0 2.915-.371l3.412-3.412a2.4 2.4 0 0 0 .371-2.915l-2.549-4.329A23.845 23.845 0 0 0 55.332 38l4.869-1.26A2.4 2.4 0 0 0 62 34.413v-4.825a2.4 2.4 0 0 0-1.8-2.324z"
					fill="none" stroke="#202020" strokeLinecap="round" strokeMiterlimit="10"
					strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="new-window-2">
				<title>New Window</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					d="M12 16H2v12h10M2 28v30h52V48" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeLinecap="round"
					strokeLinejoin="round" d="M12 6h50v12H12zm0 12v30h50V18"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer1" cx="18" cy="12" r="1" fill="none" stroke="#202020"
					strokeLinecap="round" strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer1" cx="25" cy="12" r="1" fill="none"
					stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer1" cx="32" cy="12" r="1" fill="none"
					stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="css3-1">
				<title>Css3</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					d="M6 2h52l-4 54-22 6-22-6L6 2z" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeLinecap="round"
					strokeLinejoin="round" d="M19.7 38.2l.6 5.8L32 48l12-4 .9-14H19l27-14H18"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="keyboard-1">
				<title>Keyboard</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<rect data-name="layer1"
					x="2" y="14" width="60" height="36" rx="2" ry="2" fill="none" stroke="#202020"
					strokeLinecap="round" strokeLinejoin="round" style={{ stroke: 'var(--layer2, #202020)' }}></rect>
				<path data-name="layer2" fill="none" stroke="#202020" strokeLinecap="round"
					strokeLinejoin="round" d="M10 20h4v4h-4z" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeLinecap="round"
					strokeLinejoin="round" d="M20 20h4v4h-4zm10 0h4v4h-4zm10 0h4v4h-4zm10 0h4v4h-4zM10 30h4v4h-4zm0 10h4v4h-4z"
					style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer2" fill="none" stroke="#202020" strokeLinecap="round"
					strokeLinejoin="round" d="M20 40h34v4H20z" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeLinecap="round"
					strokeLinejoin="round" d="M20 30h4v4h-4zm10 0h4v4h-4zm10 0h4v4h-4zm10 0h4v4h-4z"
					style={{ stroke: 'var(--layer2, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="filter-data-1">
				<title>Filter Data</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					d="M2 6h60L38 32v20l-12 6V32L2 6z" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="bookmark-ribbon-1">
				<title>Bookmark Ribbon</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M24 52a8 8 0 0 1 8 8V16a8 8 0 0 0-8-8H2v44zM54 8h8v44H40a8 8 0 0 0-8 8"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer2" d="M32 16a8 8 0 0 1 8-8h2" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M54 26l-6-4-6 4V4h12v22z" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="paperback-book-1">
				<title>Paperback Book</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M3.7 30.8c-2.7 0-2.1 7 .3 8.2l26 12 32-18" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer2" d="M3.7 38.8c-2.7 0-2.1 7 .3 8.2l26 12 32-18"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M3.1 22.9L30 7.1a8.6 8.6 0 0 1 7-.6L62 17 30 35S8.2 24.6 4.5 23s-3.2 6.7-.5 8l26 12 32-18"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="file-storage-1">
				<title>File Storage</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<rect data-name="layer2"
					x="8" y="2" width="48" height="60" rx="6" ry="6" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></rect>
				<path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M8 22h48M8 42h48" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M26 12v-2h12v2M26 32v-2h12v2M26 52v-2h12v2" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="angle-brackets-1">
				<title>Angle Brackets</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M24.2 10L3 32l21.2 22"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M40.2 10l21.1 22-21.1 22" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" id="sand-clock-1">
				<title>Sand Clock</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path
					strokeMiterlimit="10" strokeLinecap="round" stroke="#202020" fill="none"
					d="M16 55c4 0 9.959-5.608 12.984-8a5.114 5.114 0 0 1 6.024-.016C38.057 49.355 44 55 48 55M20.084 26c8-3.205 14.6 3.65 24.121-.216"
					data-name="layer2" strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path strokeMiterlimit="10" strokeLinecap="round"
					stroke="#202020" fill="none" d="M48 62V48.513a14 14 0 0 0-5.953-11.457l-5.527-3.881a1 1 0 0 1 0-1.637l5.526-3.881A14 14 0 0 0 48 16.2V2M16 2v14.2a14 14 0 0 0 5.954 11.457l5.526 3.881a1 1 0 0 1 0 1.637l-5.526 3.881A14 14 0 0 0 16 48.513V62m-3 0h38M13 2h38"
					data-name="layer1" strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path strokeMiterlimit="10" strokeLinecap="round"
					stroke="#202020" fill="none" d="M27.816 32.77C30.687 33.5 31.833 37 32 37c.132 0 .867-3.383 4.095-4.7"
					data-name="layer2" strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" id="world-map-1">
				<title>World Map</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path
					strokeMiterlimit="10" stroke="#202020" fill="none" d="M53.9 28.4A26 26 0 1 1 41 13m-2.1-1c-5.8 2.8-9.9 3.1-9.9 6.7s5.2 1.5 5.2 5.2-4 6.3-6.9 4.4-6.9-3-10.4 1.8-1.2 10.4 1.3 10.3 4.8-2.4 5.9.6 1.3 3 2.4 3.6 1.2 1.9.8 3.6 1.8 6.9 3.5 6.9 3.3-.7 3.5-3.5 2.3-2.9 3.3-3.7-.7-3.7 1.1-5.6 5.7-5.4 2.5-6.3-3-1.5-3.5-2.9-1.8-2.8.9-2.9a10.3 10.3 0 0 0 7.4-3.1l.7-.7"
					data-name="layer2" strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path strokeMiterlimit="10" stroke="#202020"
					fill="none" d="M51 2a10 10 0 0 0-10 10c0 9 10 20 10 20s10-11 10-20A10 10 0 0 0 51 2z"
					data-name="layer1" strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle strokeMiterlimit="10" stroke="#202020"
					fill="none" r="2" cy="12" cx="51" data-name="layer1" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="view-1">
				<title>View</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M32 14.5C14.8 14.5 2.5 32 2.5 32S14.8 49.5 32 49.5 61.5 32 61.5 32 49.2 14.5 32 14.5z"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer1" cx="32" cy="31.5" r="12" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
			</symbol>
			<symbol viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" id="pencil-1">
				<title>Pencil</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path
					strokeMiterlimit="10" stroke="#202020" fill="none" d="M55.737 16.309l3.674-3.67a5.675 5.675 0 1 0-8.025-8.025l-3.674 3.669"
					data-name="layer2" strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path d="M17.337 54.667l38.4-38.358-8.025-8.026L9.307 46.642 2.926 61.049l14.411-6.382zm-8.03-8.025l8.03 8.025"
					strokeMiterlimit="10" stroke="#202020" fill="none" data-name="layer1"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="scissor-1">
				<title>Scissor</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M39.9 15.7L28.5 58.1s-.7 4.2-8.2 3.9l1.2-3.9 7.8-29" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer2" d="M48.4 24.2L5.9 35.5s-4.3.9-3.9 8.1l3.9-1 21.2-5.7"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer1" cx="34" cy="10" r="8" transform="rotate(-45 34.042 10)"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer1" cx="54" cy="30" r="8" transform="rotate(-45 54.034 30.018)"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="color-palette-1">
				<title>Color Palette</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<circle data-name="layer2"
					cx="46" cy="20" r="4" transform="rotate(-45 46.003 20.045)" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer2" cx="32" cy="14" r="4" transform="rotate(-45 31.98 13.954)"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer2" cx="19" cy="19" r="3" transform="rotate(-45 18.985 19.036)"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer2" cx="15" cy="33" r="5" transform="rotate(-45 15.004 33.085)"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer1" d="M18.9 46C29 46 36 42.1 35 49.4c-.6 4.3-9 .4-9 7.5 0 3.3 3 4.9 7.1 5.1a29.8 29.8 0 0 0 14.2-4.2l.7-.5 1.3-.8a30 30 0 1 0-47-20.4C3.5 42.3 8.9 46 18.9 46z"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="screwdriver-1">
				<title>Screwdriver</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeLinecap="round" strokeMiterlimit="10"
					d="M36.651 31.653l15.675-15.675 5.245-.983L62 5l-2-2-10.243 4.18-1.029 5.292-15.629 15.629"
					strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M15.584 59.761L29.16 46.184a7.474 7.474 0 0 0 2.1-4.152l6.557-4.548a2.7 2.7 0 0 0 .438-4.229L31.5 26.5a2.7 2.7 0 0 0-4.229.438l-4.493 6.477a7.477 7.477 0 0 0-4.278 2.113L4.928 49.1a7.535 7.535 0 0 0 10.656 10.661z"
					fill="none" stroke="#202020" strokeLinecap="round" strokeMiterlimit="10"
					strokeLinejoin="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" id="us-dollar-1">
				<title>US Dollar</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path d="M32 10.002V2m0 60v-7.998"
					strokeMiterlimit="10" stroke="#202020" fill="none" data-name="layer2"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path strokeMiterlimit="10" stroke="#202020"
					fill="none" d="M43.834 20A12 12 0 0 0 32 10c-6.627 0-12 4.372-12 11s6 9.629 12 11c6 1.353 12 4.375 12 11s-5.373 11-12 11a12 12 0 0 1-12-12"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="discount-1">
				<title>Discount</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<circle data-name="layer2"
					cx="21" cy="23" r="5" fill="none" stroke="#202020" strokeMiterlimit="10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer2" cx="43" cy="41" r="5" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M46 18L18 46" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer1" cx="32" cy="32" r="30" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="bitcoin-1">
				<title>Bitcoin</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M24 8V2m8 6V2m-8 60v-6m8 6v-6"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M16 30h17a11 11 0 0 0 0-22H16v48h19a13 13 0 1 0 0-26"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="hidden-file-1">
				<title>Hidden File</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M38 2v8m16 8h-8m-8-4v4h4"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M16 2h-4v4m0 4v8m0 4v8m0 4v8m0 4v8m42-18v8m0-20v8m0 16v8m-42 2v4h4m4 0h8m4 0h8m4 0h10v-2m0-40v-2l-4-4m-2.7-2.7l-5-5m-2.8-2.8L38 2h-6M20 2h8"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="code-folder-1">
				<title>Code Folder</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M2 55a2 2 0 0 0 2 2h56a2 2 0 0 0 2-2V15H24V9H2z" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M38 29l7 8-7 8M26 29l-7 8 7 8" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="save-file-1">
				<title>Save File</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M54 52v10H2V12h10"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M55.3 2L62 8.7V52H12V2h43.3z" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M54 52V28H20v24M44 9v4m8-11v16.3a1.7 1.7 0 0 1-1.7 1.7H33.7a1.7 1.7 0 0 1-1.7-1.7V2"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="save-1">
				<title>Save</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M52 62V34H12v28m10-18h20m-20 8h16"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M54 2l8 8v52H2V2h52zm-12 8v4" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" d="M50 2v18a2 2 0 0 1-2 2H28a2 2 0 0 1-2-2V2"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="cell-phone-signal-1">
				<title>Cell Phone Signal</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M38 17h12v38H38zm0 38H26V25h12M26 55H14V33h12M14 55H2V41h12"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M62 55h-7" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="text-message-1">
				<title>Text Message</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M2 8v40h18v11l12-11h30V8H2z"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer1" cx="20" cy="28" r="2" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer1" cx="32" cy="28" r="2" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<circle data-name="layer1" cx="44" cy="28" r="2" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></circle>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="speech-bubble-1">
				<title>Speech Bubble</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					d="M42 8H22a20 20 0 0 0 0 40h2v10l12-10h6a20 20 0 1 0 0-40z" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="message-bubble-1">
				<title>Message Bubble</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M42 8H22a20 20 0 0 0 0 40h2v10l12-10h6a20 20 0 0 0 0-40z" fill="none"
					stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M18 18h28M18 28h28M18 38h16" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="grid-1">
				<title>Grid</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M2 32h60M32 2v60"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M2 2h60v60H2z" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="sales-performance-up-1">
				<title>Sales Performance Up</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M46 2l10 2-.9 10M52 62V24h8v38"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M36 62V34h8v28" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M20 62V44h8v18" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M4 62v-8h8v8" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M62 62H2M56 4L8 42" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="add-folder-1">
				<title>Add Folder</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M30.5 50H2V10h52v22.1M6 10V2h44v8"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<circle data-name="layer1" cx="46" cy="46" r="16" fill="none" stroke="#202020"
					strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></circle>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M46 38v16m-8-8h16" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="table-content-1">
				<title>Table Content</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					d="M2 6h60v12H2zm0 12v40h60V18" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer2" fill="none" stroke="#202020" strokeLinecap="round"
					strokeLinejoin="round" d="M14 26h36v24H14zm0 12h36M26 26v24m12-24v24"
					style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<circle data-name="layer1" cx="8" cy="12" r="1" fill="none" stroke="#202020"
					strokeLinecap="round" strokeLinejoin="round" style={{ stroke: 'var(--layer2, #202020)' }}></circle>
				<circle data-name="layer1" cx="15" cy="12" r="1" fill="none"
					stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></circle>
				<circle data-name="layer1" cx="22" cy="12" r="1" fill="none"
					stroke="#202020" strokeLinecap="round" strokeLinejoin="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></circle>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="star-medal-1">
				<title>Star Medal</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					d="M34.9 37.4L48 24c1.9-1.9 4-4 4-8V2H12v14c0 4 2.1 6.1 4 8l13.2 13.2M12 14h40"
					fill="none" stroke="#202020" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M32 56.8L22.1 62 24 51l-8-7.8 11.1-1.6 4.9-10 4.9 10L48 43.2 40 51l1.9 11-9.9-5.2z"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="wireframe-1">
				<title>Wireframe</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer1"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M2 2h24v24H2z"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M38 2h24v24H38zM2 38h24v24H2z" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer2, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M38 38h24v24H38z" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer2, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="portfolio-grid-2">
				<title>Portfolio Grid</title>
				<desc>A line styled icon from Orion Icon Library.</desc>
				<path data-name="layer2"
					fill="none" stroke="#202020" strokeMiterlimit="10" d="M2 2h12v12H2zm24 0h12v12H26zm24 0h12v12H50zM2 50h12v12H2z"
					strokeLinejoin="round" strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M26 50h12v12H26zm24 0h12v12H50z" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M2 25.988h12v12H2z" strokeLinejoin="round" strokeLinecap="round"
					style={{ stroke: 'var(--layer1, #202020)' }}></path>
				<path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
					d="M26 25.988h12v12H26zm24 0h12v12H50z" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #202020)' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" id="rss-1">
				<title>RSS Feed</title>
				<desc>A line styled icon from Font Awesome.</desc>
				<path data-name="layer1"
					d="M0 64C0 46.3 14.3 32 32 32c229.8 0 416 186.2 416 416c0 17.7-14.3 32-32 32s-32-14.3-32-32C384 253.6 226.4 96 32 96C14.3 96 0 81.7 0 64zM0 416a64 64 0 1 1 128 0A64 64 0 1 1 0 416zM32 160c159.1 0 288 128.9 288 288c0 17.7-14.3 32-32 32s-32-14.3-32-32c0-123.7-100.3-224-224-224c-17.7 0-32-14.3-32-32s14.3-32 32-32z"
					fill="none" stroke="#666666" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #666666)', strokeWidth: '18px' }}></path>
			</symbol>
			<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" id="ai-wand">
				<title>AI Wand</title>
				<desc>An AI wand icon from Font Awesome.</desc>
				<path
					data-name="layer1"
					d="M234.7 42.7L197 56.8c-3 1.1-5 4-5 7.2s2 6.1 5 7.2l37.7 14.1L248.8 123c1.1 3 4 5 7.2 5s6.1-2 7.2-5l14.1-37.7L315 71.2c3-1.1 5-4 5-7.2s-2-6.1-5-7.2L277.3 42.7 263.2 5c-1.1-3-4-5-7.2-5s-6.1 2-7.2 5L234.7 42.7zM46.1 395.4c-18.7 18.7-18.7 49.1 0 67.9l34.6 34.6c18.7 18.7 49.1 18.7 67.9 0L529.9 116.5c18.7-18.7 18.7-49.1 0-67.9L495.3 14.1c-18.7-18.7-49.1-18.7-67.9 0L46.1 395.4zM484.6 82.6l-105 105-23.3-23.3 105-105 23.3 23.3zM7.5 117.2C3 118.9 0 123.2 0 128s3 9.1 7.5 10.8L64 160l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L128 160l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L128 96 106.8 39.5C105.1 35 100.8 32 96 32s-9.1 3-10.8 7.5L64 96 7.5 117.2zm352 256c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L416 416l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L480 416l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L480 352l-21.2-56.5c-1.7-4.5-6-7.5-10.8-7.5s-9.1 3-10.8 7.5L416 352l-56.5 21.2z"
					fill="none" stroke="#666666" strokeMiterlimit="10" strokeLinejoin="round"
					strokeLinecap="round" style={{ stroke: 'var(--layer1, #666666)', strokeWidth: '18px' }}></path>
			</symbol>
		</svg>
	</div>
);

export default SvgIcons;
