import { BaseObject } from './base';
import { HashedString } from './utils';

export interface Product extends BaseObject {
	$type: 'Product';
	$id: string;
	// Display name of this product
	name: string;
	// Group name to sort under in NewsCore
	group?: string;
	// Optional filters to auto-apply this product
	filters?: string[];
	// Who to contact when a story that matches this product is removed
	kill_alert_emails?: string[];
	// Optional username to use when logging into the API for an RSS feed
	username?: string;
	// Optional password to use when logging into the API for an RSS feed
	// SHA512 hashed
	password?: HashedString;
}

export function isProduct(item: any, tableName?: string): item is Product {
	return item?.$type === 'Product' || tableName === 'Product';
}
