import type { Category as CategoryType } from '@newstex/types/category';

import type { CollectionFieldSchmaForObject } from './types';

export const Category: CollectionFieldSchmaForObject<CategoryType> = [
	{
		name: 'type',
		type: 'string',
		store: true,
		facet: true,
		optional: true,
		defaultSearchOrder: 5,
	},
	{
		name: 'code',
		type: 'string',
		store: true,
		searchable: true,
		optional: true,
		defaultSearchOrder: 1,
	},
	{
		name: 'name',
		type: 'string',
		store: true,
		searchable: true,
		optional: true,
		defaultSearchOrder: 2,
	},
	{
		name: 'description',
		type: 'string',
		store: true,
		searchable: true,
		optional: true,
		defaultSearchOrder: 3,
	},
	{
		name: 'category_map',
		type: 'string[]',
		store: true,
		searchable: true,
		optional: true,
		defaultSearchOrder: 10,
	},
	{
		name: 'story_count',
		type: 'int64',
		store: true,
		optional: true,
	},
	{
		name: 'last_applied',
		type: 'int64',
		store: true,
		optional: true,
	},
	{
		name: 'last_checked',
		type: 'int64',
		store: true,
		optional: true,
	},
];
