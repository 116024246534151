import { Product } from '@newstex/types/product';
import { Results } from '@newstex/types/results';
import { title as titleCase } from 'case';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useAPI } from '~/providers/api-provider';

interface ProductSelectorProps {
	product: Product;
	selected: boolean;
	onChange: (product: Product, selected: boolean) => void;
}

function ProductSelector({ product, selected, onChange }: ProductSelectorProps) {
	return (
		<Form.Check
			type="checkbox"
			label={product.name}
			checked={selected}
			onChange={(e) => onChange(product, e.target.checked)}
		/>
	);
}

interface ProductEditorProps {
	products?: string[];
	onChange: (products: Product[]) => void;
}

export function ProductEditor({ products, onChange }: ProductEditorProps) {
	const api = useAPI();
	const [allProducts, setAllProducts] = useState<Product[]>([]);
	const [selectedProducts, setSelectedProducts] = useState<Set<string>>(new Set(products || []));

	useEffect(() => {
		const fetchProducts = async () => {
			const response = await api.fetchWithAuth<Results<Product>>('resources/Product');
			if (response?.items) {
				setAllProducts(response.items.filter((p) => {
					const groupName = p.group?.toLowerCase() || 'default';
					return !p.deleted
						&& groupName !== 'archived'
						&& !groupName.includes('deleted')
						&& !groupName.includes('mobile')
						&& !groupName.includes('spam');
				}));
			}
		};
		fetchProducts();
	}, [api]);

	const handleProductChange = (product: Product, selected: boolean) => {
		const updatedSelectedProducts = new Set(selectedProducts);
		if (selected) {
			updatedSelectedProducts.add(product.$id);
		} else {
			updatedSelectedProducts.delete(product.$id);
		}
		setSelectedProducts(updatedSelectedProducts);
		onChange(Array.from(updatedSelectedProducts).map((id) => allProducts.find((p) => p.$id === id)!));
	};

	const groupedProducts = allProducts.reduce((groups, product) => {
		if (!groups[product.group || 'default']) {
			groups[product.group || 'default'] = [];
		}
		groups[product.group || 'default'].push(product);
		return groups;
	}, {} as Record<string, Product[]>);

	return (
		<>
			{Object.entries(groupedProducts).sort(([a], [b]) => a.localeCompare(b)).map(([group, p]) => (
				<div key={`product-group-${group}`} className="mb-3">
					<h5>{group === 'aci' ? 'ACI' : titleCase(group)}</h5>
					<Row>
						{p.map((product) => (
							<Col key={`product-${product.$id}`} xs={12} md={6}>
								<ProductSelector
									product={product}
									selected={selectedProducts.has(product.$id)}
									onChange={handleProductChange}
								/>
							</Col>
						))}
					</Row>
				</div>
			))}
		</>
	);
}
