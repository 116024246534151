import type { Product } from '@newstex/types/product';
import type { Results } from '@newstex/types/results';
import Fuse from 'fuse.js';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import LoadingSpinner from '~/components/LoadingSpinner';
import { PageTitle } from '~/components/page-title';
import Preloader from '~/components/Preloader';
import { SearchBox } from '~/components/search-box';
import { SimpleFacetMenu } from '~/components/simple-facet-menu';
import { ProductsTable } from '~/components/tables/products-table';
import { useAPI } from '~/providers/api-provider';

export const ProductSearchPage = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const api = useAPI();
	const [loading, setLoading] = useState<boolean>(false);
	const [nextToken, setNextToken] = useState<string>();
	const [allProducts, setAllProducts] = useState<Product[]>([]);
	const [productCounts, setProductCounts] = useState<Record<string, number>>({});
	const [products, setProducts] = useState<Product[]>([]);

	async function loadMore(token?: string, refresh = false) {
		const resp = await api.fetchWithAuth<Results<Product>>(
			`resources/Product?limit=100&nextToken=${token || nextToken}`,
			refresh ? { cache: 'reload' } : undefined,
		);
		if (resp?.items?.length) {
			setAllProducts((prev) => [
				...prev,
				...resp.items,
			]);
		}
		setNextToken(resp?.nextToken);
	}

	// Set Search Counts
	useEffect(() => {
		if (!nextToken && allProducts.length) {
			const counts = allProducts.reduce((acc, curr) => {
				if (curr.group) {
					acc[curr.group] = (acc[curr.group] || 0) + 1;
				}
				return acc;
			}, {} as Record<string, number>);
			setProductCounts(counts);
		} else {
			setProductCounts({});
		}
	}, [allProducts.length, nextToken]);

	// Filter Results
	useEffect(() => {
		if (!searchParams.get('group') && !searchParams.get('q')) {
			// By default, filter out the archived products
			setProducts(allProducts.filter((p) => p.group !== 'archived'));
		} else {
			const groupFilter = searchParams.get('group');
			const groupProducts = groupFilter ? allProducts.filter((p) => p.group === groupFilter) : allProducts;
			if (searchParams.get('q')) {
				const searcher = new Fuse(groupProducts, {
					shouldSort: true,
					threshold: 0.6,
					location: 0,
					distance: 100,
					minMatchCharLength: 1,
					keys: [
						'name',
						'username',
						'description',
						'kill_alert_emails',
						'group',
					],
				});
				setProducts(searcher
					.search(searchParams.get('q') || '')
					.map((result) => result.item)
					// Sort archived products to the bottom
					.sort((a, b) => {
						if (a.group === 'archived') {
							if (b.group === 'archived') {
								return 0;
							}
							return 1;
						}

						if (b.group === 'archived') {
							return -1;
						}
						return 0;
					}));
			} else {
				setProducts(groupProducts);
			}
		}
	}, [allProducts, searchParams]);

	const fetchData = async (refresh = false) => {
		setLoading(true);
		const resp = await api.fetchWithAuth<Results<Product>>(
			'resources/Product?limit=100',
			refresh ? { cache: 'reload' } : undefined,
		);
		setAllProducts(resp?.items || []);
		setNextToken(resp?.nextToken);
		if (resp?.nextToken) {
			await loadMore(resp.nextToken, refresh);
		}
		setLoading(false);
	};
	// Load all results
	useEffect(() => {
		fetchData();
	}, [api]);

	return (
		<Container fluid>
			<PageTitle title="Products" />
			<Row>
				<Col>
					<SearchBox
						refreshHook={() => {
							return fetchData(true);
						}}
					/>
				</Col>
			</Row>
			{loading && <Preloader type="three-bounce" variant="success" /> }
			<hr/>
			<Row>
				<Col md={2} xxl={1}>
					<SimpleFacetMenu
						title="Group"
						prop="group"
						options={[
							{ label: 'Active', value: '' },
							{ label: 'Clients', value: 'clients', count: productCounts.clients },
							{ label: 'Legacy', value: 'Legacy', count: productCounts.Legacy },
							{ label: 'Commercial', value: 'commercial', count: productCounts.commercial },
							{ label: 'ACI', value: 'aci', count: productCounts.aci },
							{ label: 'Test', value: 'test', count: productCounts.test },
							{ label: 'SPAM', value: 'spam', count: productCounts.spam },
							{ label: 'Archived', value: 'archived', count: productCounts.archived },
						]}
					/>
				</Col>
				<Col md={10} xxl={11}>
					{loading
						? <LoadingSpinner loading={loading} />
						: <ProductsTable
							products={products || []}
							hasMore={Boolean(nextToken?.length)}
							onLoadMore={() => loadMore(nextToken)}
						/>
					}
				</Col>
			</Row>
		</Container>
	);
};
