import type { Results } from '@newstex/types/results';
import type { SiteConfig } from '@newstex/types/site-config';
import Fuse from 'fuse.js';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import LoadingSpinner from '~/components/LoadingSpinner';
import { PageTitle } from '~/components/page-title';
import Preloader from '~/components/Preloader';
import { SearchBox } from '~/components/search-box';
import { SimpleFacetMenu } from '~/components/simple-facet-menu';
import { SitesTable } from '~/components/tables/sites-table';
import { useAPI } from '~/providers/api-provider';

export const SiteSearchPage = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const api = useAPI();
	const [loading, setLoading] = useState<boolean>(false);
	const [nextToken, setNextToken] = useState<string>();
	const [allSites, setAllSites] = useState<SiteConfig[]>([]);
	const [siteCounts, setSiteCounts] = useState<Record<string, number>>({});
	const [sites, setSites] = useState<SiteConfig[]>([]);

	async function loadMore(token?: string, refresh = false) {
		const resp = await api.fetchWithAuth<Results<SiteConfig>>(
			`resources/SiteConfig?limit=100&nextToken=${token || nextToken}`,
			refresh ? { cache: 'reload' } : undefined,
		);
		if (resp?.items?.length) {
			setAllSites((prev) => [
				...prev,
				...resp.items,
			]);
		}
		setNextToken(resp?.nextToken);
	}

	// Set Search Counts
	useEffect(() => {
		if (!nextToken && allSites.length) {
			const counts = allSites.reduce((acc, curr) => {
				if (curr.category) {
					acc[curr.category] = (acc[curr.category] || 0) + 1;
				}
				return acc;
			}, {} as Record<string, number>);
			setSiteCounts(counts);
		} else {
			setSiteCounts({});
		}
	}, [allSites.length, nextToken]);

	// Filter Results
	useEffect(() => {
		if (!searchParams.get('category') && !searchParams.get('q')) {
			setSites(allSites.filter((s) => s.status !== 'inactive'));
		} else {
			const categoryFilter = searchParams.get('category');
			const categoryFilteredSites = categoryFilter ? allSites.filter((s) => s.category === categoryFilter) : allSites;

			if (searchParams.get('q')) {
				const searcher = new Fuse(categoryFilteredSites, {
					shouldSort: true,
					threshold: 0.6,
					location: 0,
					distance: 100,
					minMatchCharLength: 1,
					keys: [
						'name',
						'$id',
						'type',
						'status',
					],
				});
				setSites(searcher
					.search(searchParams.get('q') || '')
					.map((result) => result.item));
			} else {
				setSites(categoryFilteredSites);
			}
		}
	}, [allSites, searchParams]);

	const fetchData = async (refresh = false) => {
		setLoading(true);
		const resp = await api.fetchWithAuth<Results<SiteConfig>>(
			'resources/SiteConfig?limit=100',
			refresh ? { cache: 'reload' } : undefined,
		);
		setAllSites(resp?.items || []);
		setNextToken(resp?.nextToken);
		if (resp?.nextToken) {
			await loadMore(resp.nextToken, refresh);
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchData();
	}, [api]);

	return (
		<Container fluid>
			<PageTitle title="Sites" />
			<Row>
				<Col>
					<SearchBox
						refreshHook={() => {
							return fetchData(true);
						}}
					/>
				</Col>
			</Row>
			{loading && <Preloader type="three-bounce" variant="success" />}
			<hr/>
			<Row>
				<Col md={2} xxl={1}>
					<SimpleFacetMenu
						title="Category"
						prop="category"
						options={[
							{ label: 'All', value: '' },
							...Object.entries(siteCounts).map(([category, count]) => ({
								label: category,
								value: category,
								count,
							})),
						]}
					/>
				</Col>
				<Col md={10} xxl={11}>
					{loading
						? <LoadingSpinner loading={loading} />
						: <SitesTable
							sites={sites || []}
							hasMore={Boolean(nextToken?.length)}
							onLoadMore={() => loadMore(nextToken)}
						/>
					}
				</Col>
			</Row>
		</Container>
	);
};
