import { bedrock } from '@ai-sdk/amazon-bedrock';
import type { Publication } from '@newstex/types/content';
import type { DetectFeedResponse } from '@newstex/types/detect-feed';
import type { Story } from '@newstex/types/story';
import { generateObject } from 'ai';
import { z } from 'zod';

import { DEFAULT_MODEL } from './bedrock';
import { AI_QUALIFICATION_PROMPT } from './prompts/qualification';

const QUALIFICATION_SCORE_SCHEMA = z.object({
	score: z.number().describe('The numeric qualification score for the client.'),
	// Include this to get reasons for the score
	reason: z.string().describe('The reason for the qualification score. Keep this short and concise.'),
});
const QUALIFICATION_SCORES_FOR_CLIENTS = z.object({
	CCC: QUALIFICATION_SCORE_SCHEMA.describe('The qualification score for Copyright Clearance Center (CCC).').optional(),
	Cengage: QUALIFICATION_SCORE_SCHEMA.describe('The qualification score for Cengage Gale.').optional(),
	CLA: QUALIFICATION_SCORE_SCHEMA.describe('The qualification score for Copyright Licensing Agency (CLA).').optional(),
	IndustryDive: QUALIFICATION_SCORE_SCHEMA.describe('The qualification score for Industry Dive.').optional(),
	LexisNexis: QUALIFICATION_SCORE_SCHEMA.describe('The qualification score for LexisNexis.').optional(),
	Moodys: QUALIFICATION_SCORE_SCHEMA.describe('The qualification score for Moody\'s (Acquire Media).').optional(),
	ProQuest: QUALIFICATION_SCORE_SCHEMA.describe('The qualification score for ProQuest.').optional(),
	Refinitiv: QUALIFICATION_SCORE_SCHEMA.describe('The qualification score for Refinitiv.').optional(),
	SyndiGate: QUALIFICATION_SCORE_SCHEMA.describe('The qualification score for SyndiGate.').optional(),
	ThomsonWestlaw: QUALIFICATION_SCORE_SCHEMA.describe('The qualification score for Thomson Westlaw.').optional(),
});
export type QualificationScores = z.infer<typeof QUALIFICATION_SCORES_FOR_CLIENTS>;

export type QualifyStoryParams = {
	headline: string;
	textContent: string;
	modelProvider?: typeof bedrock;
	model?: Parameters<typeof bedrock>[0];
} & Parameters<typeof bedrock>[1];

export async function qualifyStory({
	headline,
	textContent,
	model,
	modelProvider = bedrock,
	...settings
}: QualifyStoryParams): Promise<QualificationScores> {
	const result = await generateObject({
		model: modelProvider(model || DEFAULT_MODEL, settings),
		schema: QUALIFICATION_SCORES_FOR_CLIENTS,
		system: AI_QUALIFICATION_PROMPT,
		prompt: `Given the following article, please provide qualification scores for each client

		Headline: "${headline}"
		________
		${textContent}
		`,
	});
	return result.object;
}

export type QualifySiteParams = {
	detectResp: DetectFeedResponse,
	criteria: string,
	modelProvider?: typeof bedrock;
	model?: Parameters<typeof bedrock>[0];
} & Parameters<typeof bedrock>[1];

export async function qualifySite({
	detectResp,
	criteria,
	model,
	modelProvider = bedrock,
	...settings
}: QualifySiteParams): Promise<QualificationScores> {
	const articles: string[] = [];
	for (const story of detectResp.articles) {
		const storyData: string[] = [];
		storyData.push(`Headline: "${story.title}"`);
		if (story.author) {
			storyData.push(`By: "${story.author}"`);
		}

		if (story.newstex_categories) {
			storyData.push('Category Scores:');
			for (const [category, score] of Object.entries(story.newstex_categories)
				.sort((a, b) => b[1] - a[1])
				.slice(0, 5)) {
				storyData.push(`  - ${category}: ${score}`);
			}
		}

		if (story.summary) {
			storyData.push('_______________________');
			storyData.push(`"${story.summary.slice(0, 500)}"`);
			storyData.push('_______________________');
		}
		articles.push(storyData.join('\n'));
	}
	const prompt = [
		'Given the following publication, please provide qualification score based on your criteria',
		`URL: ${detectResp.url}`,
		`Name: ${detectResp.name}`,
		`Description: ${detectResp.description}`,
	];
	if (articles.length) {
		prompt.push('List of sample articles below');
		prompt.push('');
		prompt.push(...articles.slice(0, 5));
	}

	const results = await generateObject({
		model: modelProvider(model || DEFAULT_MODEL, settings),
		system: `You are a publication qualification system. You are given a publication and a criteria, and you need to provide a score for the publication based on the criteria.

			Criteria: ${criteria}
			_______________________
			ONLY RETURN SCORES FOR CLIENTS THAT ARE DEFINED IN YOUR CONTEXT. Do not respond with any other client scores.
			`,
		schema: QUALIFICATION_SCORES_FOR_CLIENTS,
		prompt: prompt.join('\n'),
	});
	return results.object;
}

export type QualifyPublicationParams = {
	publication: Publication,
	stories: Story[],
	criteria: string,
	modelProvider?: typeof bedrock;
	model?: Parameters<typeof bedrock>[0];
} & Parameters<typeof bedrock>[1];

export async function qualifyPublication({
	publication,
	criteria,
	stories,
	model,
	modelProvider = bedrock,
	...settings
}: QualifyPublicationParams): Promise<QualificationScores> {
	const articles: string[] = [];
	for (const story of stories) {
		const storyData: string[] = [];
		storyData.push(`Headline: "${story.headline}"`);
		if (story.source) {
			storyData.push(`By: "${story.source}"`);
		}

		if (story.category_scores) {
			storyData.push('Category Scores:');
			for (const [category, score] of Object.entries(story.category_scores).sort((a, b) => b[1] - a[1]).slice(0, 5)) {
				storyData.push(`  - ${category}: ${score}`);
			}
		}

		if (story.excerpt) {
			storyData.push('_______________________');
			storyData.push(`"${story.excerpt.slice(0, 500)}"`);
			storyData.push('_______________________');
		}
		articles.push(storyData.join('\n'));
	}
	const prompt = [
		'Given the following publication, please provide qualification score based on your criteria',
		`URL: ${publication.url}`,
		`Name: ${publication.name}`,
		`Description: ${publication.description}`,
	];
	if (articles.length) {
		prompt.push('List of sample articles below');
		prompt.push('');
		prompt.push(...articles.slice(0, 5));
	}

	const results = await generateObject({
		model: modelProvider(model || DEFAULT_MODEL, settings),
		system: `You are a publication qualification system. You are given a publication and a criteria, and you need to provide a score for the publication based on the criteria.

			Criteria: ${criteria}
			`,
		schema: QUALIFICATION_SCORES_FOR_CLIENTS,
		prompt: prompt.join('\n'),
	});
	return results.object;
}
