import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { KnowledgeBase } from '@newstex/types/rag';
import { useState } from 'react';
import {
	Button,
	Col,
	Form,
	Row,
} from 'react-bootstrap';

import { SelectKnowledgeBaseArticle } from '../select-knowledge-base-article';

interface LeadSubmissionFormProps {
	autoSyncToHubSpot: boolean;
	selectedKnowledgeBase: KnowledgeBase | null;
	onSubmit: (urls: string[], article?: KnowledgeBase | null, mode?: 'hubspot' | 'list') => void;
	onSelectKnowledgeBase: (article: KnowledgeBase | null) => void;
	onSelectAutoSyncToHubSpot: (autoSyncToHubSpot: boolean) => void;
}

export function LeadSubmissionForm({
	autoSyncToHubSpot,
	selectedKnowledgeBase,
	onSubmit,
	onSelectKnowledgeBase,
	onSelectAutoSyncToHubSpot,
}: LeadSubmissionFormProps) {
	const [mode, setMode] = useState<'hubspot' | 'list'>('hubspot');
	const [inputData, setInputData] = useState('');

	const handleSelectKnowledgeBase = (article: KnowledgeBase | null) => {
		onSelectKnowledgeBase(article);
	};

	const handleSelectAutoSyncToHubSpot = (autoSync: boolean) => {
		onSelectAutoSyncToHubSpot(autoSync);
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		const urls = inputData.split('\n').filter((url) => url.trim() !== '');
		onSubmit(urls, selectedKnowledgeBase, mode);
	};

	return (
		<Form onSubmit={handleSubmit}>
			<Row className="mb-3">
				<Col>
					<SelectKnowledgeBaseArticle
						category="Qualification"
						value={selectedKnowledgeBase}
						onSelect={handleSelectKnowledgeBase}
						label="Search for AI Qualification Criteria"
					/>
				</Col>
				<Col>
					<Form.Label>Auto-sync to HubSpot</Form.Label>
					<Form.Switch
						name="autoSyncToHubSpot"
						label="Automatically sync records to HubSpot"
						checked={autoSyncToHubSpot}
						onChange={() => handleSelectAutoSyncToHubSpot(!autoSyncToHubSpot)}
					/>
					<Form.Text>
						{autoSyncToHubSpot
							? 'All records will automatically be synced to HubSpot after qualification is completed.'
							: 'You will need to manually select which records to sync after qualification is completed'
						}
					</Form.Text>
				</Col>
			</Row>
			<Row className="mt-3 mb-3">
				<Col>
					<Form.Group controlId="urlInput">
						{mode === 'hubspot' && (
							<>
								<Form.Label>Enter Hubspot Company List ID</Form.Label>
								<Form.Control
									type="text"
									value={inputData}
									placeholder="https://app.hubspot.com/contacts/912345/objectLists/1234/filters"
									onChange={(e) => setInputData(e.target.value)}
									required
									pattern="^https:\/\/app\.hubspot\.com\/contacts\/\d+\/objectLists\/\d+\/filters$"
									title="Must be a valid HubSpot company list URL (e.g. https://app.hubspot.com/contacts/912345/objectLists/1234/filters)"
								/>
							</>
						)}
						{mode === 'list' && (
							<>
								<Form.Label>Enter URLs (one per line)</Form.Label>
								<Form.Control
									as="textarea"
									rows={25}
									value={inputData}
									onChange={(e) => setInputData(e.target.value)}
									placeholder={[
										'# URL to Qualify',
										'https://example.com',
										'',
										'# Hubspot Company List ID',
										'https://app.hubspot.com/contacts/912345/objectLists/1234/filters',
										'',
										'# URL and Hubspot Company ID',
										'https://example.com;912345',
									].join('\r\n')}
								/>
							</>
						)}
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col>
					{mode !== 'list' && (
						<Button
							variant="secondary"
							onClick={() => setMode('list')}
						>
							Switch to Manual Input Mode
						</Button>
					)}
					{mode !== 'hubspot' && (
						<Button
							variant="secondary"
							onClick={() => setMode('hubspot')}
						>
							Switch to HubSpot Mode
						</Button>
					)}
				</Col>
				<Col>
					<Button
						type="submit"
						disabled={inputData.trim() === ''}
						variant="success"
						className="ms-2 float-end"
					>
						<FontAwesomeIcon icon={faPlay} />{' '}
						{mode === 'list' && (<>
							Submit {inputData.trim() === '' ? 'URLs' : `${inputData?.trim().split('\n').length} URLs`}
						</>)}
						{mode === 'hubspot' && 'Run HubSpot List'}
					</Button>
				</Col>
			</Row>
		</Form>
	);
}
