import { Alert, Col, Row } from 'react-bootstrap';
import { LeadData } from '~/providers/api-provider';
import { useQualificationStatsStore } from '~/stores/qualification-stats-store';

const HIGH_SCORE_THRESHOLD = 4;
const MEDIUM_SCORE_THRESHOLD = 2;
const LOW_SCORE_THRESHOLD = 0;

export interface AISummaryStatsProps {
	totalItems: number;
	items: LeadData[];
	skippedItems: LeadData[];
	createdRecordCount: number;
	updatedRecordCount: number;
}

export function AISummaryStats() {
	const stats = useQualificationStatsStore();

	const processedItems = stats.items.filter((item) => item.qualified !== undefined).length;
	const technicallyQualified = stats.items.filter((item) => item.qualified).length;

	// Only count AI scores for technically qualified items
	const qualifiedItems = stats.items.filter((item) => item.qualified === true);
	const aiScored = qualifiedItems.filter((item) => item.max_ai_score !== undefined).length;
	const highScoring = qualifiedItems.filter((item) => (item.max_ai_score || 0) >= HIGH_SCORE_THRESHOLD).length;
	const mediumScoring = qualifiedItems.filter((item) => {
		const score = item.max_ai_score || 0;
		return score >= MEDIUM_SCORE_THRESHOLD && score < HIGH_SCORE_THRESHOLD;
	}).length;
	const lowScoring = qualifiedItems.filter((item) => {
		const score = item.max_ai_score || 0;
		return score < LOW_SCORE_THRESHOLD;
	}).length;

	if (!stats.totalItems) {
		return null;
	}

	return (
		<Row className="mb-3">
			<Col className="text-center">
				<Alert variant="success">
					<div className="d-flex flex-wrap gap-4 justify-content-center">
						<div>
							<strong>Total Sites:</strong> {stats.totalItems}
						</div>
						<div>
							<strong>Skipped:</strong> {stats.skippedItems?.length || 0}
						</div>
						<div>
							<strong>Processed:</strong> {processedItems}
						</div>
						<div>
							<strong>Technically Qualified:</strong> {technicallyQualified}
						</div>
						<div>
							<strong>Created:</strong> {stats.createdRecordCount}
						</div>
						<div>
							<strong>Updated:</strong> {stats.updatedRecordCount}
						</div>
					</div>
					{aiScored > 0 && (
						<div className="d-flex flex-wrap gap-4 justify-content-center mt-1">
							<div>
								<strong>AI Scored:</strong> {aiScored}
							</div>
							<div>
								<strong>High Score (≥{HIGH_SCORE_THRESHOLD}):</strong> {highScoring}
							</div>
							<div>
								<strong>Medium Score ({MEDIUM_SCORE_THRESHOLD}-{HIGH_SCORE_THRESHOLD - 1}):</strong> {mediumScoring}
							</div>
							<div>
								<strong>Low Score (&lt;{LOW_SCORE_THRESHOLD}):</strong> {lowScoring}
							</div>
						</div>
					)}
				</Alert>
			</Col>
		</Row>
	);
}
