import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useUserInfo } from '~/providers/user-info';

interface SettingsModalProps {
	show: boolean;
	onHide: () => void;
}

const SettingsModal: React.FC<SettingsModalProps> = ({ show, onHide }) => {
	const userInfo = useUserInfo();

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		// Handle form submission logic here
		console.log('Settings updated');
		onHide();
	};

	return (
		<Modal show={show} onHide={onHide}>
			<Modal.Header closeButton>
				<Modal.Title>User Settings</Modal.Title>
			</Modal.Header>
			<Form onSubmit={handleSubmit}>
				<Modal.Body>
					<Form.Group className="mb-3" controlId="formName">
						<Form.Label>Name</Form.Label>
						<Form.Control type="text" placeholder="Enter name" defaultValue={userInfo?.name} />
					</Form.Group>
					<Form.Group className="mb-3" controlId="formEmail">
						<Form.Label>Email address</Form.Label>
						<Form.Control type="email" placeholder="Enter email" defaultValue={userInfo?.email} />
					</Form.Group>
					<Form.Group className="mb-3" controlId="formPhone">
						<Form.Label>Phone Number</Form.Label>
						<Form.Control type="tel" placeholder="Enter phone number" defaultValue={userInfo?.phone} />
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={onHide}>
						Close
					</Button>
					<Button variant="primary" type="submit">
						Save Changes
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default SettingsModal;
