import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
	Card,
	Container,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';

/**
 * Info Icon
 */
export const InfoIcon: React.FC<{
	title: string,
	children: React.ReactNode,
}> = ({ title, children }) => {
	return (
		<OverlayTrigger
			placement="bottom"
			overlay={
				<Tooltip className="card-tooltip">
					<Container>
						<Card className="info-card">
							<Card.Header>
								<Card.Title>
									{title}
								</Card.Title>
							</Card.Header>
							<Card.Body>
								<div>
									{children}
								</div>
							</Card.Body>
						</Card>
					</Container>
				</Tooltip>
			}
		>
			<FontAwesomeIcon icon={faQuestion} />
		</OverlayTrigger>
	);
};
