import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import PropertyDisplayName from './property-display-name';

interface ActiveFilterBadgeProps {
	filterKey: string;
	value: string;
	label?: string;
}

export function ActiveFilterBadge({ filterKey, value, label }: ActiveFilterBadgeProps) {
	const [searchParams, setSearchParams] = useSearchParams();
	const isNegated = filterKey.startsWith('!');

	const handleRemove = () => {
		setSearchParams((prev) => {
			const currentValues = prev.get(filterKey)?.split(',') || [];
			if (currentValues.length <= 1) {
				prev.delete(filterKey);
			} else {
				prev.set(filterKey, currentValues.filter((v) => v !== value).join(','));
			}
			return prev;
		});
	};

	return (
		<Badge
			bg={isNegated ? 'danger' : 'primary'}
			className="me-2 mb-2 p-0"
		>
			<span className="display-name p-2 bg-secondary">
				<PropertyDisplayName typeName="Publication" propKey={filterKey.replace('!', '') as any} />
			</span>
			<span className={`display-value ${isNegated ? 'bg-danger' : 'bg-success'} p-2`}>
				{label || value}
				<Button
					variant="link"
					size="sm"
					className="text-light p-0 ps-2"
					onClick={handleRemove}
				>
					<FontAwesomeIcon icon={faTimes} />
				</Button>
			</span>
		</Badge>
	);
}
