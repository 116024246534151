import { type Alert, AlertStatus } from '@newstex/types/alert';
import type { Results } from '@newstex/types/results';
import Fuse from 'fuse.js';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { CreateAlertButton } from '~/components/create-alert-button';
import LoadingSpinner from '~/components/LoadingSpinner';
import { PageTitle } from '~/components/page-title';
import Preloader from '~/components/Preloader';
import { SearchBox } from '~/components/search-box';
import { SimpleFacetMenu } from '~/components/simple-facet-menu';
import { AlertsTable } from '~/components/tables/alerts-table';
import { useAPI } from '~/providers/api-provider';

export const AlertSearchPage = () => {
	const [searchParams] = useSearchParams();
	const api = useAPI();
	const [loading, setLoading] = useState<boolean>(false);
	const [nextToken, setNextToken] = useState<string>();
	const [allAlerts, setAllAlerts] = useState<Alert[]>([]);
	const [collectionCounts, setCollectionCounts] = useState<Record<string, number>>({});
	const [statusCounts, setStatusCounts] = useState<Partial<Record<AlertStatus, number>>>({});
	const [alerts, setAlerts] = useState<Alert[]>([]);

	async function loadMore(token?: string, refresh = false) {
		const resp = await api.fetchWithAuth<Results<Alert>>(
			`resources/Alert?limit=100&nextToken=${token || nextToken}`,
			refresh ? { cache: 'reload' } : undefined,
		);
		if (resp?.items?.length) {
			setAllAlerts((prev) => [
				...prev,
				...resp.items,
			]);
		}
		setNextToken(resp?.nextToken);
	}

	// Set Collection and Status Counts
	useEffect(() => {
		if (!nextToken && allAlerts.length) {
			const collections: Record<string, number> = {};
			const statuses: Record<string, number> = {};

			for (const alert of allAlerts) {
				if (alert.collection) {
					collections[alert.collection] = (collections[alert.collection] || 0) + 1;
				}

				if (alert.status) {
					statuses[alert.status] = (statuses[alert.status] || 0) + 1;
				}
			}

			setCollectionCounts(collections);
			setStatusCounts(statuses);
		} else {
			setCollectionCounts({});
			setStatusCounts({});
		}
	}, [allAlerts.length, nextToken]);

	// Filter Results
	useEffect(() => {
		if (!searchParams.get('collection') && !searchParams.get('status') && !searchParams.get('q')) {
			setAlerts(allAlerts);
		} else {
			let filteredAlerts = allAlerts;

			// Apply collection filter
			const collectionFilter = searchParams.get('collection');
			if (collectionFilter) {
				filteredAlerts = filteredAlerts.filter((a) => a.collection === collectionFilter);
			}

			// Apply status filter
			const statusFilter = searchParams.get('status');
			if (statusFilter) {
				filteredAlerts = filteredAlerts.filter((a) => a.status === +statusFilter);
			}

			// Apply search
			if (searchParams.get('q')) {
				const searcher = new Fuse(filteredAlerts, {
					shouldSort: true,
					threshold: 0.6,
					location: 0,
					distance: 100,
					minMatchCharLength: 1,
					keys: [
						'name',
						'$id',
						'collection',
					],
				});
				filteredAlerts = searcher
					.search(searchParams.get('q') || '')
					.map((result) => result.item);
			}

			setAlerts(filteredAlerts);
		}
	}, [allAlerts, searchParams]);

	const fetchData = async (refresh = false) => {
		setLoading(true);
		const resp = await api.fetchWithAuth<Results<Alert>>(
			'resources/Alert?limit=100',
			refresh ? { cache: 'reload' } : undefined,
		);
		setAllAlerts(resp?.items || []);
		setNextToken(resp?.nextToken);
		if (resp?.nextToken) {
			await loadMore(resp.nextToken, refresh);
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchData();
	}, [api]);

	return (
		<Container fluid>
			<PageTitle title="Alerts" />
			<Row>
				<Col>
					<SearchBox
						refreshHook={() => fetchData(true)}
					/>
					<div className="float-end">
						<CreateAlertButton />
					</div>
				</Col>
			</Row>
			{loading && <Preloader type="three-bounce" variant="success" />}
			<hr/>
			<Row>
				<Col md={2} xxl={1}>
					<SimpleFacetMenu
						title="Status"
						prop="status"
						options={[
							{ label: 'All', value: '' },
							...Object.entries(statusCounts).map(([status, count]) => ({
								label: AlertStatus[status as any],
								value: status,
								count,
							})),
						]}
					/>
					<SimpleFacetMenu
						title="Collection"
						prop="collection"
						options={[
							{ label: 'All', value: '' },
							...Object.entries(collectionCounts).map(([collection, count]) => ({
								label: collection,
								value: collection,
								count,
							})),
						]}
					/>
				</Col>
				<Col md={10} xxl={11}>
					{loading ? (
						<LoadingSpinner loading={loading} />
					) : (
						<AlertsTable
							alerts={alerts}
							hasMore={Boolean(nextToken?.length)}
							onLoadMore={() => loadMore(nextToken)}
						/>
					)}
				</Col>
			</Row>
		</Container>
	);
};
