import { faMessage, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { KnowledgeBase } from '@newstex/types/rag';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import EditRAGModal from '~/components/editors/modals/edit-rag-modal';
import { SearchFacetConfig, SearchWithFacets } from '~/components/search-with-facets';
import { KnowledgeBaseTable } from '~/components/tables/knowledge-base-table';
import { useAIChat } from '~/providers/ai-chat-provider';

export const KnowledgeBaseFacetConfig: SearchFacetConfig[] = [
	{
		attribute: 'category',
		title: 'Category',
	},
	{
		attribute: 'created_by',
		title: 'Created By',
	},
	{
		attribute: 'created_at',
		title: 'Created At',
		type: 'Date',
	},
];

export const KnowledgeBaseSearchPage = () => {
	const [createModal, setCreateModal] = useState(false);
	const aiChat = useAIChat();

	const fallbackSearch = async (q: string): Promise<KnowledgeBase[]> => {
		console.log('Fallback Search', q);
		return [];
	};

	return (
		<>
			<SearchWithFacets
				indexName="RAG"
				title="Knowledge Base"
				info="This knowledge base is designed to be used by the AI system, using Retreival Augmented Generation (RAG)."
				defaultSort="created_at:desc"
				resultsTable={KnowledgeBaseTable}
				fallbackSearch={fallbackSearch}
				facets={KnowledgeBaseFacetConfig}
				actions={<>
					<Button className="me-2" size="sm" variant="outline-success" onClick={() => setCreateModal(true)}>
						<FontAwesomeIcon icon={faPlus} /> Create
					</Button>
					<Button className="me-2" variant="outline-secondary" size="sm" onClick={() => {
						aiChat.show();
					}}>
						<FontAwesomeIcon icon={faMessage} /> AI Chat
					</Button>
				</>}
			/>
			<EditRAGModal item={{
				$type: 'RAG',
			}} show={createModal} onClose={() => setCreateModal(false)} />
		</>
	);
};
