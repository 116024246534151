import { TypedObject } from './base';

export type BaseObjectTypeName<T extends TypedObject> = T extends { $type: infer U } ? U : never;
export type BaseModelName<T extends TypedObject> = T extends { __model__: infer U } ? U : never;
export type ContentTypeName<T extends TypedObject> = T extends { __model__: 'Content', tags: [infer U] } ? U : never;
export type TypedObjectName<T extends TypedObject> = ContentTypeName<T> | BaseObjectTypeName<T> | BaseModelName<T>;

export interface ExplicitReference<T extends TypedObject = any> {
	$type: TypedObjectName<T>;
	$id: string;
}

/**
 * Use this when using just string IDs
 */
export type ReferenceString<T> = string | T;

/**
 * Use this when it could be a string ID or a full reference object
 */
export type Reference<T extends TypedObject> = string | ExplicitReference<T> | T;

export function convertReferenceToString(ref: Reference<any> | ReferenceString<any>): string {
	if (typeof ref === 'string') {
		return ref;
	}

	if (typeof ref === 'object') {
		if ('$id' in ref) {
			return ref.$id;
		}

		if ('__id__' in ref) {
			return ref.__id__;
		}

		if (ref.$type === 'Category' && ref.code) {
			return ref.code;
		}
	}

	if (ref.id) {
		return ref.id;
	}

	if (ref.code) {
		return ref.code;
	}

	throw new Error(`Could not convert reference to string: ${ref}`);
}

export function convertReferencesToStrings(
	refs: (Reference<any> | ReferenceString<any>)[] | Set<Reference<any>>,
): string[] {
	if (!refs) {
		return [];
	}

	if (Array.isArray(refs)) {
		return refs.map(convertReferenceToString);
	}

	if (typeof refs === 'object' && refs instanceof Set) {
		return Array.from(refs).map(convertReferenceToString);
	}

	return [convertReferenceToString(refs)];
}
