import { AIChatCommand } from './ai-chat';
import { GenerateStoryCommand } from './generate-story';
import { LogoutCommand } from './logout';
import type { Command } from './type';

export * from './type';

export const commands: Command[] = [
	{
		id: 'qualify-leads',
		icon: ['fad', 'table-cells'],
		name: 'Qualify Leads',
		description: 'Qualify a list of leads (by URL or HubSpot List) using Technical and AI ranking. Can optionally sync to HubSpot.',
		handler: ({ navigate }) => {
			navigate('/tools/qualify-leads');
		},
	},
	AIChatCommand,
	LogoutCommand,
	GenerateStoryCommand,
];
