import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Publication } from '@newstex/types/content';
import { useState } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';

import { CreateStoryModal } from './editors/modals/create-story-modal';

interface CreateStoryButtonProps {
	publication: Publication;
	onStoryCreated?: () => void;
	size?: ButtonProps['size'];
	variant?: ButtonProps['variant'];
	className?: string;
}

// "NTX" Publisher ID
export const NEWSTEX_PARENT_ID = 'a008000000DZvOEAA1';

export function CreateStoryButton({
	publication, onStoryCreated, size, variant, className,
}: CreateStoryButtonProps) {
	const [showCreateStoryModal, setShowCreateStoryModal] = useState(false);

	const handleCreateStory = () => {
		setShowCreateStoryModal(true);
	};

	const handleCloseModal = (saved: boolean) => {
		setShowCreateStoryModal(false);
		if (saved && onStoryCreated) {
			onStoryCreated();
		}
	};

	return (
		<>
			<Button
				size={size || 'sm'}
				variant={variant || 'outline-secondary'}
				className={className}
				onClick={handleCreateStory}
			>
				<FontAwesomeIcon icon={faPlus} className="me-1" />
				Create New Story
			</Button>

			<CreateStoryModal
				show={showCreateStoryModal}
				onClose={handleCloseModal}
				publication={publication}
			/>
		</>
	);
}
