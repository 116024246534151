import type { BaseObject } from './base';
import type { ContentStatus } from './content-status';
import type { Contract } from './contract';
import type { ISODate, UnixTimestamp } from './dates';
import { RichMediaType } from './media';
import type { Reference, ReferenceString } from './reference';
import { StatusChoice } from './status';
import type { Story } from './story';
import type { User } from './user';

/**
 * Content record types, including Publisher and Publication records
 *
 * @newscrunch Blog, Publisher, or Account, depending on the value of {@link tags} and {@link parent}
 *
 * |Tag         |Parent |NewsCrunch Type |
 * |------------|-------|----------------|
 * |Publication |_ANY_  |Blog            |
 * |Publisher   |_ANY_  |Publisher       |
 * |Publisher   |_None_ |Account         |
 */
export interface Content extends BaseObject {
	/**
	 * Type of Content Object
	 *
	 * Typically this is just "Publisher" or "Publication"
	 * @hubspot `type` Type
	 */
	$type: 'Publisher' | 'Publication';

	/**
	 * Alternate Names
	 *
	 * @calculated
	 * @hubspot `alternate_company_names` Alternate Company Names
	 */
	alt_names?: string[];

	/**
	 * Newstex Code
	 *
	 * @deprecated
	 * @newscrunch
	 *
	 * |NewsCrunch Type |NewsCrunch ID  |Display Name |
	 * |----------------|---------------|-------------|
	 * |Blog            |_newstex_code_ |Newstex Code |
	 */
	code?: string;

	/**
	 * Alternate Codes
	 */
	alt_codes?: string[];

	/**
	 * Code used by the Publisher to identify this content set
	 */
	provider_code?: string;

	/**
	 * Newstex ID
	 *
	 * For new publishers this is auto generated and set in HubSpot once certain conditions are met for the publisher
	 * @indexed
	 * @newscrunch
	 *
	 * |NewsCrunch Type |NewsCrunch ID    |Display Name |
	 * |----------------|-----------------|-------------|
	 * |Blog            |_blog_code_      |Blog ID 	    |
	 * |Publisher       |_publisher_code_ |Publisher ID |
	 * |Account         |_account_code_   |Account ID   |
	 */
	newstex_id?: string;

	/**
	 * CloudSearch ID
	 * @deprecated
	 */
	simple_id?: string;

	/**
	 * HubSpot ID
	 *
	 * @hubspot `hs_object_id` HubSpot Object ID
	 * @newscrunch
	 *
	 * |NewsCrunch Type |NewsCrunch ID |Display Name |
	 * |----------------|--------------|-------------|
	 * |Account         |_hubspot_id_  |HubSpot ID   |
	 */
	hubspot_id?: string;

	/**
	 * If set to true, this content will not be sync'd with HubSpot
	 * @defaultValue false
	 */
	block_from_hubspot?: boolean;

	/**
	 * RSS Feed URL(s) for the content
	 *
	 * @hubspot `content_feed_url` Content Feed URL
	 * @newscrunch
	 *
	 * |NewsCrunch Type |NewsCrunch ID |Display Name |
	 * |----------------|--------------|-------------|
	 * |Blog            |_xml_feed_    |XML Feed     |
	 * @category Feed
	 */
	xml_feed?: string[];

	/**
	 * URL
	 *
	 * @hubspot `website` Website
	 * @newscrunch
	 *
	 * |NewsCrunch Type |NewsCrunch ID |Display Name |
	 * |----------------|--------------|-------------|
	 * |Blog            |_blog_url_    |Blog URL     |
	 * |Account         |_website_     |Website      |
	 */
	url?: string;

	/**
	 * Domain name of URL (Calcualted, for DynamoDB lookup purposes)
	 * @calculated
	 * @category Calculated
	 */
	domain?: string;

	/**
	 * Alternate URLs
	 *
	 * Automatically set to old values of "url" when it changes
	 * @hubspot `url_history` URL History
	 */
	alt_urls?: string[];

	/**
	 * Homepage URL
	 *
	 * @deprecated
	 */
	home_url?: string;

	/**
	 * Client IDs
	 *
	 * Key/Value mapping of client ID to code. Used in royalty matching.
	 */
	client_ids?: Record<string, string>;

	/**
	 * NewsCrunch Account ID
	 *
	 * Used for linking to NewsCrunch for Accounting purposes
	 */
	account_id?: number;

	/**
	 * Integration Specialist
	 * @calculated
	 */
	integration_specialist?: Reference<User>;

	/**
	 * Normalizer CSS Selectors for Removed Tags
	 */
	norm_remove_tags?: string[];

	/**
	 * Last Reviewed At
	 */
	last_editorial_review?: UnixTimestamp;

	/**
	 * Note from last content review
	 */
	last_review_comment?: string;

	/**
	 * Last Post Date
	 *
	 * Updated by Feed processing, but ONLY if it's more than 24 hours different
	 * This is used to keep HubSpot up-to-date
	 *
	 * @category Story
	 * @calculated
	 */
	last_post_date?: UnixTimestamp;

	/**
	 * Last date a story was *received*
	 *
	 * @category Story
	 * @calculated
	 */
	last_received_date?: UnixTimestamp;

	/**
	 * First Activated Date
	 */
	activated?: UnixTimestamp;

	/**
	 * Reviewed By
	 */
	reviewed_by?: Reference<User>;

	/**
	 * Start Date
	 * @newscrunch
	 *
	 * |NewsCrunch Type |NewsCrunch ID |Display Name |
	 * |----------------|--------------|-------------|
	 * |Account         |_start_date_  |Start Date   |
	 */
	start_date?: UnixTimestamp;

	/**
	 * Integration Completion Date
	 * @calculated
	 * @newscrunch
	 *
	 * |NewsCrunch Type |NewsCrunch ID                 |Display Name                |
	 * |----------------|------------------------------|----------------------------|
	 * |Blog            |_integration_completion_date_ |Integration Completion Date |
	 */
	integration_completion_date?: UnixTimestamp;

	/**
	 * Archive Date
	 * @calculated
	 */
	archive_date?: ISODate;

	/**
	 * Deletion Date
	 * @calculated
	 * @newscrunch
	 *
	 * |NewsCrunch Type |NewsCrunch ID   |Display Name  |
	 * |----------------|----------------|--------------|
	 * |Blog            |_deletion_date_ |Deletion Date |
	 */
	deletion_date?: ISODate;

	/**
	 * Termination Reason
	 *
	 * @hubspot `termination_reason` Termination Reason
	 * @newscrunch
	 *
	 * |NewsCrunch Type |NewsCrunch ID        |Display Name       |
	 * |----------------|---------------------|-------------------|
	 * |Blog            |_termination_reason_ |Termination Reason |
	 */
	termination_reason?: string;

	//
	// Location Info
	//

	/**
	 * City
	 *
	 * @hubspot `city` City
	 * @newscrunch
	 *
	 * |NewsCrunch Type |NewsCrunch ID          |Display Name         |
	 * |----------------|-----------------------|---------------------|
	 * |Account         |_royalty_address_city_ |Royalty Address City |
	 */
	city?: string;

	/**
	 * State
	 *
	 * @hubspot `state` State
	 * @newscrunch
	 *
	 * |NewsCrunch Type |NewsCrunch ID                       |Display Name                      |
	 * |----------------|------------------------------------|----------------------------------|
	 * |Account         |_royalty_address_state_or_province_ |Royalty Address State or Province |
	 */
	state?: string;

	/**
	 * Country Focus
	 *
	 * Focus of the Content on the Blog
	 * @hubspot `country_focus` Country Focus
	 * @newscrunch
	 *
	 * |NewsCrunch Type |NewsCrunch ID   |Display Name  |
	 * |----------------|----------------|--------------|
	 * |Blog            |_country_focus_ |Country Focus |
	 */
	country?: string;

	/**
	 * Published Country
	 *
	 * Actual Country the Blog is Published in
	 * @hubspot `pub_country` Country of Origin
	 * @newscrunch
	 *
	 * |NewsCrunch Type |NewsCrunch ID             |Display Name            |
	 * |----------------|--------------------------|------------------------|
	 * |Account         |_royalty_address_country_ |Royalty Address Country |
	 */
	pub_country?: string;

	/**
	 * Region
	 */
	region?: string;

	/**
	 * Zip Code
	 *
	 * @hubspot `zip` Postal Code
	 * @newscrunch
	 *
	 * |NewsCrunch Type |NewsCrunch ID                        |Display Name                       |
	 * |----------------|-------------------------------------|-----------------------------------|
	 * |Account         |_royalty_address_zip_or_postal_code_ |Royalty Address Zip or Postal Code |
	 */
	zip?: string;

	//
	// Other Metadata
	//

	/**
	 * Description
	 *
	 * @hubspot `description` Description
	 * @newscrunch
	 *
	 * |NewsCrunch Type |NewsCrunch ID |Display Name |
	 * |----------------|--------------|-------------|
	 * |Account         |_description_ |Description  |
	 */
	description?: string;

	/**
	 * Language
	 *
	 * @hubspot `language` Language
	 * @newscrunch
	 *
	 * |NewsCrunch Type |NewsCrunch ID |Display Name |
	 * |----------------|--------------|-------------|
	 * |Blog            |_language_    |Language     |
	 */
	language?: string;

	/**
	 * LC Classes
	 *
	 * @see [Library of Congress Classifications](https://www.loc.gov/catdir/cpso/lcco/)
	 * @see {@link LC_CLASS_TABLE}
	 */
	lc_class?: string[];

	/**
	 * Topics
	 *
	 * @newscrunch NewsCrunch also stores the first topic in `main_blog_topic`
	 *
	 * |NewsCrunch Type |NewsCrunch ID     |Display Name    |
	 * |----------------|------------------|----------------|
	 * |Blog            |_all_blog_topics_ |All Blog Topics |
	 */
	topics?: string[];

	/**
	 * Approved Products
	 *
	 * @hubspot `approved_products` Approved Products
	 * @see {@link Content.product_status}
	 */
	products?: string[];

	/**
	 * Writer Gender
	 * @newscrunch
	 *
	 * |NewsCrunch Type |NewsCrunch ID   |Display Name  |
	 * |----------------|----------------|--------------|
	 * |Blog            |_writer_gender_ |Writer Gender |
	 */
	writer_gender?: string;

	/**
	 * Political bent
	 * @newscrunch
	 *
	 * |NewsCrunch Type |NewsCrunch ID    |Display Name   |
	 * |----------------|-----------------|---------------|
	 * |Blog            |_political_bent_ |Political Bent |
	 */
	political_bent?: string;

	//
	// HubSpot Sync Fields added by Michael March 2022
	//

	/**
	 * Content Category
	 *
	 * @hubspot `content_category` Content Category
	 */
	content_category?: string;

	/**
	 * Blog, Video, or Web Publication?
	 *
	 * @hubspot `source_type` Blog, Video, or Web Publication?
	 */
	source_type?: 'Blog' | 'Video' | 'Web Publication';

	/**
	 * Frequency
	 *
	 * @hubspot `frequency` Frequency
	 */
	frequency?: string;

	/**
	 * Organization Type
	 *
	 * Funding and Organization type
	 * @hubspot `org_type` Org Type
	 */
	org_type?: 'Independent' | 'Non-Profit' | 'Corporate' | 'Government' | 'Educational' | 'Traditional News' | 'Other';

	/**
	 * Funded By
	 * @deprecated
	 */
	funded_by?: string[];

	/**
	 * Status
	 *
	 * @defaultValue Lead
	 * @hubspot `account_status` Status
	 * @newscrunch
	 *
	 * |NewsCrunch Type |NewsCrunch ID |Display Name |
	 * |----------------|--------------|-------------|
	 * |Blog            |_status_      |Status       |
	 * |Publisher       |_status_      |Status       |
	 * |Account         |_status_      |Status       |
	 */
	status?: StatusChoice;

	/**
	 * Restriction Type
	 *
	 * Old restriction types used when we had Mobile and custom deliveries for specific clients only
	 * @newscrunch
	 *
	 * |NewsCrunch Type |NewsCrunch ID      |Display Name     |
	 * |----------------|-------------------|-----------------|
	 * |Blog            |_restriction_type_ |Restriction Type |
	 * |Publisher       |_restriction_type_ |Restriction Type |
	 * |Account         |_restriction_type_ |Restriction Type |
	 */
	restriction_type?: 'Custom' | 'Mobile' | 'Publisher Delivery';

	//
	// Ownership
	//

	/**
	 * Contract
	 * @deprecated
	 */
	contract?: ReferenceString<Contract>;

	/**
	 * Parent
	 *
	 * @hubspot `hs_parent_company_id` Parent Company
	 */
	parent?: ReferenceString<Publisher>;

	/**
	 * Newstex Owner
	 *
	 * @hubspot `newstex_owner` Newstex Owner
	 */
	owner?: ReferenceString<User>;

	/**
	 * Email addresses of the owners of this content (Bloggers, not the Newstex owner)
	 */
	owner_emails?: string[];

	//
	// Information
	//

	/**
	 * Authoritative Ranking
	 *
	 * min: -10
	 * max: 10
	 * @depricated
	 */
	auth_rank?: number

	/**
	 * Calculate Royalties
	 *
	 * If true, this record should be sync'd with NewsCrunch
	 * @defaultValue true
	 * @hubspot *required* `calculate_royalties` Calculate Royalties
	 * @category NewsCrunch
	 * @newscrunch This is always true in NewsCrunch
	 *
	 * |NewsCrunch Type |NewsCrunch ID         |Display Name        |
	 * |----------------|----------------------|--------------------|
	 * |Account         |_calculate_royalties_ |Calculate Royalties |
	 */
	calculate_royalties?: boolean;

	/**
	 * Closed Sites Only
	 *
	 * If true, this content can only be dellivered to "Closed Site" distributors (no open web distribution)
	 * @defaultValue false
	 * @hubspot *required* `closed_sites_only` Closed Sites Only
	 */
	closed_sites_only?: boolean;

	/**
	 * Custom Copyright Line
	 *
	 * If set, this custom copyright line will be used instead of the default "Delivered by Newstex" dateline
	 */
	copyright?: string;

	/**
	 * Moved To
	 *
	 * Allows us to "Move" a NewsCore record to another
	 */
	moved_to?: ReferenceString<Content>;

	/**
	 * Feed Priority
	 *
	 * Priority: used to mark things as a high priority if they are revenue generating or need
	 * priority feed status. Copied down to the [[Feed]] object
	 * @defaultValue standard
	 * @hubspot `feed_priority` Feed Priority
	 * @category Feed
	 */
	feed_priority?: 'high' | 'low' | 'standard';

	/**
	 * Exempt from Auto-Archive
	 *
	 * If true, this content will not be automatically archived if it has no recent content
	 * @defaultValue false
	 * @hubspot `disable_auto_archive` Exempt from Automatic Archival
	 * @category HubSpot
	 */
	disable_auto_archive?: boolean;

	/**
	 * Logo URL
	 *
	 * @hubspot `logo_url` Logo URL
	 */
	logo_url?: string;

	/** Allow Marketing */
	allow_marketing?: boolean;
	/** Translation Rights */
	can_translate?: boolean;
	/**
	 * Editorial Recomended Removal
	 *
	 * TODO: Remove this?
	 */
	recommended_removal?: boolean;

	/**
	 * Disable Controversial Story Alerts
	 *
	 * If set to true, slack alerts for "Controversial Content" will be disabled
	 * @defaultValue false
	 */
	disable_controv_alert?: boolean;

	/**
	 * Average number of story posts per week
	 * @category Calculated
	 */
	posts_per_week?: number;

	/**
	 * IQR Multiplier for Anomaly detection
	 *
	 * @see [../scripts/check-cloud-watch-metrics.ts]
	 */
	anomaly_iqr_multiplier?: number;

	/**
	 * Technical Contact Email
	 *
	 * The email address of the contact to alert for feed issues at the company
	 *
	 * @hubspot string
	 */
	technical_contact_email?: string;
}

export interface ContentProductStatus {
	status: 'Lead' | 'Approved' | 'Rejected';
	reviewed_by?: Reference<User>;
	reviewed_at?: UnixTimestamp;
	transaction_id?: string;
	comment?: string;
}

export interface Publisher extends Content {
	$type: 'Publisher';
}

export interface Publication extends Content {
	$type: 'Publication';

	/*
	 * @newscrunch
	 *
	 * |NewsCrunch Type |NewsCrunch ID        |Display Name       |
	 * |----------------|---------------------|-------------------|
	 * |Blog            |_publication_status_ |Publication Status |
	 */
	content_status?: ContentStatus;

	content_category?: string;

	/*
	 * @newscrunch
	 *
	 * |NewsCrunch Type |NewsCrunch ID      |Display Name     |
	 * |----------------|-------------------|-----------------|
	 * |Blog            |_publication_type_ |Publication Type |
	 */
	content_type?: string;

	feed_priority?: 'standard' | 'high' | 'low';
	frequency?: string;

	/** Valid Feed */
	valid_feed?: boolean;

	/**
	 * List of approved products
	 */
	products?: string[];

	/**
	 * Detailed status of each product
	 */
	product_status?: Record<string, ContentProductStatus>;

	// TODO: Move these up to the top-level Content record type?
	average_monthly_revenue?: number;
	maximum_monthly_revenue?: number;
	max_revenue?: number;

	/** Publication Type */
	pub_type?: string;

	/**
	 * Publisher
	 *
	 * The parent of a Publication is always called a Publisher
	 */
	parent?: ReferenceString<Publisher>;

	/**
	 * Blocked Content configuration
	 *
	 *
	 * This is a mapping of story fields to a list of blocked values. If a story has a field that
	 * matches one of the blocked values, it will be blocked, and the story will not be processed.
	 * @category Feed
	 */
	blocked_content?: Partial<Record<keyof Story, string[]>>;

	/**
	 * Rich Media Restrictions
	 *
	 * This allows stripping out Images or Video from content
	 */
	rich_media_prohibited?: RichMediaType[];

	/**
	 * AI Score
	 *
	 * A score from 0 to 100 that indicates the relevance of the stories in this publication.
	 * Explicitly used for status of "Lead".
	 */
	ai_score?: number;

	// Joined fields from Searches in TypeSense
	Publisher?: Partial<Publisher>;
}

export function isPublisher(obj?: any): obj is Publisher {
	// New style $type check
	if (obj?.$type === 'Publisher') {
		return true;
	}

	if (!obj?.tags) {
		return false;
	}
	const tags = typeof obj?.tags === 'string' ? obj.tags.split(';') : obj.tags;
	// Handle the case where tags is a Set
	if (tags instanceof Set) {
		return tags.has('Publisher') || false;
	}
	return tags?.includes?.('Publisher') || false;
}

export function isPublication(obj?: any): obj is Publication {
	// New style $type check
	if (obj?.$type === 'Publication') {
		return true;
	}

	if (!obj?.tags) {
		return false;
	}
	const tags = typeof obj?.tags === 'string' ? obj.tags.split(';') : obj.tags;
	// Handle the case where tags is a Set
	if (tags instanceof Set) {
		return tags.has('Publication') || false;
	}
	return tags?.includes?.('Publication') || false;
}

export function isContent(obj?: any, tableName?: string): obj is Content {
	return obj && (
		isPublisher(obj)
		|| isPublication(obj)
		|| obj?.__model__ === 'Content'
		|| tableName === 'Content'
		|| tableName === 'Publication'
		|| tableName === 'Publisher'
	);
}
