import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchHit } from '@newstex/search';
import { Story } from '@newstex/types/story';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useSearchParams } from 'react-router-dom';
import { formatDateString } from '~/lib/utils';

import DataTable, { DataTableMethods } from '../DataTable';
import { LoadMoreButton } from '../load-more-button';
import { PropertyDisplayValue } from '../property-display-value';

export function StoriesTable(
	{
		items,
		searchText,
		hasMore,
		onLoadMore,
		loading,
		hideColumns,
		fallbackSearch,
	}: {
		items: SearchHit<Story>[],
		searchText?: string,
		hasMore?: boolean,
		onLoadMore?: () => void | Promise<void>,
		loading?: boolean,
		hideColumns?: string[],
		fallbackSearch?: (q: string) => Promise<SearchHit<Story>[]>,
	},
) {
	const [searchParams] = useSearchParams();
	const queryText = useMemo(() => {
		if (searchText) {
			return searchText;
		}
		return searchParams.get('q') || '';
	}, [searchText, searchParams]);
	const dataTableRef = useRef<DataTableMethods>(null);
	const columnHelper = createColumnHelper<Story & { Publication?: { id: string; name: string; newstex_id: string }}>();
	const columns = useMemo(() => [
		columnHelper.accessor('headline', {
			header: 'Headline',
			enableSorting: true,
			cell: ({ getValue, row: { original }}) => {
				return <>
					{/* TODO: Make this highlightable */}
					<Link to={`/stories/${original.__id__}`}>
						<PropertyDisplayValue propName="headline" propValue={getValue()} />
					</Link>
					{original.categories?.includes('SPAM') && <span className="badge bg-danger ms-2">SPAM</span>}
					{original.categories?.includes('CONTROV') && <span className="badge bg-danger ms-2">Controversial</span>}
					{original.categories?.includes('PRHYPE') && <span className="badge bg-danger ms-2">PR Hype</span>}
					{original.categories?.includes('GUESTPOST') && <span className="badge bg-danger ms-2">Guest Post</span>}
					<br />
					<small className="text-muted">
						{original.__id__}
					</small>
					<br />
					<small>
						<PropertyDisplayValue
							propName="permalink"
							propValue={original.permalink}
							maxLength={100}
							className="text-muted"
						/>
					</small>
				</>;
			},
		}),
		columnHelper.accessor('stats.word_count', {
			header: 'Word Count',
			enableSorting: true,
			cell: ({ getValue }) => {
				return <>
					<PropertyDisplayValue propName="word_count" propValue={getValue()} />
				</>;
			},
		}),
		columnHelper.accessor('publication', {
			header: 'Publication',
			enableSorting: false,
			cell: ({ getValue, row: { original }}) => {
				if (original.Publication) {
					return <div key={original.Publication.newstex_id}>
						<Link to={`/publications/${original.Publication.id}`}>
							{original.Publication.name}
							{original.Publication.newstex_id && <small> [{original.Publication.newstex_id}]</small>}
						</Link>
					</div>;
				}
				return <>
					<PropertyDisplayValue propName="publication" propValue={getValue()} />
				</>;
			},
		}),
		columnHelper.accessor('language', {
			header: 'Language',
			enableSorting: false,
			cell: ({ getValue }) => {
				return <>
					<PropertyDisplayValue propName="language" propValue={getValue() || 'en'} />
				</>;
			},
		}),
		columnHelper.accessor('received_at', {
			header: 'Received At',
			enableSorting: true,
			cell: ({ getValue }) => {
				return <PropertyDisplayValue propName="received_at" propValue={getValue()} />;
			},
		}),
		columnHelper.accessor('date', {
			header: 'Publication Date',
			enableSorting: true,
			cell: ({ getValue }) => {
				return <PropertyDisplayValue propName="date" propValue={getValue()} />;
			},
		}),
	], [columnHelper]);

	return (<>
		<DataTable
			columns={columns}
			items={items || []}
			ref={dataTableRef}
			searchText={searchText}
			loading={loading}
			defaultSort="received_at"
			columnVisibility={hideColumns?.reduce((acc, col) => ({ ...acc, [col]: false }), {})}
			fallbackSearch={fallbackSearch}
			striped
			sortable
		/>
		<LoadMoreButton show={hasMore} onClick={onLoadMore} />
		<div className="float-end pb-2">
			<Button variant="outline-secondary" size="sm" onClick={() => {
				dataTableRef.current?.exportToCSV('Stories', {
					'Newstex ID': '__id__',
					Headline: 'headline',
					Publication: (item) => item?.Content?.name || item?.publication,
					'Publication Newstex ID': (item) => item?.Content?.newstex_id || '',
					'Publication Date': (item) => formatDateString(item.date),
					'Received At': (item) => formatDateString(item.received_at),
					Permalink: 'permalink',
					'External ID': 'external_id',
					'Word Count': (item) => item.stats?.word_count,
					Language: 'language',
					Author: 'source',
				});
			}}>
				<FontAwesomeIcon icon={faDownload} /> CSV
			</Button>
		</div>
	</>);
}
