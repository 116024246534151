import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { CategoryDebugData, CategoryDebugMatch } from '@newstex/types/category-debug-data';
import { convertReferencesToStrings } from '@newstex/types/reference';
import type { Story } from '@newstex/types/story';
import { createColumnHelper } from '@tanstack/react-table';
import Case from 'case';
import {
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { Button } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useAPI } from '~/providers/api-provider';

import DataTable, { DataTableMethods } from './DataTable';
import { Highlighter } from './Highlighter';

export function CategoryDebugger({ story }: { story: Story }) {
	const api = useAPI();
	const [loading, setLoading] = useState<boolean>(false);
	const [debugData, setDebugData] = useState<CategoryDebugData>();
	const dataTableRef = useRef<DataTableMethods>(null);
	const [searchText, setSearchText] = useState<string>('');
	const [highlightClassNames, setHighlightClassNames] = useState<Record<string, string>>({});
	const [highlightWords, setHighlightWords] = useState<string[]>([]);
	const [categoryOptions, setCategoryOptions] = useState<string[]>([]);
	const columnHelper = createColumnHelper<CategoryDebugMatch>();
	const columns = useMemo(() => [
		columnHelper.accessor('field', {
			header: 'Field',
			enableSorting: true,
			cell: ({ getValue }) => {
				return <b>{Case.title(getValue())}</b>;
			},
		}),
		columnHelper.accessor('text', {
			header: 'Text',
			enableSorting: true,
		}),
		columnHelper.accessor('category', {
			header: 'Category',
			enableSorting: true,
		}),
		columnHelper.accessor('weight', {
			header: 'Weight',
			enableSorting: true,
		}),
	], [debugData?.matches]);

	const setMatches = (matches: CategoryDebugMatch[]) => {
		setHighlightWords(matches.map((match) => match.text));
		setHighlightClassNames(Object.fromEntries(matches.map((match) => {
			let textClassName = 'warning';

			if (match.weight < 0) {
				textClassName = 'danger';
			}

			return [match.text, `px-2 text-bg-${textClassName}`];
		})));
	};

	const setSearchCategories = (categories: string[]) => {
		if (debugData?.matches) {
			setMatches(debugData.matches.filter((match) => categories.includes(match.category)));
		} else {
			setMatches([]);
		}
	};

	useEffect(() => {
		setCategoryOptions(convertReferencesToStrings(story.categories || []));
		const fetchData = async () => {
			const resp = await api.fetchWithAuth<CategoryDebugData>(`resources/Story/${story.__id__}/category-debug`);
			setDebugData(resp);
			if (resp.matches) {
				const matchingCategoryCodes = new Set(resp.matches.map((match) => match.category));
				setCategoryOptions(Array.from(matchingCategoryCodes).sort());
			}
			setSearchCategories(['SPAM', 'PRHYPE']);
			setLoading(false);
		};
		setLoading(true);
		fetchData();
	}, [story.__id__]);

	return (<>
		<Typeahead
			id="category-select"
			labelKey="category"
			options={categoryOptions}
			placeholder="Choose a category..."
			className="typeahead-container"
			clearButton
			style={{
				maxWidth: '300px',
				marginBottom: '10px',
			}}
			onChange={(selected) => {
				setSearchCategories(selected.map((item) => item.toString()));
				setSearchText(selected.join(' OR '));
			}}
		/>

		<pre className="wrap">
			<Highlighter
				highlightClassName={highlightClassNames}
				searchWords={highlightWords}
				autoEscape={true}
				textToHighlight={debugData?.text_content || ''}
			/>
		</pre>
		<DataTable
			columns={columns}
			items={debugData?.matches || []}
			ref={dataTableRef}
			searchText={searchText}
			tableRowClassNameHelper={({ original }) => {
				if (original.category === 'SPAM') {
					return 'table-danger';
				}
				return '';
			}}
			striped
			sortable
		/>
		<div className="float-end">
			<Button variant="outline-secondary" size="sm" onClick={() => {
				dataTableRef.current?.exportToCSV(`${story.__id__} Category Debug Data`);
			}}>
				<FontAwesomeIcon icon={faDownload} /> CSV
			</Button>
		</div>
	</>);
}
