import { bedrock } from '@ai-sdk/amazon-bedrock';
import type { QueryFor } from '@newstex/core/aws/ddb';
import type { Logger } from '@newstex/core/logger';
import type {
	Publication,
	Reference,
	Story,
	TypedObject,
	TypedObjectName,
} from '@newstex/types';
import { CoreTool, generateObject, generateText } from 'ai';
import { z } from 'zod';

import { DEFAULT_MODEL } from '../bedrock';

export interface ResourceLookup {
	/**
	 * Function to get a publication by ID
	 */
	getPublication?: (id: string) => Promise<Publication | null>;
	/**
	 * Function to query DynamoDB
	 */
	query?: <T extends TypedObject>(params: QueryFor<T>) => Promise<T[]>;
	/**
	 * Function to get a single item from DynamoDB by its type and ID
	 */
	getById?: <T extends TypedObject>(
		typeName: TypedObjectName<T>,
		id: Reference<T>,
		args?: {
			ConsistentRead?: boolean;
			ProjectionExpression?: string;
			ExpressionAttributeNames?: Record<string, string>;
		}
	) => Promise<T | null>;
	/**
	 * Function to get a story file by story ID and file name
	 */
	getStoryFile?: (story: Story, fileName: string) => Promise<string | null>;
}

export interface AgentStep {
	id: string;
	label: string;
	description: string;
}

export interface AgentContext {
	/**
	 * The model provider to use for LLM calls
	 */
	modelProvider?: typeof bedrock;
	/**
	 * The specific model to use
	 */
	model?: Parameters<typeof bedrock>[0];
	/**
	 * Additional settings to pass to the model provider
	 */
	settings?: Parameters<typeof bedrock>[1];
	/**
	 * Resource lookup functions
	 */
	resources?: ResourceLookup;
	logger?: Logger;
	onProgress?: (step: string) => void;
}

/**
 * Base Agent class that provides common functionality for AI agents
 */
export abstract class Agent {
	protected context: AgentContext;
	protected modelProvider: typeof bedrock;
	protected model: Parameters<typeof bedrock>[0];
	protected settings: Parameters<typeof bedrock>[1];
	protected resources: ResourceLookup;
	protected prompt: string = '';
	protected tools: Record<string, CoreTool> = {};
	protected logger: Logger | Console;
	protected onProgress?: (step: string, details?: string) => void;
	public steps: AgentStep[] = [];

	constructor(context: AgentContext = {}, logger?: Logger | Console) {
		this.context = context;
		this.modelProvider = context.modelProvider || bedrock;
		this.model = context.model || DEFAULT_MODEL;
		this.settings = context.settings || {};
		this.resources = context.resources || {};
		this.logger = logger || console;
		this.onProgress = context.onProgress;
	}

	/**
	 * Set the system prompt for the agent
	 */
	protected setPrompt(prompt: string): this {
		this.prompt = prompt;
		return this;
	}

	/**
	 * Add a tool to the agent's toolkit
	 */
	addTool(name: string, tool: CoreTool) {
		this.tools[name] = tool;
		return this;
	}

	/**
	 * Get all registered tools
	 */
	getTools() {
		return this.tools;
	}

	/**
	 * Generate a text response using the agent's model
	 */
	protected async generateText(
		system: string,
		messages: { role: 'user' | 'assistant' | 'system'; content: string; }[] = [],
		options: {
			maxSteps?: number;
			toolChoice?: 'auto' | 'none';
		} = {},
	): Promise<string> {
		const resp = await generateText({
			model: this.modelProvider(this.model, this.settings),
			system,
			messages,
			maxSteps: options.maxSteps ?? 1,
			tools: this.tools,
			toolChoice: options.toolChoice ?? 'none',
		});
		return resp.text;
	}

	/**
	 * Generate a response using the agent's model with a specific schema
	 */
	protected async generateObject<T extends z.ZodType>(
		schema: T,
		system: string,
		messages: { role: 'user' | 'assistant' | 'system'; content: string; }[] = [],
	): Promise<z.infer<T>> {
		const results = await generateObject<z.infer<T>>({
			model: this.modelProvider(this.model, this.settings),
			output: 'object',
			schema,
			system,
			messages,
		});
		return results.object;
	}

	/**
	 * Update the agent's context
	 */
	public updateContext(context: Partial<AgentContext>): void {
		this.context = {
			...this.context,
			...context,
		};
		if (context.modelProvider) {
			this.modelProvider = context.modelProvider;
		}

		if (context.model) {
			this.model = context.model;
		}

		if (context.settings) {
			this.settings = context.settings;
		}

		if (context.resources) {
			this.resources = {
				...this.resources,
				...context.resources,
			};
		}
	}

	/**
	 * Get a publication by ID
	 */
	protected async getPublication(id: string): Promise<Publication | null> {
		if (!this.resources.getPublication) {
			throw new Error('getPublication function not provided');
		}
		return this.resources.getPublication(id);
	}

	/**
	 * Query DynamoDB
	 */
	protected async query<T extends TypedObject>(params: QueryFor<T>): Promise<T[]> {
		if (!this.resources.query) {
			throw new Error('query function not provided');
		}
		return this.resources.query<T>(params);
	}

	/**
	 * Get a single item from DynamoDB by its type and ID
	 */
	protected async getById<T extends TypedObject>(
		typeName: TypedObjectName<T>,
		id: Reference<T>,
		args?: Parameters<NonNullable<ResourceLookup['getById']>>[2],
	): Promise<T | null> {
		if (!this.resources.getById) {
			throw new Error('getById function not provided');
		}
		return this.resources.getById<T>(typeName, id, args);
	}

	/**
	 * Update the current progress step
	 */
	protected updateProgress(step: string, details?: string) {
		this.onProgress?.(step, details);
	}
}
