import { UnixTimestamp } from './dates';
import { ISOLanguageCode } from './language';
import { ReferenceString } from './reference';
import { User } from './user';

/**
 * Newstex Category
 * TableName: Categories
 *
 * @author: Chris Moyer <cmoyer@newstex.com>
 */
export interface Category {
	$type: 'Category';
	code: string;
	category_map?: string[];
	negated_by?: string[];

	description?: string;
	type?: string;
	name?: string;
	last_applied?: UnixTimestamp;
	last_checked?: UnixTimestamp;
	story_count?: number;

	created_by?: ReferenceString<User>;
	created_at?: UnixTimestamp;

	modified_by?: ReferenceString<User>;
	modified_at?: UnixTimestamp;
}
export interface CategoryMarker {
	$type: 'CategoryMarker';
	text: string;
	category: string;
	weight: number;
	language?: ISOLanguageCode
	created_by?: ReferenceString<User>;
	created_at?: UnixTimestamp;
}
export interface CategoryMarkerMap {
	[language: string]: {
		[text: string]: {
			[category: string]: number;
		};
	};
}

export function isCategory(item: any, tableName?: string): item is Category {
	return item?.$type === 'Category' || tableName === 'Category';
}
