import 'handsontable/dist/handsontable.full.min.css';
import { BUILD_TIMESTAMP } from '@newstex/core/version';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faFontAwesome, fas } from '@fortawesome/free-solid-svg-icons';
import { faExclamationTriangle, fad } from '@fortawesome/pro-duotone-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas as proSolid } from '@fortawesome/pro-solid-svg-icons';
import { fat } from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsd } from '@fortawesome/sharp-duotone-solid-svg-icons';
import { fasl } from '@fortawesome/sharp-light-svg-icons';
import { fasr } from '@fortawesome/sharp-regular-svg-icons';
import { fass } from '@fortawesome/sharp-solid-svg-icons';
import { fast } from '@fortawesome/sharp-thin-svg-icons';
import { registerAllModules } from 'handsontable/registry';
import { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { BrowserRouter } from 'react-router-dom';

import './app.scss';

import Layout from './components/Layout';
import Preloader from './components/Preloader';
import { AIChatProvider } from './providers/ai-chat-provider';
import { AIProvider } from './providers/ai-provider';
import { AnalyticsProvider } from './providers/analytics-provider';
import { APIProvider } from './providers/api-provider';
import { AuthProvider, useAuth } from './providers/auth';
import { CommandBar } from './providers/command-bar';
import { ConfirmProvider } from './providers/confirm';
import { HubSpotProvider } from './providers/hubspot-provider';
import { SearchProvider, useSearch } from './providers/search';
import { UserInfoProvider, useUserInfo } from './providers/user-info';
import NewstexRoutes from './routes';
import { CustomErrorBoundary } from './components/error';
import { toast } from 'react-toastify';

registerAllModules();

library.add(
	fas,
	fab,
	faFontAwesome,
	fad,
	fal,
	far,
	proSolid,
	fat,
	faUsd,
	fasl,
	fasr,
	fass,
	fast,
);

function RequireSearchContext(props: { children: React.ReactNode }) {
	const auth = useAuth();
	const searchContext = useSearch();
	const userInfo = useUserInfo();

	if (searchContext?.searchClient) {
		return props.children;
	}

	return (
		<Modal
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			show={true}
		>
			<Modal.Header>
				<Modal.Title id="contained-modal-title-vcenter">
					{userInfo && !userInfo.search
						? 'No Account Created'
						: 'Loading...'
					}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="pb-5">
				{window.location.hostname !== 'admin.newstex.com' && (
					<div className="d-flex justify-content-center alert alert-warning" style={{ fontSize: '1.25rem', width: '100%', clear: 'both' }}>
						<FontAwesomeIcon icon={faExclamationTriangle} style={{ color: '#FFA500' }} />
						<span className="ms-2">Development Environment</span>
					</div>
				)}

				<div className="pt-5 d-flex justify-content-center">
					{userInfo && !userInfo.search
						? <>
							<span className="visually-hidden">No Account Created</span>
							<div>
								No account for the email address <i>{userInfo.email}</i> has been created.
							</div>
						</>
						: <>
							<span className="visually-hidden">Loading...</span>
							<Preloader type="three-bounce" variant="success" />
						</>
					}
				</div>
			</Modal.Body>
			<Modal.Footer className="d-flex justify-content-between">
				<div>
					{auth?.runAs && auth.assumeUser && (
						<Button variant="success" onClick={() => {
							auth.assumeUser!(null);
						}}>
							Back to my account
						</Button>
					)}
				</div>
				<Button variant="danger" onClick={() => {
					auth?.logout();
				}}>
					Logout
				</Button>
			</Modal.Footer>
		</Modal>
	);
}



export function App() {
	async function checkVersion() {
		const statusResp = await fetch(`${import.meta.env.VITE_API_URL}/status`).then((r) => r.json());
		console.log('Version Check', {
			version: BUILD_TIMESTAMP,
			apiVersion: statusResp.version.BUILD_TIMESTAMP,
		});

		if (BUILD_TIMESTAMP !== statusResp.version.BUILD_TIMESTAMP) {
			toast.warning('New version available. Please reload the page.');
		}
	}

	useEffect(() => {
		/* ========= Add Box Shadow in Header on Scroll ======== */
		window.addEventListener('scroll', () => {
			const header: any = document.querySelector('.header');
			if (header === null) return;
			if (window.scrollY > 0) {
				header.style.boxShadow = '0px 0px 30px 0px rgba(200, 208, 216, 0.30)';
			} else {
				header.style.boxShadow = 'none';
			}
		});
		checkVersion();
		// Every 15 minutes, check if the version has changed
		const interval = setInterval(checkVersion, 60000 * 15);
		return () => clearInterval(interval);
	}, []);
	return (
		<CustomErrorBoundary>
			<BrowserRouter>
				<ConfirmProvider>
					<AuthProvider>
						<APIProvider>
							<UserInfoProvider>
								<AnalyticsProvider>
									<SearchProvider>
										<AIProvider>
											<HubSpotProvider>
												<RequireSearchContext>
													<AIChatProvider>
														<CommandBar>
															<Layout>
																<NewstexRoutes />
															</Layout>
														</CommandBar>
													</AIChatProvider>
												</RequireSearchContext>
											</HubSpotProvider>
										</AIProvider>
									</SearchProvider>
								</AnalyticsProvider>
							</UserInfoProvider>
						</APIProvider>
					</AuthProvider>
				</ConfirmProvider>
			</BrowserRouter>
		</CustomErrorBoundary>
	);
}
