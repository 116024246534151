import { formatSchedule, parseSchedule } from '@newstex/core/schedule';
import { useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

interface ScheduleInputProps {
	value: string;
	onChange?: (value: string) => void;
	isInvalid?: boolean;
}

export function ScheduleInput({ value, onChange, isInvalid }: ScheduleInputProps) {
	const [displayValue, setDisplayValue] = useState(value ? formatSchedule(value) : '');
	const [cronExpression, setCronExpression] = useState(value ? parseSchedule(value) : '');

	// Convert cron to human readable on mount and when value changes
	useEffect(() => {
		if (!value) {
			setDisplayValue('');
			return;
		}

		try {
			setDisplayValue(formatSchedule(value));
			setCronExpression(parseSchedule(value));
		} catch (e) {
			// If we can't format it, just show the raw value
			setDisplayValue(value);
			setCronExpression(value);
		}
	}, [value]);

	const handleChange = (newValue: string) => {
		setDisplayValue(newValue);
		setCronExpression(parseSchedule(newValue));

		if (!newValue) {
			onChange?.('');
			return;
		}

		try {
			// In text mode, try to parse the human-readable input
			const cronValue = parseSchedule(newValue);
			if (cronValue) {
				console.debug('Parsed cron value:', cronValue);
				onChange?.(cronValue);
			}
		} catch (e) {
			// If parsing fails, just update the display value
			console.debug('Failed to parse schedule:', e);
		}
	};

	return (
		<InputGroup>
			<Form.Control
				type="text"
				value={displayValue}
				onChange={(e) => handleChange(e.target.value)}
				isInvalid={isInvalid}
				placeholder="e.g. Every weekday from 9am to 5pm"
			/>
			<InputGroup.Text>
				<input type="hidden" name="schedule" value={cronExpression} />
				{cronExpression || '* * * * *'}
			</InputGroup.Text>
			<Form.Control.Feedback type="invalid">
				Please enter a valid schedule
			</Form.Control.Feedback>
		</InputGroup>
	);
}
