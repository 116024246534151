// feed/:id
import { faBug, faExternalLinkAlt, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Feed, FeedIssue } from '@newstex/types/feed';
import { useEffect, useState } from 'react';
import {
	Alert,
	Button,
	Card,
	Col,
	Container,
	Form,
	Modal,
	Row,
	Table,
} from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { AsanaTaskModal } from '~/components/asana-task';
import EditButton from '~/components/edit-button';
import LoadingSpinner from '~/components/LoadingSpinner';
import { ParentBreadcrumb } from '~/components/parent-breadcrumb';
import { PropertyDisplay } from '~/components/properties';
import { PropertyDisplayValue } from '~/components/property-display-value';
import { RefreshButton } from '~/components/refresh-button';
import { StatusBadge } from '~/components/status-badge';
import { UpdateModal } from '~/components/update-modal';
import ViewStoriesButton from '~/components/view-stories-button';
import { useAPI } from '~/providers/api-provider';

export function FeedProperties({ feed, updateFeed }:
{
	feed: Feed,
	updateFeed: (updates: Partial<Feed>, title: string) => Promise<void>,
}) {
	return (<Container fluid>
		{feed.last_error && <Row>
			<Col>
				<Card className="feed error bg-danger-subtle">
					<Card.Body>
						<h5>Read Error {feed.last_error.time
							&& <small><PropertyDisplayValue propName="date" propValue={feed.last_error.time} /></small>
						}</h5>
						<PropertyDisplayValue
							propName="last_error"
							propValue={feed.last_error}
						/>
					</Card.Body>
				</Card>
			</Col>
		</Row>}

		<Row>
			<Col md={6} sm={12}>
				<Card className="feed">
					<Card.Header>
						<h5 className="card-title">General Info</h5>
					</Card.Header>
					<Card.Body>
						<Row className="property-display">
							<PropertyDisplay propKey="name" propValue={feed.name} typeName="Feed" />
							<PropertyDisplay propKey="polling_delay" propValue={feed.polling_delay} typeName="Feed" />
							<PropertyDisplay propKey="priority" propValue={feed.priority} typeName="Feed" />
							<PropertyDisplay propKey="segment" propValue={feed.segment} typeName="Feed" />
							<PropertyDisplay propKey="created_at" propValue={feed.created_at} typeName="Feed" />
							<PropertyDisplay propKey="last_story_count" propValue={feed.last_story_count} typeName="Feed" />
							<PropertyDisplay propKey="last_received_date" propValue={feed.last_received_date} typeName="Feed" />
						</Row>
					</Card.Body>
					<Card.Footer>
						<div className="d-flex justify-content-center">
							{
								feed.status === 'Active'
									&& <span className="ps-1">
										<Button size="sm" variant="outline-warning"
											title="Pause Feed"
											onClick={() => updateFeed({
												status: 'Standby',
											}, 'Pause Feed')}>Pause Feed</Button>
									</span>
							}
							{
								feed.status !== 'Active'
									&& <span className="ps-1">
										<Button size="sm" variant="outline-success"
											title="Activate Feed"
											onClick={() => updateFeed({
												status: 'Active',
											}, 'Activate Feed')}>Activate Feed</Button>
									</span>
							}
							{
								feed.status !== 'Inactive'
									&& <span className="ps-1">
										<Button size="sm" variant="outline-danger"
											title="Disable Feed"
											onClick={() => updateFeed({
												status: 'Inactive',
											}, 'Disable Feed')}>Disable Feed</Button>
									</span>
							}
						</div>
					</Card.Footer>
				</Card>
			</Col>
			<Col md={6} sm={12}>
				<Card className="feed">
					<Card.Header>
						<h5 className="card-title">Processing Info</h5>
					</Card.Header>
					<Card.Body>
						<Row className="property-display">
							<PropertyDisplay propKey="ft_process" propValue={feed.ft_process} typeName="Feed" />
							<PropertyDisplay propKey="process_updates" propValue={feed.process_updates} typeName="Feed" />
							<PropertyDisplay propKey="use_cloudscraper" propValue={feed.use_cloudscraper} typeName="Feed" />
							<PropertyDisplay propKey="use_ftfeed" propValue={feed.use_ftfeed} typeName="Feed" />
							<PropertyDisplay propKey="use_h2" propValue={feed.use_h2} typeName="Feed" />
							<PropertyDisplay propKey="bypass_robots" propValue={feed.bypass_robots} typeName="Feed" />
							<PropertyDisplay propKey="user_agent" propValue={feed.user_agent} typeName="Feed" />
							<PropertyDisplay propKey="last_read" propValue={feed.last_read} typeName="Feed" />
							<PropertyDisplay propKey="next_read" propValue={feed.next_read} typeName="Feed" />
						</Row>
					</Card.Body>
				</Card>
			</Col>

			<Col md={6} sm={12}>
				<Card className="feed">
					<Card.Header>
						<h5 className="card-title">{feed.type} Info</h5>
					</Card.Header>
					<Card.Body>
						<Row className="property-display">
							<PropertyDisplay propKey="feed_uri" propValue={feed.feed_uri} typeName="Feed" />
						</Row>
					</Card.Body>
				</Card>
			</Col>

			<Col md={6} sm={12}>
				<Card className="feed">
					<Card.Header>
						<h5 className="card-title">Auth Info</h5>
					</Card.Header>
					<Card.Body>
						<Row className="property-display">
							<PropertyDisplay propKey="auth_type" propValue={feed.auth_type} typeName="Feed" />
							<PropertyDisplay propKey="username" propValue={feed.username} typeName="Feed" />
							<PropertyDisplay propKey="passwd" propValue={feed.passwd} propName="password" typeName="Feed" />
						</Row>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	</Container>);
}

export function FeedPage() {
	const params = useParams();
	const api = useAPI();
	const [feed, setFeed] = useState<Feed>();
	const [loading, setLoading] = useState(true);
	const [debugging, setDebugging] = useState(false);
	const [refreshTime, setRefreshTime] = useState<number>(-1);

	// Error Modal
	const [showErrorModal, setShowErrorModal] = useState(false);

	// Read Feed Modal
	const [showReadFeedModal, setShowReadFeedModal] = useState(false);
	const [readArchive, setReadArchive] = useState(false);
	const [reprocessStories, setReprocessStories] = useState(false);

	// Update Modal
	const [showUpdateModal, setShowUpdateModal] = useState(false);
	const [feedUpdates, setFeedUpdates] = useState<Partial<Feed>>();
	const [modalTitle, setUpdateModalTitle] = useState('');

	// Debug Modal
	const [showDebugModal, setShowDebugModal] = useState(false);
	const [feedIssues, setFeedIssues] = useState<FeedIssue[]>([]);

	const FIVE_MINUTES_AGO = Math.round((new Date().getTime() - 1000 * 60 * 5) / 1000);

	const fetchData = async (refresh = false) => {
		setLoading(true);
		const resp = await api.fetchWithAuth(
			`resources/Feed/${params.id}`,
			refresh ? { cache: 'reload' } : undefined,
		);
		setFeed(resp.items?.[0]);
		setLoading(false);
	};

	const readFeed = async () => {
		setLoading(true);
		try {
			const resp = await api.fetchWithAuth(`resources/Feed/${params.id}/read`, {
				method: 'POST',
				body: JSON.stringify({
					reprocess: reprocessStories,
					archive: readArchive,
				}),
			});
			if (resp?.items?.[0]) {
				setFeed(resp.items?.[0]);
				// Refresh the feed in 30 seconds
				setRefreshTime(30);
			}
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	const debugFeed = async () => {
		setDebugging(true);
		setShowDebugModal(true);
		try {
			const resp = await api.fetchWithAuth(`resources/Feed/${params.id}/debug`, {
				method: 'POST',
				body: JSON.stringify({
					reprocess: reprocessStories,
					archive: readArchive,
				}),
			});
			if (resp?.items?.length) {
				setFeedIssues(resp.items);
			}
		} catch (err) {
			console.error(err);
		} finally {
			setDebugging(false);
		}
	};
	const getTooltipText = () => {
		if (loading) {
			return 'Loading...';
		}

		if (!feed?.feed_uri) {
			return 'Missing feed URI';
		}

		if (feed?.last_read && feed.last_read > FIVE_MINUTES_AGO) {
			return 'Must wait 5 minutes before reading again';
		}
		return 'Read this feed now';
	};

	useEffect(() => {
		console.log('Refresh Time', refreshTime);
		if (refreshTime > 0) {
			const timeout = setTimeout(() => {
				setRefreshTime((x) => x - 1);
			}, 1000);
			return () => clearTimeout(timeout);
		}

		if (refreshTime === 0) {
			setRefreshTime(-1);
			// Force a cache-busting reload after a Refresh
			fetchData(true);
		}
	}, [refreshTime]);

	useEffect(() => {
		fetchData();
	}, [params]);

	const updateFeed = async (updates: Partial<Feed>, title: string) => {
		console.log('Updating Feed', {
			title,
			updates,
		});
		setUpdateModalTitle(title);
		setShowUpdateModal(true);
		setFeedUpdates(updates);
	};

	return (
		<Container fluid>
			<Modal show={showReadFeedModal} onHide={() => setShowReadFeedModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Read Feed Options</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Check
							type="checkbox"
							label="Read Archive Feed"
							checked={readArchive}
							onChange={(e) => setReadArchive(e.target.checked)}
						/>
						<Form.Check
							type="checkbox"
							label="Reprocess All Available Stories"
							checked={reprocessStories}
							onChange={(e) => setReprocessStories(e.target.checked)}
						/>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowReadFeedModal(false)}>
						Close
					</Button>
					<Button variant="primary" onClick={() => {
						readFeed();
						setShowReadFeedModal(false);
					}}>
						Submit
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={showDebugModal}
				onHide={() => setShowDebugModal(false)}
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title>Debug Feed</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{debugging && <LoadingSpinner loading={debugging} />}
					{!debugging && <Table>
						<thead>
							<tr>
								<th>Level</th>
								<th>Message</th>
								{feedIssues?.some((issue) => issue.story) && <th>Story</th>}
							</tr>
						</thead>
						<tbody>
							{feedIssues
								// Sort errors to the top
								.sort((a, b) => ((a.level === 'error' && b.level !== 'error') ? -1 : 0))
								// Map them to rows
								.map((issue, index) => (
									<tr key={index} className={`table-${issue.level}`}>
										<td>{issue.level}</td>
										<td>
											{issue.message}
											{issue.feedURL && (
												<div className="text-muted small">
													<Link target="_blank" to={issue.feedURL}>{issue.feedURL} <FontAwesomeIcon icon={faExternalLinkAlt} /></Link>
												</div>
											)}
										</td>
										<td>
											{issue.story?.title && (
												<div><i>{issue.story.title}</i></div>
											)}
											{issue.story?.date && (
												<div><PropertyDisplayValue propName="date" propValue={issue.story.date} /></div>
											)}
											{issue.story?.id && (
												<Link to={`/stories/${issue.story.id}`}>{issue.story.id}</Link>
											)}
										</td>
									</tr>
								))}
						</tbody>
					</Table>
					}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowDebugModal(false)}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
			{showUpdateModal && feed && feedUpdates && <UpdateModal
				item={feed}
				updates={feedUpdates}
				title={modalTitle}
				onHide={(saved) => {
					setShowUpdateModal(false);
					if (saved) {
						// Refresh the feed in 10 seconds to catch any updates
						setRefreshTime(10);
					}
				}}
			/>}
			{showErrorModal && <AsanaTaskModal
				taskLink={feed?.error_task_url!}
				onHide={() => {
					setShowErrorModal(false);
				}}
			/>}
			<div className="title-wrapper pt-30">
				<div className="row align-items-center">
					<div className="col-md-6">
						<div className="title">
							<h2>
								{feed?.name}
								&nbsp;<StatusBadge status={feed?.status} indicator />
							</h2>
							<h6 className="text-muted">
								<PropertyDisplayValue
									propName="feed_uri"
									propValue={feed?.feed_uri}
									className="text-muted"
								/>
							</h6>
						</div>
					</div>
					<div className="col-md-6 text-end">
						<div className="breadcrumb-wrapper">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<ParentBreadcrumb obj={feed?.content} />
									<li className="breadcrumb-item active">
										<a>{feed?.name} [Feed]</a>
									</li>
								</ol>
							</nav>
							<div className="float-end">
								{feed?.error_task_url && <span className="ps-1">
									<Button
										title="View Bug Report"
										variant="outline-secondary"
										size="sm"
										onClick={() => setShowErrorModal(true)}
									>
										<FontAwesomeIcon icon={faBug} />
									</Button>
								</span>}

								<span className="ps-1">
									<Button size="sm" variant="outline-secondary" disabled={
										// Must not be loading
										!(!loading
											// Must have a feed URI
											&& feed?.feed_uri
											// Must not have been read within the last 5 minutes
											&& (!feed?.last_read
												|| feed.last_read < FIVE_MINUTES_AGO)
										)
									}
									title={getTooltipText()}
									onClick={() => setShowReadFeedModal(true) }>
										<FontAwesomeIcon icon={faNewspaper} /> Read Feed Now
									</Button>
								</span>
								<span className="ps-1">
									<Button size="sm" variant="outline-secondary" disabled={
										// Must not be loading
										!(!loading
											// Must have a feed URI
											&& feed?.feed_uri
										)
									}
									onClick={() => {
										debugFeed();
									}}>
										<FontAwesomeIcon icon={faBug} /> Debug Feed
									</Button>
								</span>
								<span className="ps-1">
									<EditButton
										item={feed}
										typeHint="Feed"
										size="sm"
										refreshHandler={() => fetchData(true)}
									/>
								</span>
								<span className="ps-1">
									<ViewStoriesButton publication={feed?.content} size="sm" />
								</span>
								<span className="ps-1">
									<RefreshButton size="sm" refreshHandler={() => {
										return fetchData(true);
									}} />
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<hr />

			<Row>
				<Col sm={12}>
					<LoadingSpinner loading={loading} />
					{refreshTime > 0 && <div className="text-center">
						<Alert variant="warning">
							<h1>Refreshing in {refreshTime} seconds</h1>
						</Alert>
					</div>}
					{!loading && feed && <FeedProperties feed={feed} updateFeed={updateFeed} /> }
				</Col>
			</Row>
		</Container>
	);
}
