import { bedrock } from '@ai-sdk/amazon-bedrock';
import { CoreMessage, streamText } from 'ai';

import { DEFAULT_MODEL } from './bedrock';
import { AI_CHAT_PROMPT } from './prompts/chat';
import { AIToolsContext, type AIToolsContextArgs } from './tools';

export type StreamTextParams = Parameters<typeof streamText>[0];
export type ChatMessage = CoreMessage;
export type StreamTextResult = ReturnType<typeof streamText>;

// Define the chat parameters type
export type ChatParams = {
	messages: CoreMessage[];
	modelProvider?: typeof bedrock;
	model?: Parameters<typeof bedrock>[0];
	onChunk?: StreamTextParams['onChunk'];
	onFinish?: StreamTextParams['onFinish'];
	onStepFinish?: StreamTextParams['onStepFinish'];
} & Parameters<typeof bedrock>[1];

export class AIChatContext {
	public toolContext: AIToolsContext;
	public modelProvider: typeof bedrock;
	public model: Parameters<typeof bedrock>[0];

	constructor({
		modelProvider = bedrock,
		model = DEFAULT_MODEL,
		...args
	}: {
		modelProvider?: typeof bedrock;
		model?: Parameters<typeof bedrock>[0];
	} & AIToolsContextArgs) {
		this.modelProvider = modelProvider;
		this.model = model;
		this.toolContext = new AIToolsContext(args);
	}

	streamChat({
		messages,
		prompt,
		onChunk,
		onFinish,
		onStepFinish,
		...settings
	}: Omit<ChatParams, 'modelProvider' | 'model'> & { prompt?: string | null }) {
		return streamText({
			model: this.modelProvider(this.model, settings),
			system: prompt ? `${AI_CHAT_PROMPT}\n\n${prompt}` : AI_CHAT_PROMPT,
			messages,
			tools: this.toolContext.tools,
			toolChoice: 'auto',
			maxSteps: 5,
			// TODO: Figure out why the types are not being inferred correctly
			onChunk: onChunk as any,
			onFinish: onFinish as any,
			onStepFinish: onStepFinish as any,
		});
	}
}
