import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import {
	Card,
	Col,
	Container,
	Row,
} from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AIChatCommand } from '~/commands/ai-chat';
import type { Command, ModalFields } from '~/commands/type';
import ModalDataEditor from '~/components/modal-data-editor';
import PageHeader from '~/components/PageHeader';
import { useAIChat } from '~/providers/ai-chat-provider';
import { useAI } from '~/providers/ai-provider';
import { useUserInfo } from '~/providers/user-info';

export const TOOLS: Command[] = [
	{
		id: 'qualify-leads',
		icon: ['fad', 'table-cells'],
		name: 'Qualify Leads',
		description: 'Qualify a list of leads (by URL or HubSpot List) using Technical and AI ranking. Can optionally sync to HubSpot.',
		handler: ({ navigate }) => {
			navigate('/tools/qualify-leads');
		},
	},
	{
		id: 'match',
		icon: ['fad', 'folder-search'],
		name: 'Match Tool',
		description: 'Match a list of names or domains against our system to find potential matches. Upload data via CSV or paste directly into the table.',
		handler: ({ navigate }) => {
			navigate('/tools/match');
		},
	},
	AIChatCommand,
];

/**
 * Tools
 */
export function ToolsPage() {
	const { id } = useParams();
	const [modalData, setModalData] = useState<ModalFields | null>(null);
	const userInfo = useUserInfo();
	const navigate = useNavigate();
	const ai = useAI();
	const aiChat = useAIChat();

	useEffect(() => {
		if (id?.length) {
			const tool = TOOLS.find((t) => t.id === id);
			if (tool?.handler) {
				tool.handler({
					setModalData,
					navigate,
					ai,
					aiChat,
				});
			}
		}
	}, [id]);

	return (
		<Container fluid>
			<PageHeader title="Tools" />
			<ModalDataEditor
				modalData={modalData}
				setModalData={setModalData}
				onClose={() => {
					navigate('/tools');
				}}
			/>

			<Row className="text-center">
				{TOOLS.map((script) => (
					<Col key={script.id}>
						<Card className="h-100 hover-animate card">
							<Card.Header>
								<h4>
									{script.icon && <FontAwesomeIcon icon={script.icon} className="me-1" />}
									{script.name}
								</h4>
							</Card.Header>
							<Card.Body>
								{script.description}
							</Card.Body>
							<Link className="stretched-link" to={`/tools/${script.id}`} />
						</Card>
					</Col>
				))}
			</Row>
		</Container>
	);
}
