/* eslint-disable array-callback-return */

import type { IconName } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BUILD_DATE, version } from '@newstex/core/version';
import { useEffect, useState } from 'react';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Variant } from 'react-bootstrap/esm/types';
import { useNavigate } from 'react-router-dom';
import type { ModalFields } from '~/commands/type';
import { useAIChat } from '~/providers/ai-chat-provider';
import { useAI } from '~/providers/ai-provider';
import { useUserInfo } from '~/providers/user-info';
import { REPORTS } from '~/routes/reports';
import { TOOLS } from '~/routes/tools';

import ActiveLink from './ActiveLink';
import { CreatePublicationHubSpotModal } from './create-publication-hubspot-modal';
import ModalDataEditor from './modal-data-editor';
import { RequirePermissions } from './require-permissions';

export interface SidebarProps {
	sidebarShrink: boolean;
}

function MenuLink({
	to, icon, title, tooltip, id, badge, badgeColor,
}: {
	to: string;
	icon: IconName;
	title: string;
	tooltip?: string;
	id: string;
	badge?: number | null;
	badgeColor?: Variant;
}) {
	return (
		<ActiveLink
			to={to}
			activeClassName="active"
			className="sidebar-link text-muted d-flex align-items-center"
			title={tooltip}
			key={id}
			id={`sidebar-menu-${id}`}
		>
			<FontAwesomeIcon className="svg-icon-md me-3" icon={['fad', icon]} />
			<span className="text flex-grow-1" title={tooltip}>{title}</span>
			{badge && (<Badge bg={badgeColor} className="ms-auto">{badge}</Badge>)}
		</ActiveLink>
	);
}

export default function Sidebar({ sidebarShrink }: SidebarProps) {
	const [modalData, setModalData] = useState<ModalFields | null>(null);
	const [criticalAlerts, setCriticalAlerts] = useState<number | null>(null);
	const [showAddPublication, setShowAddPublication] = useState(false);
	const userInfo = useUserInfo();
	const navigate = useNavigate();
	const ai = useAI();
	const aiChat = useAIChat();

	useEffect(() => {
		setCriticalAlerts(userInfo?.alerts?.length || null);
	}, [userInfo?.alerts]);

	return (<>
		<ModalDataEditor
			modalData={modalData}
			setModalData={setModalData}
		/>
		<CreatePublicationHubSpotModal
			show={showAddPublication}
			onClose={(success) => {
				setShowAddPublication(false);
			}}
		/>
		<div
			className={`sidebar py-3 ${sidebarShrink ? 'shrink show' : ''}`}
			id="sidebar"
		>
			<div className="sidebar-content">
				<h6 className="sidebar-heading">
					Main
					{window.location.hostname !== 'admin.newstex.com' && (
						<span className="badge bg-warning text-dark ms-2">Dev</span>
					)}
				</h6>
				<ul className="list-unstyled">
					<li className="sidebar-list-item">
						<MenuLink to="/" icon="house" title="Dashboard" id="home" />
					</li>

					<RequirePermissions permissions={['newscrunch']}>
						<li className="nav-item">
							<MenuLink to="/newscrunch" icon="file-invoice-dollar" title="Newscrunch" id="newscrunch" />
						</li>
					</RequirePermissions>

					<RequirePermissions permissions={['admin']}>
						<li className="nav-item">
							<MenuLink to="/docs/kb" icon="file-circle-info" title="Knowledge Base" id="docs-kb" />
						</li>
					</RequirePermissions>

					<RequirePermissions permissions={['admin']}>
						<li className="nav-item">
							<MenuLink to="/alerts" icon="bell" title="Alerts" id="alerts" badge={criticalAlerts} badgeColor="danger" />
						</li>
					</RequirePermissions>

					<RequirePermissions permissions={['admin', 'dev']}>
						<li className="nav-item">
							<MenuLink to="/docs/dev" icon="code-simple" title="Dev Documentation" id="docs-dev" />
						</li>
					</RequirePermissions>

					{userInfo?.my_pubs?.length && (
						<li className="nav-item">
							<MenuLink to="/revenue" icon="dollar-sign" title="Revenue Reports" id="revenue" />
						</li>
					)}
				</ul>
				<ul className="list-unstyled">
					<h6 className="sidebar-heading">Content</h6>
					<RequirePermissions permissions={['admin']}>
						<li className="nav-item">
							<MenuLink to="/publishers" icon="cabinet-filing" title="Publishers" tooltip="Publisher" id="publishers" />
						</li>
						<li className="nav-item">
							<MenuLink to="/publications" icon="browser" title="Publications" tooltip="Publications" id="publications" />
						</li>
						<li>
							<MenuLink to="/feeds?status=Active" icon="rss" title="Feeds" id="feeds" />
						</li>
					</RequirePermissions>
					<li>
						<MenuLink to="/stories" icon="newspaper" title="Stories" id="story" />
					</li>
					<RequirePermissions permissions={['admin']}>
						<li>
							<MenuLink to="/categories" icon="group-arrows-rotate" title="Categories" id="category" />
						</li>
						<li>
							<MenuLink to="/products" icon="box" title="Products" id="products" />
						</li>
						<li>
							<MenuLink to="/delivery?status=Active" icon="truck" title="Deliveries" id="delivery" />
						</li>
					</RequirePermissions>
					{!userInfo?.auth_groups?.includes('admin') && (
						<li>
							<ActiveLink
								to="#AddPublication"
								activeClassName="active"
								className="sidebar-link text-muted"
								title="Add Publication"
								onClick={(e) => {
									e.preventDefault();
									setShowAddPublication(true);
									(e.currentTarget as HTMLElement).blur();
									return false;
								}}
							>
								<FontAwesomeIcon className="svg-icon-md me-3" icon={['fad', 'plus']} />
								<span className="text" title="Add Publication">Add Publication</span>
							</ActiveLink>
						</li>
					)}
				</ul>
				<RequirePermissions permissions={['admin']}>
					<ul className="list-unstyled">
						<h6 className="sidebar-heading">Tools</h6>
						{TOOLS.map((script) => (
							<li key={script.id}>
								<ActiveLink
									to={`#${script.id}`}
									className="sidebar-link text-muted"
									activeClassName=""
									title={script.name}
									onClick={(e) => {
										e.preventDefault();
										console.log('clicked', script);
										script.handler({
											setModalData,
											navigate,
											ai,
											aiChat,
										});
										(e.currentTarget as HTMLElement).blur();
										return false;
									}}
								>
									{script.icon && <FontAwesomeIcon className="svg-icon-md me-3" icon={script.icon} />}
									<span className="text" title={script.name}>{script.name}</span>
								</ActiveLink>
							</li>
						))}
					</ul>
					<ul className="list-unstyled">
						<h6 className="sidebar-heading">Projects</h6>
						<li>
							<MenuLink to="/sites" icon="project-diagram" title="Sites" id="sites" />
						</li>
						<li>
							<MenuLink to="/subscribers" icon="users" title="Newsletter Subscribers" id="subscribers" />
						</li>
					</ul>
					<ul className="list-unstyled">
						<h6 className="sidebar-heading">Reports</h6>
						{REPORTS.map((report) => (
							<li key={report.id}>
								<MenuLink to={`/reports/${report.id}`} icon={report.icon} title={report.name} id={report.id} />
							</li>
						))}
					</ul>
				</RequirePermissions>

				<div className="sidebar-bottom">
					<OverlayTrigger
						placement="top"
						overlay={
							<Tooltip>
								Version: {version}<br/>
								Build Date: {BUILD_DATE}
							</Tooltip>
						}
					>
						<div className="version text-muted small">
							Version: {version}
						</div>
					</OverlayTrigger>
				</div>
			</div>
		</div>
	</>);
}
