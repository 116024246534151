import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import type { NormalizedSearchResponse } from '@newstex/search/types';
import { Story } from '@newstex/types/story';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import KPIStatsWidget, { KPIProps } from './KPIStats';

export interface SearchStatsProps {
	thisWeek: NormalizedSearchResponse<Story> | null;
	lastWeek: NormalizedSearchResponse<Story> | null;
}

// Facet Stats
const FACET_STAT_CONFIGS = [
	/*
	{
		name: 'Average Sentiment',
		icon: 'angle-brackets-1',
		id: 'stats.sentiment_score',
	},
	*/
	{
		name: 'Average Word Count',
		icon: 'chat-bubble-1',
		id: 'stats.word_count',
	},
] as const;

// Category Differences
const CATEGORY_STAT_CONFIGS = [
	{
		name: 'Breaking News',
		icon: 'star-medal-1',
		code: 'BREAKING',
		colors: {
			up: 'green',
			down: 'red',
		},
	},
	{
		name: 'Guest Post',
		icon: 'man-1',
		code: 'GUESTPOST',
		colors: {
			up: 'red',
			down: 'green',
		},
	},
	{
		name: 'PR Hype',
		icon: 'paper-plane-1',
		code: 'PRHYPE',
		colors: {
			up: 'red',
			down: 'green',
		},
	},
	{
		name: 'Spam',
		icon: 'bin-1',
		code: 'SPAM',
		colors: {
			up: 'red',
			down: 'green',
		},
	},
	{
		name: 'Controversial Content',
		icon: 'heartbeat-1',
		code: 'CONTROV',
		colors: {
			up: 'red',
			down: 'green',
		},
	},
] as const;

export default function SearchStats({ thisWeek, lastWeek }: SearchStatsProps) {
	const [kpiStats, setKPIStats] = useState<KPIProps[]>([]);
	useEffect(() => {
		if (thisWeek && lastWeek) {
			console.log({
				thisWeek,
				lastWeek,
			});
			// Total Stories
			const thisWeekHits = thisWeek.found || 0;
			const lastWeekHits = lastWeek.found || 0;
			const storyDiff = thisWeekHits - lastWeekHits;
			const totalStoriesWidget: KPIProps = {
				name: 'Stories Last 7 Days',
				icon: 'news-1',
				number: thisWeekHits.toLocaleString(),
				footer: '',
				color: 'blue',
				indicator: storyDiff > 0 ? faCaretUp : faCaretDown,
			};
			if (storyDiff > 0) {
				totalStoriesWidget.footer = `Up ${((storyDiff / thisWeekHits) * 100).toFixed(2)} %`;
				totalStoriesWidget.color = 'green';
			} else {
				totalStoriesWidget.footer = `Down ${((Math.abs(storyDiff) / thisWeekHits) * 100).toFixed(2)}%`;
				totalStoriesWidget.color = 'red';
			}

			// Tickered Stories
			const tickeredStoriesThisWeek = thisWeek.facets?._tags?.values?.['Has Company Tickers'] || 0;
			const tickeredStoriesLastWeek = lastWeek.facets?._tags?.values?.['Has Company Tickers'] || 0;
			const tickeredDiff = tickeredStoriesThisWeek - tickeredStoriesLastWeek;
			const tickeredStoriesWidget: KPIProps = {
				name: 'Tickered Stories',
				icon: 'dollar-badge-1',
				number: tickeredStoriesThisWeek.toLocaleString(),
				footer: '',
				color: 'blue',
				indicator: tickeredDiff > 0 ? faCaretUp : faCaretDown,
			};

			if (tickeredDiff > 0) {
				tickeredStoriesWidget.footer = `Up ${((tickeredDiff / tickeredStoriesThisWeek) * 100).toFixed(2)} %`;
				tickeredStoriesWidget.color = 'green';
			} else {
				tickeredStoriesWidget.footer = `Down ${((Math.abs(tickeredDiff) / tickeredStoriesThisWeek) * 100).toFixed(2)}%`;
				tickeredStoriesWidget.color = 'red';
			}

			const widgets: KPIProps[] = [
				totalStoriesWidget,
				tickeredStoriesWidget,
			];

			// Facet Stats
			for (const facetStatConfig of FACET_STAT_CONFIGS) {
				const currentStatValue = thisWeek.facets?.[facetStatConfig.id]?.stats?.avg || 0;
				const lastStatValue = lastWeek.facets?.[facetStatConfig.id]?.stats?.avg || 0;
				const facetStatWidget: KPIProps = {
					name: facetStatConfig.name,
					icon: facetStatConfig.icon,
					number: currentStatValue.toFixed(0),
					footer: `Last Week: ${lastStatValue.toFixed(0)}`,
					color: 'blue',
					indicator: currentStatValue > lastStatValue ? faCaretUp : faCaretDown,
				};
				if (currentStatValue > lastStatValue) {
					facetStatWidget.footer = `Up From ${lastStatValue.toFixed(0)}`;
					facetStatWidget.color = 'green';
				} else {
					facetStatWidget.footer = `Down From ${lastStatValue.toFixed(0)}`;
					facetStatWidget.color = 'red';
				}
				widgets.push(facetStatWidget);
			}

			// Category Stats
			for (const categoryStatConfig of CATEGORY_STAT_CONFIGS) {
				const currentStatValue = thisWeek.facets?.categories?.values?.[categoryStatConfig.code] || 0;
				const currentStatPercent = currentStatValue / thisWeekHits;
				const lastStatValue = lastWeek.facets?.categories?.values?.[categoryStatConfig.code] || 0;
				const lastStatPercent = lastWeekHits ? lastStatValue / lastWeekHits : 0;
				const facetStatWidget: KPIProps = {
					name: categoryStatConfig.name,
					icon: categoryStatConfig.icon,
					number: `${(currentStatPercent * 100).toFixed(2)}% (${currentStatValue.toFixed(0)})`,
					footer: `Last Week: ${lastStatValue.toFixed(0)}`,
					color: 'blue',
					indicator: currentStatValue > lastStatValue ? faCaretUp : faCaretDown,
					link: `/stories?categories=${categoryStatConfig.code}`,
				};
				if (currentStatPercent > lastStatPercent) {
					facetStatWidget.footer = `Up ${((currentStatPercent - lastStatPercent) * 100).toFixed(2)}%`;
					facetStatWidget.color = categoryStatConfig.colors.up;
				} else {
					facetStatWidget.footer = `Down ${((lastStatPercent - currentStatPercent) * 100).toFixed(2)}%`;
					facetStatWidget.color = categoryStatConfig.colors.down;
				}
				widgets.push(facetStatWidget);
			}

			setKPIStats(widgets);
		}
	}, [thisWeek, lastWeek]);
	return (
		<Row className="mb-3">
			{kpiStats.map((item, index) => (
				<Col sm={6} lg={3} key={index} className="mb-4">
					<KPIStatsWidget {...item} />
				</Col>
			))}
		</Row>
	);
}
