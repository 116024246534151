/**
 * Multi-Value Edit Field
 */
import { TypeNameToTypeMap } from '@newstex/types/infer-types';
import React, { useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';

import PropertyDisplayName from '../property-display-name';

import { EditTextFieldProps } from './text';

export interface EditMultiValueFieldProps<T extends keyof TypeNameToTypeMap> extends EditTextFieldProps<T> {
	trimValues?: boolean,
	validate?: (value: string) => boolean,
}

export default function EditableMultiValueField<T extends keyof TypeNameToTypeMap>({
	typeName,
	propKey,
	item,
	updates,
	setUpdates,
	trimValues,
	validate,
	showControlOnly,
}: EditMultiValueFieldProps<T>) {
	const [values, setValues] = useState<string[]>([]);
	const [errors, setErrors] = useState<boolean[]>([]);

	useEffect(() => {
		const initialValues = (updates?.[propKey] as string[]) || (item?.[propKey] as string[]) || [];
		setValues([
			...initialValues,
			// Always end with an empty value
			'',
		]);
		// Initialize error state for each value
		setErrors(new Array(initialValues.length + 1).fill(false));
	}, [updates, item]);

	const handleChange = (index: number, value: string) => {
		const newValues = [...values];
		newValues[index] = value;

		// Update error state if validation is provided
		if (validate) {
			const newErrors = [...errors];
			newErrors[index] = value !== '' && !validate(value);
			setErrors(newErrors);
		}

		// Add new empty field at the end if needed
		if (index === values.length - 1 && value !== '') {
			newValues.push('');
			const newErrors = [...errors];
			newErrors.push(false);
			setErrors(newErrors);
		}

		setValues(newValues);

		if (setUpdates) {
			setUpdates({
				...updates,
				[propKey]: newValues.filter(v => v !== ''),
			});
		}
	};

	if (showControlOnly) {
		return (<>
			{values.map((value, index) => (
				<Form.Control
					key={index}
					type="text"
					name={String(propKey)}
					value={value}
					onChange={(e) => handleChange(index, e.target.value)}
					className={`mb-2 ${errors[index] ? 'is-invalid' : ''}`}
				/>
			))}
		</>);
	}

	return (
		<>
			<Col md={3} className="text-end fw-bold">
				<Form.Label><PropertyDisplayName propKey={propKey} typeName={typeName} /></Form.Label>
			</Col>
			<Col md={9}>
				{values.map((value, index) => (
					<Form.Control
						key={index}
						type="text"
						name={String(propKey)}
						value={value}
						onChange={(e) => handleChange(index, e.target.value)}
						className={`mb-2 ${errors[index] ? 'is-invalid' : ''}`}
					/>
				))}
			</Col>
		</>
	);
}
