import { type Alert, AlertStatus } from '@newstex/types/alert';
import { useEffect, useState } from 'react';
import {
	Card,
	Col,
	Container,
	Row,
} from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { AlertBadge } from '~/components/alert-badge';
import EditButton from '~/components/edit-button';
import LoadingSpinner from '~/components/LoadingSpinner';
import { NotFound } from '~/components/not-found';
import { PropertyDisplay } from '~/components/properties';
import { RefreshButton } from '~/components/refresh-button';
import { RequirePermissions } from '~/components/require-permissions';
import { SearchLinkButton } from '~/components/search-link-button';
import { useAPI } from '~/providers/api-provider';

import { AlertHistory } from './history';

function AlertProperties({ alert }: { alert: Alert }) {
	return (
		<Container fluid>
			<Row>
				<Col md={6} sm={12}>
					<Card>
						<Card.Header>
							<h5 className="card-title">General Info</h5>
						</Card.Header>
						<Card.Body>
							<Row className="property-display">
								<PropertyDisplay propKey="name" propValue={alert.name} typeName="Alert" />
								<PropertyDisplay propKey="description" propValue={alert.description} typeName="Alert" />
								<br/>
								<PropertyDisplay propKey="created_at" propValue={alert.created_at} typeName="Alert" />
								<PropertyDisplay propKey="last_run" propValue={alert.last_run} typeName="Alert" />
								<PropertyDisplay propKey="status" propValue={alert.status} typeName="Alert" />
								<PropertyDisplay propKey="emails" propValue={alert.emails} typeName="Alert" />
							</Row>
						</Card.Body>
					</Card>
				</Col>
				<Col md={6} sm={12}>
					<Card>
						<Card.Header>
							<h5 className="card-title">
								Search Configuration
								<div className="float-end">
									<SearchLinkButton
										search={alert.search}
										indexName={alert.collection}
										size="sm"
										variant="outline-secondary"
									/>
								</div>
							</h5>
						</Card.Header>
						<Card.Body>
							<Row className="property-display">
								<PropertyDisplay
									propKey="collection"
									propName="Collection"
									propValue={alert.collection}
									typeName="Alert"
								/>
								<PropertyDisplay
									propKey="search.q"
									propName="Search Query"
									propValue={alert.search.q}
									typeName="Alert"
								/>
								<PropertyDisplay
									propKey="search.filter_by"
									propName="Search Filters"
									propValue={alert.search.filter_by}
									typeName="Alert"
								/>
								<PropertyDisplay
									propKey="check_field"
									propName="Check Field"
									propValue={alert.check_field || 'found'}
									typeName="Alert"
								/>
							</Row>
						</Card.Body>
					</Card>
				</Col>
				<Col md={6} sm={12}>
					<Card>
						<Card.Header>
							<h5 className="card-title">Schedule</h5>
						</Card.Header>
						<Card.Body>
							<Row className="property-display">
								<PropertyDisplay propKey="schedule" propValue={alert.schedule} typeName="Alert" />
							</Row>
						</Card.Body>
					</Card>
				</Col>
				<Col md={6} sm={12}>
					<Card>
						<Card.Header>
							<h5 className="card-title">Expected Results</h5>
						</Card.Header>
						<Card.Body>
							<Row className="property-display">
								<PropertyDisplay propKey="expected" propValue={alert.expected} typeName="Alert" />
								<PropertyDisplay propKey="last_values" propValue={alert.last_values} typeName="Alert" />
							</Row>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export function AlertPage() {
	const params = useParams();
	const api = useAPI();
	const [alert, setAlert] = useState<Alert>();
	const [loading, setLoading] = useState(true);

	const fetchData = async (refresh = false) => {
		setLoading(true);
		const resp = await api.fetchWithAuth(
			`resources/Alert/${params.id}`,
			refresh ? { cache: 'reload' } : undefined,
		);
		setAlert(resp.items?.[0]);
		setLoading(false);
	};

	useEffect(() => {
		fetchData();
	}, [params]);

	if (!alert) {
		if (loading) {
			return (
				<Container>
					<LoadingSpinner loading={loading} />
				</Container>
			);
		}
		return (
			<Container>
				<NotFound type="Alert" id={params.id} />
			</Container>
		);
	}

	return (
		<Container fluid>
			<div className="title-wrapper pt-30">
				<div className="row align-items-center">
					<div className="col-md-6">
						<div className="title">
							<h2>{alert.name} <AlertBadge status={alert.status || AlertStatus.NO_DATA} /></h2>
							<h6 className="text-muted">Collection: {alert.collection}</h6>
						</div>
					</div>
					<div className="col-md-6 text-end">
						<div className="breadcrumb-wrapper">
							<nav aria-label="breadcrumb" className="mb-0">
								<ol className="breadcrumb">
									<li className="breadcrumb-item active">
										<Link to="/alerts">Alerts</Link>
									</li>
									<li className="breadcrumb-item active">
										{alert.$id}
									</li>
								</ol>
							</nav>
							<div className="float-end">
								<RequirePermissions permissions={['admin']}>
									<span className="ps-1">
										<EditButton
											item={{
												...alert,
												$type: 'Alert',
											}}
											size="sm"
											refreshHandler={() => fetchData(true)}
										/>
									</span>
								</RequirePermissions>
								<span className="ps-1">
									<RefreshButton
										size="sm"
										refreshHandler={() => fetchData(true)}
									/>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<hr />

			<LoadingSpinner loading={loading} />
			{!loading && alert && (
				<>
					<AlertHistory alert={alert} />
					<AlertProperties alert={alert} />
				</>
			)}
		</Container>
	);
}
