/**
 * Select Edit Field
 */
import { TypeNameToTypeMap } from '@newstex/types/infer-types';
import { Col, Form } from 'react-bootstrap';

import PropertyDisplayName from '../property-display-name';

import { EditTextFieldProps } from './text';

export interface EditSelectFieldProps<T extends keyof TypeNameToTypeMap> extends EditTextFieldProps<T> {
	options: readonly string[] | { value: string; label: string }[],
}

export function Option<T extends keyof TypeNameToTypeMap>({ option }: {
	option: EditSelectFieldProps<T>['options'][number],
}) {
	if (typeof option === 'string') {
		return <option key={option} value={option}>{option}</option>;
	}
	return <option key={option.value} value={option.value}>{option.label}</option>;
}

export default function EditableSelectField<T extends keyof TypeNameToTypeMap>({
	typeName,
	propKey,
	item,
	options,
	updates,
	setUpdates,
}: EditSelectFieldProps<T>) {
	return (
		<>
			<Col md={3} className="text-end fw-bold">
				<Form.Label><PropertyDisplayName propKey={propKey} typeName={typeName} /></Form.Label>
			</Col>
			<Col md={9}>
				<Form.Control as="select" name={String(propKey)} value={String(updates[propKey] || item?.[propKey] || '')} onChange={(e) => {
					if (setUpdates) {
						setUpdates({
							...updates,
							[propKey]: e.target.value,
						});
					}
				}}>
					{options.map((option) => <Option option={option} />)}
				</Form.Control>
			</Col>
		</>
	);
}
