import type { Story as StoryType } from '@newstex/types/story';

import type { CollectionFieldSchmaForObject } from './types';

export const Story: CollectionFieldSchmaForObject<StoryType & { owner_emails?: string[] }> = [
	{
		name: 'headline',
		type: 'string',
		store: true,
		sort: true,
		defaultSearchOrder: 1,
	},
	{
		name: 'date',
		type: 'int64',
		store: true,
		facet: true,
		range_index: true,
	},
	{
		name: 'received_at',
		type: 'int64',
		store: true,
		facet: true,
		range_index: true,
	},
	{
		name: 'excerpt',
		type: 'string',
		store: true,
		optional: true,
		defaultSearchOrder: 10,
	},
	{
		name: 'permalink',
		type: 'string',
		store: true,
		optional: true,
		defaultSearchOrder: 0,
	},
	{
		name: 'thumb_url',
		type: 'string',
		store: true,
		optional: true,
	},
	{
		name: '_tags',
		type: 'string[]',
		optional: true,
		store: true,
		facet: true,
	},
	{
		name: 'ai_scores',
		type: 'float',
		optional: true,
		store: true,
		facet: true,
	},
	{
		name: 'categories',
		type: 'string[]',
		optional: true,
		store: true,
		facet: true,
		reference: 'Category.id',
	},
	{
		name: 'content_type',
		type: 'string',
		optional: true,
		store: true,
		facet: true,
	},
	{
		name: 'company_tickers',
		type: 'string[]',
		optional: true,
		store: true,
		facet: true,
	},
	{
		name: 'provider_tickers',
		type: 'string[]',
		optional: true,
		store: true,
		facet: true,
	},
	{
		name: 'content',
		type: 'string[]',
		optional: true,
		store: true,
		facet: true,
	},
	{
		name: 'language',
		type: 'string',
		optional: true,
		store: true,
		facet: true,
	},
	{
		name: 'products',
		type: 'string[]',
		optional: true,
		store: true,
		facet: true,
		reference: 'Product.id',
	},
	{
		name: 'provider_categories',
		type: 'string[]',
		store: true,
		facet: true,
		optional: true,
	},
	{
		name: 'pub_type',
		type: 'string',
		store: true,
		facet: true,
		optional: true,
	},
	{
		name: 'publication',
		type: 'string',
		store: true,
		facet: true,
		optional: true,
		reference: 'Publication.id',
	},
	{
		name: 'source',
		type: 'string',
		store: true,
		facet: true,
		optional: true,
		defaultSearchOrder: 2,
	},
	{
		name: 'stats',
		type: 'object',
		optional: true,
	},
	{
		name: 'stats.character_count',
		type: 'int32',
		store: true,
		facet: true,
		optional: true,
		range_index: true,
	},
	{
		name: 'stats.line_count',
		type: 'int32',
		store: true,
		facet: true,
		optional: true,
		range_index: true,
	},
	{
		name: 'stats.word_count',
		type: 'int32',
		store: true,
		facet: true,
		optional: true,
		range_index: true,
	},
	{
		name: 'stats.sentiment_score',
		type: 'int32',
		store: true,
		facet: true,
		optional: true,
		range_index: true,
	},
	{
		name: 'status',
		type: 'string',
		store: true,
		facet: true,
	},
	/** For Authorization purposes */
	{
		name: 'owner_emails',
		type: 'string[]',
		facet: true,
		store: true,
		searchable: true,
		optional: true,
	},
	/* END Authorization */

	/*
	{
		name: 'embedding',
		type: 'float[]',
		optional: true,
		num_dim: 1536,
	},
	*/
];
