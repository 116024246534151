import React, {
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import { SearchBox } from './search-box';

interface DebouncedSearchBoxProps {
	/** Delay in milliseconds before triggering the URL update and search */
	debounceMs?: number;
	/** Optional hook to refresh the search results */
	refreshHook?: () => Promise<void>;
}

/**
 * A search box component that provides immediate UI feedback while debouncing the actual search
 * operation to prevent excessive API calls and improve performance.
 *
 * Features:
 * - Immediate input feedback
 * - Debounced URL updates (which trigger the search)
 * - Syncs with URL parameters
 * - Maintains browser history clean using replace
 */
export function DebouncedSearchBox({
	debounceMs = 300,
	...props
}: DebouncedSearchBoxProps) {
	const [searchParams, setSearchParams] = useSearchParams();
	// Track input value separately from URL to allow immediate UI updates
	const [inputValue, setInputValue] = useState(() => searchParams.get('q') || '');
	const timeoutRef = useRef<NodeJS.Timeout>();

	// Memoize the search params update function to prevent unnecessary recreations
	const updateSearchParams = useCallback((value: string) => {
		setSearchParams((params) => {
			const newParams = new URLSearchParams(params);
			if (value) {
				newParams.set('q', value);
			} else {
				newParams.delete('q');
			}
			newParams.delete('page');
			return newParams;
		}, { replace: true }); // Use replace to avoid cluttering browser history
	}, [setSearchParams]);

	// Sync with URL changes (e.g., browser navigation)
	useEffect(() => {
		const urlQuery = searchParams.get('q') || '';
		if (urlQuery !== inputValue) {
			setInputValue(urlQuery);
		}
	}, [searchParams]);

	// Handle search input changes with debouncing
	const handleSearch = useCallback((value: string) => {
		// Update input value immediately for responsive UI
		setInputValue(value);

		// Reset existing timeout to debounce URL updates
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}

		// Schedule the URL update which will trigger the search
		timeoutRef.current = setTimeout(() => {
			updateSearchParams(value);
		}, debounceMs);
	}, [debounceMs, updateSearchParams]);

	// Clean up timeout on unmount
	useEffect(() => {
		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, []);

	return (
		<SearchBox
			{...props}
			initialQuery={inputValue}
			refineHook={handleSearch}
		/>
	);
}
