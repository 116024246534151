import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useUserInfo } from '~/providers/user-info';
import 'react-toastify/dist/ReactToastify.css';

import Footer from './footer';
import Header from './Header';
import Sidebar from './sidebar';
import SvgIcons from './SvgIcons';

const Layout = (pageProps: any) => {
	const [sidebarShrink, setSidebarShrink] = useState(false);
	const userInfo = useUserInfo();

	useEffect(() => {
		// Check if we're in a browser environment and the user is an admin
		if (typeof window !== 'undefined' && userInfo?.auth_groups?.includes('admin')) {
			// If no response within 5 seconds, assume extension is not installed
			const timeoutId = setTimeout(() => {
				toast.warning(
					<>
						<b>The NewsCore Chrome extension is not installed.</b>
						<p>Some features may be limited.</p>
						<p>Click here to install.</p>
					</>,
					{
						autoClose: false,
						toastId: 'extension-missing',
						onClick: () => {
							window.open('https://chromewebstore.google.com/detail/newstex-hubspot/blcegcgjimpjhefnkgokgnbakbdkngac', '_blank');
						},
					},
				);
			}, 5000);

			// Setup message listener
			const messageHandler = (event: MessageEvent) => {
				if (event.data?.type === 'NEWSCORE_response') {
					console.log('Received response', event.data);
					// Extension returned a response, so clear the timeout
					clearTimeout(timeoutId);
					if (!event.data?.data?.ok) {
						// Extension is not working, so we need to show an error
						toast.error(
							<>
								<p>The NewsCore Chrome extension was detected, but not working.</p>
								<p>Some features may be limited.</p>
								<code>{JSON.stringify(event.data, null, 2)}</code>
							</>,
							{
								autoClose: false,
								toastId: 'extension-not-working',
							},
						);
					} else {
						toast.dismiss('extension-missing');
						// toast.info('The NewsCore Chrome extension was detected and is working.');
					}
				}
				// Always return true to allow the message to continue to the next listener
				return true;
			};

			window.addEventListener('message', messageHandler);

			// Send check status message
			window.postMessage({
				type: 'NEWSCORE_checkStatus',
				id: `check-status-${Date.now()}`,
			}, '*');

			// Cleanup
			return () => {
				window.removeEventListener('message', messageHandler);
				clearTimeout(timeoutId);
			};
		}
	}, [userInfo]);

	return (
		<div className={pageProps.className}>
			<ToastContainer
				position="bottom-right"
				newestOnTop
				closeOnClick
			/>
			{!pageProps.hideHeader && (
				<Header
					setSidebarShrink={setSidebarShrink}
					sidebarShrink={sidebarShrink}
				/>
			)}

			<div className="d-flex align-items-stretch">
				{!pageProps.hideSidebar && <Sidebar sidebarShrink={sidebarShrink} />}
				<div
					className={`page-holder ${
						pageProps.pageHolderClass
							? pageProps.pageHolderClass
							: 'bg-gray-100'
					}`}
				>
					{pageProps.children}
					{!pageProps.hideFooter && <Footer />}
				</div>
			</div>
			<SvgIcons />
		</div>
	);
};

export default Layout;
