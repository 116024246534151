import {
	ArcElement,
	CategoryScale,
	Chart,
	Legend,
	LinearScale,
	PieController,
	PointElement,
	Tooltip,
} from 'chart.js';
import { useEffect, useRef } from 'react';

export interface ChartComponentProps {
	className?: string;
	color?: string;
	colors?: string | string[];
	labels: string[];
	values: number[];
	hideLegend?: boolean;
	aspectRatio?: number;
	showPercentage?: boolean;
}

export default function ChartComponent({
	className,
	color,
	colors,
	labels,
	values,
	hideLegend,
	aspectRatio,
	showPercentage,
}: ChartComponentProps) {
	const chartRef = useRef<HTMLCanvasElement>(null);

	// Calculate total for percentages
	const total = values.reduce((sum, value) => sum + value, 0);

	// Create labels with percentages if enabled
	const displayLabels = showPercentage
		? labels.map((label, i) => {
			const percentage = ((values[i] / total) * 100).toFixed(1);
			return `${label} (${percentage}%)`;
		})
		: labels;

	const options = {
		plugins: {
			legend: {
				display: !hideLegend,
			},
			tooltip: {
				callbacks: {
					label: showPercentage
						? (context: any) => {
							const value = context.raw;
							const percentage = ((value / total) * 100).toFixed(1);
							return `${context.label.split(' (')[0]}: ${value.toLocaleString()} (${percentage}%)`;
						}
						: undefined,
				},
			},
		},
		aspectRatio: aspectRatio || 2,
	};

	const data = {
		labels: displayLabels,
		datasets: [
			{
				data: values,
				backgroundColor: Array.isArray(colors) ? colors : Array(values.length).fill(colors || color),
				hoverBackgroundColor: Array.isArray(colors) ? colors : Array(values.length).fill(colors || color),
			},
		],
	};
	useEffect(() => {
		Chart.register(
			PieController,
			CategoryScale,
			ArcElement,
			PointElement,
			LinearScale,
			Legend,
			Tooltip,
		);
		Chart.defaults.font.family = 'Poppins, system-ui, -apple-system, Roboto, Arial, system-ui, -apple-system, sans-serif';
		Chart.defaults.plugins.tooltip.padding = 10;
		Chart.defaults.plugins.tooltip.backgroundColor = 'rgba(0, 0, 0, 0.7)';
		Chart.defaults.scale.ticks.color = 'rgba(0, 0, 0, 0.4)';

		const chart = new Chart(chartRef.current!, {
			type: 'pie',
			options,
			data,
		});
		return () => chart.destroy();
	}, [data, options]);
	return <canvas className={className} ref={chartRef} />;
}
