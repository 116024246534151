import { faDownload, faMessage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchHit } from '@newstex/search';
import { KnowledgeBase } from '@newstex/types/rag';
import { createColumnHelper } from '@tanstack/react-table';
import { kebab } from 'case';
import { useMemo, useRef } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { formatDateString } from '~/lib/utils';
import { useAIChat } from '~/providers/ai-chat-provider';
import { useConfirm } from '~/providers/confirm';

import DataTable, { DataTableMethods } from '../DataTable';
import { LoadMoreButton } from '../load-more-button';
import { PropertyDisplayValue } from '../property-display-value';

export function KnowledgeBaseTable(
	{
		items,
		searchText,
		hasMore,
		onLoadMore,
		loading,
		hideColumns,
		fallbackSearch,
	}: {
		items: SearchHit<KnowledgeBase>[],
		searchText?: string,
		hasMore?: boolean,
		onLoadMore?: () => void | Promise<void>,
		loading?: boolean,
		hideColumns?: string[],
		fallbackSearch?: (q: string) => Promise<SearchHit<KnowledgeBase>[]>,
	},
) {
	const aiChat = useAIChat();
	const { confirmDialog } = useConfirm();

	const dataTableRef = useRef<DataTableMethods>(null);
	const columnHelper = createColumnHelper<KnowledgeBase>();
	const columns = useMemo(() => [
		columnHelper.accessor('title', {
			header: 'Title',
			enableSorting: true,
			cell: ({ getValue, row: { original }}) => {
				return (
					<Link to={`/docs/kb/${original.$id}`}>
						<PropertyDisplayValue propName="title" propValue={getValue()} />
					</Link>
				);
			},
		}),
		columnHelper.accessor('category', {
			header: 'Category',
			enableSorting: true,
			cell: ({ getValue }) => {
				return <PropertyDisplayValue propName="category" propValue={getValue()} />;
			},
		}),
		columnHelper.accessor('created_at', {
			header: 'Created At',
			enableSorting: true,
			cell: ({ getValue }) => {
				return <PropertyDisplayValue propName="created_at" propValue={getValue()} />;
			},
		}),
		columnHelper.accessor('created_by', {
			header: 'Created By',
			enableSorting: true,
			cell: ({ getValue }) => {
				return <PropertyDisplayValue propName="created_by" propValue={getValue()} />;
			},
		}),
		columnHelper.display({
			id: 'actions',
			header: '',
			cell: ({ row: { original }}) => {
				return <div className="text-end">
					<Button variant="success" size="sm" onClick={async () => {
						if (await confirmDialog({
							title: 'Start New Chat',
							details: <>
								<Alert variant="warning" className="mt-2">
									This will start a new chat, <strong>erasing any existing chat history.</strong>
								</Alert>
								<div className="mt-2">
									This chat will be able to answer questions about <i>{original.title}</i>.
								</div>
								{original.questions && <>
									<div className="mt-2">
										You can ask questions like:
										<ul>
											{original.questions.map((q) => (
												<li key={kebab(q)}>{q}</li>
											))}
										</ul>
									</div>
								</>}
							</>,
						})) {
							aiChat.createWithPrompt(original);
						}
					}}>
						<FontAwesomeIcon icon={faMessage} /> Start New Chat
					</Button>
				</div>;
			},
		}),
	], [columnHelper]);

	return (<>
		<DataTable
			columns={columns}
			items={items || []}
			ref={dataTableRef}
			searchText={searchText}
			loading={loading}
			columnVisibility={hideColumns?.reduce((acc, col) => ({ ...acc, [col]: false }), {})}
			fallbackSearch={fallbackSearch}
			striped
			sortable
		/>
		<LoadMoreButton show={hasMore} onClick={onLoadMore} />
		<div className="float-end pb-2">
			<Button variant="outline-secondary" size="sm" onClick={() => {
				dataTableRef.current?.exportToCSV('Knowledge Base', {
					ID: '$id',
					Title: 'title',
					Questions: (item) => item.questions?.join('; '),
					Category: 'category',
					Content: 'answer',
					'Created At': (item) => formatDateString(item.created_at),
					'Updated At': (item) => formatDateString(item.updated_at),
				});
			}}>
				<FontAwesomeIcon icon={faDownload} /> CSV
			</Button>
		</div>
	</>);
}
