/* eslint-disable no-nested-ternary */
/**
 * Advanced search page
 */
import { SearchResults, getIndexName } from '@newstex/search/client';
import { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Loader } from 'react-bootstrap-typeahead';
import { useSearchParams } from 'react-router-dom';
import { AdvancedSearchFilters } from '~/components/advanced-search-filters';
import LoadingSpinner from '~/components/LoadingSpinner';
import { ObjectCard } from '~/components/object-card';
import { PageTitle } from '~/components/page-title';
import { useSearch } from '~/providers/search';

function NoResults({
	title,
	loading,
}: {
	title?: string,
	loading?: boolean,
}) {
	const [searchParams] = useSearchParams();

	return (
		<div className="text-muted p-4">
			{loading ? <LoadingSpinner loading={loading} /> : (
				<center>
					<div>
						No results for <i>{searchParams.get('q') || title || ''}</i>
					</div>
				</center>
			)}
		</div>
	);
}

function SearchHits({
	results,
	tableName,
}: {
	results: SearchResults<any>,
	tableName: string,
}) {
	if (results.grouped_hits) {
		return <Container fluid>
			{results.grouped_hits.map((group) => (<>
				<Row key={group.group_key.join('-')} className="mt-4 mb-4 border-bottom">
					<Col>
						<h4>{group.group_key.join(', ')} [{group.found}]</h4>
					</Col>
				</Row>
				<Row>
					{group.hits.map((hit) => (
						<Col md={4} key={hit.document.id} className="mb-4">
							<ObjectCard object={hit.document} tableName={tableName} />
						</Col>
					))}
				</Row>
			</>))}
		</Container>;
	}

	return <Container fluid>
		<Row>
			{results.hits.map((hit) => (
				<Col xl={4} md={8} key={hit.id} className="mb-4">
					<ObjectCard object={hit} tableName={tableName} />
				</Col>
			))}
		</Row>
	</Container>;
}

export const AdvancedSearchPage = () => {
	const search = useSearch();
	const [searchParams, setSearchParams] = useSearchParams();
	const [loading, setLoading] = useState(false);
	const [results, setSearchResults] = useState<SearchResults<any>>();
	const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
	}, [searchParams]);
	const doSearch = async (refresh?: boolean) => {
		if (search?.searchClient && searchParams.get('indexName')) {
			const page = parseInt(searchParams.get('page') || '1', 10);
			setLoading(true);
			const searchResponse = await search.searchClient.search({
				indexName: getIndexName(searchParams.get('indexName') || ''),
				query: searchParams.get('q') || '*',
				query_by: searchParams.get('query_by') || undefined,
				group_by: searchParams.get('group_by') || undefined,
				facet_by: searchParams.get('facet_by') || undefined,
				include_fields: searchParams.get('include_fields') || undefined,
				filter_by: searchParams.get('filter_by') || undefined,
				// Odd hack to support sorting by stats fields which for some reason get converted to `stats_`
				sort_by: (searchParams.get('sort_by') || '_text_match:desc').replace(/stats_/g, 'stats.'),
				per_page: 100,
				page,
				use_cache: !refresh,
			});

			console.log('GOT response', searchResponse);
			setSearchResults(searchResponse);

			if (searchResponse.page && searchResponse.page !== page) {
				setSearchParams((params) => {
					params.set('page', searchResponse.page.toString());
					return params;
				});
			}

			setLoading(false);
		}
	};

	useEffect(() => {
		if (debounceTimeout.current) {
			clearTimeout(debounceTimeout.current);
		}

		debounceTimeout.current = setTimeout(() => {
			doSearch();
		}, 300);

		return () => {
			if (debounceTimeout.current) {
				clearTimeout(debounceTimeout.current);
			}
		};
	}, [searchParams, search?.searchClient]);

	if (!search?.searchClient) {
		return <Loader />;
	}

	return (
		<Container fluid>
			<PageTitle title="Advanced Search">
				<Row>
					<Col>
						<small>
							found {results?.found?.toLocaleString() || '0'}
							results{results?.search_time_ms && ` in ${results.search_time_ms}ms`}
						</small>
					</Col>
				</Row>
			</PageTitle>
			<AdvancedSearchFilters refreshHook={() => doSearch(true)} />
			<hr/>
			{loading ? <LoadingSpinner loading={loading} /> : (
				results?.found
					? <SearchHits results={results} tableName={searchParams.get('indexName') || 'HubSpot'} />
					: <NoResults title="Advanced Search" loading={loading}/>
			)}
		</Container>
	);
};
