/*
 * Delivery Object
 */
import type { BaseObject } from './base';
import { UnixTimestamp } from './dates';
import type { StatusChoice } from './status';

export interface Delivery extends BaseObject {
	$type: 'Delivery';
	type?: 'S3' | 'SFTP' | 'FTP' | 'HTTP';
	status?: StatusChoice;
	description?: string;

	// Simplified Story Templates
	story_template?: 'xml' | 'xhtml' | 'newsml';

	products?: string[];
	queue_name?: string;
	recipient?: string;
	recipient_name?: string;

	// Email address of the technical contact for issues with this delivery
	tech_contact?: string;

	// Last time a delivery was made to this delivery
	last_delivery?: UnixTimestamp;

	// Default: '%(story_id)s%(ext)s'
	name_template?: string;

	// Number of seconds to wait for stories before sending them
	// Used for batching updates
	update_frequency?: number;

	// Encode as Base64
	encode?: boolean;

	// If true, verify the delivery was successful
	verify?: boolean;

	// If true, merge all stories from each publication into a single file
	merge_pubs?: boolean;

	// If true, send updates to stories, otherwise only send version 1
	send_updates?: boolean;

	// For HTTP/SFTP/FTP types
	url?: string;
	host?: string;
	username?: string;
	password?: string;
	pasv?: boolean;
	path?: string;
	port?: number;
	method?: 'POST' | 'PUT';

	// For SFTP with Private Key Authentication
	private_key?: string;

	// For S3 types
	bucket_name?: string;
	user_id?: string;
	user_email?: string;
}

export function isDelivery(item: any, tableName?: string): item is Delivery {
	return item?.$type === 'Delivery' || tableName === 'Delivery';
}
