import { type Product } from '@newstex/types/product';
import React from 'react';
import { Badge, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const ProductCard: React.FC<{
	item?: Product;
}> = ({
	item,
}) => {
	if (!item) {
		return <></>;
	}

	return (
		<Card className="product h-100">
			<Card.Body>
				<Card.Title className="">
					<div>
						<Link to={`/product/${item.$id}`}>
							{item.name}
						</Link>
					</div>
					<h6 className="text-muted">
						{item.$id}
					</h6>
				</Card.Title>
				<div>
					{item.group && (
						<div className="mb-2">
							<strong>Group:</strong> {item.group}
						</div>
					)}
					{item.filters && item.filters.length > 0 && (
						<div className="mb-2">
							<strong>Filters:</strong>
							{item.filters.map((filter, index) => (
								<Badge key={index} bg="secondary" className="me-1">
									{filter}
								</Badge>
							))}
						</div>
					)}
					{item.kill_alert_emails && (
						<div className="mb-2">
							<strong>Kill Alert Emails:</strong> {item.kill_alert_emails.join(', ')}
						</div>
					)}
					{item.username && (
						<div className="mb-2">
							<strong>Username:</strong> {item.username}
						</div>
					)}
				</div>
			</Card.Body>
		</Card>
	);
};
