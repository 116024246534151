import type { SearchResponseFacetCountSchema } from 'typesense/lib/Typesense/Documents';

import type { NormalizedSearchResponse } from './types';

/**
 * Convert search facets to a usable format
 */
export function convertSearchFacets<T = any>(facets: SearchResponseFacetCountSchema<T & { id: string }>[]) {
	if (!facets || facets.length === 0) {
		return {};
	}
	const result: NormalizedSearchResponse<T>['facets'] = {};
	for (const facet of facets) {
		const counts: Record<string, number> = {};
		for (const value of facet.counts) {
			counts[value.value] = value.count;
		}

		result[String(facet.field_name)] = {
			values: counts,
			stats: facet.stats,
		};
	}
	return result;
}
