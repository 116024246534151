import { type Publication, type Publisher } from '@newstex/types/content';
import { useState, useEffect } from 'react';
import {
	Alert,
	Button,
	Modal,
	Spinner,
	Form,
	Container,
	Col,
	Row,
	Card,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useAPI } from '~/providers/api-provider';
import { PublisherSelect } from '../../editors/publisher-select';
import { convertReferenceToString } from '@newstex/types/reference';
import { Results } from '@newstex/types/results';
import { StatusBadge } from '~/components/status-badge';
import { HubSpotLink } from '~/components/hubspot-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faExternalLinkAlt } from '@fortawesome/pro-duotone-svg-icons';
import { PropertyDisplay } from '~/components/properties';
import { PropertyDisplayValue } from '~/components/property-display-value';

interface ChangePublisherModalProps {
	publication: Publication;
	show: boolean;
	onClose: (saved: boolean) => void;
}

function PubCardDetails({ pub }: { pub: Publisher }) {
	return (<>
		<Card.Body>
			<Card.Title>
			<h6>
				<Link to={`/publication/${pub.$id}`}>
					{pub.name}
				</Link>
				</h6>
			</Card.Title>
			<dl>
				<dt>Status:</dt>
				<dd><PropertyDisplayValue propName="status" propValue={pub.status} typeName="Publisher" /></dd>
				<dt>Newstex ID:</dt>
				<dd><PropertyDisplayValue propName="newstex_id" propValue={pub.newstex_id} typeName="Publisher" /></dd>
				<dt>Description:</dt>
				<dd><PropertyDisplayValue propName="description" propValue={pub.description} typeName="Publisher" /></dd>
			</dl>
		</Card.Body>
		<Card.Footer>
			{pub.url && (
				<a className="corporate-website" href={pub.url} target="_blank">
					{pub.url} <FontAwesomeIcon icon={faExternalLinkAlt} />
					</a>
				)}
		</Card.Footer>
	</>);
}

export function ChangePublisherModal({ publication, show, onClose }: ChangePublisherModalProps) {
	const [isSaving, setIsSaving] = useState(false);
	const [error, setError] = useState('');
	const [selectedPublisher, setSelectedPublisher] = useState<Publisher | null>(null);
	const [currentPublisher, setCurrentPublisher] = useState<Publisher | null>(null);
	const api = useAPI();

	const loadPublisher = async (id: string) => {
		const resp = await api.fetchWithAuth<Results<Publisher>>(`resources/Publisher/${id}`);
		setCurrentPublisher(resp?.items?.[0] || null);
	};

	// Reset error and selection when modal is opened
	useEffect(() => {
		if (show) {
			setError('');
			setSelectedPublisher(null);
		}
	}, [show]);

	useEffect(() => {
		if (publication.parent) {
			loadPublisher(convertReferenceToString(publication.parent));
		}
	}, [publication.parent]);

	const handleSave = async () => {
		if (!selectedPublisher) {
			setError('Please select a new Publisher');
			return;
		}

		if (selectedPublisher.$id === publication.parent) {
			setError('Please select a different Publisher');
			return;
		}

		setIsSaving(true);
		try {
			await api.fetchWithAuth(`resources/Publication/${publication.$id}`, {
				method: 'PATCH',
				body: JSON.stringify({
					parent: selectedPublisher.$id,
				}),
			});
			toast.success('Publication moved successfully');
			onClose(true);
		} catch (e) {
			console.error(e);
			toast.error(`Failed to move publication: ${String(e)}`);
			setError(String(e));
		} finally {
			setIsSaving(false);
		}
	};

	return (
		<Modal
			show={show}
			onHide={() => onClose(false)}
			size="xl"
		>
			<Modal.Header closeButton>
				<Modal.Title>Change Publisher for {publication.name}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Alert variant="warning">
					<strong>Warning:</strong> Moving a Publication to a different Publisher is a major operation that affects:
					<ul>
						<li>Story delivery and distribution (Story IDs will change)</li>
						<li>Royalty reporting and payments</li>
						<li>HubSpot relationships</li>
					</ul>
					This operation should only be performed with explicit approval from the Editorial and Finance teams.
				</Alert>
				{error && <Alert variant="danger">{error}</Alert>}

				<Form.Group className="mb-3">
					<Form.Label>Select New Publisher</Form.Label>
					<PublisherSelect
						value={selectedPublisher}
						onChange={setSelectedPublisher}
					/>
					<Form.Text className="text-muted">
						Search for and select the new Publisher for this Publication
					</Form.Text>
				</Form.Group>

				{selectedPublisher && (
					<Alert variant="info">
						<Container fluid>
							<Row>
								<Col className="text-center">
									Moving <strong>{publication.name}</strong>
								</Col>
							</Row>
							<Row>
								{currentPublisher && (<Col>
									<Card>
										<Card.Header>Current Publisher
											<div className="float-end">
												<StatusBadge status={currentPublisher.status} />
												<HubSpotLink item={currentPublisher} size={28} />
											</div>
										</Card.Header>
										<PubCardDetails pub={currentPublisher} />
									</Card>
								</Col>
								)}
								<Col>
									<Card>
										<Card.Header>New Publisher
											<div className="float-end">
												<StatusBadge status={selectedPublisher.status} />
												<HubSpotLink item={selectedPublisher} size={28} />
											</div>
										</Card.Header>
										<PubCardDetails pub={selectedPublisher} />
									</Card>
								</Col>
							</Row>
						</Container>
					</Alert>
				)}
			</Modal.Body>
			<Modal.Footer>
				{isSaving && <Spinner animation="border" size="sm" />}
				<Button variant="secondary" onClick={() => onClose(false)}>Cancel</Button>
				<Button
					variant="warning"
					onClick={handleSave}
					disabled={isSaving || !selectedPublisher}
				>
					Move Publication
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
