import type { CollectionFieldSchmaForObject } from './types';

export const HubSpot: CollectionFieldSchmaForObject<any> = [
	{
		name: 'type',
		type: 'string',
		store: true,
		facet: true,
		optional: true,
	},
	{
		name: 'account_status',
		type: 'string',
		store: true,
		optional: true,
		facet: true,
	},
	{
		name: 'associatedcompanyid',
		type: 'string',
		store: true,
		optional: true,
		facet: true,
	},
	{
		name: 'category',
		type: 'string',
		store: true,
		optional: true,
		facet: true,
	},
	{
		name: 'content_category',
		type: 'string',
		store: true,
		optional: true,
		facet: true,
	},
	{
		name: 'hs_lead_status',
		type: 'string',
		store: true,
		optional: true,
		facet: true,
	},
	{
		name: 'hs_parent_company_id',
		type: 'string',
		store: true,
		optional: true,
		facet: true,
	},
	{
		name: 'hubspot_owner_id',
		type: 'string',
		store: true,
		optional: true,
		facet: true,
	},
	{
		name: 'lifecyclestage',
		type: 'string',
		store: true,
		optional: true,
		facet: true,
	},
	{
		name: 'name',
		type: 'string',
		store: true,
		defaultSearchOrder: 2,
		optional: true,
	},
	{
		name: 'alternate_company_names',
		type: 'string[]',
		store: true,
		defaultSearchOrder: 3,
		optional: true,
	},
	{
		name: 'description',
		type: 'string',
		store: true,
		defaultSearchOrder: 10,
		optional: true,
	},
	{
		name: 'feed_description',
		type: 'string',
		store: true,
		defaultSearchOrder: 11,
		optional: true,
	},
	{
		name: 'seo_description',
		type: 'string',
		store: true,
		defaultSearchOrder: 12,
		optional: true,
	},
	{
		name: 'content_feed_url',
		type: 'string',
		store: true,
		defaultSearchOrder: 2,
		optional: true,
	},
	{
		name: 'firstname',
		type: 'string',
		store: true,
		defaultSearchOrder: 9,
		optional: true,
	},
	{
		name: 'lastname',
		type: 'string',
		store: true,
		defaultSearchOrder: 9,
		optional: true,
	},
	{
		name: 'website',
		type: 'string',
		store: true,
		defaultSearchOrder: 0,
		optional: true,
	},
	{
		name: 'email',
		type: 'string',
		store: true,
		defaultSearchOrder: 2,
		optional: true,
	},
	{
		name: 'domain',
		type: 'string',
		store: true,
		defaultSearchOrder: 1,
		optional: true,
	},
	{
		name: 'company',
		type: 'string',
		store: true,
		defaultSearchOrder: 20,
		optional: true,
	},
	{
		name: 'phone',
		type: 'string',
		store: true,
		defaultSearchOrder: 8,
		optional: true,
	},
	{
		name: 'alt_website_url_1',
		type: 'string',
		store: true,
		defaultSearchOrder: 3,
		optional: true,
	},
	{
		name: 'alt_website_url_2',
		type: 'string',
		store: true,
		defaultSearchOrder: 3,
		optional: true,
	},
	{
		name: 'alt_website_url_3',
		type: 'string',
		store: true,
		defaultSearchOrder: 3,
		optional: true,
	},
	{
		name: 'alt_website_url_4',
		type: 'string',
		store: true,
		defaultSearchOrder: 3,
		optional: true,
	},
	{
		name: 'metadata_sources',
		type: 'string[]',
		store: true,
		optional: true,
		facet: true,
	},
	{
		name: 'provenance',
		type: 'string',
		store: true,
		optional: true,
		facet: true,
	},
	{
		name: 'related_website_urls',
		type: 'string[]',
		store: true,
		optional: true,
		facet: true,
		defaultSearchOrder: 51,
	},
	{
		name: 'related_company_domains',
		type: 'string[]',
		store: true,
		optional: true,
		facet: true,
		defaultSearchOrder: 52,
	},
];
