import { useEffect, useState } from 'react';
import { remark } from 'remark';
import remarkMermaid from 'remark-mermaidjs';

interface MermaidGraphProps {
	chart: string;
	className?: string;
}

export function MermaidGraph({ chart, className = '' }: MermaidGraphProps) {
	const [svg, setSvg] = useState<string>('');

	useEffect(() => {
		remark()
			.use(remarkMermaid)
			.process(`\`\`\`mermaid\n${chart}\n\`\`\``)
			.then((file: any) => {
				setSvg(String(file));
			})
			.catch(console.error);
	}, [chart]);

	return (
		<div
			className={className}
			dangerouslySetInnerHTML={{ __html: svg }}
		/>
	);
}
