import type { AsanaStory } from '@newstex/types/asana/story';
import type { AsanaTask } from '@newstex/types/asana/task';
import { useEffect, useState } from 'react';
import {
	Button,
	Col,
	Form,
	Modal,
	Row,
	Spinner,
} from 'react-bootstrap';
import Markdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { useAPI } from '~/providers/api-provider';

import { PropertyDisplayValue } from './property-display-value';

/**
 * Display an Asana Task in a Modal
 */
export function AsanaTaskModal({
	taskLink,
	onHide,
}: {
	taskLink: string;
	onHide: () => void;
}) {
	const [loading, setLoading] = useState(true);
	const [task, setTask] = useState<AsanaTask>();
	const [comments, setComments] = useState<AsanaStory[]>();
	const [showSystemUpdates, setShowSystemUpdates] = useState(false);
	const api = useAPI();
	useEffect(() => {
		const taskParts = taskLink.match(/\/(\d+)(?:\/f)?$/);
		const taskId = taskParts?.[1];
		if (taskId) {
			setLoading(true);
			api.fetchWithAuth(`asana/${taskId}`).then((response) => {
				console.log('Task', response?.data);
				setTask(response?.data);
				api.fetchWithAuth(`asana/${taskId}/stories`).then((commentResponse) => {
					console.log('Comments', commentResponse?.data);
					setComments(commentResponse?.data);
					setLoading(false);
				});
			});
		}
	}, [taskLink]);
	return (<>
		<Modal
			size="xl"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			show={true}
			onHide={onHide}
		>
			<Modal.Header closeButton>
				<Modal.Title>
					{task?.name}
					<div className="text-muted h6">
						{task?.completed ? 'Completed' : 'In Progress'}
						{task?.assignee && ' - '}
						{task?.assignee?.name}
					</div>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{loading && <div className="text-center">
					<Spinner animation="border" role="status">
						<span className="visually-hidden">Loading...</span>
					</Spinner>
				</div>}
				<Row className="pb-2">
					{task?.created_at && <>
						<Col className="fw-bold text-end" md={2} xl={1}>Created</Col>
						<Col className="property-value" md={10} xl={11}>
							<PropertyDisplayValue propValue={task.created_at} propName="created_at" />
						</Col>
					</>}
					{task?.modified_at && <>
						<Col className="fw-bold text-end" md={2} xl={1}>Modified</Col>
						<Col className="property-value" md={10} xl={11}>
							<PropertyDisplayValue propValue={task.modified_at} propName="modified_at" />
						</Col>
					</>}
					{task?.due_at && <>
						<Col className="fw-bold text-end" md={2} xl={1}>Due</Col>
						<Col className="property-value" md={10} xl={11}>
							<PropertyDisplayValue propValue={task.due_at} propName="date" />
						</Col>
					</>}

				</Row>

				{task?.html_notes
					? <div className="asana-task-notes">
						<div dangerouslySetInnerHTML={{ __html: task.html_notes.replace(/<a /g, '<a target="_blank" ') }} style={{ width: '100%' }} />
					</div>
					: <Markdown>{task?.notes || ''}</Markdown>
				}
				{comments && <>
					<hr/>
				</>}
				<div className={`asana-task-comments ${showSystemUpdates ? 'asana-story-show-all' : ''}`}>
					{comments?.map((comment) => (
						<div key={comment.gid} className={`asana-story asana-story-${comment.type}`}>
							<div className="float-end asana-story-created-at">
								<PropertyDisplayValue propValue={comment.created_at} propName="date" />
							</div>
							{comment.type === 'comment' && <div>
								<span className="asana-story-author">{comment.created_by?.name}</span>
							</div>}
							<div className="asana-story-text">
								{comment.html_text
									? <div className="asana-task-notes">
										<div
											dangerouslySetInnerHTML={{ __html: comment.html_text.replace(/<a /g, '<a target="_blank" ') }}
											style={{ width: '100%' }}
										/>
									</div>
									: <Markdown>{comment.text}</Markdown>
								}

							</div>
						</div>
					))}
				</div>
			</Modal.Body>
			<Modal.Footer className="d-flex justify-content-between">
				<Form>
					<Form.Check
						type="switch"
						id="custom-switch"
						label="System Updates"
						onChange={(e) => setShowSystemUpdates(e.target.checked)}
						checked={showSystemUpdates}
					/>
				</Form>
				<Link to={taskLink} target="_blank">
					<Button variant="secondary">
						View in Asana
					</Button>
				</Link>
			</Modal.Footer>
		</Modal>
	</>);
}
