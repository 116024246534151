import { BaseObject } from './base';
import type { UnixTimestamp } from './dates';
import type { StatusChoice } from './status';

const FEED_TYPES = ['HTTPFeed', 'S3Feed', 'YouTubeFeed'];

export interface Feed extends BaseObject {
	$id: string;
	status: StatusChoice;
	name: string;
	type: 'HTTPFeed' | 'S3Feed' | 'YouTubeFeed';
	content?: string;

	priority?: string;
	segment?: number;
	next_read?: UnixTimestamp;

	deleted?: boolean;

	ft_max_stories?: number;
	created_at?: UnixTimestamp;
	created_by?: string;
	modified_at?: UnixTimestamp;
	modified_by?: string;

	proc?: string;
	parser?: string;
	feed_uri?: string;
	polling_delay?: number;
	run_cat_engine?: boolean;

	user_agent?: string;

	run_digger?: boolean;

	last_read?: UnixTimestamp;

	/**
	 * Process each individual story using the FT Extraction API
	 */
	ft_process?: boolean;

	process_updates?: boolean;

	name_template?: string;

	// Optional encoding override for this feed
	encoding?: BufferEncoding;

	// If true, automatically extract tickers from the text of the story
	extract_tickers?: boolean;

	gzip?: boolean;

	/**
	 * Attempt to bypass Cloudflare's anti-bot page
	 */
	use_cloudscraper?: boolean;

	/**
	 * Use the FT Feed API to bypass any known Bot Protection
	 */
	use_ftfeed?: boolean;

	/**
	 * Use HTTP/2 instead of Fetch
	 */
	use_h2?: boolean;

	/**
	 * If set, ignore any robots.txt directives
	 */
	bypass_robots?: boolean;

	// Authentication support
	auth_type?: 'basic' | 'digest';
	username?: string;
	passwd?: string;
	custom_headers?: Record<string, string>;

	s3_data?: {
		bucket: {
			name: string;
		};
		object: {
			key: string;
		};
	};

	last_error?: {
		time: UnixTimestamp;
		error: string;
	}

	// YouTube ID
	yt_id?: string;
	// YouTube User ID
	user_id?: string;

	/**
	 * Last feed read's "Last-Modified" header
	 *
	 * This is passed along into the "If-Modified-Since" header on the next read
	 */
	last_read_last_modified_header?: string;

	/**
	 * URL Prefix that's required to parse any stories
	 *
	 * Highly recommended when adding sitemap feeds, to prevent accidentally parsing items that are
	 * not blog posts
	 */
	required_prefix?: string;

	/**
	 * Bug tracking ticket URL
	 *
	 * This is used to record an Asana or HubSpot task that's related to any errors being tracked for this feed.
	 */
	error_task_url?: string;

	/**
	 * Number of new stories in the feed the last time we read it
	 *
	 * @category Story
	 * @calculated
	 */
	last_story_count?: number;

	/**
	 * Last date a story was *received*
	 *
	 * @category Story
	 * @calculated
	 */
	last_received_date?: UnixTimestamp;

	/**
	 * Last date a story was *published*
	 *
	 * @category Story
	 * @calculated
	 */
	last_post_date?: UnixTimestamp;
}

export function isFeed(obj: any, tableName?: string): obj is Feed {
	return Boolean(
		(obj?.$type && FEED_TYPES.includes(obj?.$type))
		|| (obj != null && (tableName === 'Feeds' || tableName === 'Feed')),
	);
}

export interface FeedIssue {
	level: 'fatal' | 'error' | 'warning';
	message: string;
	statusCode?: number;
	feedURL?: string;
	story?: {
		id?: string;
		date?: UnixTimestamp;
		external_id?: string;
		title?: string;
		url?: string;
	};
}
