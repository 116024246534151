/**
 * Adapted from react-highlight-words
 *
 * @see https://www.npmjs.com/package/react-highlight-words
 * @see https://github.com/bvaughn/react-highlight-words/blob/master/src/Highlighter.js
 */
import memoizeOne from 'memoize-one';
import {
	CSSProperties,
	FunctionComponent,
	ReactNode,
	createElement,
} from 'react';

import { Chunk, findAll } from './utils';

interface HighlighterProps {
	activeClassName?: string;
	activeIndex?: number;
	activeStyle?: CSSProperties;
	autoEscape?: boolean;
	className?: string;
	findChunks?: (args: any) => Chunk[];
	highlightClassName?: string | { [key: string]: string };
	highlightStyle?: CSSProperties;
	highlightTag?: string | FunctionComponent<any> | ReactNode;
	searchWords: (string | RegExp)[];
	textToHighlight: string;
	unhighlightTag?: string | FunctionComponent<any> | ReactNode;
	unhighlightClassName?: string;
	unhighlightStyle?: CSSProperties;
	[key: string]: any; // for rest props
}

export const Highlighter: FunctionComponent<HighlighterProps> = ({
	activeClassName = '',
	activeIndex = -1,
	activeStyle,
	autoEscape,
	caseSensitive = false,
	className,
	findChunks,
	highlightClassName = '',
	highlightStyle = {},
	highlightTag = 'mark',
	searchWords,
	textToHighlight,
	unhighlightTag = 'span',
	unhighlightClassName = '',
	unhighlightStyle,
	...rest
}) => {
	const chunks = findAll({
		autoEscape,
		caseSensitive,
		findChunks,
		searchWords,
		textToHighlight,
	});
	const HighlightTag: any = highlightTag;
	let highlightIndex = -1;
	let highlightClassNames = '';
	let highlightStyles: CSSProperties | undefined;

	const lowercaseProps = (object: { [key: string]: any }): { [key: string]: any } => {
		const mapped: { [key: string]: any } = {};
		for (const key in object) {
			mapped[key.toLowerCase()] = object[key];
		}
		return mapped;
	};
	const memoizedLowercaseProps = memoizeOne(lowercaseProps);

	return createElement('span', {
		className,
		...rest,
		children: chunks.map((chunk: Chunk, index: number) => {
			const text = textToHighlight.substr(chunk.start, chunk.end - chunk.start);

			if (chunk.highlight) {
				highlightIndex++;

				let highlightClass: string;
				if (typeof highlightClassName === 'object') {
					if (!caseSensitive) {
						highlightClassName = memoizedLowercaseProps(highlightClassName);
						highlightClass = (highlightClassName as { [k: string]: string })[text.toLowerCase()];
					} else {
						highlightClass = highlightClassName[text];
					}
				} else {
					highlightClass = highlightClassName;
				}

				const isActive = highlightIndex === +activeIndex;

				highlightClassNames = `${highlightClass} ${isActive ? activeClassName : ''}`;
				highlightStyles = isActive === true && activeStyle != null
					? ({ ...highlightStyle, ...activeStyle })
					: highlightStyle;

				const props: any = {
					children: text,
					className: highlightClassNames,
					key: index,
					style: highlightStyles,
				};

				if (typeof HighlightTag !== 'string') {
					props.highlightIndex = highlightIndex;
				}

				return createElement(HighlightTag, props);
			}
			return createElement(unhighlightTag as string, {
				children: text,
				className: unhighlightClassName,
				key: index,
				style: unhighlightStyle,
			});
		}),
	});
};

export type { HighlighterProps };
