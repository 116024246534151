import TurndownService from 'turndown';

let CACHED_TURNDOWN: TurndownService | undefined;
export function getTurndown(): TurndownService {
	if (!CACHED_TURNDOWN) {
		CACHED_TURNDOWN = new TurndownService({
			headingStyle: 'atx',
			codeBlockStyle: 'fenced',
			bulletListMarker: '-',
		});
	}
	return CACHED_TURNDOWN;
}

export function convertHtmlToMarkdown(html: string): string {
	const turndown = getTurndown();
	return turndown.turndown(html);
}
