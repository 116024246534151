import { tool } from 'ai';
import omit from 'object.omit';
import { z } from 'zod';

import type { AIToolsContext } from '.';

export function saveResourceFactory(context: AIToolsContext) {
	return tool({
		description: [
			'add (or update) a resource to your knowledge base.',
			'When asked to save a new knowledge base resource, use this tool.',
			'Passing in an existing `id` will update the resource instead of creating a new one.',
			'** ALWAYS CONFIRM WITH THE USER BEFORE SAVING A RESOURCE **',
		].join(' '),
		parameters: z.object({
			title: z.string().optional()
				.describe('the title of the resource'),
			questions: z.array(z.string()).optional()
				.describe('an array of other questions that the resource answers'),
			answer: z.string().optional().describe([
				'REQUIRED for saving NEW resources, OMIT to leave unchanged for updating existing articles.',
				'The content or resource to add to the knowledge base. Use Markdown with steps when possible.',
			].join('\r\n')),
			category: z.string().optional()
				.describe('if provided, the resource will be added to the given category. Use `RAG` for general resources.'),
			id: z.string().optional()
				.describe('if provided, the resource with `id` will be updated instead of created'),
		}),
		execute: async ({
			id, title, questions, answer, category,
		}) => {
			context.logger?.debug('Saving resource', {
				title, questions, answer, category, id,
			});
			if (context.onToolCall) {
				context.onToolCall('saveResource', {
					title, questions, answer, category, id,
				});
			}
			await context.saveResource({
				$type: 'RAG',
				$id: id,
				title,
				questions,
				answer,
				category,
				created_by: context.user?.$id,
			});
			if (id) {
				return 'Resource updated';
			}
			return 'Resource created';
		},
	});
}

export function searchResourceFactory(context: AIToolsContext) {
	return tool({
		description: [
			'Searches for "resources" in the knowledge base.',
			'Use this to get information from the Newstex knowledge base to answer questions.',
			'** ALWAYS USE THIS TOOL BEFORE ANSWERING A QUESTION **',
		].join(' '),
		parameters: z.object({
			question: z.string()
				.describe('the users question. Use `*` to list ALL resources.'),
			userSpecific: z.boolean().optional()
				.describe('if true, the search will only return results that were created by the current user (i.e. "MY" resources)'),
			category: z.string().optional()
				.describe('if provided, the search will only return results that match the given category'),
			sort_by: z.string().optional()
				.describe('the field to sort the results by, using TypeSense sort_by string syntax'),
		}),
		execute: async ({
			question, userSpecific, category, sort_by,
		}) => {
			context.logger?.debug('Searching resources', {
				question, userSpecific, category, sort_by,
			});
			if (context.onToolCall) {
				context.onToolCall('searchResource', {
					question, userSpecific, category, sort_by,
				});
			}
			let filterBy: string | undefined;
			if (userSpecific) {
				filterBy = `created_by:"${context.user?.$id}"`;
			}

			if (category) {
				filterBy = filterBy ? `${filterBy} &&` : '';
				filterBy += `category:"${category}"`;
			}
			const results = await context.searchClient.search({
				indexName: 'RAG',
				query: question,
				filter_by: filterBy,
				exclude_fields: 'embedding',
				query_by: ['question', 'questions', 'title', 'answer'],
				sort_by,
			});
			const output = (results.hits || ['No Results Found']).map((hit) => omit(hit, ['embedding', '$type']));
			context.logger?.debug('Search results', { filterBy, results, output });
			return output;
		},
	});
}
