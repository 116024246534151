import {
	IconDefinition,
	faDashboard,
	faLineChart,
	faTable,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { title as titleCase } from 'case';
import React from 'react';
import {
	Button,
	ButtonGroup,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

interface DisplayModeButtonProps {
	mode: string;
	icon: IconDefinition;
	searchKey?: string;
	default?: boolean;
	onClick?: () => void;
}

export const DisplayModeButton: React.FC<DisplayModeButtonProps> = ({
	mode, icon, searchKey, default: isDefault, onClick,
}) => {
	const [searchParams, setSearchParams] = useSearchParams();
	return (
		<OverlayTrigger
			placement="bottom"
			overlay={
				<Tooltip>
					{`${titleCase(mode)} view`}
				</Tooltip>
			}
		>
			<Button
				variant="outline-secondary"
				size="sm"
				onClick={onClick || (() => setSearchParams((params) => {
					params.set(searchKey || 'mode', mode);
					return params;
				}))}
				title={`Switch to ${mode} view`}
				active={searchParams.get(searchKey || 'mode') === mode || (isDefault && !searchParams.get(searchKey || 'mode'))}
			>
				<FontAwesomeIcon icon={icon} />
			</Button>
		</OverlayTrigger>
	);
};

export const DisplayModeButtons: React.FC = () => {
	return (
		<div className="text-end mb-2">
			<ButtonGroup>
				<DisplayModeButton
					mode="table"
					icon={faTable}
					default
				/>
				<DisplayModeButton
					mode="timeline"
					icon={faLineChart}
				/>
				<DisplayModeButton
					mode="dashboard"
					icon={faDashboard}
				/>
			</ButtonGroup>
		</div>
	);
};
