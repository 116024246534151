import Preloader from './Preloader';

export default function LoadingSpinner({
	loading,
	title,
	hideTitle,
}: {
	loading: boolean,
	title?: string,
	hideTitle?: boolean,
}) {
	return (
		<div className="loader" style={{ display: loading ? 'block' : 'none' }}>
			<center className="justify-content-center pt-5 pb-5 card-body">
				{!hideTitle && <h1>{title || 'Loading...'}</h1> }
				<Preloader type="three-bounce" variant="success" />
			</center>
		</div>
	);
}
