import { tool } from 'ai';
import omit from 'object.omit';
import { z } from 'zod';

import type { AIToolsContext } from '.';

export function saveMemoryFactory(context: AIToolsContext) {
	return tool({
		description: [
			'Add (or update) a memory to the RAG system.',
			'When asked to remember something, use this tool.',
			'Passing in an existing `id` will update the memory instead of creating a new one.',
		].join(' '),
		parameters: z.object({
			content: z.string()
				.describe('the content to remember'),
			link: z.string().optional()
				.describe('optional link or reference associated with this memory'),
			category: z.string().optional()
				.describe('if provided, the memory will be added to the given category'),
			id: z.string().optional()
				.describe('if provided, the memory with `id` will be updated instead of created'),
		}),
		execute: async ({
			id, content, link, category,
		}) => {
			context.logger?.debug('Saving memory', {
				content, link, category, id,
			});
			if (context.onToolCall) {
				context.onToolCall('saveMemory', {
					content, link, category, id,
				});
			}
			await context.saveResource({
				$id: id,
				$type: 'RAGMemory',
				content,
				link,
				category,
				created_by: context.user?.$id,
			});
			if (id) {
				return 'Memory updated';
			}
			return 'Memory created';
		},
	});
}

export function searchMemoryFactory(context: AIToolsContext) {
	return tool({
		description: [
			'Searches through memories in the RAG system.',
			'Use this to recall information from previous conversations or stored memories.',
			'** USE THIS TOOL TO ACCESS HISTORICAL CONTEXT **',
		].join(' '),
		parameters: z.object({
			query: z.string()
				.describe('the search query. Use `*` to list ALL memories.'),
			userSpecific: z.boolean().optional()
				.default(true)
				.describe('if true, the search will only return memories created by the current user'),
			category: z.string().optional()
				.describe('if provided, the search will only return memories that match the given category'),
			sort_by: z.string().optional()
				.describe('the field to sort the results by, using TypeSense sort_by string syntax'),
		}),
		execute: async ({
			query, userSpecific, category, sort_by,
		}) => {
			context.logger?.debug('Searching memories', {
				query, userSpecific, category, sort_by,
			});
			if (context.onToolCall) {
				context.onToolCall('searchMemory', {
					query, userSpecific, category, sort_by,
				});
			}
			let filterBy: string | undefined;
			if (userSpecific) {
				filterBy = `created_by:"${context.user?.$id}"`;
			}

			if (category) {
				filterBy = filterBy ? `${filterBy} &&` : '';
				filterBy += `category:"${category}"`;
			}
			const results = await context.searchClient.search({
				indexName: 'RAGMemory',
				query,
				filter_by: filterBy,
				exclude_fields: 'embedding',
				query_by: ['content', 'link'],
				sort_by,
			});
			const output = (results.hits || ['No Results Found']).map((hit) => omit(hit, ['embedding', '$type']));
			context.logger?.debug('Memory search results', { filterBy, results, output });
			return output;
		},
	});
}
