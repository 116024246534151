import { Results } from '@newstex/types/results';
import React, { useEffect, useState } from 'react';
import { useAPI } from '~/providers/api-provider';

import BarChart, { BarChartProps } from '../charts/BarChart';
import LoadingSpinner from '../LoadingSpinner';

interface DeliveryStatsProps {
	deliveryID?: string;
}

export const DeliveryStats: React.FC<DeliveryStatsProps> = ({ deliveryID }) => {
	const [loading, setLoading] = useState(false);
	const [storyStats, setStoryStats] = useState<Pick<BarChartProps, 'datasets' | 'labels'>>();
	const [period, setPeriod] = useState<'day' | 'week' | 'month'>('week');
	const api = useAPI();

	useEffect(() => {
		let cancelled = false;
		const loadDeliveryStats = async (nextToken?: string) => {
			if (cancelled) {
				return;
			}
			setLoading(true);
			setStoryStats(undefined);

			let since = '30d';
			if (period === 'day') {
				since = '30d';
			} else if (period === 'week') {
				since = '90d';
			} else if (period === 'month') {
				// 3 Months
				since = '120d';
			}

			const searchParams = new URLSearchParams({
				since,
				timeGroup: period,
			});
			if (deliveryID) {
				searchParams.append('delivery_id', deliveryID);
			}

			if (nextToken) {
				searchParams.append('NextToken', nextToken);
			}

			const statsResp = await api.fetchWithAuth<Results<any>>(`stats/delivery-counts?${searchParams}`);

			if (cancelled) {
				return;
			}

			if (statsResp.nextToken) {
				setTimeout(loadDeliveryStats, 1000, statsResp.nextToken);
			}

			if (statsResp.items?.length) {
				let labels: BarChartProps['labels'] = [];
				const datasets: BarChartProps['datasets'] = [];
				// Get all unique days first
				for (const item of statsResp.items) {
					if (item.day && !labels.some((label) => label.name === item.day.split(' ')[0])) {
						const date = new Date(item.day);
						labels.push({
							name: item.day.split(' ')[0],
							backgroundColor: (period === 'day' && (date.getDay() === 0 || date.getDay() === 6)) ? 'rgba(0,0,0,0.1)' : undefined,
						});
					}
				}
				// Sort them by oldest first
				labels = labels.sort((a, b) => {
					const dateA = new Date(a.name);
					const dateB = new Date(b.name);
					return dateA.getTime() - dateB.getTime();
				});
				// Then create the datasets, making sure there is a value for each day
				for (const item of statsResp.items) {
					let dataset = datasets.find((d) => d.label === (item.delivery_name || 'Delivered'));
					if (!dataset) {
						// Populate initially with all zeros
						dataset = {
							label: item.delivery_name || 'Delivered',
							data: labels.map(() => 0),
						};
						// If we're only filtering on a single delivery ID, just use a line chart
						if (deliveryID) {
							dataset.type = 'line';
							dataset.borderWidth = 2;
							dataset.color = 'green';
							datasets.unshift(dataset);
						} else {
							// Otherwise, everything is a bar chart
							datasets.push(dataset);
						}
					}

					if (item.story_count) {
						const labelIndex = labels.findIndex((label) => label.name === item.day.split(' ')[0]);
						if (labelIndex !== -1) {
							dataset.data[labelIndex] = item.story_count;
						}
					}
				}

				if (!cancelled) {
					setStoryStats({
						labels,
						datasets: datasets.slice(0, 10),
					});
				}
			} else if (!cancelled) {
				setStoryStats(undefined);
			}

			if (!cancelled && !statsResp.nextToken) {
				setLoading(false);
			}
		};

		loadDeliveryStats();
		return () => {
			cancelled = true;
		};
	}, [deliveryID, period, api]);

	if (!loading && !storyStats) {
		return (
			<h5 className="text-center">
				No story delivery data available
			</h5>
		);
	}

	return (
		<>
			{storyStats ? (
				<BarChart
					setPeriod={setPeriod}
					period={period}
					{...storyStats}
				/>
			)
				: <LoadingSpinner
					hideTitle={true}
					loading={loading}
				/>
			}
		</>
	);
};
