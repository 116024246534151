import { type Alert } from '@newstex/types/alert';
import { useRef, useState, useEffect } from 'react';
import {
	Alert as BSAlert,
	Button,
	Container,
	Form,
	Modal,
	Spinner,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useAPI } from '~/providers/api-provider';

import { AlertForm } from '../alert-form';
import { arrayEqual } from '@newstex/core/array-utils';

interface EditAlertModalProps {
	item: Alert;
	show: boolean;
	onClose: (saved: boolean) => void;
}

export function EditAlertModal({ item, show, onClose }: EditAlertModalProps) {
	const [isSaving, setIsSaving] = useState(false);
	const [error, setError] = useState('');
	const formRef = useRef<HTMLFormElement>(null);
	const api = useAPI();

	// Reset error when modal is opened with a new item
	useEffect(() => {
		if (show) {
			setError('');
		}
	}, [show, item?.$id]);

	const saveAlert = () => {
		if (formRef.current) {
			const formData = new FormData(formRef.current);
			const updates: Partial<Alert> = {};

			// Check name
			const name = formData.get('name')?.toString()?.trim();
			if (!name) {
				setError('Name is required');
				return;
			}

			if (name !== item.name) {
				updates.name = name;
			}

			const description = formData.get('description')?.toString().trim();
			if (description && description !== item.description) {
				updates.description = description;
			}

			// Check search query and filters
			const searchQuery = formData.get('search')?.toString() || '*';
			const searchFilters = formData.get('search_filters')?.toString() || '';
			if (searchQuery !== item.search.q || searchFilters !== item.search.filter_by) {
				updates.search = {
					q: searchQuery,
					filter_by: searchFilters,
				};
			}

			// Check schedule
			const schedule = formData.get('schedule')?.toString();
			console.log('Schedule', schedule);
			if (schedule && schedule !== item.schedule) {
				updates.schedule = schedule;
			}

			// Check expected values - use strict comparison
			const min = formData.get('min') ? Number(formData.get('min')) : undefined;
			const max = formData.get('max') ? Number(formData.get('max')) : undefined;
			const diff = formData.get('diff') ? Number(formData.get('diff')) : undefined;
			if (min !== item.expected?.min || max !== item.expected?.max || diff !== item.expected?.diff) {
				updates.expected = {
					min,
					max,
					diff,
				};
			}

			// Check field - handle empty string vs undefined
			const checkField = formData.get('check_field')?.toString() || undefined;
			if (checkField !== item.check_field) {
				updates.check_field = checkField;
			}

			// Emails
			const emails = formData.getAll('emails');
			if (emails?.length) {
				const newEmails = [];
				for (const email of emails) {
					if (email.toString().trim().length > 0) {
						newEmails.push(email.toString().trim());
					}
				}
				if (!arrayEqual(newEmails, item.emails || [], true)) {
					updates.emails = newEmails;
				}
			}

			// If no changes, return early
			if (Object.keys(updates).length === 0) {
				onClose(false);
				return;
			}

			setIsSaving(true);
			console.log('Updating with:', updates);
			toast.info('Updating Alert...');
			api.fetchWithAuth(`resources/Alert/${item.$id}`, {
				method: 'PATCH',
				body: JSON.stringify(updates),
			}).then(() => {
				toast.success('Alert updated');
				setIsSaving(false);
				onClose(true);
			}).catch((e) => {
				console.error(e);
				toast.error(`Alert update failed: ${String(e)}`);
				setError(String(e));
				setIsSaving(false);
			});
		}
	};

	return (
		<Modal
			size="lg"
			show={show}
			onHide={() => onClose(false)}
		>
			<Modal.Header closeButton>
				<Modal.Title>Edit Alert</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{error && <BSAlert variant="danger">{error}</BSAlert>}
				<Form ref={formRef}>
					<Container fluid>
						<AlertForm item={item} isEdit disabled={isSaving} />
					</Container>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				{isSaving && <Spinner animation="border" size="sm" />}
				<Button variant="secondary" onClick={() => onClose(false)}>Cancel</Button>
				<Button
					variant="primary"
					onClick={() => saveAlert()}
					disabled={isSaving}
				>
					Save Changes
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
