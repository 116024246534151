import { faChevronDown, faChevronUp, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Publication } from '@newstex/types/content';
import { AccountInfoRelatedContent } from '@newstex/types/responses/info';
import type { RevenueAndEarningsForContact } from '@newstex/types/revenue';
import { createColumnHelper } from '@tanstack/react-table';
import {
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	Button,
	Card,
	Col,
	Container,
	Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BarChart, { BarChartProps } from '~/components/charts/BarChart';
import DataTable, { DataTableMethods } from '~/components/DataTable';
import LoadingSpinner from '~/components/LoadingSpinner';
import MyPubs from '~/components/my-pubs';
import KPIStatsWidget from '~/components/Widgets/KPIStats';
import { formatDateYYYYMMDD } from '~/lib/utils';
import { useAPI } from '~/providers/api-provider';
import { useSearch } from '~/providers/search';
import { useUserInfo } from '~/providers/user-info';

export function RevenueChart({ data }: { data: RevenueAndEarningsForContact }) {
	// Short circuit if there's no revenue data
	if (!data.earnings?.length || data.earnings.length === 0) {
		return <h4 className="text-center">No revenue data available</h4>;
	}

	const [stats, setStats] = useState<BarChartProps['datasets']>([]);
	const [dataLabels, setLabels] = useState<BarChartProps['labels']>([]);
	const [viewType, setViewType] = useState<'monthly' | 'quarterly'>('monthly');

	useEffect(() => {
		const datasets: BarChartProps['datasets'] = [];
		const periods: string[] = [];

		// Find the newest date in the revenue data
		const startDate = new Date(Math.max(...data.earnings.map((row) => new Date(`${row.period}T00:00:00`).getTime())));

		// For monthly view, show last 13 months
		// For quarterly view, show last 20 quarters (5 years)
		const numPeriods = viewType === 'monthly' ? 13 : 20;
		const currentDate = new Date(`${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-01T00:00:00`);

		// Start from current month/quarter
		if (viewType === 'monthly') {
			for (let i = 0; i < numPeriods; i++) {
				const year = currentDate.getFullYear();
				const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
				periods.unshift(`${year}-${month}`);
				currentDate.setMonth(currentDate.getMonth() - 1);
			}
		} else {
			// For quarterly view, start from current quarter
			const currentQuarter = Math.floor(currentDate.getMonth() / 3);
			currentDate.setMonth(currentQuarter * 3); // Set to start of current quarter

			for (let i = 0; i < numPeriods; i++) {
				const year = currentDate.getFullYear();
				const quarter = Math.floor(currentDate.getMonth() / 3) + 1;
				periods.unshift(`${year}-Q${quarter}`);
				currentDate.setMonth(currentDate.getMonth() - 3);
			}
		}

		const earningsByClient: Record<string, Record<string, number>> = {};
		const totalsByPeriod: Record<string, number> = {};

		// Calculate earnings by client and totals
		for (const row of data.earnings) {
			const period = viewType === 'monthly'
				? row.period
				: `${row.period.slice(0, 4)}-Q${Math.floor((parseInt(row.period.slice(5, 7), 10) - 1) / 3) + 1}`;

			if (!earningsByClient[row.client]) {
				earningsByClient[row.client] = {};
			}

			if (!earningsByClient[row.client][period]) {
				earningsByClient[row.client][period] = 0;
			}

			if (!totalsByPeriod[period]) {
				totalsByPeriod[period] = 0;
			}

			const earnings = row.earnings || 0;
			earningsByClient[row.client][period] += earnings;
			totalsByPeriod[period] += earnings;
		}

		// Define distinct colors for up to 10 clients
		const clientColors = [
			'#2E86C1', // Blue
			'#E74C3C', // Red
			'#8E44AD', // Purple
			'#F39C12', // Orange
			'#16A085', // Turquoise
			'#D35400', // Dark Orange
			'#27AE60', // Emerald
			'#C0392B', // Dark Red
			'#3498DB', // Light Blue
			'#9B59B6', // Light Purple
		];

		// Add bar charts for each client
		const sortedClients = Object.entries(earningsByClient)
			.sort((a, b) => b[1][periods[periods.length - 1]] - a[1][periods[periods.length - 1]]);

		let index = 0;
		for (const [clientName, clientData] of sortedClients) {
			datasets.push({
				label: clientName,
				data: periods.map((period) => clientData[period] || 0),
				stack: 'earnings',
				backgroundColor: clientColors[index % clientColors.length],
			});
			index++;
		}

		// Add total line chart
		datasets.push({
			label: 'Total',
			type: 'line',
			data: periods.map((period) => totalsByPeriod[period] || 0),
			borderColor: '#1B5E20',
			backgroundColor: '#1B5E20',
			borderWidth: 2,
			pointRadius: 3,
			order: 0, // Make sure line appears above bars
		});

		setStats(datasets);
		setLabels(periods.map((period) => ({
			name: viewType === 'monthly'
				? new Date(`${period}-01T00:00:00`).toLocaleDateString(undefined, { month: 'short', year: 'numeric' })
				: period,
		})));
	}, [data, viewType]);

	return (<>
		<div className="d-flex justify-content-end mb-3">
			<div className="btn-group">
				<button
					className={`btn btn-sm ${viewType === 'monthly' ? 'btn-primary' : 'btn-outline-secondary'}`}
					onClick={() => setViewType('monthly')}
				>
					Monthly
				</button>
				<button
					className={`btn btn-sm ${viewType === 'quarterly' ? 'btn-primary' : 'btn-outline-secondary'}`}
					onClick={() => setViewType('quarterly')}
				>
					Quarterly
				</button>
			</div>
		</div>
		{stats.length > 0 && <BarChart
			labels={dataLabels}
			datasets={stats}
			period="month"
			stacked={true}
			formatValue={(value) => `$${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
		/>}
	</>);
}

export function RevenueTable({ data }: { data: RevenueAndEarningsForContact }) {
	const dataTableRef = useRef<DataTableMethods>(null);
	const columnHelper = createColumnHelper<any>();
	const [isExpanded, setIsExpanded] = useState(false);

	// Process data for table display
	const tableData = useMemo(() => {
		if (!data.clientEarnings?.length) return [];

		// Create a map of periods to aggregate earnings by client
		const periodMap = new Map<string, { [key: string]: number }>();
		const periodTotals = new Map<string, number>();

		for (const earning of data.transactions) {
			periodTotals.set(earning.period, earning.earnings || 0);
		}

		// Process all earnings data
		for (const earning of data.clientEarnings) {
			const period = earning.period;
			if (!periodMap.has(period)) {
				periodMap.set(period, {});
			}
			const periodData = periodMap.get(period)!;
			periodData[earning.client] = earning.earnings || 0;
		}

		// Convert map to array of objects for table
		return Array.from(periodMap.entries())
			.map(([period, clientData]) => ({
				period,
				...clientData,
				total: periodTotals.get(period) || 0,
			}))
			.sort((a, b) => b.period.localeCompare(a.period)); // Sort by period descending
	}, [data]);

	const columns = useMemo(() => {
		// Get unique client names from the data
		const clients = Array.from(new Set(data.earnings?.map((e) => e.client) || [])).sort();

		return [
			columnHelper.accessor('period', {
				header: 'Period',
				cell: ({ getValue }) => {
					const period = getValue();
					return new Date(`${period}-01`).toLocaleDateString(undefined, {
						month: 'long',
						year: 'numeric',
					});
				},
			}),
			...clients.map((client) => columnHelper.accessor(client, {
				header: client,
				cell: ({ getValue }) => {
					const value = getValue() || 0;
					return `$${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
				},
			})),
			columnHelper.accessor('total', {
				header: 'Total',
				cell: ({ getValue }) => {
					const value = getValue() || 0;
					return `$${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
				},
			}),
		];
	}, [data]);

	const handleExportCSV = () => {
		const formatDollar = (value: number) => `$${(value || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;

		// Get the most recent period from the sorted tableData
		const lastPeriod = tableData[0]?.period;
		const formattedPeriod = lastPeriod ? new Date(`${lastPeriod}-01`).toLocaleDateString(undefined, {
			month: 'long',
			year: 'numeric',
		}) : 'Unknown Period';

		dataTableRef.current?.exportToCSV(`Newstex Content Fee Summary - ${formattedPeriod}`, {
			Period: (item) => new Date(`${item.period}-01`).toLocaleDateString(undefined, {
				month: 'long',
				year: 'numeric',
			}),
			...Object.fromEntries(
				data.earnings?.map((e) => e.client)
					.filter((v, i, a) => a.indexOf(v) === i)
					.sort()
					.map((client) => [client, (item) => formatDollar(item[client] || 0)]),
			),
			Total: (item) => formatDollar(item.total || 0),
		});
	};

	return (
		<Card className="mt-4">
			<Card.Header className="d-flex justify-content-between align-items-center">
				<div className="d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={() => setIsExpanded(!isExpanded)}>
					<div className="card-heading">Monthly Revenue Summary</div>
					<div className="ms-2">
						<FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} className="text-muted" />
					</div>
				</div>
				<div>
					<Button variant="outline-secondary" size="sm" onClick={handleExportCSV}>
						<FontAwesomeIcon icon={faDownload} /> Export to CSV
					</Button>
				</div>
			</Card.Header>
			<div className={`collapse ${isExpanded ? 'show' : ''}`}>
				<Card.Body>
					<DataTable
						columns={columns}
						items={tableData}
						ref={dataTableRef}
						striped
						sortable
					/>
				</Card.Body>
			</div>
		</Card>
	);
}

export function UserDashboard() {
	const userInfo = useUserInfo();
	const api = useAPI();
	const search = useSearch();
	const [loading, setLoading] = useState(true);
	const [revenue, setRevenue] = useState<RevenueAndEarningsForContact>();
	const [myPubs, setMyPubs] = useState<AccountInfoRelatedContent[]>([]);

	useEffect(() => {
		const fetchData = async () => {
			if (api) {
				// Fetch revenue for up to 5 years
				const resp = await api.fetchWithAuth<RevenueAndEarningsForContact>('revenue?months=60');
				setRevenue(resp);
			}
			// Use the search client to fetch last post date stats for each publication
			const pubs = userInfo?.my_pubs || [];
			if (search.searchClient && pubs.length) {
				const searchResp = await search.searchClient.search<Publication>({
					indexName: 'Publication',
					q: '*',
					query_by: 'name',
					sort_by: 'last_post_date:desc',
					per_page: 100,
				});
				for (const hit of searchResp.hits) {
					if (hit.$id && hit.last_post_date) {
						const pub = pubs.find((item) => item.newscore_id === hit.$id);
						if (pub) {
							pub.last_post_date = formatDateYYYYMMDD(hit.last_post_date);
						}
					}
				}
			}
			setMyPubs(pubs.sort((a, b) => (b.last_post_date || '').localeCompare(a.last_post_date || '')));
			setLoading(false);
		};
		setLoading(true);
		fetchData();
	}, [api, search]);

	return (
		<Container fluid className="px-lg-4 px-xl-5">
			<div className="page-header d-flex justify-content-between align-items-center">
				<h1 className="page-heading">Activity Dashboard</h1>
			</div>
			{loading && <LoadingSpinner loading={loading} /> }
			{!loading && (<>
				<Row className="mb-3">
					<Col sm={6} lg={3} className="mb-4">
						<Card className="h-100 hover-animate">
							<Card.Body className="d-flex align-items-center justify-content-between">
								<div>
									<h4 className='fw-normal'>{revenue?.currentPeriod && (<>
										{new Date(`${revenue.currentPeriod}T00:00:00`).toLocaleDateString(undefined, { month: 'long', year: 'numeric' })}
									</>)}</h4>
									<div className="subtitle text-sm text-muted mb-0">Most recent reporting period</div>
								</div>
								<Link to={`/revenue?period=${revenue?.currentPeriod}`} className="stretched-link" />
							</Card.Body>
						</Card>
					</Col>
					<Col sm={6} lg={3} className="mb-4">
						<KPIStatsWidget
							name="Total Lifetime Earnings"
							icon="dollar-badge-1"
							prefix="$"
							number={revenue?.totalLifetimeEarnings?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '0.00'}
							footer="Total lifetime earnings for your account"
							color="green"
							link="/revenue"
						/>
					</Col>
					<Col sm={6} lg={3} className="mb-4">
						<KPIStatsWidget
							name="Current Month Earnings"
							icon="dollar-sign-1"
							prefix="$"
							number={revenue?.currentMonthEarnings?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '0.00'}
							footer={`Content Fees earned in ${revenue?.currentPeriod
								? new Date(`${revenue.currentPeriod}T00:00:00`).toLocaleDateString(undefined, { month: 'long', year: 'numeric' })
								: 'the current month'}`
							}
							link={`/revenue?period=${revenue?.currentPeriod}`}
							color="green"
						/>
					</Col>
					<Col sm={6} lg={3} className="mb-4">
						<KPIStatsWidget
							name="Accrued Content Fees"
							icon="money-box-1"
							prefix="$"
							number={revenue?.currentMonthPredisbursementAmount?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '0.00'}
							footer="Content Fees that have been earned but not yet paid out"
							color="blue"
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						{revenue && <RevenueChart data={revenue} />}
						{revenue && <RevenueTable data={revenue} />}
					</Col>
				</Row>
			</>)}
			<section className="mb-3 mb-lg-5">
				{userInfo?.my_pubs && (
					<MyPubs
						title="My Publications"
						pubs={myPubs}
					/>
				)}
			</section>
		</Container>
	);
}
