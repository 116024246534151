import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';

import { CreateDeliveryModal } from './create-delivery-modal';

interface CreateDeliveryButtonProps {
	onDeliveryCreated?: () => void;
	size?: ButtonProps['size'];
	variant?: ButtonProps['variant'];
	className?: string;
}

export function CreateDeliveryButton({
	onDeliveryCreated,
	size,
	variant,
	className,
}: CreateDeliveryButtonProps) {
	const [showCreateModal, setShowCreateModal] = useState(false);

	const handleCreateDelivery = () => {
		setShowCreateModal(true);
	};

	const handleCloseModal = (saved: boolean) => {
		setShowCreateModal(false);
		if (saved && onDeliveryCreated) {
			onDeliveryCreated();
		}
	};

	return (
		<>
			<Button
				size={size || 'sm'}
				variant={variant || 'outline-success'}
				className={className}
				onClick={handleCreateDelivery}
			>
				<FontAwesomeIcon icon={faPlus} className="me-1" />
				New Delivery
			</Button>

			<CreateDeliveryModal
				show={showCreateModal}
				onClose={handleCloseModal}
			/>
		</>
	);
}
