/**
 * Auto-generate schedules from human-readable input
 *
 * NOTE: This was mostly generated by AI, including the tests.
 */
import { formatTime, parseTime } from './time';

const DAYS_OF_WEEK = {
	sunday: 0,
	monday: 1,
	tuesday: 2,
	wednesday: 3,
	thursday: 4,
	friday: 5,
	saturday: 6,
} as const;

type DayOfWeek = keyof typeof DAYS_OF_WEEK;

function getDayNumber(day: string): number {
	const normalized = day.toLowerCase() as DayOfWeek;
	if (normalized in DAYS_OF_WEEK) {
		return DAYS_OF_WEEK[normalized];
	}
	throw new Error(`Invalid day: ${day}`);
}

export interface Schedule {
	label: string;
	pattern: RegExp;
	cron?: string;
	transform?: (matches: string[]) => string;
}

export const COMMON_SCHEDULES: Schedule[] = [
	{
		label: 'Every hour',
		pattern: /^every hour$/i,
		cron: '0 * * * *',
	},
	{
		label: 'Every N hours',
		pattern: /^every (\d+) hours?$/i,
		cron: '0 */$1 * * *',
	},
	{
		label: 'Every N minutes',
		pattern: /^every (\d+) minutes?$/i,
		cron: '*/$1 * * * *',
	},
	{
		label: 'Daily at time',
		pattern: /^(?:every day |daily )?at (\d{1,2}(?::\d{2})?(?:am|pm))$/i,
		transform: (matches) => {
			const time = parseTime(matches[1]);
			return `${time.minute} ${time.hour} * * *`;
		},
	},
	{
		label: 'Weekdays at time',
		pattern: /^(?:every )?weekday(?:s)? at (\d{1,2}(?::\d{2})?(?:am|pm))$/i,
		transform: (matches) => {
			const time = parseTime(matches[1]);
			return `${time.minute} ${time.hour} * * 1-5`;
		},
	},
	{
		label: 'Weekends at time',
		pattern: /^(?:every )?weekend(?:s)? at (\d{1,2}(?::\d{2})?(?:am|pm))$/i,
		transform: (matches) => {
			const time = parseTime(matches[1]);
			return `${time.minute} ${time.hour} * * 0,6`;
		},
	},
	{
		label: 'Specific day at time',
		pattern: /^(?:every )?((?:mon|tues|wednes|thurs|fri|satur|sun))(?:day)s? at (\d{1,2}(?::\d{2})?(?:am|pm))$/i,
		transform: (matches) => {
			const [, dayPrefix, timeStr] = matches;
			const dayMap = {
				sun: 'sunday',
				mon: 'monday',
				tues: 'tuesday',
				wednes: 'wednesday',
				thurs: 'thursday',
				fri: 'friday',
				satur: 'saturday',
			};
			try {
				const fullDay = dayMap[dayPrefix.toLowerCase() as keyof typeof dayMap];
				const dayNum = getDayNumber(fullDay);
				const time = parseTime(timeStr);
				return `${time.minute} ${time.hour} * * ${dayNum}`;
			} catch {
				return null; // Return null to continue trying other patterns
			}
		},
	},
	{
		label: 'Between times',
		pattern: /^(?:every )?(?:(weekday|weekend|(?:mon|tues|wednes|thurs|fri|satur|sun)(?:day))s? )?(?:between|from) (\d{1,2}(?::\d{2})?(?:am|pm)) (?:and|to) (\d{1,2}(?::\d{2})?(?:am|pm))$/i,
		transform: (matches) => {
			const [, dayType, startStr, endStr] = matches;
			const start = parseTime(startStr);
			const end = parseTime(endStr);

			let days = '*';
			if (dayType) {
				const dayMap = {
					sun: 'sunday',
					mon: 'monday',
					tues: 'tuesday',
					wednes: 'wednesday',
					thurs: 'thursday',
					fri: 'friday',
					satur: 'saturday',
				};

				if (dayType.toLowerCase() === 'weekday') {
					days = '1-5';
				} else if (dayType.toLowerCase() === 'weekend') {
					days = '0,6';
				} else {
					// Extract day prefix and look up in map
					const dayPrefix = dayType.toLowerCase().replace('day', '');
					const fullDay = dayMap[dayPrefix as keyof typeof dayMap];
					if (fullDay) {
						days = String(getDayNumber(fullDay));
					} else {
						return null; // Return null to continue trying other patterns
					}
				}
			}

			return `0 ${start.hour}-${end.hour} * * ${days}`;
		},
	},
	{
		label: 'Every N hours between times',
		pattern: /^every (\d+) hours? (?:between|from) (\d{1,2}(?::\d{2})?(?:am|pm)) (?:and|to) (\d{1,2}(?::\d{2})?(?:am|pm))$/i,
		transform: (matches) => {
			const [, interval, startStr, endStr] = matches;
			const start = parseTime(startStr);
			const end = parseTime(endStr);
			return `0 ${start.hour}-${end.hour}/${interval} * * *`;
		},
	},
	{
		label: 'Hourly on days',
		pattern: /^hourly on (weekday|weekend)s?$/i,
		transform: (matches) => {
			const [, dayType] = matches;
			const days = dayType.toLowerCase() === 'weekday' ? '1-5' : '0,6';
			return `0 * * * ${days}`;
		},
	},
	{
		label: 'Every N minutes on days',
		pattern: /^every (\d+) minutes? on (weekday|weekend)s?$/i,
		transform: (matches) => {
			const [, interval, dayType] = matches;
			const days = dayType.toLowerCase() === 'weekday' ? '1-5' : '0,6';
			return `*/${interval} * * * ${days}`;
		},
	},
	{
		label: 'Every N hours on days',
		pattern: /^every (\d+) hours? on (weekday|weekend)s?$/i,
		transform: (matches) => {
			const [, interval, dayType] = matches;
			const days = dayType.toLowerCase() === 'weekday' ? '1-5' : '0,6';
			return `0 */${interval} * * ${days}`;
		},
	},
];

/**
 * Convert a human-readable schedule to a cron expression
 */
export function parseSchedule(input: string): string {
	// First check if it's already a valid cron expression
	if (/^[*\d,-/\s]+$/.test(input) && input.split(/\s+/).length === 5) {
		return input;
	}

	// Try to match against common patterns
	for (const schedule of COMMON_SCHEDULES) {
		const matches = schedule.pattern.exec(input);
		if (matches) {
			if (schedule.transform) {
				const result = schedule.transform(matches);
				if (result !== null) {
					return result;
				}
				// If transform returns null, continue trying other patterns
				continue;
			}
			// Replace $1, $2 etc in cron string with matched groups
			return schedule.cron.replace(/\$(\d+)/g, (_, n) => matches[+n]);
		}
	}

	throw new Error(`Unable to parse schedule: ${input}`);
}

/**
 * Convert a day number to its name
 */
function getDayName(day: number): string {
	return Object.entries(DAYS_OF_WEEK).find(([, num]) => num === day)?.[0] ?? '';
}

/**
 * Convert a cron expression to a human-readable string
 */
export function formatSchedule(cron: string): string {
	const parts = cron.split(' ');
	if (parts.length !== 5) {
		throw new Error('Invalid cron expression');
	}

	const [minute, hour, , , day] = parts;

	// Handle common patterns
	if (minute === '0' && hour === '*') {
		if (day === '*') {
			return 'every hour';
		}

		if (day === '1-5') {
			return 'hourly on weekdays';
		}

		if (day === '0,6') {
			return 'hourly on weekends';
		}
	}

	if (minute.startsWith('*/')) {
		const interval = minute.replace('*/', '');
		if (day === '*') {
			return `every ${interval} minutes`;
		}

		if (day === '1-5') {
			return `every ${interval} minutes on weekdays`;
		}

		if (day === '0,6') {
			return `every ${interval} minutes on weekends`;
		}
	}

	if (minute === '0' && hour.startsWith('*/')) {
		const interval = hour.replace('*/', '');
		if (day === '*') {
			return `every ${interval} hours`;
		}

		if (day === '1-5') {
			return `every ${interval} hours on weekdays`;
		}

		if (day === '0,6') {
			return `every ${interval} hours on weekends`;
		}
	}

	// Handle specific times
	if (!hour.includes('*') && !hour.includes(',') && !hour.includes('-')) {
		// Single time patterns
		const timeStr = minute === '0'
			? formatTime(+hour)
			: formatTime(+hour, +minute);

		if (day === '*') {
			return `every day at ${timeStr}`;
		}

		if (day === '1-5') {
			return `weekdays at ${timeStr}`;
		}

		if (day === '0,6') {
			return `weekends at ${timeStr}`;
		}

		if (/^\d$/.test(day)) {
			const dayName = getDayName(+day);
			return `${dayName}s at ${timeStr}`;
		}

		// If we can't parse the time format, return the original cron
		return cron;
	}

	// Handle time ranges
	if (minute === '0' && hour.includes('-')) {
		// Fix the parsing of hour ranges
		const [startHour, endHourPart] = hour.split('-');
		const [endHour] = endHourPart.split('/');
		const timeRange = `between ${formatTime(+startHour)} and ${formatTime(+endHour)}`;

		if (hour.includes('/')) {
			const interval = hour.split('/')[1];
			return `every ${interval} hours ${timeRange}`;
		}

		if (day === '*') {
			return timeRange;
		}

		if (day === '1-5') {
			return `weekdays ${timeRange}`;
		}

		if (day === '0,6') {
			return `weekends ${timeRange}`;
		}

		if (/^\d$/.test(day)) {
			const dayName = getDayName(+day);
			return `${dayName}s ${timeRange}`;
		}
	}

	// For complex patterns, return the original cron
	return cron;
}
