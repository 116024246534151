import {
	FormEvent,
	ReactNode,
	createContext,
	useContext,
	useState,
} from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

interface ConfirmOptions {
	message?: string;
	title?: string;
	details?: ReactNode;
	form?: ReactNode;
}

interface ConfirmContextType {
	confirmDialog: (message: string | ConfirmOptions) => Promise<false | [boolean, any]>;
}

const ConfirmContext = createContext<ConfirmContextType | undefined>(undefined);

export const useConfirm = (): ConfirmContextType => {
	const context = useContext(ConfirmContext);
	if (!context) {
		throw new Error('useConfirm must be used within a ConfirmProvider');
	}
	return context;
};

interface ConfirmProviderProps {
	children: ReactNode;
}

interface ConfirmState {
	message?: string;
	title?: string;
	details?: ReactNode;
	form?: ReactNode;
	resolve: (value: any) => void;
}

export const ConfirmProvider = ({ children }: ConfirmProviderProps) => {
	const [confirmState, setConfirmState] = useState<ConfirmState | null>(null);

	const confirmDialog = (message: string | ConfirmOptions): Promise<false | [boolean, any]> => {
		return new Promise((resolve) => {
			if (typeof message === 'string') {
				setConfirmState({ message, resolve });
			} else {
				setConfirmState({ ...message, resolve });
			}
		});
	};

	const handleClose = (result: boolean, formData?: any) => {
		if (confirmState) {
			if (!result) {
				confirmState.resolve(false);
			}
			confirmState.resolve([true, formData || true]);
			setConfirmState(null);
		}
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		const formDataObject = Object.fromEntries(formData.entries());
		handleClose(true, formDataObject);
	};

	return (
		<ConfirmContext.Provider value={{ confirmDialog }}>
			{children}
			<Modal show={!!confirmState} onHide={() => handleClose(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{confirmState?.title || 'Confirm'}</Modal.Title>
				</Modal.Header>
				<Form onSubmit={handleSubmit}>
					<Modal.Body>
						{confirmState?.message && <div>{confirmState.message}</div>}
						{confirmState?.details && <div>{confirmState.details}</div>}
						{confirmState?.form && confirmState.form}
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={() => handleClose(false)}>
							Cancel
						</Button>
						<Button variant="primary" type="submit">
							OK
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</ConfirmContext.Provider>
	);
};
