import type { TypedObject } from '@newstex/types';
import { useRef, useState } from 'react';
import {
	Alert,
	Button,
	Form,
	InputGroup,
	Modal,
	Table,
} from 'react-bootstrap';
import { useAPI } from '~/providers/api-provider';

import LoadingSpinner from './LoadingSpinner';
import { PropertyDisplayValue } from './property-display-value';

/**
 * Modal used to Confirm changes (does not support edits)
 */
export function UpdateModal<T extends TypedObject>({
	item, updates, title, onHide,
}:
{
	item: T
	updates: Partial<T>,
	title: string,
	onHide: (saved: boolean) => void,
}) {
	const api = useAPI();
	const commentRef = useRef<HTMLInputElement>(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<any>();

	const doFeedUpdate = async () => {
		setError(null);

		if (!commentRef.current?.value) {
			// Focus and highlight the input
			commentRef.current?.focus();
			commentRef.current?.select();
			commentRef.current?.setCustomValidity('Please enter a comment');
			return;
		}
		setLoading(true);
		try {
			await api.updateItem(item, {
				$comment: commentRef.current?.value,
				...updates,
			});
			onHide(true);
		} catch (err) {
			console.error(err);
			setError(err);
		}
		setLoading(false);
	};

	return <Modal show={true} onHide={() => onHide(false)}>
		<Modal.Header closeButton>
			<Modal.Title>{title}</Modal.Title>
			{ error && <Alert variant="danger">{error.message || String(error)}</Alert> }
		</Modal.Header>
		<Modal.Body>
			<Table>
				<thead>
					<tr>
						<th>Property</th>
						<th>Old Value</th>
						<th>New Value</th>
					</tr>
				</thead>
				<tbody>
					{Object.entries(updates).map(([key, value]) => (
						<tr key={key}>
							<td>{key}</td>
							<td>
								<PropertyDisplayValue propName={key} propValue={item[key as keyof T]} />
							</td>
							<td>
								<PropertyDisplayValue propName={key} propValue={value} />
							</td>
						</tr>
					))}
				</tbody>
			</Table>
			{loading
				? <LoadingSpinner loading={loading} title="Saving" />
				: <Form onSubmit={(e) => {
					e.preventDefault();
					doFeedUpdate();
				}}>
					<InputGroup className="mb-3">
						<Form.Control
							placeholder="Update Comment"
							aria-label="Update Comment"
							aria-describedby="Update Comment"
							ref={commentRef}
							required
						/>
					</InputGroup>
				</Form>
			}
		</Modal.Body>
		<Modal.Footer>
			<Button variant="secondary" onClick={() => onHide(false)} disabled={loading}>
				Cancel
			</Button>
			<Button variant="primary" onClick={() => {
				doFeedUpdate();
			}} disabled={loading}>
				Submit
			</Button>
		</Modal.Footer>

	</Modal>;
}
