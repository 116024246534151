/*
 * TypeSense search utilities
 */
import type { Logger } from '@aws-lambda-powertools/logger';
import { now } from '@newstex/core/date';
import { sleep } from '@newstex/core/sleep';
import type { Story } from '@newstex/types';
import omit from 'object.omit';
import Typesense from 'typesense';
import type { Client } from 'typesense';
import type Collection from 'typesense/lib/Typesense/Collection';
import type { ConfigurationOptions } from 'typesense/lib/Typesense/Configuration';
import type {
	DocumentSchema,
	SearchParams,
	SearchResponse,
	SearchResponseHit,
} from 'typesense/lib/Typesense/Documents';

import * as TYPESENSE_SCHEMAS from './schemas';
import { convertSearchFacets } from './utils';

const SEARCH_INDEX_NAME_MAP: Record<string, string> = {
	Publisher: 'Publisher',
	Publication: 'Publication',
	stories: 'Story',
	story: 'Story',
	publisher: 'Publisher',
	publication: 'Publication',
};

export type SearchIndexName = 'Story' | 'Publisher' | 'Publication' | 'Feed' | 'Category' | 'Delivery' | 'Product' | 'HubSpot' | 'RAG' | 'RAGMemory';

export type SearchHit<T> = T & {
	id: string;
	_score: number;
};
export function getIndexName(indexName: string): SearchIndexName {
	if (indexName.toLowerCase() === 'newscoreobjects' || indexName.toLowerCase() === 'content') {
		throw new Error(`Invalid legacy index name; ${indexName}`);
	}
	return (SEARCH_INDEX_NAME_MAP[indexName.toLowerCase()] || indexName) as SearchIndexName;
}
export function getDefaultQueryBy(indexName: string): string[] {
	return TYPESENSE_SCHEMAS[getIndexName(indexName)]
		?.filter((field) => field.defaultSearchOrder != null)
		?.sort((a, b) => a.defaultSearchOrder - b.defaultSearchOrder)
		?.map((field) => field.name)
		|| ['name'];
}

export interface SearchResults<T> extends Omit<SearchResponse<T>, 'hits' | 'grouped_hits'> {
	hits: SearchHit<T>[];
	grouped_hits?: (Omit<SearchResponse<T>['grouped_hits'][number], 'hits'> & { hits: SearchHit<T>[] })[];
	facets: Record<string, any>;
}

/**
 * Newstex Custom Typesense Search Client
 */
export class SearchClient {
	public client: Client;
	public collections: Record<string, Collection<any>> = {};
	private refreshCredentials?: () => Promise<void>;
	private logger?: Logger | Console;

	constructor(
		config: ConfigurationOptions,
		logger?: Logger | Console,
		refreshCredentials?: () => Promise<ConfigurationOptions>,
	) {
		this.client = new Typesense.Client(config);
		this.logger = logger;
		this.collections = {};
		if (refreshCredentials) {
			this.refreshCredentials = async () => {
				const newConfig = await refreshCredentials();
				this.client = new Typesense.Client(newConfig);
			};
		}
	}

	normalizeHit<T>(hit: SearchResponseHit<T & { id: string; }>, indexName?: string): SearchHit<T> {
		const normalizedItem: SearchHit<any> = {
			...(hit.document || hit),
			_score: +(hit.text_match_info?.score || 0),
		};

		if (indexName === 'Publisher' || indexName === 'Publication') {
			if (!normalizedItem.$id) {
				normalizedItem.$id = normalizedItem.id;
			}

			if (!normalizedItem.$type) {
				normalizedItem.$type = indexName;
			}

			if (normalizedItem.Publisher) {
				normalizedItem.Publisher = this.normalizeHit(normalizedItem.Publisher, 'Publisher');
			}
		}

		if (indexName === 'Story') {
			normalizedItem.__id__ = normalizedItem.id;
			normalizedItem.__type__ = 'Story';

			if (normalizedItem.Publication) {
				normalizedItem.Publication = this.normalizeHit(normalizedItem.Publication, 'Publication');
			}
		}
		return normalizedItem;
	}

	normalizeGroupedHits<T>(groupedHits: SearchResponse<T & { id: string }>['grouped_hits'], indexName: string) {
		return groupedHits?.map((group) => ({
			...group,
			hits: group.hits?.map((hit) => this.normalizeHit<T>(hit, indexName)),
		}));
	}

	getSearchIndexForType(modelName: string) {
		return this.getSearchIndex(getIndexName(modelName));
	}

	async getSearchIndex<T = any>(indexName: SearchIndexName, attempt = 1): Promise<Collection<T & { id: string }>> {
		if (!this.collections[indexName]) {
			try {
				this.collections[indexName] = this.client.collections<T & { id: string }>(indexName);
			} catch (e: any) {
				if (attempt < 4) {
					if (this.refreshCredentials) {
						await this.refreshCredentials();
					}
					await sleep(500 * attempt);
					return this.getSearchIndex(indexName, attempt + 1);
				}
				this.logger?.error({
					message: 'Error getting collection',
					error: e,
					indexName,
				});
				throw e;
			}
		}
		return this.collections[indexName];
	}

	normalizeQueryParams(params: Omit<SearchParams, 'filter_by'> & {
		indexName: string;
		query?: string;
		filter_by?: string | string[];
	}) {
		const output: SearchParams = {
			q: params.query || '*',
			...omit(params, ['indexName', 'query', 'filter_by']),
		};

		if (!output.query_by) {
			output.query_by = getDefaultQueryBy(params.indexName).join(',');
		}

		if (params.filter_by) {
			if (Array.isArray(params.filter_by)) {
				output.filter_by = params.filter_by.join(' && ');
			} else if (typeof params.filter_by === 'string') {
				output.filter_by = params.filter_by;
			}
		}

		// Allow a filter_by to have relative timestap filters like `{now - 2 days}`, `{now - 1
		// week}`, etc.
		if (output.filter_by && typeof output.filter_by === 'string') {
			output.filter_by = output.filter_by.replace(/{now\s?(-|\+)\s?(\d+)\s?(h(our)?|d(ay)?|w(eek)?|m(onth)?|y(ear)?)s?}/g, (_match, sign, amount, unit) => {
				return `${now().add(sign === '-' ? -amount : +amount, unit).unix()}`;
			});
		}

		if (params.facet_by && Array.isArray(params.facet_by)) {
			output.facet_by = params.facet_by.join(',');
		}

		if (output.query_by && Array.isArray(output.query_by)) {
			output.query_by = output.query_by.join(',');
		}
		return output;
	}

	async search<T = any>(params: SearchParams & {
		indexName: SearchIndexName;
		query?: string;
	}, attempt = 1): Promise<SearchResults<T & { id: string }>> {
		if (!params.indexName) {
			throw new Error('indexName is required');
		}
		const index = await this.getSearchIndex<T>(params.indexName);
		if (!index?.documents) {
			throw new Error(`Index ${params.indexName} not found`);
		}
		try {
			// If this is a large search, we need to use the multiSearch API
			const normalizedParams = this.normalizeQueryParams(params);
			if (JSON.stringify(normalizedParams).length > 4000) {
				const multiResp = await this.multiSearch<[T]>([params]);
				return multiResp?.[0];
			}
			const resp = await index.documents().search(normalizedParams);
			return {
				...resp,
				grouped_hits: this.normalizeGroupedHits<T>(resp.grouped_hits, resp.request_params?.collection_name),
				hits: resp.hits?.map((item) => this.normalizeHit<T>(item, resp.request_params?.collection_name)),
				facets: convertSearchFacets<T>(resp.facet_counts),
			};
		} catch (e: any) {
			this.logger?.error({
				message: 'Error searching',
				error: e,
				params,
			});
			if (this.refreshCredentials && attempt < 4) {
				await sleep(500 * attempt);
				await this.refreshCredentials();
				return this.search(params, attempt + 1);
			}
			throw e;
		}
	}

	async multiSearch<T extends any[] = any[]>(params: (SearchParams & {
		indexName: string;
		query?: string;
	})[], attempt = 1): Promise<SearchResults<T[number] & { id: string }>[]> {
		try {
			const resp = await this.client.multiSearch.perform<(T[number] & DocumentSchema)[]>({
				searches: params.map((p) => ({
					...this.normalizeQueryParams(p),
					collection: p.indexName,
				})),
			});
			return resp.results.map((r) => ({
				...r,
				grouped_hits: this.normalizeGroupedHits<T[number]>(r.grouped_hits, r.request_params?.collection_name),
				hits: r.hits?.map((hit) => this.normalizeHit<T[number]>(hit, r.request_params?.collection_name)),
				facets: convertSearchFacets<T[number]>(r.facet_counts),
			}));
		} catch (e: any) {
			this.logger?.error({
				message: 'Error multi-searching',
				error: e,
				params,
			});
			if (this.refreshCredentials && attempt < 4) {
				await sleep(500 * attempt);
				await this.refreshCredentials();
				return this.multiSearch(params, attempt + 1);
			}
			throw e;
		}
	}

	async getStats() {
		return this.client.metrics.retrieve();
	}

	async upsert<T = any>(indexName: SearchIndexName, document: T) {
		try {
			const index = await this.getSearchIndex(indexName);
			return await index.documents().upsert(document);
		} catch (e: any) {
			this.logger?.error({
				message: 'Error upserting document',
				error: e,
				indexName,
				document,
			});
			throw e;
		}
	}

	searchStories(params: Omit<Parameters<typeof this.search>[0], 'indexName'>) {
		return this.search<Story>({
			...params,
			indexName: 'Story',
		});
	}

	// Find similar stories by ID given a story ID, uses Vector search in Typesense
	async findSimilarStories(id: string, extraParams: SearchParams = {}) {
		const index = await this.getSearchIndex<Story>('Story');
		const resp = await index.documents().search({
			q: '*',
			query_by: 'embedding',
			vector_query: `embedding:([], id: ${id})`,
			limit: 10,
			...extraParams,
		});
		return resp;
	}
}
