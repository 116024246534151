import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Delivery } from '@newstex/types/delivery';
import { kebab } from 'case';
import { useState } from 'react';
import {
	Alert,
	Button,
	Col,
	Container,
	Form,
	Modal,
	Row,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAPI } from '~/providers/api-provider';

interface CreateDeliveryModalProps {
	show: boolean;
	onClose: (saved: boolean) => void;
}

const DELIVERY_TYPES = ['SFTP', 'HTTP', 'S3'] as const;
type DeliveryType = typeof DELIVERY_TYPES[number];

const HTTP_METHODS = ['POST', 'PUT'] as const;
type HttpMethod = typeof HTTP_METHODS[number];

const STORY_TEMPLATES = ['xml', 'xhtml', 'newsml'] as const;
type StoryTemplate = typeof STORY_TEMPLATES[number];

export function CreateDeliveryModal({
	show,
	onClose,
}: CreateDeliveryModalProps) {
	const navigate = useNavigate();
	const [error, setError] = useState<string | null>(null);
	const [saving, setSaving] = useState(false);
	const [deliveryType, setDeliveryType] = useState<DeliveryType>('SFTP');
	const [usePrivateKey, setUsePrivateKey] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [newDelivery, setNewDelivery] = useState<Partial<Delivery>>({
		type: 'SFTP',
		status: 'Standby',
		verify: true,
	});
	const api = useAPI();

	const handleSave = async () => {
		if (!newDelivery.name?.trim()) {
			setError('Name is required');
			return;
		}

		if (!newDelivery.queue_name?.trim()) {
			setError('Queue name is required');
			return;
		}

		// Validate type-specific required fields
		if (deliveryType === 'SFTP') {
			if (!newDelivery.host?.trim()) {
				setError('Host is required for SFTP deliveries');
				return;
			}

			if (!newDelivery.username?.trim()) {
				setError('Username is required for SFTP deliveries');
				return;
			}
			// Port is optional, defaults to 22
		} else if (deliveryType === 'HTTP') {
			if (!newDelivery.url?.trim()) {
				setError('URL is required for HTTP deliveries');
				return;
			}

			if (!newDelivery.method?.trim()) {
				setError('HTTP Method is required for HTTP deliveries');
				return;
			}
		} else if (deliveryType === 'S3') {
			if (!newDelivery.bucket_name?.trim()) {
				setError('Bucket name is required for S3 deliveries');
				return;
			}

			if (!newDelivery.user_id?.trim() && !newDelivery.user_email?.trim()) {
				setError('User ID or Email is required for S3 deliveries');
				return;
			}
		}

		// If not using private key, ensure it's not sent to the backend
		if (!usePrivateKey) {
			delete newDelivery.private_key;
		}

		setSaving(true);
		try {
			const response = await api.fetchWithAuth<Delivery>('resources/Delivery', {
				method: 'POST',
				body: JSON.stringify(newDelivery),
			});

			if (response) {
				onClose(true);
				toast.success('Delivery created successfully', {
					onClick: () => {
						navigate(`/delivery/${response.$id}`);
					},
				});
			} else {
				console.error('Failed to save delivery');
				toast.error(`Failed to save delivery: ${response}`);
			}
		} catch (err) {
			console.error('Error saving delivery:', err);
			setError(String(err));
		} finally {
			setSaving(false);
		}
	};

	// Update queue name when title changes
	const handleNameChange = (name: string) => {
		setNewDelivery({
			...newDelivery,
			name,
			queue_name: `prod-${kebab(name)}`,
		});
	};

	return (
		<Modal show={show} onHide={() => onClose(false)} size="xl">
			<Modal.Header closeButton>
				<Modal.Title>Create New Delivery</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{error && <Alert className="text-center mb-3" variant="danger">{error}</Alert>}
				<Form>
					<Container fluid>
						<Row className="mb-3">
							<Col>
								<Form.Group>
									<Form.Label>Name</Form.Label>
									<Form.Control
										type="text"
										value={newDelivery.name || ''}
										onChange={(e) => handleNameChange(e.target.value)}
										required
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row className="mb-3">
							<Col>
								<Form.Group>
									<Form.Label>Type</Form.Label>
									<Form.Select
										value={deliveryType}
										onChange={(e) => {
											const type = e.target.value as DeliveryType;
											setDeliveryType(type);
											setNewDelivery({ ...newDelivery, type });
										}}
									>
										{DELIVERY_TYPES.map((type) => (
											<option key={type} value={type}>{type}</option>
										))}
									</Form.Select>
								</Form.Group>
							</Col>
						</Row>

						{/* Common Fields */}
						<Row className="mb-3">
							<Col>
								<Form.Group>
									<Form.Label>Technical Contact</Form.Label>
									<Form.Control
										type="text"
										value={newDelivery.tech_contact || ''}
										onChange={(e) => setNewDelivery({ ...newDelivery, tech_contact: e.target.value })}
									/>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group>
									<Form.Label>Recipient</Form.Label>
									<Form.Control
										type="text"
										value={newDelivery.recipient || ''}
										onChange={(e) => setNewDelivery({ ...newDelivery, recipient: e.target.value })}
									/>
								</Form.Group>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col>
								<Form.Group>
									<Form.Label>Queue Name</Form.Label>
									<Form.Control
										type="text"
										value={newDelivery.queue_name || ''}
										onChange={(e) => setNewDelivery({ ...newDelivery, queue_name: e.target.value })}
										placeholder="prod-delivery-queue"
										required
										className={error && !newDelivery.queue_name?.trim() ? 'border-danger' : ''}
									/>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group>
									<Form.Label>Story Template</Form.Label>
									<Form.Select
										value={newDelivery.story_template || 'xml'}
										onChange={(e) => setNewDelivery({
											...newDelivery,
											story_template: e.target.value as StoryTemplate,
										})}
									>
										{STORY_TEMPLATES.map((template) => (
											<option key={template} value={template}>{template.toUpperCase()}</option>
										))}
									</Form.Select>
								</Form.Group>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col>
								<Form.Group>
									<Form.Check
										type="checkbox"
										label="Verify Receipt"
										checked={newDelivery.verify || false}
										onChange={(e) => setNewDelivery({ ...newDelivery, verify: e.target.checked })}
									/>
								</Form.Group>
							</Col>
						</Row>

						{/* SFTP & HTTP Fields */}
						{(deliveryType === 'SFTP' || deliveryType === 'HTTP') && (
							<>
								<Row className="mb-3">
									<Col>
										<Form.Group>
											<Form.Label>Username</Form.Label>
											<Form.Control
												type="text"
												value={newDelivery.username || ''}
												onChange={(e) => setNewDelivery({ ...newDelivery, username: e.target.value })}
												required
											/>
										</Form.Group>
									</Col>
									<Col>
										<Form.Group>
											<Form.Label className="d-flex justify-content-between align-items-center">
												{usePrivateKey ? 'Private Key' : 'Password'}
												<Button
													size="sm"
													variant={usePrivateKey ? 'secondary' : 'outline-secondary'}
													onClick={() => {
														const useKey = !usePrivateKey;
														setUsePrivateKey(useKey);
														if (useKey) {
															setNewDelivery({
																...newDelivery,
																private_key: 'SSM:SFTP_SECRET_KEY',
																password: undefined,
															});
														} else {
															setNewDelivery({
																...newDelivery,
																private_key: undefined,
																password: '',
															});
														}
													}}
												>
													{usePrivateKey ? 'Use Password' : 'Use Private Key'}
												</Button>
											</Form.Label>
											{!usePrivateKey ? (
												<div className="position-relative">
													<Form.Control
														type={showPassword ? 'text' : 'password'}
														value={newDelivery.password || ''}
														onChange={(e) => setNewDelivery({ ...newDelivery, password: e.target.value })}
													/>
													<Button
														size="sm"
														variant="link"
														className="position-absolute top-50 end-0 translate-middle-y"
														style={{ padding: '0.25rem 0.5rem' }}
														onClick={() => setShowPassword(!showPassword)}
													>
														<FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
													</Button>
												</div>
											) : (
												<Form.Control
													type="text"
													value={newDelivery.private_key || 'SSM:SFTP_SECRET_KEY'}
													onChange={(e) => setNewDelivery({ ...newDelivery, private_key: e.target.value })}
													placeholder="SSM:SFTP_SECRET_KEY"
												/>
											)}
										</Form.Group>
									</Col>
								</Row>
							</>
						)}

						{/* SFTP-specific Fields */}
						{deliveryType === 'SFTP' && (
							<Row className="mb-3">
								<Col>
									<Form.Group>
										<Form.Label>Host</Form.Label>
										<Form.Control
											type="text"
											value={newDelivery.host || ''}
											className={error && !newDelivery.host?.trim() ? 'border-danger' : ''}
											onChange={(e) => setNewDelivery({ ...newDelivery, host: e.target.value })}
											required
										/>
									</Form.Group>
								</Col>
								<Col>
									<Form.Group>
										<Form.Label>Port</Form.Label>
										<Form.Control
											type="number"
											value={newDelivery.port || '22'}
											onChange={(e) => setNewDelivery({ ...newDelivery, port: parseInt(e.target.value, 10) })}
											placeholder="22"
										/>
									</Form.Group>
								</Col>
							</Row>
						)}

						{/* HTTP-specific Fields */}
						{deliveryType === 'HTTP' && (
							<>
								<Row className="mb-3">
									<Col>
										<Form.Group>
											<Form.Label>URL</Form.Label>
											<Form.Control
												type="url"
												value={newDelivery.url || ''}
												onChange={(e) => setNewDelivery({ ...newDelivery, url: e.target.value })}
												required
											/>
										</Form.Group>
									</Col>
									<Col>
										<Form.Group>
											<Form.Label>HTTP Method</Form.Label>
											<Form.Select
												value={newDelivery.method || 'POST'}
												onChange={(e) => setNewDelivery({ ...newDelivery, method: e.target.value as HttpMethod })}
											>
												{HTTP_METHODS.map((method) => (
													<option key={method} value={method}>{method}</option>
												))}
											</Form.Select>
										</Form.Group>
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Form.Group>
											<Form.Check
												type="checkbox"
												label="Base64 Encode Content"
												checked={newDelivery.encode || false}
												onChange={(e) => setNewDelivery({ ...newDelivery, encode: e.target.checked })}
											/>
										</Form.Group>
									</Col>
								</Row>
							</>
						)}

						{/* S3-specific Fields */}
						{deliveryType === 'S3' && (
							<Row className="mb-3">
								<Col>
									<Form.Group>
										<Form.Label>Bucket Name</Form.Label>
										<Form.Control
											type="text"
											value={newDelivery.bucket_name || ''}
											onChange={(e) => setNewDelivery({ ...newDelivery, bucket_name: e.target.value })}
											required
										/>
									</Form.Group>
								</Col>
								<Col>
									<Form.Group>
										<Form.Label>User ID/Email</Form.Label>
										<Form.Control
											type="text"
											value={newDelivery.user_id || newDelivery.user_email || ''}
											onChange={(e) => setNewDelivery({
												...newDelivery,
												user_id: e.target.value,
												user_email: e.target.value.includes('@') ? e.target.value : undefined,
											})}
											required
										/>
									</Form.Group>
								</Col>
							</Row>
						)}
					</Container>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={() => onClose(false)} disabled={saving}>
					Cancel
				</Button>
				<Button variant="primary" onClick={handleSave} disabled={saving}>
					{saving ? 'Creating...' : 'Create Delivery'}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
