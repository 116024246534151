/**
 * Edit Product Modal
 */
import { Product } from '@newstex/types/product';
import { useRef, useState, useEffect } from 'react';
import {
	Alert,
	Button,
	Col,
	Container,
	Form,
	Modal,
	Row,
} from 'react-bootstrap';
import { useAPI } from '~/providers/api-provider';

import EditableMultiValueField from '../multi-value';
import EditableTextField from '../text';

export interface EditProductModalProps {
	item?: Product,
	show: boolean,
	onClose: (saved: boolean) => void,
}

export default function EditProductModal({
	item,
	show,
	onClose,
}: EditProductModalProps) {
	const api = useAPI();
	const commentRef = useRef<HTMLInputElement>(null);
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState<any>();
	const [updates, setUpdates] = useState<Partial<Product>>({});

	// Reset updates when modal is opened with a new item
	useEffect(() => {
		if (show) {
			setUpdates({});
		}
	}, [show, item?.$id]);

	const doUpdate = async () => {
		if (item) {
			setError(null);

			if (!commentRef.current?.value) {
				commentRef.current?.focus();
				commentRef.current?.select();
				commentRef.current?.setCustomValidity('Please enter a comment');
				return;
			}
			setSaving(true);
			try {
				await api.updateItem<Product>(item, {
					$comment: commentRef.current?.value,
					...updates,
				});
				onClose(true);
			} catch (err) {
				console.error(err);
				setError(err);
			}
			setTimeout(() => {
				setSaving(false);
				onClose(true);
			}, 2000);
		}
	};

	return (
		<Modal show={item && show} onHide={() => onClose(false)} size="xl" fullscreen="xl-down">
			<Modal.Header closeButton>
				<Modal.Title>Edit Product <i>{item?.name}</i></Modal.Title>
				{error && <Alert variant="danger">{error.message || String(error)}</Alert>}
			</Modal.Header>
			<Form onSubmit={(e) => {
				e.preventDefault();
				doUpdate();
			}}>
				<Modal.Body>
					<Container fluid className={saving ? 'opacity-50' : ''}>
						<Row className="mb-3">
							<EditableTextField
								typeName="Product"
								propKey="name"
								item={item}
								updates={updates}
								setUpdates={setUpdates}
							/>
						</Row>
						<Row className="mb-3">
							<EditableTextField
								typeName="Product"
								propKey="group"
								item={item}
								updates={updates}
								setUpdates={setUpdates}
							/>
						</Row>
						<Row className="mb-3">
							<EditableMultiValueField
								typeName="Product"
								propKey="kill_alert_emails"
								item={item}
								updates={updates}
								setUpdates={setUpdates}
								trimValues
							/>
						</Row>
						<Row className="mt-3 pt-3" style={{ borderTop: 'dashed 1px #ccc' }}>
							<Col md={3} className="text-end fw-bold">
								<Form.Label>Comment</Form.Label>
							</Col>
							<Col md={9}>
								<Form.Control type="text" name="$comment" ref={commentRef} required placeholder="Required: Enter a comment" />
							</Col>
						</Row>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => onClose(false)} disabled={saving}>
						Cancel
					</Button>
					<Button variant="primary" onClick={() => {
						doUpdate();
					}} disabled={saving || !updates || Object.keys(updates).length === 0}>
						Submit
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
}
