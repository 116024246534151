// ENUMs
export const PUBLICATION_TYPES = [
	'Abstract',
	'Facebook',
	'Magazine',
	'Mobile',
	'News Wire',
	'Newspaper',
	'Newspaper Weblog',
	'Press Release',
	'Twitter',
	'Video',
	'Weblog',
	'Google +',
	'Custom',
	'LinkedIn',
] as const;
export const PUBLICATION_STATUSES = [
	'1 Editorial Review',
	'2 Provider Prep',
	'3 Integration',
	'4 Testing',
	'5 Active',
	'7 Archival',
	'8 Deleted',
	'9 Hiatus',
	'N Non-Qualifying',
] as const;
export const STATUSES = [
	'Lead',
	'Standby',
	'Active',
	'Inactive',
	'Deleted',
] as const;
export const POLITICAL_BENTS = [
	'Center',
	'Left',
	'Mix',
	'Non-partisan',
	'None',
	'Right',
] as const;
export const WRITER_GENDERS = [
	'Female',
	'Male',
	'Mix',
	'Unknown',
] as const;
export const RESTRICTION_TYPES = [
	'Custom',
	'Mobile',
	'no',
	'None',
	'Publisher Delivery',
	'yes',
] as const;
export const REPORTING_STATUSES = [
	'Active',
	'Inactive',
] as const;
