export enum ContentStatus {
	EDITORIAL_REVIEW = '1 Editorial Review',
	PROVIDER_PREP = '2 Provider Prep',
	INTEGRATION = '3 Integration',
	TESTING = '4 Testing',
	ACTIVE = '5 Active',
	ARCHIVAL = '7 Archival',
	DELETED = '8 Deleted',
	HIATUS = '9 Hiatus',
	ADMINISTRATION = 'A Administration',
	NON_QUALIFYING = 'N Non-Qualifying',
	MOVED = 'M Moved',
}
