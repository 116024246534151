import { faHubspot } from '@fortawesome/free-brands-svg-icons';
import { faEllipsisV, faNewspaper, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Content } from '@newstex/types/content';
import { Dropdown } from 'react-bootstrap';
import { useUserInfo } from '~/providers/user-info';

export default function ContentActions({ content }: { content: Content }) {
	const userInfo = useUserInfo();

	return (
		<div className="text-end">
			<Dropdown align="end">
				<Dropdown.Toggle variant="header-more">
					<FontAwesomeIcon icon={faEllipsisV} />
				</Dropdown.Toggle>

				<Dropdown.Menu className="text-sm">
					<Dropdown.Item href={`/stories?content=${content.newstex_id}`}>
						<FontAwesomeIcon
							icon={faNewspaper}
							className="me-2 opacity-5"
						/>
						View Stories
					</Dropdown.Item>
					{userInfo?.auth_groups?.includes('admin') && (
						<>
							<Dropdown.Item
								disabled={!content.hubspot_id}
								href={content.hubspot_id ? `https://app.hubspot.com/contacts/20613241/record/0-2/${content.hubspot_id}` : '#!'}
							>
								<FontAwesomeIcon
									icon={faHubspot}
									className="me-2 opacity-5"
								/>
								View in HubSpot
							</Dropdown.Item>
							<Dropdown.Item href="#!">
								<FontAwesomeIcon
									icon={faTrashAlt}
									className="me-2 opacity-5"
								/>
								Remove
							</Dropdown.Item>
						</>
					)}
				</Dropdown.Menu>
			</Dropdown>
		</div>
	);
}
