import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Content } from '@newstex/types/content';
import { useState } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';

import { ExportStoriesModal } from './editors/modals/export-stories-modal';

interface ExportStoriesButtonProps {
	content: Content;
	size?: ButtonProps['size'];
	variant?: ButtonProps['variant'];
	className?: string;
}

export function ExportStoriesButton({
	content, size, variant, className,
}: ExportStoriesButtonProps) {
	const [showExportStoriesModal, setShowExportStoriesModal] = useState(false);

	const handleExportStories = () => {
		setShowExportStoriesModal(true);
	};

	const handleCloseModal = () => {
		setShowExportStoriesModal(false);
	};

	return (
		<>
			<Button
				size={size || 'sm'}
				variant={variant || 'outline-secondary'}
				className={className}
				onClick={handleExportStories}
			>
				<FontAwesomeIcon icon={faFileExport} className="me-1" />
				Export Stories
			</Button>

			<ExportStoriesModal
				show={showExportStoriesModal}
				onClose={handleCloseModal}
				content={content}
			/>
		</>
	);
}
