/**
 * Edit Modal
 */
import { Feed } from '@newstex/types/feed';
import { useRef, useState, useEffect } from 'react';
import {
	Alert,
	Button,
	Col,
	Container,
	Form,
	Modal,
	Row,
} from 'react-bootstrap';
import { useAPI } from '~/providers/api-provider';

import EditableBooleanField from '../boolean';
import EditableTextField from '../text';

const POLLING_DELAY_OPTIONS = [
	{ label: '15 minutes', value: 15 * 60 },
	{ label: '1 Hour', value: 60 * 60 },
	{ label: '3 Hours', value: 3 * 60 * 60 },
	{ label: '8 Hours', value: 8 * 60 * 60 },
	{ label: 'Daily', value: 24 * 60 * 60 },
	{ label: 'Weekly', value: 7 * 24 * 60 * 60 },
];

const SEGMENT_OPTIONS = [
	{ label: 'Prod', value: -1 },
	{ label: 'Dev', value: -2 },
	{ label: 'Chrome Extension', value: -3 },
];

export interface EditFeedModalProps {
	item?: Feed,
	show: boolean,
	onClose: (saved: boolean) => void,
}

export default function EditFeedModal({
	item,
	show,
	onClose,
}: EditFeedModalProps) {
	const api = useAPI();
	const commentRef = useRef<HTMLInputElement>(null);
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState<any>();
	const [updates, setUpdates] = useState<Partial<Feed>>({});

	// Reset updates when modal is opened with a new item
	useEffect(() => {
		if (show) {
			setUpdates({});
		}
	}, [show, item?.$id]);

	const doUpdate = async () => {
		if (item) {
			setError(null);

			if (!commentRef.current?.value) {
				// Focus and highlight the input
				commentRef.current?.focus();
				commentRef.current?.select();
				commentRef.current?.setCustomValidity('Please enter a comment');
				return;
			}
			setSaving(true);
			console.log('Saving', item, commentRef.current?.value, updates);
			try {
				await api.updateItem<Feed>(item, {
					$comment: commentRef.current?.value,
					...updates,
				});
				onClose(true);
			} catch (err) {
				console.error(err);
				setError(err);
			}
			setTimeout(() => {
				setSaving(false);
				onClose(true);
			}, 2000);
		}
	};

	return <Modal show={item && show} onHide={() => onClose(false)} size="xl" fullscreen="xl-down">
		<Modal.Header closeButton>
			<Modal.Title>Edit Feed <i>{item?.name}</i></Modal.Title>
			{ error && <Alert variant="danger">{error.message || String(error)}</Alert> }
		</Modal.Header>
		<Form onSubmit={(e) => {
			e.preventDefault();
			doUpdate();
		}}>
			<Modal.Body>
				<Container fluid className={saving ? 'opacity-50' : ''}>
					<Row className="mb-3">
						<EditableTextField
							typeName="Feed"
							propKey="name"
							item={item}
							updates={updates}
							setUpdates={setUpdates}
						/>
					</Row>
					<Row className="mb-3">
						<Col md={3} className="text-end fw-bold">
							<Form.Label>Polling Delay</Form.Label>
						</Col>
						<Col md={9}>
							<Form.Select
								value={updates.polling_delay ?? item?.polling_delay ?? (60 * 60)}
								onChange={(e) => {
									setUpdates({
										...updates,
										polling_delay: parseInt(e.target.value, 10),
									});
								}}
							>
								{POLLING_DELAY_OPTIONS.map((option) => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</Form.Select>
						</Col>
					</Row>
					<Row className="mb-3">
						<Col md={3} className="text-end fw-bold">
							<Form.Label>Segment</Form.Label>
						</Col>
						<Col md={9}>
							<Form.Select
								value={updates.segment ?? item?.segment ?? -1}
								onChange={(e) => {
									setUpdates({
										...updates,
										segment: parseInt(e.target.value, 10),
									});
								}}
							>
								{SEGMENT_OPTIONS.map((option) => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</Form.Select>
						</Col>
					</Row>
					<Row>
						<EditableBooleanField
							typeName="Feed"
							propKey="ft_process"
							item={item}
							updates={updates}
							setUpdates={setUpdates}
						/>
						<EditableBooleanField
							typeName="Feed"
							propKey="process_updates"
							item={item}
							updates={updates}
							setUpdates={setUpdates}
						/>
						<EditableBooleanField
							typeName="Feed"
							propKey="use_h2"
							item={item}
							updates={updates}
							setUpdates={setUpdates}
						/>
						<EditableBooleanField
							typeName="Feed"
							propKey="bypass_robots"
							item={item}
							updates={updates}
							setUpdates={setUpdates}
						/>
						<EditableBooleanField
							typeName="Feed"
							propKey="use_cloudscraper"
							item={item}
							updates={updates}
							setUpdates={setUpdates}
						/>
						<EditableBooleanField
							typeName="Feed"
							propKey="use_ftfeed"
							item={item}
							updates={updates}
							setUpdates={setUpdates}
						/>
					</Row>
					<Row className="mt-3 pt-3" style={{ borderTop: 'dashed 1px #ccc' }}>
						<Col md={3} className="text-end fw-bold">
							<Form.Label>Comment</Form.Label>
						</Col>
						<Col md={9}>
							<Form.Control type="text" name="$comment" ref={commentRef} required placeholder="Required: Enter a comment" />
						</Col>
					</Row>
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={() => onClose(false)} disabled={saving}>
				Cancel
				</Button>
				<Button variant="primary" onClick={() => {
					doUpdate();
				}} disabled={saving || !updates || Object.keys(updates).length === 0}>
				Submit
				</Button>
			</Modal.Footer>
		</Form>

	</Modal>;
}
