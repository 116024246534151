import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export interface HeaderWithTooltipProps {
	title: string;
	tooltip: string;
	placement?: 'top' | 'right' | 'bottom' | 'left';
}

export function HeaderWithTooltip({ title, tooltip, placement = 'top' }: HeaderWithTooltipProps) {
	return (<>
		<OverlayTrigger
			placement={placement}
			overlay={
				<Tooltip title={tooltip}>
					<h6>{title}</h6>
					{tooltip}
				</Tooltip>
			}
		>
			<span>{title}</span>
		</OverlayTrigger>
	</>);
}

/**
 * Creates a function that renders a HeaderWithTooltip component.
 *
 * Used for the `header` value of the data table component.
 */
export function headerWithTooltip(props: HeaderWithTooltipProps) {
	return () => <HeaderWithTooltip {...props} />;
}
