import { AnalyticsClient } from '@newstex/analytics';
import type { EventData } from '@newstex/types/analytics';
import {
	createContext,
	useContext,
	useEffect,
	useRef,
} from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth } from './auth';
import { useUserInfo } from './user-info';

export interface AnalyticsContextType {
	trackEvent: (name: string, data?: EventData, count?: number) => Promise<void>;
	trackPageView: (path: string) => Promise<void>;
}

const AnalyticsContext = createContext<AnalyticsContextType | undefined>(undefined);

export function AnalyticsProvider({ children }: React.PropsWithChildren<{}>) {
	const { user } = useAuth();
	const userInfo = useUserInfo();
	const location = useLocation();
	const clientRef = useRef<AnalyticsClient | null>(null);

	// Initialize or update the analytics client when user info changes
	useEffect(() => {
		if (userInfo?.aws) {
			if (!clientRef.current) {
				const analyticsClient = new AnalyticsClient(userInfo, userInfo.refresh);
				clientRef.current = analyticsClient;
				analyticsClient.identify({
					user_name: userInfo.name,
					user_id: userInfo.$id,
				});
			} else {
				clientRef.current.updateConfig(userInfo);
			}
		}
	}, [userInfo?.aws, user]);

	// Track page views when location changes
	useEffect(() => {
		if (clientRef.current) {
			clientRef.current.trackPageView(location.pathname);
		}
	}, [location.pathname, clientRef.current]);

	// Flush analytics on unmount
	useEffect(() => {
		return () => {
			if (clientRef.current) {
				clientRef.current.flush();
			}
		};
	}, []);

	const trackEvent = async (name: string, data?: EventData, count = 1) => {
		if (clientRef.current) {
			await clientRef.current.trackEvent(name, data, count);
		}
	};

	const trackPageView = async (path: string) => {
		if (clientRef.current) {
			await clientRef.current.trackPageView(path);
		}
	};

	return (
		<AnalyticsContext.Provider value={{
			trackEvent,
			trackPageView,
		}}>
			{children}
		</AnalyticsContext.Provider>
	);
}

export function useAnalytics() {
	return useContext(AnalyticsContext);
}
