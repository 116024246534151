import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Content } from '@newstex/types/content';
import type { Results } from '@newstex/types/results';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAPI } from '~/providers/api-provider';

export const ContentRecordLink: React.FC<{
	typeName: 'Publication' | 'Publisher';
	id?: string | Content;
	obj?: Content;
}> = (props) => {
	if (typeof props.id === 'object' && props.id.$id) {
		return <ContentRecordLink
			id={props.id.$id}
			obj={props.id}
			typeName={props.typeName}
		/>;
	}

	const [content, setContent] = useState<Content | null | undefined>(props.obj);
	const api = useAPI();

	useEffect(() => {
		const fetchData = async () => {
			const resp = await api.fetchWithAuth<Results<Content>>(`resources/${props.typeName}/${props.id}`);
			setContent(resp.items?.[0]);
		};

		if (props.id && props.typeName && !props.obj) {
			fetchData();
		}
	}, [props.id, props.typeName]);

	if (!props.id || !props.typeName) {
		return <></>;
	}

	return (
		<Link to={`/${props.typeName.toLowerCase()}s/${props.id}`}>
			{content ? (
				<>
					{content.name || props.id}
					{content.newstex_id && ` [${content.newstex_id}]`}
				</>
			) : (
				<FontAwesomeIcon icon={faSpinner} spin />
			)}
		</Link>
	);
};
