import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	ColumnDef,
	PaginationState,
	Row,
	SortingState,
	VisibilityState,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';
import Case from 'case';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import React, {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useMemo,
	useState,
} from 'react';
import { Pagination, Table } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

export interface DataTableProps<T = any> {
	columns: ColumnDef<T, any>[];
	items: T[];
	sortable?: boolean;
	nameAsc?: boolean;
	cellClassname?: string;
	defaultSort?: string;
	hideHeader?: boolean;
	striped?: boolean;
	defaultPageSize?: number;
	searchText?: string;
	tableRowClassNameHelper?: (row: Row<T>) => string;
	onSort?: (state: SortingState) => void;
	loading?: boolean;
	fallbackSearch?: (q: string) => Promise<T[]>;
	columnVisibility?: VisibilityState;
	className?: string;
	pagination?: boolean;
}

const SUPPORTED_CSV_TYPES = ['number', 'string', 'boolean', 'null', 'undefined'];

export interface DataTableMethods {
	exportToCSV<T = any>(filename?: string, exportConfig?: Record<string, string | ((item: T) => string)>): void;
}

const DataTable = forwardRef(({
	className,
	columns,
	items,
	nameAsc,
	cellClassname,
	defaultSort,
	hideHeader,
	striped,
	defaultPageSize = 20,
	searchText,
	sortable,
	tableRowClassNameHelper,
	onSort,
	loading,
	fallbackSearch,
	columnVisibility,
	pagination,
}: DataTableProps, ref) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [fallbackItems, setFallbackItems] = useState<any[]>([]);
	const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: defaultPageSize,
	});

	const data = useMemo(() => (fallbackItems.length ? fallbackItems : items), [items, fallbackItems]);

	const initialSort: SortingState = useMemo(() => {
		if (!sortable) return [];

		if (searchParams.get('sort_by')) {
			const sortBy = searchParams.get('sort_by')!.split(':');
			return [{
				id: sortBy[0],
				desc: sortBy[1] === 'desc',
			}];
		}

		if (defaultSort) {
			return [{
				id: defaultSort,
				desc: !nameAsc,
			}];
		}

		return [];
	}, [sortable, searchParams, defaultSort, nameAsc]);

	const [sorting, setSorting] = useState<SortingState>(initialSort);

	const table = useReactTable({
		columns,
		data,
		enableSorting: sortable,
		onSortingChange: setSorting,
		getFilteredRowModel: getFilteredRowModel(),
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: pagination ? getPaginationRowModel() : undefined,
		initialState: {
			sorting: [{
				id: defaultSort ?? 'name',
				desc: !nameAsc,
			}],
			pagination: {
				pageIndex,
				pageSize,
			},
		},
		state: {
			sorting,
			columnVisibility,
			globalFilter: searchText,
			...(pagination && {
				pagination: { pageIndex, pageSize },
			}),
		},
		pageCount: pagination ? Math.ceil(data.length / pageSize) : undefined,
		onPaginationChange: pagination ? setPagination : undefined,
		manualPagination: false,
	});

	// Memoize the fallback search handler
	const handleFallbackSearch = useCallback(async () => {
		if (searchText
			&& searchText.length > 3
			&& !table.getRowModel().rows.length
			&& fallbackSearch
		) {
			try {
				const filteredItems = await fallbackSearch(searchText);
				setFallbackItems(filteredItems);
				table.setGlobalFilter('');
			} catch (error) {
				console.error('Fallback search failed:', error);
			}
		}
	}, [searchText, fallbackSearch]);

	useEffect(() => {
		setFallbackItems([]);

		if (searchText === '*') {
			table.setGlobalFilter('');
			return;
		}

		const timeoutId = setTimeout(handleFallbackSearch, 1000);
		return () => clearTimeout(timeoutId);
	}, [searchText, handleFallbackSearch]);

	const exportToCSV: DataTableMethods['exportToCSV'] = (filename, exportConfig) => {
		const csvConfig = mkConfig({
			filename: filename || 'Newstex-Report',
			fieldSeparator: ',',
			decimalSeparator: '.',
			quoteCharacter: '"',
			quoteStrings: true,
			useKeysAsHeaders: true,
		});

		// Convert items to a format that can be handled by export-to-csv
		const dataToExport = items.map((item) => {
			const exportData: Record<string, any> = {};
			if (exportConfig) {
				for (const [key, value] of Object.entries(exportConfig)) {
					if (typeof value === 'function') {
						exportData[key] = value(item);
					} else if (typeof value === 'string') {
						exportData[key] = item[value] || '';
					}

					if (Array.isArray(exportData[key])) {
						exportData[key] = exportData[key].join(';');
					}

					// If the value is an object, convert it to a string
					if (typeof exportData[key] === 'object') {
						console.warn('OBJECT DUMP', key, typeof exportData[key], exportData[key]);
						exportData[key] = JSON.stringify(exportData[key]);
					}

					// Anything other than a number, string, boolean, null, or undefined should be converted to a string
					if (!SUPPORTED_CSV_TYPES.includes(typeof exportData[key])) {
						exportData[key] = String(exportData[key]);
					}
				}
			} else {
				for (const column of columns as any[]) {
					if (column.header) {
						const accessor = column.accessorFn || column.accessorKey || column.id;
						const header = typeof column.header === 'string' ? column.header : column.id;
						if (header) {
							if (typeof accessor === 'function') {
								exportData[header] = accessor(item);
							} else if (typeof accessor === 'string') {
								exportData[header] = item[accessor];
							} else {
								console.warn('No accessor', accessor);
							}

							if (Array.isArray(exportData[header])) {
								exportData[header] = exportData[header].join(';');
							}

							// If the value is an object, convert it to a string
							if (typeof exportData[header] === 'object') {
								console.warn('OBJECT DUMP', header, typeof exportData[header], exportData[header]);
								exportData[header] = JSON.stringify(exportData[header]);
							}

							// Anything other than a number, string, boolean, null, or undefined should be converted to a string
							if (!SUPPORTED_CSV_TYPES.includes(typeof exportData[header])) {
								exportData[header] = String(exportData[header]);
							}
						}
					}
				}
			}
			return exportData;
		});

		// Generates the CSV string
		const csv = generateCsv(csvConfig)(dataToExport);

		// Triggers the download
		download(csvConfig)(csv);
	};

	useImperativeHandle(ref, () => ({
		exportToCSV,
	}));

	return (
		<React.Fragment>
			<Table
				hover
				className={`align-middle mb-0 ${className}`}
				striped={striped}
				responsive
				style={{
					minHeight: '300px',
				}}
			>
				<thead className={hideHeader ? 'd-none' : ''}>
					{table.getHeaderGroups().map((headerGroup) => (
						<tr key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<th
										colSpan={header.colSpan}
										rowSpan={header.rowSpan}
										style={{ width: `${header.getSize()}px` }}
										className={`py-4 prop-${Case.kebab(header.id)}`}
										key={header.id}
										onClick={header.column.getToggleSortingHandler()}
									>
										<span className="d-flex align-items-center text-transform-none ">
											{header.isPlaceholder ? null : flexRender(
												header.column.columnDef.header,
												header.getContext(),
											)}
											{header.column.columnDef.enableSorting ? (
												<span className="d-grid ms-auto">
													<FontAwesomeIcon
														icon={faCaretUp}
														className={`ms-1 ${
															header.column.getIsSorted() === 'asc' ? '' : 'opacity-6'
														}`}
														color={
															header.column.getIsSorted() === 'asc' ? '#f71' : '#999'
														}
														size="sm"
													/>
													<FontAwesomeIcon
														icon={faCaretDown}
														className={`ms-1 ${
															header.column.getIsSorted() === 'desc' ? '' : 'opacity-6'
														}`}
														color={
															header.column.getIsSorted() === 'desc' ? '#f71' : '#999'
														}
														size="sm"
													/>
												</span>
											) : null}
										</span>
									</th>
								);
							})}
						</tr>
					))}
				</thead>
				<tbody className={`position-relative border-top-0 ${loading ? 'opacity-50 loading' : ''}`} data-row-count={table.getRowModel().rows.length}>
					{(pagination ? table.getRowModel().rows : table.getRowModel().rows).map((row) => (
						<tr id={`TABLE-ROW-${row.id}`} key={row.id} className={tableRowClassNameHelper?.(row) || ''}>
							{row.getVisibleCells().map((cell) => {
								return (
									<td
										className={cellClassname ?? `prop-value ${cell.column.id}`}
										key={cell.id}
										style={{ width: `${cell.column.getSize()}px` }}
									>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</td>
								);
							})}
						</tr>
					))}
				</tbody>
			</Table>
			{pagination && (
				<div className="d-flex flex-column align-items-center mt-3">
					<div className="text-muted small mb-2">
						{(() => {
							const totalRows = table.getFilteredRowModel().rows.length;
							const start = totalRows === 0 ? 0 : pageIndex * pageSize + 1;
							const end = Math.min((pageIndex + 1) * pageSize, totalRows);
							return `Showing ${start} to ${end} of ${totalRows} entries`;
						})()}
					</div>
					<Pagination>
						<Pagination.First
							onClick={() => table.setPageIndex(0)}
							disabled={!table.getCanPreviousPage()}
						/>
						<Pagination.Prev
							onClick={() => table.previousPage()}
							disabled={!table.getCanPreviousPage()}
						/>
						{(() => {
							const totalPages = table.getPageCount();
							const currentPage = pageIndex;
							const pages = [];

							// Always show first page
							if (currentPage > 2) {
								pages.push(
									<Pagination.Item key={0} onClick={() => table.setPageIndex(0)}>
										1
									</Pagination.Item>,
								);
								if (currentPage > 3) {
									pages.push(<Pagination.Ellipsis key="start-ellipsis" />);
								}
							}

							// Show pages around current page
							for (let i = Math.max(0, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
								pages.push(
									<Pagination.Item
										key={i}
										active={currentPage === i}
										onClick={() => table.setPageIndex(i)}
									>
										{i + 1}
									</Pagination.Item>,
								);
							}

							// Always show last page
							if (currentPage < totalPages - 3) {
								if (currentPage < totalPages - 4) {
									pages.push(<Pagination.Ellipsis key="end-ellipsis" />);
								}
								pages.push(
									<Pagination.Item
										key={totalPages - 1}
										onClick={() => table.setPageIndex(totalPages - 1)}
									>
										{totalPages}
									</Pagination.Item>,
								);
							}

							return pages;
						})()}
						<Pagination.Next
							onClick={() => table.nextPage()}
							disabled={!table.getCanNextPage()}
						/>
						<Pagination.Last
							onClick={() => table.setPageIndex(table.getPageCount() - 1)}
							disabled={!table.getCanNextPage()}
						/>
					</Pagination>
				</div>
			)}
		</React.Fragment>
	);
});

export default DataTable;
