export enum FeedPriority {
	/**
	 * Low priority feeds are read last, and typically no more than once a day
	 */
	LOW = -1,
	/**
	 * Standard (default) feeds are read once up to twice a day
	 */
	STANDARD = 1,
	/**
	 * All HIGH priority feeds are read first,
	 * and have alerts if they do not produce at least
	 * one story every working day
	 */
	HIGH = 2,
}

export const FEED_PRIORITY_MAPPING: Record<string, FeedPriority> = {
	'': FeedPriority.STANDARD,
	default: FeedPriority.STANDARD,
	standard: FeedPriority.STANDARD,
	high: FeedPriority.HIGH,
	low: FeedPriority.LOW,
};
