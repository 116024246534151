import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '~/components/LoadingSpinner';
import { PageTitle } from '~/components/page-title';
import Preloader from '~/components/Preloader';
import { useAPI } from '~/providers/api-provider';

export function RevenuePage() {
	const { id } = useParams<{ id: string }>();
	const api = useAPI();
	const [loading, setLoading] = useState<boolean>(true);
	const [revenue, setRevenue] = useState<any>(null); // Replace 'any' with the correct type for a revenue item

	const fetchData = async (refresh = false) => {
		setLoading(true);
		try {
			const resp = await api.fetchWithAuth(
				`revenue/${id}`,
				refresh ? { cache: 'reload' } : undefined,
			);
			setRevenue(resp.items?.[0] || null);
		} catch (error) {
			console.error('Error fetching revenue data:', error);
			// Handle error (e.g., show error message to user)
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, [id, api]);

	return (
		<Container fluid>
			<PageTitle title={`Revenue Report: ${revenue?.name || ''}`} />
			{loading && <Preloader type="three-bounce" variant="success" />}
			<Row>
				<Col>
					{loading && (<LoadingSpinner loading={loading} />) }
					{revenue ? (
						<pre>{JSON.stringify(revenue, null, 2)}</pre>
					) : (
						<p>No revenue data found.</p>
					)}
				</Col>
			</Row>
		</Container>
	);
}
