import React, { useEffect, useState } from 'react';
import { Form, FormControlProps } from 'react-bootstrap';

interface DebouncedSearchInputProps extends Omit<FormControlProps, 'onChange'> {
	onSearch: (value: string) => void;
	debounceMs?: number;
}

export function DebouncedSearchInput({
	onSearch,
	debounceMs = 300,
	value: externalValue,
	...props
}: DebouncedSearchInputProps) {
	const [inputValue, setInputValue] = useState(externalValue as string || '');

	// Update internal value when external value changes
	useEffect(() => {
		setInputValue(externalValue as string || '');
	}, [externalValue]);

	// Debounce the search callback
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			onSearch(inputValue);
		}, debounceMs);

		return () => clearTimeout(timeoutId);
	}, [inputValue, debounceMs, onSearch]);

	return (
		<Form.Control
			{...props}
			value={inputValue}
			onChange={(e) => setInputValue(e.target.value)}
		/>
	);
}
