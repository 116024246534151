import { useEffect } from 'react';
import './hubspot-form.scss';

interface HubSpotFormProps {
	portalId: string;
	formId: string;
	region?: string;
	onSubmit?: () => void;
	defaultValues?: Record<string, string>;
}

export function HubSpotForm({
	portalId,
	formId,
	region = 'na1',
	onSubmit,
	defaultValues,
}: HubSpotFormProps) {
	useEffect(() => {
		const script = document.createElement('script');
		script.src = 'https://js.hsforms.net/forms/v2.js';
		document.body.appendChild(script);

		script.addEventListener('load', () => {
			if ((window as any).hbspt) {
				(window as any).hbspt.forms.create({
					region,
					portalId,
					formId,
					target: '#hubspot-form-container',
					onFormSubmitted: () => {
						onSubmit?.();
					},
					cssClass: 'hs-custom-form',
					onFormReady: (form: HTMLFormElement) => {
						if (defaultValues) {
							for (const [name, value] of Object.entries(defaultValues)) {
								const input = form.querySelector(`input[name="${name}"]`) as HTMLInputElement;
								if (input) {
									input.focus();
									input.value = value;
									// Trigger change event to ensure HubSpot's form validation recognizes the change
									const event = new Event('change', { bubbles: true });
									input.dispatchEvent(event);
									input.blur();
								} else {
									console.error(`Input with name "${name}" not found in the form`);
								}
							}
						}
					},
				});
			}
		});

		return () => {
			document.body.removeChild(script);
		};
	}, [portalId, formId, region, onSubmit, defaultValues]);

	return <div id="hubspot-form-container" />;
}
