import * as Sentry from '@sentry/react';
import { Component, ErrorInfo, ReactNode } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { useCacheStore } from '~/stores/cache-store';

interface Props {
	children?: ReactNode;
}

interface State {
	hasError: boolean;
	error?: Error;
}

function ErrorFallback({ error }: { error: Error }) {
	return (
		<Container fluid>
			<Modal show={true} centered size="xl">
				<Modal.Header>
					<Modal.Title>Something went wrong</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>{error.message}</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="danger" onClick={() => {
						localStorage.clear();
						useCacheStore().clear();
						window.location.reload();
					}}>
						Clear Cache and Reload
					</Button>
					<Button variant="secondary" onClick={() => window.location.reload()}>
						Reload
					</Button>
				</Modal.Footer>
			</Modal>
		</Container>
	);
}

export class CustomErrorBoundary extends Component<Props, State> {
	public static getDerivedStateFromError(error: Error): State {
		return { hasError: true, error };
	}

	public state: State = {
		hasError: false,
	};

	public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error('Uncaught error:', error, errorInfo);
		Sentry.captureException(error);
	}

	public render() {
		if (this.state.hasError) {
			return <ErrorFallback error={this.state.error!} />;
		}

		return this.props.children;
	}
}

export function RouteErrorBoundary() {
	const error = useRouteError();

	if (isRouteErrorResponse(error)) {
		return (
			<Container fluid>
				<center className="p-5">
					<h1>{error.status} {error.statusText}</h1>
					<div>{error.data}</div>
				</center>
			</Container>
		);
	}

	return <ErrorFallback error={error as Error} />;
}
