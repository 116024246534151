import { Logout } from '~/providers/auth';

import { Command } from './type';

export const LogoutCommand: Command = {
	id: 'logout',
	name: 'Logout',
	description: 'Logout from the current session',
	handler: Logout,
};
