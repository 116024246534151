import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SearchFacetConfig, SearchWithFacets } from '~/components/search-with-facets';
import { PublicationTable } from '~/components/tables/publication-table';
import { PublisherTable } from '~/components/tables/publisher-table';

export const ContentFacetConfig: SearchFacetConfig[] = [
	{
		attribute: 'status',
		title: 'Status',
		sortBy: ['name:asc'],
	},
	{
		// Menu type is currently broken
		// type: 'Menu',
		attribute: 'closed_sites_only',
		title: 'Closed Sites Only',
	},
	{
		attribute: 'country',
		title: 'Country',
		showMore: true,
	},
	{
		attribute: 'language',
		title: 'Language',
		showMore: true,
	},
	{
		attribute: 'topics',
		title: 'Topic',
		showMore: true,
	},
];

export const PublicationFacetConfig: SearchFacetConfig[] = [
	...ContentFacetConfig,
	{
		attribute: 'last_post_date',
		title: 'Last Post Date',
		type: 'Date',
	},
	{
		attribute: 'integration_completion_date',
		title: 'Integration Completion Date',
		type: 'Date',
	},
	{
		attribute: 'content_type',
		title: 'Content Type',
	},
	{
		attribute: 'pub_country',
		title: 'Pub Country',
		showMore: true,
	},
];

export const PublisherSearchPage = () => {
	const [searchParams] = useSearchParams();
	const [filters, setFilters] = useState< Record<string, string[]>>({});
	useEffect(() => {
		if (searchParams.has('parent')) {
			setFilters({
				parent: searchParams.getAll('parent'),
			});
		} else {
			setFilters({});
		}
	}, [searchParams]);
	return (
		<SearchWithFacets
			indexName="Publisher"
			title="Publishers"
			filters={filters}
			sortBy={[
				// Sort by relevance first
				'_text_match:desc',
				// Then by status
				'_eval([ (status:Active):5, (status:Standby):4, (status:Pending):3, (status:Inactive):2, (status:Lead):0 ]):desc',
			]}
			resultsTable={PublisherTable}
			facets={ContentFacetConfig}
		/>
	);
};

export const PublicationSearchPage = () => {
	const [searchParams] = useSearchParams();
	const [filters, setFilters] = useState< Record<string, string[]>>({});
	useEffect(() => {
		if (searchParams.has('parent')) {
			setFilters({
				parent: searchParams.getAll('parent'),
			});
		} else {
			setFilters({});
		}
	}, [searchParams]);
	return (
		<SearchWithFacets
			indexName="Publication"
			title="Publications"
			filters={filters}
			sortBy={[
				// Sort by relevance first
				'_text_match:desc',
				// Then by status
				'_eval([ (status:Active):5, (status:Standby):4, (status:Pending):3, (status:Inactive):2, (status:Lead):0 ]):desc',
				// Then by last received date
				'last_received_date:desc',
			]}
			resultsTable={PublicationTable}
			facets={PublicationFacetConfig}
		/>
	);
};
