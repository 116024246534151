import type { Logger } from '@newstex/core/logger';
import type { SearchClient } from '@newstex/search/client';
import type { KnowledgeBase, RAGMemory } from '@newstex/types/rag';
import type { AccountInfoResponse } from '@newstex/types/responses/info';
import { PartialModel } from '@newstex/types/utils';

import { saveMemoryFactory, searchMemoryFactory } from './memory';
import { saveResourceFactory, searchResourceFactory } from './resources';
import { searchTypeSenseFactory } from './search';

export interface AIToolsContextArgs {
	searchClient: SearchClient;
	saveResource: (resource: Partial<KnowledgeBase | RAGMemory>) => Promise<void>;
	onToolCall?: (cmdName: string, cmdParams: Record<string, any>) => void;
	logger?: Logger | Console;
	user?: AccountInfoResponse;
	extraTools?: Record<string, any>;
}

export class AIToolsContext {
	public searchClient: SearchClient;
	public tools: Record<string, any>;
	public onToolCall?: (cmdName: string, cmdParams: Record<string, any>) => void;
	public logger?: Logger | Console;
	public user?: AccountInfoResponse;
	public saveResource: (resource: PartialModel<KnowledgeBase | RAGMemory>) => Promise<void>;

	constructor({
		searchClient,
		saveResource,
		onToolCall,
		logger,
		user,
		extraTools,
	}: AIToolsContextArgs) {
		this.searchClient = searchClient;
		this.saveResource = saveResource;
		this.tools = {
			searchTypeSense: searchTypeSenseFactory(this),
			addResource: saveResourceFactory(this),
			searchKnowledgeBase: searchResourceFactory(this),
			addMemory: saveMemoryFactory(this),
			searchMemory: searchMemoryFactory(this),
			...extraTools,
		};
		this.onToolCall = onToolCall;
		this.logger = logger;
		this.user = user;
	}
}
