import { Lambda } from '@aws-sdk/client-lambda';
import { UserError } from '@newstex/core/errors';
import { sleep } from '@newstex/core/sleep';
import { DetectFeedRequest, DetectFeedResponse } from '@newstex/types/detect-feed';
import { isErrorType } from '@newstex/types/error';
import { AWSLambdaFunctions } from '@newstex/types/responses/info';
import omit from 'object.omit';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { create } from 'zustand';

import { useUserInfo } from './user-info';
import type { UserInfo } from './user-info';

interface AWSState {
	lambdaClient: Lambda | null;
	setLambdaClient: (client: Lambda | null) => void;
	invokeLambda: (opts: {
		functionName: AWSLambdaFunctions;
		payload: any;
		attempt?: number;
		abortController?: AbortController;
		userInfo: UserInfo;
	}) => Promise<any>;
	refreshCredentials: (userInfo: UserInfo) => Promise<void>;
	detect: (
		opts: DetectFeedRequest & { userInfo: UserInfo },
		abortController?: AbortController,
	) => Promise<DetectFeedResponse>;
	setupLambdaClient: (aws: UserInfo['aws']) => Lambda | null;
}

let lastRefresh: Date | null = null;

export const useAWSStore = create<AWSState>()((set, get) => ({
	lambdaClient: null,
	setLambdaClient: (client) => set({ lambdaClient: client }),

	setupLambdaClient: (aws) => {
		if (!aws?.AccessKeyId || !aws?.SecretAccessKey || !aws?.SessionToken) {
			console.error('No AWS credentials found', aws);
			toast.error('No AWS credentials found, please refresh your browser', {
				toastId: 'aws-credentials-not-found',
				theme: 'dark',
				autoClose: false,
				onClick: () => {
					window.location.reload();
				},
			});
			return null;
		}

		const client = new Lambda({
			region: 'us-east-1',
			credentials: {
				accessKeyId: aws.AccessKeyId,
				secretAccessKey: aws.SecretAccessKey,
				sessionToken: aws.SessionToken,
			},
		});
		get().setLambdaClient(client);
		return client;
	},

	refreshCredentials: async (userInfo) => {
		if (!userInfo?.refresh) {
			throw new Error('No refresh function available');
		}

		const now = new Date();
		if (lastRefresh && (now.getTime() - lastRefresh.getTime()) < 1000 * 60 * 1) {
			console.trace('Already refreshing AWS credentials', {
				lastRefresh,
				diff: now.getTime() - lastRefresh.getTime(),
			});
			return;
		}

		console.trace('Refreshing AWS credentials', {
			lastRefresh,
			now,
		});
		toast.warning('AWS credentials expired, refreshing...', {
			toastId: 'aws-credentials-expired',
		});
		lastRefresh = now;
		const updatedInfo = await userInfo.refresh();

		if (!updatedInfo?.aws) {
			throw new Error('No AWS credentials found after refresh');
		}

		const client = get().setupLambdaClient(updatedInfo.aws);
		get().setLambdaClient(client);
		await sleep(1000);
	},

	invokeLambda: async ({
		functionName, payload, attempt = 0, abortController, userInfo,
	}) => {
		const state = get();

		if (!state.lambdaClient) {
			if (!userInfo) {
				console.trace('FATAL: **** NO USER INFO ****', { userInfo });
			}

			if (!userInfo?.aws) {
				console.trace('FATAL: **** NO AWS CREDENTIALS ****', { userInfo });
				await state.refreshCredentials(userInfo);
				throw new Error('No AWS credentials found');
			}
			state.setupLambdaClient(userInfo.aws);
			throw new Error('Lambda client not initialized');
		}

		if (!userInfo?.functions || !userInfo.functions[functionName]) {
			throw new Error(`Invalid Lambda function name: ${functionName}`);
		}

		try {
			const response = await state.lambdaClient.invoke({
				FunctionName: userInfo.functions[functionName],
				Payload: JSON.stringify(payload),
			});

			if (abortController?.signal.aborted) {
				console.debug('Aborting invokeLambda');
				return;
			}

			if (response.StatusCode !== 200) {
				console.error('Lambda invocation failed', response);
				throw new Error(`Lambda invocation failed with status ${response.StatusCode}`);
			}

			if (response.FunctionError) {
				console.error('Lambda function error', response);
				if (response.Payload) {
					console.error('Lambda function error payload', response.Payload.transformToString());
				}
				throw new Error(`Lambda function error: ${response.FunctionError}`);
			}

			if (!response.Payload) {
				console.debug('No payload returned from Lambda');
				return null;
			}

			const result = JSON.parse(response.Payload.transformToString());
			return result;
		} catch (error: any) {
			console.error('Error invoking Lambda', attempt, error);
			if (abortController?.signal.aborted) {
				throw error;
			}

			if (String(error).toLowerCase().includes('expired') && attempt < 2) {
				await state.refreshCredentials(userInfo);
				console.log('Refreshed AWS credentials, retrying Lambda invocation');
				return state.invokeLambda({
					functionName,
					payload,
					attempt: attempt + 1,
					abortController,
					userInfo,
				});
			}
			console.error('Error invoking Lambda', attempt, error);
			if (attempt < 3) {
				return state.invokeLambda({
					functionName,
					payload,
					attempt: attempt + 1,
					abortController,
					userInfo,
				});
			}
			throw error;
		}
	},

	detect: async ({ userInfo, ...req }, abortController?: AbortController) => {
		if (!req?.url && !req?.feed_url) {
			throw new UserError({
				statusCode: 400,
				code: 'BadRequest',
				message: 'Invalid Request, must provide a feed_url or url',
			});
		}

		for (const propName of ['url', 'feed_url'] as const) {
			if (req[propName]) {
				if (
					req[propName]
					&& !req[propName].startsWith('https://')
					&& !req[propName].startsWith('http://')
				) {
					req[propName] = `https://${req[propName]}`;
				}
			}
		}

		let output: DetectFeedResponse = {
			...req,
		};

		const state = get();

		if (output.feed_url) {
			if (output.feed_url === 'https://wordpress.com/blog/feed/') {
				throw new UserError({
					statusCode: 400,
					code: 'REDIRECTS_TO_AUTOMATTIC_FEED',
					message: 'Redirects to Automattic Feed',
				});
			}
			const resp = await state.invokeLambda({
				functionName: 'parseFeed',
				payload: req,
				abortController,
				userInfo,
			});
			if (isErrorType(resp)) {
				return state.detect({ ...omit(req, ['feed_url']), userInfo }, abortController);
			}
			output = resp;
		} else if (output.url) {
			const resp = await state.invokeLambda({
				functionName: 'parseHTML',
				payload: req,
				abortController,
				userInfo,
			});
			if (isErrorType(resp)) {
				throw new UserError({
					statusCode: 400,
					code: resp.code || 'DETECTION_FAILED',
					message: resp.message || 'Failed to detect feed',
				});
			}
			output = resp;
		}

		if (req.classify && output.articles?.length) {
			console.debug('Classify Articles', { articles: output.articles.length });
			const promises: Promise<any>[] = [];
			for (let i = 0; i < Math.min(output.articles.length, 5); i++) {
				if (abortController?.signal.aborted) {
					console.debug('Aborting classifyArticles: 1');
					break;
				}
				const article = output.articles[i];
				const articleIndex = i;
				promises.push(state.invokeLambda({
					functionName: 'addCategories',
					payload: article,
					abortController,
					userInfo,
				})
					.then((storyWithCategories) => state.invokeLambda({
						functionName: 'classifyStory',
						payload: storyWithCategories,
						abortController,
						userInfo,
					}))
					.then((classifiedStory) => {
						output!.articles![articleIndex] = classifiedStory;
					}));
			}

			if (abortController?.signal.aborted) {
				console.debug('Aborting classifyArticles: 2');
				return output;
			}

			await Promise.all(promises);

			if (abortController?.signal.aborted) {
				console.debug('Aborting classifyArticles: 3');
				return output;
			}

			return state.invokeLambda({
				functionName: 'classifyFeed',
				payload: output,
				abortController,
				userInfo,
			});
		}

		return output;
	},
}));

// Initialize AWS client when user info changes
export function useInitializeAWS() {
	const userInfo = useUserInfo();
	const setupLambdaClient = useAWSStore((state) => state.setupLambdaClient);

	useEffect(() => {
		if (userInfo?.aws) {
			setupLambdaClient(userInfo.aws);
		}
	}, [userInfo?.aws, setupLambdaClient]);
}

// Export a hook for easy access to AWS functionality
export function useAWS() {
	const userInfo = useUserInfo();
	const store = useAWSStore();

	return {
		invokeLambda: (opts: Omit<Parameters<typeof store.invokeLambda>[0], 'userInfo'>) => (userInfo ? store.invokeLambda({ ...opts, userInfo }) : Promise.reject(new Error('No user info available'))),
		refreshCredentials: () => (userInfo ? store.refreshCredentials(userInfo) : Promise.reject(new Error('No user info available'))),
		detect: (opts: Omit<DetectFeedRequest, 'userInfo'>, abortController?: AbortController) => (userInfo ? store.detect({ ...opts, userInfo }, abortController) : Promise.reject(new Error('No user info available'))),
	};
}
