import {
	Card,
	Col,
	Container,
	Row,
} from 'react-bootstrap';
import Icon from '~/components/Icon';
import { PageTitle } from '~/components/page-title';
import SvgIcons from '~/components/SvgIcons';

export const IconsPage = () => {
	const allIcons = SvgIcons().props.children.props.children;
	for (const icon of allIcons) {
		console.log(icon);
	}
	return (
		<Container fluid>
			<PageTitle title="Icons" />
			<Row>
				{allIcons.map((icon: any) => (
					<Col
						sm={6}
						xl={4}
						xxl={2}
						className="mb-3 text-center"
						key={icon.key || icon.props.id}
					>
						<Card className="shadow-sm hover-animate h-100">
							<Card.Body>
								<div
									className="icon icon-xl mx-auto mb-4 bg-primary-subtle"
								>
									<Icon icon={icon.props.id} className="text-primary" />
								</div>

								<h3 className="h5">{icon.props.id}</h3>
								<div className="text-muted text-sm">
									{icon.props.children[0].props.children}
								</div>
							</Card.Body>
						</Card>
					</Col>
				))}
			</Row>
		</Container>
	);
};
