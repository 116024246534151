import React from 'react';

interface DateTimeProps {
	ts?: number | string;
	dateOnly?: boolean;
}

export const DateTime: React.FC<DateTimeProps> = (props) => {
	if (!props.ts) {
		return null;
	}
	let d: Date | null = null;

	if (typeof props.ts === 'number') {
		if (props.ts < 10000000000) {
			d = new Date(props.ts * 1000);
		} else {
			d = new Date(props.ts);
		}
	} else if (typeof props.ts === 'string') {
		if (props.ts.length === 10) {
			// NOTE: No time zone information, assume Local time
			d = new Date(`${props.ts}T00:00:00`);
		} else {
			d = new Date(props.ts);
		}
	}

	if (d != null) {
		return (
			<time dateTime={d.toISOString()}>
				{props.dateOnly ? d.toLocaleDateString() : d.toLocaleString()}
			</time>
		);
	}

	return <>{props.ts}</>;
};
