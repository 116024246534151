import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Results } from '@newstex/types/results';
import { SummaryEarningsByPeriodAndClient } from '@newstex/types/revenue';
import { useEffect, useState } from 'react';
import {
	Card,
	Col,
	Container,
	Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LoadingSpinner from '~/components/LoadingSpinner';
import PageHeader from '~/components/PageHeader';
import KPIStatsWidget from '~/components/Widgets/KPIStats';
import { useAPI } from '~/providers/api-provider';
import { useSearch } from '~/providers/search';

/**
 * Newscrunch default page
 */
export function NewscrunchPage() {
	const api = useAPI();
	const search = useSearch();
	const [loading, setLoading] = useState(true);
	const [revenue, setRevenue] = useState<SummaryEarningsByPeriodAndClient[]>();
	const [currentPeriod, setCurrentPeriod] = useState<string>();
	const [currentPeriodRevenue, setCurrentPeriodRevenue] = useState<number>();
	const [openReportingPeriod, setOpenReportingPeriod] = useState<string>();

	useEffect(() => {
		const fetchData = async () => {
			if (api) {
				const resp = await api.fetchWithAuth<Results<SummaryEarningsByPeriodAndClient>>('revenue');
				console.log('revenue', resp);
				setRevenue(resp.items);
				if (resp.items.length) {
					const currentReportingPeriod = resp.items[0].period;
					setCurrentPeriod(currentReportingPeriod);

					// Open Reporting Period is 1 month after current period
					const openPeriodDate = new Date(currentReportingPeriod);
					openPeriodDate.setMonth(openPeriodDate.getMonth() + 1);
					setOpenReportingPeriod(openPeriodDate.toISOString().slice(0, 7));

					let totalRevenue = 0;
					for (const item of resp.items) {
						if (item.period === currentReportingPeriod) {
							if (item.newstexRevenue) {
								totalRevenue += item.newstexRevenue;
							}
						}
					}
					console.log('totalRevenue', totalRevenue);
					setCurrentPeriodRevenue(totalRevenue);
				} else {
					console.log('no revenue', JSON.stringify(resp));
					setCurrentPeriod(undefined);
					setCurrentPeriodRevenue(undefined);
				}
			}
			setLoading(false);
		};
		setLoading(true);
		fetchData();
	}, [api, search]);

	return (
		<Container fluid>
			<PageHeader title="NewsCrunch Revenue Processing" />
			<Row className="mb-3">
				<Col sm={6} lg={3} className="mb-4">
					<Card className="h-100 hover-animate">
						<Card.Body className="d-flex align-items-center justify-content-between">
							<div>
								<h4 className="fw-normal text-blue">
									{ loading ? <LoadingSpinner hideTitle loading /> : (<>
										{openReportingPeriod && new Date(openReportingPeriod).toLocaleDateString(undefined, { month: 'long', year: 'numeric' })}
									</>)}
								</h4>
								<div className="subtitle text-sm text-muted mb-0">Current Reporting Period</div>
							</div>
							<div className="flex-shrink-0 ms-3 text-blue">
								<FontAwesomeIcon icon="calendar-days" className="text-blue" />
							</div>
						</Card.Body>
						<Card.Footer className="py-3 bg-blue-subtle">
							<Row className="align-items-center text-blue">
								<Col xs={10}>
									<div className="mb-0">Open Reporting Period</div>
								</Col>
							</Row>
						</Card.Footer>
					</Card>
				</Col>

				<Col sm={6} lg={3} className="mb-4">
					<Card className="h-100 hover-animate">
						<Card.Body className="d-flex align-items-center justify-content-between">
							<div>
								{loading ? <LoadingSpinner hideTitle loading/> : (
									<h4 className='fw-normal'>{currentPeriod && (<>
										{new Date(currentPeriod).toLocaleDateString(undefined, { month: 'long', year: 'numeric' })}
									</>)}</h4>
								)}
								<div className="subtitle text-sm text-muted mb-0">Most recent payment period</div>
							</div>
						</Card.Body>
					</Card>
				</Col>
				<Col sm={6} lg={3} className="mb-4">
					<KPIStatsWidget
						name="Last Period Revenue"
						icon="dollar-badge-1"
						number={Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(currentPeriodRevenue || 0)}
						footer={`Total Revenue for ${currentPeriod}`}
						color="green"
						loading={loading}
					/>
				</Col>
			</Row>
			<Row className="text-center">
				<Col>
					<Card className="h-100 hover-animate card">
						<Card.Header>
							<h4>Matching Reports</h4>
						</Card.Header>
						<Card.Body>
							Upload and process matching reports from distributors.
						</Card.Body>
						<Link className="stretched-link" to="/newscrunch/matching-report" />
					</Card>
				</Col>
				<Col>
					<Card className="h-100 hover-animate card">
						<Card.Header>
							<h4>Another Cool Thing</h4>
						</Card.Header>
						<Card.Body>
							Do something cool
						</Card.Body>
						<Link className="stretched-link" to="/newscrunch/another-cool-thing" />
					</Card>
				</Col>
			</Row>
		</Container>
	);
}
