import React, { useEffect, useState } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';

const ActiveLink = ({ activeClassName, ...props }: LinkProps & {
	activeClassName: string,
}) => {
	const location = useLocation();
	const [classNames, setClassNames] = useState<string[]>(props.className?.split(' ') || []);
	useEffect(() => {
		if (location.pathname === props.to) {
			if (!classNames.includes(activeClassName)) {
				setClassNames([...classNames, activeClassName]);
			}
		} else if (classNames.includes(activeClassName)) {
			setClassNames(classNames.filter((className) => className !== activeClassName));
		}
	}, [location.pathname]);
	return (
		<Link {...props} className={classNames.join(' ').trim()} />
	);
};

export default ActiveLink;
