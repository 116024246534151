import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faDownload, faSpinner } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useAPI } from '~/providers/api-provider';

export interface DownloadOption {
	name: string;
	path: string;
	icon?: IconDefinition | string;
	filename?: string;
}

interface DownloadButtonProps {
	options: DownloadOption[];
	size?: 'sm' | 'lg';
}

export function DownloadButton({ options, size }: DownloadButtonProps) {
	const [loading, setLoading] = useState(false);
	const api = useAPI();

	const handleDownload = async (option: DownloadOption) => {
		setLoading(true);
		try {
			const response = await api.fetchWithAuthRaw(option.path);
			const blob = await response.blob();
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = option.filename ?? `${option.name}.xml`;
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(url);
			document.body.removeChild(a);
		} catch (error) {
			console.error('Download failed:', error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Dropdown style={{ display: 'inline-block' }}>
			<Dropdown.Toggle
				variant="outline-secondary"
				size={size}
				disabled={loading}
			>
				{loading && <FontAwesomeIcon icon={faSpinner} spin />}
				{!loading && <FontAwesomeIcon icon={faDownload} />}
			</Dropdown.Toggle>

			<Dropdown.Menu>
				{options.map((option) => (
					<Dropdown.Item
						key={option.path}
						onClick={() => handleDownload(option)}
					>
						{option.icon && (
							typeof option.icon === 'string' ? (
								<span className={`icon ${option.icon}`} />
							) : (
								<FontAwesomeIcon icon={option.icon} className="me-2" />
							)
						)}
						{option.name}
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	);
}
