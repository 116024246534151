import { useEffect, useState } from 'react';
import {
	Badge,
	Form,
	ListGroup,
	ListGroupItem,
} from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import { Panel } from './Panel';

export interface SimpleFacetMenuOption {
	value: string;
	label: string;
	count?: number | string;
}

export interface SimpleFacetMenuProps {
	title: string;
	prop: string;
	options?: SimpleFacetMenuOption[];
	searchResult?: Record<string, number>;
	searchable?: boolean;
	supportNegation?: boolean;
}

/**
 * SIMPLE Facet menu, just changes a search parameter
 */
export const SimpleFacetMenu = ({
	title, prop, options, searchResult, searchable, supportNegation,
}: SimpleFacetMenuProps) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [facetOptions, setFacetOptions] = useState<SimpleFacetMenuOption[]>([]);
	const [longPressTimer, setLongPressTimer] = useState<NodeJS.Timeout | null>(null);
	const [flashNegation, setFlashNegation] = useState<string | null>(null);
	const [isLongPress, setIsLongPress] = useState(false);

	useEffect(() => {
		if (options) {
			const optionsWithCount: SimpleFacetMenuOption[] = options.map((option) => ({
				...option,
				count: option.count || searchResult?.[option.value],
			}));
			setFacetOptions(optionsWithCount);
		} else if (searchResult) {
			// Combine normal and negated facet values
			const combinedResults = { ...searchResult };
			const negatedResults = Object.entries(searchResult)
				.filter(([value]) => searchParams.get(`!${prop}`) === value)
				.reduce((acc, [value, count]) => ({ ...acc, [value]: count }), {});

			Object.assign(combinedResults, negatedResults);

			const searchResultOptions: SimpleFacetMenuOption[] = Object.entries(combinedResults)
				.map(([value, count]) => ({
					value,
					label: value,
					count,
				}))
				.sort((a, b) => (b.count as number) - (a.count as number));

			searchResultOptions.unshift({
				value: '',
				label: 'All',
			});
			setFacetOptions(searchResultOptions);
		}
	}, [searchResult, options, searchParams]);

	const handleMouseDown = (value: string) => {
		if (!supportNegation || value === '') return; // Prevent negation for "All" option

		setIsLongPress(false);
		setLongPressTimer(setTimeout(() => {
			setIsLongPress(true);
			setFlashNegation(value);
		}, 500));
	};

	const handleMouseUp = (option: SimpleFacetMenuOption) => {
		if (longPressTimer) {
			clearTimeout(longPressTimer);
			setLongPressTimer(null);
		}

		// Apply the filter
		setSearchParams((prev) => {
			// Clear both normal and negated versions first
			prev.delete(prop);
			prev.delete(`!${prop}`);

			if (option.value) {
				if (isLongPress && supportNegation) {
					prev.set(`!${prop}`, option.value);
				} else {
					prev.set(prop, option.value);
				}
			}
			return prev;
		});

		// Reset states
		setFlashNegation(null);
		setIsLongPress(false);
	};

	const handleMouseLeave = () => {
		if (longPressTimer) {
			clearTimeout(longPressTimer);
			setLongPressTimer(null);
		}
		setFlashNegation(null);
		setIsLongPress(false);
	};

	return (
		<Panel header={title}>
			{searchable && <Form.Control
				type="text"
				placeholder="Search"
				onChange={(e) => {
					setSearchParams((prev) => {
						if (e.target.value) {
							prev.set(prop, `${e.target.value}*`);
						} else {
							prev.delete(prop);
						}
						return prev;
					});
				}}
			/>}
			<ListGroup className="mb-4">
				{facetOptions?.map((option, index) => {
					const selected = (searchParams.get(prop) || '') === option.value;
					const negated = (searchParams.get(`!${prop}`) || '!') === option.value;
					const isAll = option.value === '';

					return (
						<ListGroupItem
							key={`${prop}-${option.value}-${index}`}
							className={`list-group-item-action
								${selected ? 'active' : ''}
								${negated ? 'active bg-danger' : ''}
								${!isAll && flashNegation === option.value ? 'bg-danger-subtle' : ''}`}
							style={{ cursor: 'pointer' }}
							onMouseDown={() => handleMouseDown(option.value)}
							onMouseUp={() => handleMouseUp(option)}
							onMouseLeave={handleMouseLeave}
						>
							{option.label}
							{option.count != null && <Badge
								className="float-end"
								bg={selected || negated ? 'light' : 'secondary'}
								text={selected || negated ? 'dark' : 'light'}
							>{option.count}</Badge>}
						</ListGroupItem>
					);
				})}
			</ListGroup>
		</Panel>
	);
};
