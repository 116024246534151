import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Product } from '@newstex/types/product';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import DataTable, { DataTableMethods } from '../DataTable';
import { LoadMoreButton } from '../load-more-button';
import { PropertyDisplayValue } from '../property-display-value';

export function ProductsTable(
	{
		products,
		searchText,
		hasMore,
		onLoadMore,
	}: {
		products: Product[],
		searchText?: string,
		hasMore?: boolean,
		onLoadMore?: () => void,
	},
) {
	const dataTableRef = useRef<DataTableMethods>(null);
	const columnHelper = createColumnHelper<Product>();
	const columns = useMemo(() => [
		columnHelper.accessor('name', {
			header: 'Name',
			enableSorting: !hasMore,
			cell: ({ getValue, row: { original }}) => {
				return <>
					<Link to={`/products/${original.$id}`}>
						<PropertyDisplayValue propName="name" propValue={getValue()} />
					</Link>
				</>;
			},
		}),
		columnHelper.accessor('group', {
			header: 'Group',
			enableSorting: true,
		}),
		columnHelper.accessor('filters', {
			header: 'Filters',
			enableSorting: true,
		}),
		columnHelper.accessor('kill_alert_emails', {
			header: 'Kill Alert Emails',
			enableSorting: true,
		}),
		columnHelper.accessor('username', {
			header: 'Username',
			enableSorting: !hasMore,
		}),
	], [products]);

	return (<>
		<DataTable
			columns={columns}
			items={products || []}
			ref={dataTableRef}
			searchText={searchText}
			tableRowClassNameHelper={({ original }: { original: Product }) => {
				if (original.group === 'archived') {
					return 'table-danger';
				}
				return '';
			}}
			nameAsc
			striped
			sortable
		/>
		<LoadMoreButton show={hasMore} onClick={onLoadMore} />
		<div className="float-end pb-2">
			<Button variant="outline-secondary" size="sm" onClick={() => {
				dataTableRef.current?.exportToCSV('Products');
			}}>
				<FontAwesomeIcon icon={faDownload} /> CSV
			</Button>
		</div>
	</>);
}
