import { KnowledgeBase } from '@newstex/types/rag';
import { useEffect, useState } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useSearch } from '~/providers/search';

interface SelectKnowledgeBaseArticleProps {
	category?: string;
	onSelect?: (article: KnowledgeBase | null) => void;
	value?: KnowledgeBase | null;
	className?: string;
	label?: string;
	disabled?: boolean;
}

export function SelectKnowledgeBaseArticle({
	category = 'Qualification',
	onSelect,
	value,
	className,
	label = 'Search for Knowledge Base Article',
	disabled,
}: SelectKnowledgeBaseArticleProps) {
	const [knowledgeBaseOptions, setKnowledgeBaseOptions] = useState<KnowledgeBase[]>([]);
	const { searchClient } = useSearch();

	const searchKnowledgeBase = async (query = '') => {
		if (!searchClient) {
			toast.error('Search client not initialized');
			return;
		}

		const results = await searchClient.search({
			indexName: 'RAG',
			query,
			filter_by: `category:"${category}"`,
		});

		if (results.hits.length === 0 && query) {
			toast.warning('No matching knowledge base articles found');
			return;
		}

		// Find the first article with "All Clients" in the title, and set that as the default value
		if (!value) {
			const allClientsArticle = results.hits.find((kb) => kb.title?.includes('All Clients'));
			if (allClientsArticle) {
				onSelect?.(allClientsArticle);
			}
		}

		setKnowledgeBaseOptions(results.hits);
	};

	useEffect(() => {
		searchKnowledgeBase();
	}, []);

	return (
		<FormGroup className={className}>
			<Form.Label>{label}</Form.Label>
			<Select
				isDisabled={disabled}
				placeholder="Select an option to perform AI Qualification - Leave blank to only perform technical qualifications"
				options={[{
					label: 'None - DO NOT USE AI QUALIFICATION',
					value: '',
				}, ...knowledgeBaseOptions.map((kb) => ({
					label: kb.title || '',
					value: kb.$id || (kb as any).id || '',
				}))]}
				value={value ? {
					label: value.title || '',
					value: value.$id || (value as any).id || '',
				} : null}
				onChange={(option) => {
					onSelect?.(knowledgeBaseOptions.find((a) => a.$id === option?.value) || null);
				}}
			/>
			<Form.Text className="text-muted">
				{value ? (
					<>
						AI Qualification will be run on all technically valid items you submit.
					</>
				) : (
					<>
						<strong>WARNING: </strong>
						AI Qualification will be skipped, only technical qualifications will be performed.
					</>
				)}
			</Form.Text>
		</FormGroup>
	);
}
