import type { SearchClient, SearchIndexName, SearchResults } from '@newstex/search';
import {
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { getFilterString } from '~/lib/search-utils';
import { useSearch } from '~/providers/search';

interface UseTypesenseSearchProps {
	indexName: SearchIndexName;
	queryBy?: string[];
	facets?: string[];
	includeFields?: string;
	sortBy?: string[];
	defaultSort?: string;
	timelineFacet?: string;
}

interface SearchParams {
	query: string;
	filters: Record<string, string[]>;
	page: number;
	groupBy?: string;
	sortBy?: string;
}

export function useTypesenseSearch<T>({
	indexName,
	queryBy,
	facets,
	includeFields,
	sortBy,
	defaultSort,
	timelineFacet,
}: UseTypesenseSearchProps) {
	const search = useSearch();
	const [searchParams] = useSearchParams();
	const [loading, setLoading] = useState(false);
	const [results, setSearchResults] = useState<SearchResults<T>>();
	const [hasMore, setHasMore] = useState(false);
	const [searchRequestParams, setSearchRequestParams] = useState<Parameters<SearchClient['search']>[0]>();
	const searchTimeoutRef = useRef<NodeJS.Timeout>();
	const currentSearchParams = useRef<SearchParams>();

	// Extract and prepare search parameters
	const prepareSearchParams = useCallback(() => {
		const filters: Record<string, string[]> = {};
		for (const [key, value] of searchParams.entries()) {
			if (key !== 'q' && key !== 'mode' && key !== 'sort_by'
				&& key !== 'page' && key !== 'group_by') {
				filters[key] = Array.isArray(value) ? value : [value];
			}
		}

		currentSearchParams.current = {
			query: searchParams.get('q') || '',
			filters,
			page: parseInt(searchParams.get('page') || '1', 10),
			groupBy: searchParams.get('group_by') || undefined,
			sortBy: searchParams.get('sort_by') || undefined,
		};
	}, [searchParams]);

	const doSearch = useCallback(async (refresh?: boolean) => {
		if (!search?.searchClient || !currentSearchParams.current) return;

		const facetNames = [...(facets || [])];
		if (timelineFacet && !facetNames.includes(timelineFacet)) {
			facetNames.push(timelineFacet);
		}

		const {
			query, filters, page, groupBy, sortBy: paramSortBy,
		} = currentSearchParams.current;
		setLoading(true);

		const tsSearchRequestParams: Parameters<typeof search.searchClient.search>[0] = {
			indexName,
			query,
			query_by: queryBy?.join(','),
			group_by: groupBy,
			facet_by: facetNames?.join(','),
			include_fields: includeFields,
			filter_by: getFilterString(filters),
			sort_by: sortBy?.join(',')
				|| (paramSortBy || defaultSort || '_text_match:desc')
					.replace(/stats_/g, 'stats.'),
			per_page: 100,
			page,
			use_cache: !refresh,
		};
		setSearchRequestParams(tsSearchRequestParams);

		try {
			const searchResponse = await search.searchClient.search(tsSearchRequestParams);
			setHasMore(Boolean(searchResponse.page
				&& searchResponse.page * 100 < searchResponse.found));
			setSearchResults(searchResponse);
		} catch (error) {
			console.error('Search failed:', error);
		} finally {
			setLoading(false);
		}
	}, [search?.searchClient, indexName, queryBy, facets, includeFields,
		sortBy, defaultSort, timelineFacet]);

	// Update search params and trigger search
	useEffect(() => {
		if (searchTimeoutRef.current) {
			clearTimeout(searchTimeoutRef.current);
		}

		prepareSearchParams();
		searchTimeoutRef.current = setTimeout(() => {
			doSearch();
		}, 300);

		return () => {
			if (searchTimeoutRef.current) {
				clearTimeout(searchTimeoutRef.current);
			}
		};
	}, [searchParams, prepareSearchParams]);

	const loadMore = useCallback(async () => {
		if (!results || !searchRequestParams || !search?.searchClient) return;

		const searchResponse = await search.searchClient.search({
			...searchRequestParams,
			page: results.page + 1,
		});

		setSearchResults((r) => {
			if (!r) return searchResponse;
			return {
				...r,
				hits: [...(r.hits || []), ...searchResponse.hits],
				page: searchResponse.page,
			};
		});
		setHasMore(Boolean(searchResponse.page
			&& searchResponse.page * 100 < searchResponse.found));
	}, [results, searchRequestParams, search?.searchClient]);

	return {
		loading,
		results,
		hasMore,
		doSearch,
		loadMore,
	};
}
