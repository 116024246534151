import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Icon from '../Icon';
import LoadingSpinner from '../LoadingSpinner';
import { SVGIconsName } from '../SvgIcons';

export interface KPIProps {
	icon: SVGIconsName;
	color: string;
	number: string;
	name: string;
	footer: string;
	indicator?: IconDefinition;
	/**
	 * Prefix to add before the number
	 */
	prefix?: string;
	/**
	 * Location to link button clicks to
	 */
	link?: string;

	/**
	 * If set to true, we'll use a loading spinner in palce of the number
	 */
	loading?: boolean;
}

export default function KPIStatsWidget(props: KPIProps) {
	return (
		<Card className="h-100 hover-animate">
			<Card.Body className="d-flex align-items-center justify-content-between">
				<div>
					<h4 className={`fw-normal text-${props.color}`}>
						{ props.loading ? <LoadingSpinner hideTitle loading /> : (<>
							{props.prefix || ''}{props.number}
						</>)}
					</h4>
					<div className="subtitle text-sm text-muted mb-0">{props.name}</div>
				</div>
				<div className="flex-shrink-0 ms-3">
					<Icon icon={props.icon} className={`text-${props.color}`} />
				</div>
			</Card.Body>
			<Card.Footer className={`py-3 bg-${props.color}-light`}>
				<Row className={`align-items-center text-${props.color}`}>
					<Col xs={10}>
						<div className="mb-0">{props.footer}</div>
					</Col>
					{props.indicator && (
						<Col xs={2} className="text-end">
							<FontAwesomeIcon icon={props.indicator} />
						</Col>
					)}
				</Row>
				<Link to={props.link || ''} className={props.link ? 'stretched-link' : ''} />
			</Card.Footer>
		</Card>
	);
}
