import { TypedObject } from '@newstex/types/base';
import { isCategory } from '@newstex/types/category';
import { isContent } from '@newstex/types/content';
import { isDelivery } from '@newstex/types/delivery';
import { isFeed } from '@newstex/types/feed';
import { isProduct } from '@newstex/types/product';
import { isStory } from '@newstex/types/story';
import React from 'react';

import { CategoryCard } from './category-card';
import { ContentCard } from './content-card';
import { DeliveryCard } from './delivery/delivery-card';
import { FeedCard } from './feed-card';
import { ProductCard } from './product/product-card';
import { StoryCard } from './story/story-card';

/**
 * Generic component for displaying the card-version of any object
 */
export const ObjectCard: React.FC<{
	object: TypedObject;
	tableName?: string;
}> = ({
	object,
	tableName,
}) => {
	if (isContent(object, tableName)) {
		return <ContentCard item={object} />;
	}

	if (isStory(object, tableName)) {
		console.log('Story', object);
		return <StoryCard item={object} />;
	}

	if (isDelivery(object, tableName)) {
		return <DeliveryCard item={object} />;
	}

	if (isProduct(object, tableName)) {
		return <ProductCard item={object} />;
	}

	if (isCategory(object, tableName)) {
		return <CategoryCard item={object} />;
	}

	if (isFeed(object, tableName)) {
		console.log('Feed', object);
		return <FeedCard item={object} />;
	}
	console.log('Unknown Object', object, tableName);

	return (
		<div>
			{object.name}
		</div>
	);
};
