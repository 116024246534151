/**
 * Refresh Button
 */
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';

export interface RefreshButtonProps {
	refreshHandler: () => Promise<void>;
	size?: 'sm' | 'lg';
	loading?: boolean;
	setLoading?: (loading: boolean) => void;
}

export function RefreshButton({
	refreshHandler,
	size,
	loading,
	setLoading,
}: RefreshButtonProps) {
	return (
		<Button disabled={loading} size={size} variant="outline-secondary" onClick={async () => {
			setLoading?.(true);
			await refreshHandler();
			setLoading?.(false);
		}}>
			<FontAwesomeIcon icon={faRefresh} spin={loading} />
		</Button>
	);
}
