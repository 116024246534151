import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { PropertyDisplayValue } from '../property-display-value';
import { PropertyHistoryButton } from '../property-history-button';

interface PropertiesTableProps {
	item?: Record<string, any>;
}

export const PropertiesTable: React.FC<PropertiesTableProps> = (props) => {
	const location = useLocation();
	const [searchTerm, setSearchTerm] = useState('');

	function scrollToProperty(property: string, attempt = 0) {
		const element = document.querySelector(`[data-property-id="${property}"]`);
		if (element) {
			element.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
				inline: 'center',
			});
		} else if (attempt < 10) {
			setTimeout(() => {
				scrollToProperty(property, attempt + 1);
			}, 1000);
		}
	}

	useEffect(() => {
		if (location.hash) {
			setTimeout(() => {
				scrollToProperty(location.hash.slice(1));
			}, 1000);
		}
	}, [location.hash]);

	const normalizeString = (str: string) => {
		return str.toLowerCase().replace(/[_\s$]/g, '');
	};

	const filterProperties = (key: string, value: any) => {
		const normalizedSearch = normalizeString(searchTerm);
		const normalizedKey = normalizeString(key);
		const normalizedValue = normalizeString(String(value));

		return normalizedKey.includes(normalizedSearch) || normalizedValue.includes(normalizedSearch);
	};

	return (
		<>
			<Form.Group className="mb-3">
				<Form.Control
					type="text"
					placeholder="Search properties..."
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
				/>
			</Form.Group>
			{props.item && (
				<table className="table table-striped">
					<tbody>
						{Object.entries(props.item || {})
							.filter(([key, value]) => filterProperties(key, value))
							.sort(([a], [b]) => a.localeCompare(b))
							.map(([key, value]) => (
								<tr
									key={key}
									data-property-id={key}
									className={`property ${
										location.hash?.slice(1) === key ? 'active' : ''
									}`}
								>
									<th style={{ width: '240px' }}>
										<PropertyHistoryButton propName={key} item={props.item} /> {key}
									</th>
									<td><PropertyDisplayValue propName={key} propValue={value} /></td>
								</tr>
							))}
					</tbody>
				</table>
			)}
		</>
	);
};
