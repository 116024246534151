import { Publication } from '@newstex/types/content';
import { Story } from '@newstex/types/story';
import { marked } from 'marked';
import pick from 'object.pick';
import { z } from 'zod';

import { Agent, type AgentContext, type ResourceLookup } from './agent';

// Re-export the schema from generate-story.ts
export const GeneratedStorySchema = z.object({
	headline: z.string().describe('A clear, concise headline for the story'),
	provider_categories: z.preprocess(
		(val) => (typeof val === 'string' ? val.split(',').map((s) => s.trim()) : val),
		z.array(z.string()).optional()
			.describe('A list of categories that the story belongs to'),
	),
	excerpt: z.string().max(500)
		.describe('A brief summary of the story, maximum 500 characters'),
	text_content: z.string().describe('The story content in Markdown format'),
	company_tickers: z.preprocess(
		(val) => (typeof val === 'string' ? val.split(',').map((s) => s.trim()) : val),
		z.array(z.string()).optional()
			.describe('A list of company tickers mentioned in the story'),
	),
});

export type GeneratedStory = z.infer<typeof GeneratedStorySchema>;

export interface StoryGenerationResources extends ResourceLookup {
	/**
	 * Get a story by its ID
	 */
	getStory?: (id: string) => Promise<Story | null>;

	/**
	 * Get a story file (like content.html) from S3
	 */
	getStoryFile?: (story: Story, filename: string) => Promise<string | null>;

	/**
	 * Search for stories
	 */
	searchStories?: (params: {
		query: string;
		query_by?: string[];
		filter_by?: string;
		sort_by?: string;
		per_page?: number;
		page?: number;
		facet_by?: string[];
	}) => Promise<{
		hits: Story[];
		found: number;
		facets?: Record<string, { values?: Record<string, number> }>;
	}>;
}
const STORY_GUIDELINES = `
Your task is to review the following stories and create a new story that is relevant to the search query and the stories.

Guidelines:
- Write in a professional, journalistic style
- Use clear, factual language
- Format content using Markdown
- Include links to original stories
- Always use ISO format (YYYY-MM-DD) for dates
- Always include links to source articles, such as [Story Headline]({story.permalink})
- DO NOT HALLUCINATE, Do not include facts or assumptions not included in the source stories
`;

const DEFAULT_STORY_PROMPT = `You are a professional content writer for Newstex,
specializing in creating well-structured, informative content summarizing other stories.

${STORY_GUIDELINES}`;

export class GenerateStoryAgent extends Agent {
	protected prompt = DEFAULT_STORY_PROMPT;

	constructor(context: AgentContext & { resources?: StoryGenerationResources, publication?: Publication }) {
		super(context);
		if (context.publication) {
			this.setPrompt(`You are a professional blogger writing for "${context.publication.name}".

${context.publication.description ? `Description for Publication: > ${context.publication.description}
` : ''}.
${context.publication.url ? `The publication's website is ${context.publication.url}.
` : ''}
${context.publication.content_category ? `The publication's content category is: ${context.publication.content_category}.
` : ''}
${STORY_GUIDELINES}
-Be sure to take the angle from the publication's perspective.`);
		}

		// Set the steps for this agent
		this.steps = [
			{
				id: 'searching',
				label: 'Search',
				description: 'Searching for related stories...',
			},
			{
				id: 'generating',
				label: 'Generate',
				description: 'Generating draft story...',
			},
			{
				id: 'polishing',
				label: 'Polish',
				description: 'Polishing content...',
			},
		];

		// Initialize tools based on available resources
		const resources = context.resources as StoryGenerationResources;
		if (resources?.getStory && resources?.getStoryFile) {
			this.addTool('generateFilingSummary', {
				description: 'Provide a summary of a given SEC Filing by `id`.',
				parameters: z.object({
					id: z.string().describe('The `id` field of the story to fetch'),
				}),
				execute: async ({ id }) => {
					if (!id.match(/^([A-Za-z0-9]{2,})-(\d{6,})-(\d{20,})$/)) {
						return '**ERROR**: Invalid story ID format';
					}
					const story = await resources.getStory(id);
					if (!story) {
						return '**ERROR**: Story not found';
					}
					const content = await resources.getStoryFile(story, 'content.html');
					if (!content) {
						return '**ERROR**: Story content not found';
					}
					// Generate a summary using the model
					const summary = await this.generateSummary(content, {
						filingType: story.provider_categories?.join(', '),
						filingDate: new Date(story.date * 1000).toISOString().split('T')[0],
					});
					return `${summary}\n\n[View Filing](${story.permalink})`;
				},
			});
		}
	}

	protected setPrompt(prompt: string): this {
		this.prompt = prompt;
		return this;
	}

	/**
	 * Generate a story based on the current prompt and any provided messages
	 */
	async generateStory(
		messages: { role: 'user' | 'assistant' | 'system'; content: string; }[],
		sourceStories?: Story[],
	): Promise<Partial<Story>> {
		if (!this.prompt) {
			throw new Error('No prompt set for story generation');
		}

		// If we have source stories, analyze them first
		if (sourceStories?.length) {
			this.updateProgress('searching', `Analyzing ${sourceStories.length} source stories`);

			// Extract key information from each story
			const storyDetails = await Promise.all(sourceStories.slice(0, 10).map(async (story) => {
				return {
					headline: story.headline,
					excerpt: story.excerpt,
					permalink: story.permalink,
					categories: story.provider_categories,
					date: story.date ? new Date(story.date * 1000).toISOString().split('T')[0] : undefined,
				};
			}));

			// Add source story information to the messages
			messages = [
				...messages,
				{
					role: 'user',
					content: [
						'Here are the source stories to analyze and summarize:',
						'',
						...storyDetails.map((story, index) => [
							`Story ${index + 1}:`,
							`Headline: ${story.headline}`,
							`Date: ${story.date || 'Unknown'}`,
							`Categories: ${story.categories?.join(', ') || 'None'}`,
							`Link: ${story.permalink || 'None'}`,
							'Content:',
							story.excerpt || 'No content available',
							story.permalink ? `[View Story](${story.permalink})` : '',
						].join('\n')),
						'',
						'Please use specific quotes and statistics from these stories where appropriate.',
						'Make sure to synthesize the information into a cohesive narrative.',
					].join('\n'),
				},
			];
		}

		this.updateProgress('generating', 'Drafting story content...');
		const text = await this.generateText(this.prompt, messages, {
			maxSteps: 20,
			toolChoice: 'auto',
		});

		if (text.length > 100) {
			this.updateProgress('polishing', 'Structuring and formatting story...');
			return this.polishStory(text);
		}

		throw new Error('Generated story text is too short');
	}

	/**
	 * Generate a summary of content (like an SEC filing)
	 */
	private async generateSummary(
		content: string,
		context: { filingType?: string; filingDate?: string; },
	): Promise<string> {
		return this.generateText(
			'You are a professional financial analyst. Summarize the given SEC filing content concisely.',
			[{
				role: 'user',
				content: [
					`Please summarize this ${context.filingType || 'SEC filing'} from ${context.filingDate || 'today'}:`,
					'',
					content,
				].join('\n'),
			}],
		);
	}

	/**
	 * Polish and structure a story from raw text
	 */
	protected async polishStory(text: string): Promise<Partial<Story>> {
		this.updateProgress('polishing', 'Extracting story components...');
		const result = await this.generateObject(
			GeneratedStorySchema,
			'You are a professional editor. Structure the given story text into a proper format, making sure to keep all links and markdown formatting.',
			[{
				role: 'user',
				content: [
					'Please structure this story into a format with headline, excerpt, content, and categories.',
					'',
					text,
				].join('\n'),
			}],
		);

		this.updateProgress('polishing', 'Formatting content...');
		// Convert markdown to HTML
		const htmlContent = await Promise.resolve(marked(result.text_content));

		return {
			...pick(result, ['headline', 'excerpt', 'provider_categories', 'company_tickers']),
			html_content: htmlContent,
		};
	}

	/**
	 * Clean an existing story with specific instructions
	 */
	async cleanStory(story: Partial<Story>, instructions: string): Promise<Partial<Story>> {
		this.updateProgress('polishing');

		try {
			const structured = await this.generateObject(
				GeneratedStorySchema,
				instructions,
				[{
					role: 'user',
					content: [
						'Please structure this story into a proper format with headline, excerpt, content, and categories.',
						'**IMPORTANT**: Keep all links in the story.',
						'Respond with ONLY a JSON object matching this schema:',
						JSON.stringify(GeneratedStorySchema.shape, null, 2),
						'',
						'Current story:',
						JSON.stringify(pick(story, [
							'headline',
							'excerpt',
							'text_content',
							'provider_categories',
							'company_tickers',
						]), null, 2),
					].join('\n'),
				}],
			);
			const htmlContent = await Promise.resolve(marked(structured.text_content));
			return {
				...structured,
				html_content: htmlContent,
			};
		} catch (err) {
			throw new Error(`Failed to clean story: ${err instanceof Error ? err.message : String(err)}`);
		}
	}
}
