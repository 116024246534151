import type { SearchResults } from '@newstex/search';
import React, { useEffect, useRef, useState } from 'react';

import SparklineComponent, { SparklineData } from './SparklineComponent';

interface SearchCountTimelineProps {
	facetName?: string;
	results?: SearchResults<any>;
}

export const SearchCountTimeline: React.FC<SearchCountTimelineProps> = ({ facetName, results }) => {
	const [searchStats, setSearchStats] = useState<SparklineData[]>([]);
	const centerRef = useRef<HTMLDivElement>(null); // Reference to the center element
	const [width, setWidth] = useState<number>(200); // State to store the width

	useEffect(() => {
		if (facetName && results) {
			const facetResults = results.facets?.[facetName]?.sort((a: any, b: any) => a.name?.localeCompare(b.name || ''));

			if (
				// Not undefined
				facetResults != null
				// Is an array of facets
				&& Array.isArray(facetResults)
				// Has at least one result
				&& facetResults?.length
			) {
				const dataValues: SparklineData[] = [];

				for (const item of facetResults) {
					dataValues.push({
						name: facetName,
						date: item.name,
						value: item.count,
					});
				}
				console.log(dataValues);
				setSearchStats(dataValues);
			}
		} else {
			console.log('No Search States', facetName);
			setSearchStats([]);
		}
		const updateWidth = () => {
			if (centerRef.current) {
				setWidth(centerRef.current.offsetWidth); // Update the width based on the current element's offsetWidth
			}
		};

		updateWidth(); // Update width on mount

		window.addEventListener('resize', updateWidth); // Update width on window resize

		return () => window.removeEventListener('resize', updateWidth); // Cleanup listener on component unmount
	}, [facetName, results, centerRef]);

	return <center ref={centerRef}>
		<SparklineComponent
			showTooltip={true}
			width={width}
			data={searchStats}
		/>
	</center>;
};
