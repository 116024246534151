/*
 * Frontend specific utility functions
 */

export function sleep(ms: number) {
	return new Promise((resolve) => {
		setTimeout(resolve, ms);
	});
}

export function convertToDate(date: Date | string | number): Date {
	if (typeof date === 'number') {
		if (date < 10000000000) {
			// Unix timestamp in seconds
			return new Date(date * 1000);
		}
		return new Date(date);
	}

	if (typeof date === 'string') {
		return new Date(date);
	}
	return date;
}

export function formatDateString(date: Date | string | number, options?: Intl.DateTimeFormatOptions) {
	if (!date) {
		return '';
	}
	return convertToDate(date).toLocaleString('en-US', {
		// Default to Eastern time
		timeZone: 'America/New_York',
		...(options || {}),
	});
}

export function formatDateYYYYMMDD(date: Date | string | number) {
	if (!date) {
		return '';
	}
	const d = convertToDate(date);
	const year = d.getFullYear();
	const month = String(d.getMonth() + 1).padStart(2, '0');
	const day = String(d.getDate()).padStart(2, '0');
	return `${year}-${month}-${day}`;
}
