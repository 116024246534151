/**
 * Wait for a certain period of time
 *
 * @param ms Milliseconds to wait
 */
export async function sleep(ms: number) {
	return new Promise((resolve) => {
		setTimeout(resolve, ms);
	});
}
