import { useEffect, useState } from 'react';
import { useAPI } from '~/providers/api-provider';

import { hasPermission } from '../require-permissions';

interface UserLinkProps {
	id: string;
}

const UserLink: React.FC<UserLinkProps> = ({ id }) => {
	if (!hasPermission(['admin'])) {
		return <></>;
	}

	if (!id) {
		return <></>;
	}

	if (id.match(/^[0-9]{1,5}$/)) {
		return <span>Invalid ID: {id}</span>;
	}

	const api = useAPI();
	const [userName, setUserName] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		const fetchUserName = async () => {
			setLoading(true);
			try {
				const resp = await api.fetchWithAuth(`resources/User/${id}`);
				if (resp?.items?.[0]?.name) {
					setUserName(resp.items[0].name);
				}
			} catch (err) {
				console.error(err);
			} finally {
				setLoading(false);
			}
		};

		fetchUserName();
	}, [id, api]);

	if (loading) {
		return <span className="text-muted">Loading...</span>;
	}

	return <i>{userName || `Unknown User (${id})`}</i>;
};

export default UserLink;
