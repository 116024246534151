import type { Category } from './category';
import type { Publication } from './content';
import type { ISO8601Timestamp, UnixTimestamp } from './dates';
import type { Feed } from './feed';
import type { Product } from './product';
import type { Reference, ReferenceString } from './reference';
import type { Ticker } from './ticker';

/**
 * A single story (piece of content)
 *
 * TableName: Story
 * @author: Chris Moyer <cmoyer@newstex.com>
 */
export interface Story {
	__type__: 'Story';
	__id__: string;

	/**
	 * Date to purge the record (DynamoDB TTL)
	 */
	purge_by?: UnixTimestamp;

	/**
	 * Headline/title of the story
	 */
	headline: string;

	/**
	 * Normalized headline (for searching purposes)
	 * @calculated
	 * @see `packages/classify/normalize.normalizeHeadline`
	 */
	normalized_headline?: string

	// URL to the story
	permalink?: string;

	// Short summary
	excerpt?: string;

	// Full text-version of the story
	text_content?: string;

	// HTML version of the story
	html_content?: string;

	// Language this story is written in
	language?: string;

	// Optional URL to a thumbnail image for this story
	thumb_url?: string;

	// For media types
	content_type?: string;
	video_url?: string;

	// Author
	source?: string;

	// Publication Type
	pub_type?: string;

	// ID of the Publication that authored this story
	publication?: ReferenceString<Publication>;

	// Newstex IDs of all content associated with this story
	// Used for search indexing (includes both the publisher and publication newstex_id)
	content?: string[]

	// Publication-provided unique ID for this story
	external_id?: string;

	// Products
	products?: ReferenceString<Product>[];

	// Tickers from the publisher
	provider_tickers?: ReferenceString<Ticker>[];
	// Tickers we identified
	company_tickers?: ReferenceString<Ticker>[];

	// Publication-provided categories
	provider_categories?: string[];
	// Newstex identified categories
	categories?: ReferenceString<Category>[];
	// Scores for each category with matching markers
	category_scores?: Record<string, number>;

	// Version number
	version?: number;
	// Active if this is a live story
	status?: 'Standby' | 'Active' | 'Removed';

	// Publication date
	date?: UnixTimestamp;
	// Publication updated date
	updated?: UnixTimestamp;

	// Date created
	created_at?: UnixTimestamp
	// Date Modified
	modified_at?: UnixTimestamp

	// Date Received
	// NOTE: This can not be a UnixTimestamp since the story DB has this as a string index
	received_at?: ISO8601Timestamp

	// Last time this record was updated by ANYTHING
	sys_modstamp?: UnixTimestamp;

	// MD5 Hash of the raw story (used for update detection)
	original_hash?: string;

	// Feed this was generated from
	feed?: Reference<Feed>;

	// AI Qualification scores
	ai_scores?: Record<string, number>;

	// S3 path to store files for this story
	path?: string;

	// Story body stats
	stats?: {
		character_count: number,
		line_count: number,
		word_count: number,
		sentiment_score?: number,
	};

	/**
	 * AI Embedding for the story
	 */
	embedding?: number[];

	// Joined fields from Searches in TypeSense
	Publication?: Partial<Publication>;
}

export interface StoryReference extends Partial<Story> {
	s3: {
		bucket: string,
		key: string,
	},
}

export function isStory(obj: any, tableName?: string): obj is Story {
	return obj?.__type__ === 'Story' || tableName === 'Story';
}
