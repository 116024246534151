const Icon = (props: any) => (
	<svg
		className={`svg-icon ${props.className ? props.className : ''}`}
		style={props.style}
	>
		<use xlinkHref={`#${props.icon}`} />
	</svg>
);

export default Icon;
