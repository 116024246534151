import { title as titleCase } from 'case';
import { TypeNameToTypeMap, InferType } from '@newstex/types/infer-types';

interface PropertyDisplayNameProps<T extends keyof TypeNameToTypeMap> {
	propKey: keyof InferType<T>;
	typeName: T;
}

const DISPLAY_NAME_MAP: Record<string, Record<string, string | React.ReactNode>> = {
	Feed: {
		ft_process: 'Extract Full Text',
		ft_max_stories: 'Max FT Extracted Stories',
		use_h2: 'Use HTTP/2 Protocol',
		use_cloudscraper: 'Use CloudScraper',
		use_ftfeed: (
			<>
				<div>Use FT Feed API</div>
				<span className="text-muted small ps-1">(bypass bot protection)</span>
			</>
		),
		feed_uri: 'Feed URL',
	},
	Publication: {
		xml_feed: 'Content Feed URL',
		country: 'Country Focus',
		pub_country: 'Country of Origin',
	},
	RAG: {
		questions: 'Questions',
	},
	Alert: {
		emails: 'Alert Recipients',
	},
};

export function getDisplayName<T extends keyof TypeNameToTypeMap>(key: keyof InferType<T>, typeName: T) {
	return DISPLAY_NAME_MAP[String(typeName)]?.[String(key)] || titleCase(String(key));
}

/**
 * Render the "Name" for a property
 */
export default function PropertyDisplayName<T extends keyof TypeNameToTypeMap>({
	typeName,
	propKey,
}: PropertyDisplayNameProps<T>) {
	return <span key={String(propKey)}>{getDisplayName(propKey, typeName)}</span>;
}
