import { faPlay, faSync } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Spinner } from 'react-bootstrap';
import { LeadData } from '~/providers/api-provider';

interface QualificationButtonsProps {
	items: LeadData[];
	running: boolean;
	syncAll: () => void;
	runQualification: () => void;
	nextPage?: () => void;
}

export function QualificationButtons({
	items,
	running,
	syncAll,
	runQualification,
	nextPage,
}: QualificationButtonsProps) {
	const readyToSyncCount = items.filter((item) => item.sync_status === 'Ready').length;
	const unqualifiedCount = items.filter((item) => item.qualified == null).length;
	const showNextPage = nextPage && unqualifiedCount === 0;
	const showSyncAll = readyToSyncCount > 0 && unqualifiedCount === 0;

	if (showNextPage) {
		return (
			<Button
				onClick={nextPage}
				variant="primary"
				className="ms-2 float-end"
			>
				Next Page →
			</Button>
		);
	}

	if (showSyncAll) {
		return (
			<Button
				onClick={syncAll}
				disabled={running}
				variant="success"
				className="ms-2 float-end"
			>
				<FontAwesomeIcon icon={faSync} /> Sync All
			</Button>
		);
	}

	return (
		<Button
			onClick={runQualification}
			disabled={running || items.length === 0 || unqualifiedCount === 0}
			variant="success"
			className="ms-2 float-end"
		>
			{running ? <Spinner animation="border" size="sm" /> : <FontAwesomeIcon icon={faPlay} />} Run
			({unqualifiedCount})
		</Button>
	);
}
