import { toast } from 'react-toastify';

interface CacheStore {
	// Core storage operations
	setItem: <T = string>(key: string, value: T, required?: boolean) => void;
	getItem: <T = string>(key: string) => T | null;
	removeItem: (key: string) => void;
	clear: () => void;

	// Memory fallback when localStorage fails
	memoryStore: Map<string, any>;
}

/**
 * Attempts to request more storage quota from the browser
 * Returns true if successful
 */
async function requestStorageQuota(): Promise<boolean> {
	try {
		if ('storage' in navigator && 'persist' in navigator.storage) {
			await navigator.storage.persist();
			return true;
		}
	} catch (e) {
		console.warn('Failed to request storage quota:', e);
	}
	return false;
}

/**
 * Checks if we have enough storage space for the given value
 * Returns true if we do, false if we don't
 */
async function checkStorageQuota(value: string): Promise<boolean> {
	try {
		if ('storage' in navigator && 'estimate' in navigator.storage) {
			const estimate = await navigator.storage.estimate();
			// Add 10% buffer to the value size for safety
			const requiredBytes = value.length * 2 * 1.1;
			return (estimate.quota || 0) - (estimate.usage || 0) > requiredBytes;
		}
	} catch (e) {
		console.warn('Failed to check storage quota:', e);
	}
	return true; // Assume we have space if we can't check
}

const memoryStore = new Map<string, any>();

const CacheStore = {
	setItem: async <T = string>(key: string, value: T, required = false) => {
		try {
			// Try localStorage first
			localStorage.setItem(key, JSON.stringify(value));
		} catch (e) {
			console.warn('localStorage.setItem failed:', e);

			// Check if it's a quota error
			if (e instanceof Error && e.name === 'QuotaExceededError') {
				// Try to request more storage
				const gotMoreStorage = await requestStorageQuota();
				if (gotMoreStorage) {
					try {
						localStorage.setItem(key, JSON.stringify(value));
						return;
					} catch (e2) {
						console.warn('localStorage.setItem still failed after quota increase:', e2);
					}
				}

				// Check if we'll have space after cleanup
				const hasSpace = await checkStorageQuota(JSON.stringify(value));
				if (hasSpace) {
					// Try to free up space by removing old items
					try {
						// Remove items older than 30 days
						const now = Date.now();
						for (let i = 0; i < localStorage.length; i++) {
							const key = localStorage.key(i);
							if (key) {
								try {
									const item = localStorage.getItem(key);
									if (item) {
										const data = JSON.parse(item);
										if (data && data.timestamp && now - data.timestamp > 30 * 24 * 60 * 60 * 1000) {
											localStorage.removeItem(key);
										}
									}
								} catch (e) {
									// Skip items we can't parse
								}
							}
						}
						// Try storing again
						localStorage.setItem(key, JSON.stringify(value));
						return;
					} catch (e3) {
						console.warn('localStorage.setItem still failed after cleanup:', e3);
					}
				}
			}

			if (required) {
				// Try storing in sessionStorage
				sessionStorage.setItem(key, JSON.stringify(value));
				return;
			}

			// Fall back to memory store
			memoryStore.set(key, value);
			toast.warning('Local storage is full - some data will not persist between page refreshes');
		}
	},

	getItem: <T = string>(key: string) => {
		try {
			// Try localStorage first
			const value = localStorage.getItem(key);
			try {
				if (value !== null) return JSON.parse(value) as T;
			} catch (e) {
				console.warn('Failed to parse JSON for key', { key, value, error: e });
				return value as T;
			}
		} catch (e) {
			console.warn('localStorage.getItem failed:', e);
		}

		try {
			const value = sessionStorage.getItem(key);
			if (value) return JSON.parse(value) as T;
		} catch (e) {
			console.warn('sessionStorage.getItem failed:', e);
		}

		// Fall back to memory store
		const memoryValue = memoryStore.get(key);
		if (memoryValue) return memoryValue as T;
		return null;
	},

	removeItem: (key: string) => {
		try {
			localStorage.removeItem(key);
		} catch (e) {
			console.warn('localStorage.removeItem failed:', e);
		}
		try {
			sessionStorage.removeItem(key);
		} catch (e) {
			console.warn('sessionStorage.removeItem failed:', e);
		}
		memoryStore.delete(key);
	},

	clear: () => {
		try {
			localStorage.clear();
		} catch (e) {
			console.warn('localStorage.clear failed:', e);
		}
		try {
			sessionStorage.clear();
		} catch (e) {
			console.warn('sessionStorage.clear failed:', e);
		}
		memoryStore.clear();
	},
};

export function useCacheStore() {
	return CacheStore;
}
