import { faAlignLeft, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useCommandBar } from '~/providers/command-bar';
import { useUserInfo } from '~/providers/user-info';

import UserMenu from './UserMenu';

export interface HeaderProps {
	setSidebarShrink: React.Dispatch<React.SetStateAction<boolean>>;
	sidebarShrink?: boolean;
}

export default function Header({ setSidebarShrink, sidebarShrink }: HeaderProps) {
	const commandBar = useCommandBar();
	const userInfo = useUserInfo();

	return (
		<header className="header">
			<Navbar
				bg="white"
				expand="lg"
				className="px-4 py-2 shadow"
			>
				<a
					className="sidebar-toggler text-gray-500 me-4 me-lg-5 lead"
					href="#"
					onClick={() => setSidebarShrink(!sidebarShrink)}
				>
					<FontAwesomeIcon icon={faAlignLeft} />
				</a>
				<Link to="/">
					<Navbar.Brand className="fw-bold text-uppercase text-base">
						<span className="d-none d-brand-partial">Newstex</span>{' '}
						<span className="d-none d-sm-inline">Admin</span>
						{window.location.hostname !== 'admin.newstex.com' && (
							<span className="badge bg-warning text-dark ms-2">Dev</span>
						)}

					</Navbar.Brand>
				</Link>
				<div className="ms-auto d-flex align-items-center mb-0">
					{userInfo?.auth_groups?.includes('admin') && (
						<a
							className="text-gray-500 me-2 me-lg-3 lead"
							title="Search"
							href="#"
							onClick={() => commandBar.show()}
						>
							<FontAwesomeIcon icon={faSearch} />
						</a>
					)}
					<UserMenu />
				</div>
			</Navbar>
		</header>
	);
}
