/**
 * Client ID / Name mapping
 *
 * TODO: Move this to the DB somewhere?
 */
export const CLIENTS = {
	acquiremedia: 'Acquire Media',
	ccc: 'CCC',
	cla: 'CLA',
	industry_dive: 'Industry Dive',
	lexisnexis: 'LexisNexis',
	proquest: 'Proquest',
	refinitiv: 'Refinitiv',
	syndigate: 'Syndigate',
	thomson: 'Thomson Reuters',
	cengage: 'Cengage Gale',
} as const;
export async function getClients() {
	return CLIENTS;
}
export async function getClientName(clientId: string) {
	return CLIENTS[clientId];
}
export async function getClientId(clientName: string) {
	return Object.keys(CLIENTS).find((key) => CLIENTS[key] === clientName);
}
