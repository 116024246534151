export interface Time {
	hour: number;
	minute: number;
}

/**
 * Parse a time string in 12-hour format
 */
export function parseTime(timeStr: string): Time {
	const match = timeStr.toLowerCase().match(/^(\d{1,2})(?::(\d{2}))?\s*(am|pm)$/);
	if (!match) {
		throw new Error(`Invalid time format: ${timeStr}`);
	}

	const [, hours, minutes, meridiem] = match;
	let hour = parseInt(hours, 10);

	// Convert to 24-hour format
	if (meridiem === 'pm' && hour !== 12) {
		hour += 12;
	} else if (meridiem === 'am' && hour === 12) {
		hour = 0;
	}

	return {
		hour,
		minute: minutes ? parseInt(minutes, 10) : 0,
	};
}

/**
 * Convert 24-hour time to 12-hour format with am/pm
 */
export function formatTime(hour: number, minute: number = 0): string {
	const meridiem = hour >= 12 ? 'pm' : 'am';
	let hour12 = hour % 12;
	if (hour12 === 0) hour12 = 12;

	if (minute === 0) {
		return `${hour12}${meridiem}`;
	}
	return `${hour12}:${minute.toString().padStart(2, '0')}${meridiem}`;
}
