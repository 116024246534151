import { Story } from '@newstex/types/story';
import { useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot } from '@fortawesome/free-solid-svg-icons';

import { GenerateStoryModal } from './modals/generate-story-modal';
import { RequirePermissions } from '../require-permissions';

interface GenerateStoryButtonProps {
	disabled?: boolean;
	initialTopic?: string;
	stories?: Story[];
	searchQuery?: string;
}

export function GenerateStoryButton({
	disabled,
	initialTopic,
	stories,
	searchQuery,
}: GenerateStoryButtonProps) {
	const [showGenerateModal, setShowGenerateModal] = useState(false);

	// Don't show the button if we don't have any stories or a search query
	if (!stories?.length && !searchQuery && !initialTopic) {
		return null;
	}

	return (
		<RequirePermissions permissions={['admin']}>
			<OverlayTrigger
				placement="top"
				overlay={
					<Tooltip>
						Generate a story from {stories?.length ? `${stories.length} search results` : 'scratch'}
					</Tooltip>
				}
			>
				<Button
					className="me-2"
					size="sm"
					variant="outline-primary"
					disabled={disabled}
					onClick={() => setShowGenerateModal(true)}
				>
					<FontAwesomeIcon icon={faRobot} className="me-1" />
					Generate Story
				</Button>
			</OverlayTrigger>

			{showGenerateModal && (
				<GenerateStoryModal
					show={true}
					onClose={() => {
						setShowGenerateModal(false);
					}}
					initialTopic={initialTopic}
					stories={stories}
					searchQuery={searchQuery}
				/>
			)}
		</RequirePermissions>
	);
}
