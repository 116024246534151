import { SearchIndexName } from '@newstex/search/client';
import { tool } from 'ai';
import { z } from 'zod';

import type { AIToolsContext } from '.';

export function searchTypeSenseFactory(context: AIToolsContext) {
	const currentTimestamp = Math.floor(new Date().getTime() / 1000);
	return tool({
		description: [
			'IMPORTANT: ALWAYS SEARCH THE KNOWLEDGE BASE USING `searchKnowledgeBase` BEFORE USING THIS TOOL.',
			'',
			'Search TypeSense for Stories, Publishers, Publications, Deliveries, Categories, or Feeds.',
			'',
			'Search results are limited to 10 records at a time.',
			'',
			'Search TypeSense for Stories, Publishers, Publications, Deliveries, Categories, or Feeds.',
			'',
			'When filtering by date, always use unix timestamps in seconds.',
			'',
			`The current date and time is ${currentTimestamp}.`,
			`Yesterday was ${currentTimestamp - 24 * 60 * 60}.`,
			'Example searching for stories published by StockPulse today (within the last 24 hours):',
			'```',
			'collectionName: stories',
			'filter_by: content:"STOK"',
			`date:>${currentTimestamp - 24 * 60 * 60}`,
			'```',
			'',
			'',
			'Available collections:',
			'- Story',
			'- Publisher',
			'- Publication',
			'- Product',
			'- Delivery',
			'- Category',
			'- Feed',
			'- HubSpot',
			'*** ALWAYS CONSULT YOUR KNOWLEDGE BASE FOR MORE DETAILS ON SPECIFIC COLLECTIONS AND SEARCH SYNTAX ***',
		].join('\n'),
		parameters: z.object({
			collectionName: z.enum([
				'Story',
				'Publisher',
				'Publication',
				'Product',
				'Delivery',
				'Category',
				'Feed',
				'HubSpot',
				'RAG',
			]).describe('The collection name to search'),
			query: z.string().describe('The plain text search query. Use "*" to match any result.'),
			filter_by: z.string().optional().describe('The filters to apply to the search, using TypeSense filter_by string syntax.'),
			facet_by: z.string().optional().describe('The facets to apply to the search, using TypeSense facet_by string syntax.'),
			sort_by: z.string().optional().describe('The field to sort the results by, using TypeSense sort_by string syntax.'),
			per_page: z.number().optional().describe('The number of results to return per page.'),
			page: z.number().optional().describe('The page number to return (defaults to 1).'),
		}),
		execute: async ({
			collectionName,
			query,
			filter_by,
			facet_by,
			sort_by,
			per_page,
			page,
		}) => {
			context.logger?.debug('Searching', {
				collectionName,
				query,
				filter_by,
				facet_by,
				sort_by,
				per_page,
				page,
			});
			if (context.onToolCall) {
				context.onToolCall('searchTypeSense', {
					collectionName,
					query,
					filter_by,
					facet_by,
					sort_by,
					per_page,
					page,
				});
			}
			try {
				const results = await context.searchClient.search({
					indexName: collectionName as SearchIndexName,
					query,
					filter_by,
					facet_by,
					sort_by,
					per_page,
					page,
				});
				context.logger?.debug('Search results', { results });
				if (results.found === 0) {
					return `No Results Found for ${collectionName} with query "${query}"`;
				}
				return results;
			} catch (e: any) {
				context.logger?.error('Error searching', e);
				return `Error searching: ${String(e.message || e.msg || e)}`;
			}
		},
	});
}
