/* eslint-disable no-nested-ternary */
import { faFacebook, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import {
	faDownload,
	faExternalLinkAlt,
	faPause,
	faTrash,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { QualificationScores } from '@newstex/ai/qualify';
import { createColumnHelper } from '@tanstack/react-table';
import { kebab as kebabCase } from 'case';
import { useMemo, useRef } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useAnalytics } from '~/providers/analytics-provider';
import { LeadData } from '~/providers/api-provider';
import { useConfirm } from '~/providers/confirm';

import '~/scss/qualify-leads.scss';

import DataTable, { DataTableMethods } from '../DataTable';
import { HubSpotLink } from '../hubspot-link';

import { QualificationButtons } from './qualification-button';

interface LeadQualificationTableProps {
	items: LeadData[];
	running: boolean;
	abortController: AbortController;
	runQualification: () => void;
	clearData: () => void;
	pause: () => void;
	className?: string;
	showScores: boolean;
	syncAll: () => void;
	syncLead: (lead: LeadData) => void;
	nextPage?: () => void;
}

export function AIRankingReasons({ reasons }: { reasons: QualificationScores }) {
	return <ul>
		{Object.entries(reasons).map(([key, value]) => (
			<li key={key}><b>{key} ({value?.score || '0'}):</b> {value?.reason}</li>
		))}
	</ul>;
}

export const LeadQualificationTable = ({
	className,
	items,
	running,
	abortController,
	runQualification,
	clearData,
	showScores,
	pause,
	syncAll,
	syncLead,
	nextPage,
}: LeadQualificationTableProps) => {
	const analytics = useAnalytics();
	const { confirmDialog } = useConfirm();
	const dataTableRef = useRef<DataTableMethods>(null);
	const columnHelper = createColumnHelper<LeadData>();

	const columns = useMemo(() => [
		columnHelper.accessor('sync_status', {
			header: 'Sync Status',
			size: 10,
			cell: ({ getValue, row: { original }}) => {
				const value = getValue();
				return <>
					<div className={`sync-status-${kebabCase(value || '')}`}>
						{value}
						{original.hubspot_id && <> <HubSpotLink id={original.hubspot_id} /></>}
					</div>
				</>;
			},
		}),
		columnHelper.accessor('url', {
			header: 'URL',
			size: 50,
			cell: ({ getValue, row: { original }}) => {
				const value = getValue();
				return (<>
					<div className="url" style={{
						maxWidth: '500px',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
					}}>
						<a href={value} target="_blank" rel="noreferrer">{value}</a>
					</div>
					{original.feed_url && <div className="feed-url" style={{
						maxWidth: '500px',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'wrap',
					}}>
						<a
							href={original.feed_url}
							className="text-muted"
							target="_blank"
							rel="noreferrer"
						>
							{original.feed_url}
							<FontAwesomeIcon className="ms-1" icon={faExternalLinkAlt} />
						</a>
					</div>}
				</>);
			},
		}),
		columnHelper.accessor('name', {
			header: 'Name',
			size: 50,
			cell: ({ getValue, row: { original }}) => {
				const value = getValue();
				return (<>
					{original.hubspot_records?.[0]?.name ? (
						<div className="hubspot-name">
							<a
								href={`https://app.hubspot.com/contacts/20613241/record/0-2/${original.hubspot_records?.[0]?.hs_object_id || original.hubspot_records?.[0]?.id}`}
								target="_blank"
								rel="noreferrer"
							>
								{original.hubspot_records?.[0]?.name.slice(0, 100)} {original.hubspot_records?.[0]?.name.length > 100 ? '...' : ''}
							</a>
						</div>
					) : (
						<div className="name">{value}</div>
					)}
				</>);
			},
		}),
		columnHelper.accessor('qualified', {
			header: 'Tech Qualified',
			size: 10,
			cell: ({ getValue }) => {
				const value = getValue();
				return value == null ? '' : (value ? 'Yes' : 'No');
			},
		}),
		...(showScores ? [
			columnHelper.accessor('max_ai_score', {
				header: 'AI Score',
				size: 10,
			}),
			columnHelper.accessor((row) => {
				if (row.qualified && row.ai_rankings) {
					return row.ai_rankings;
				}
				return row.rejection_reasons?.join(', ');
			}, {
				header: 'Score Reason',
				id: 'score_reason',
				size: 20,
				cell: ({ getValue, row: { original }}) => {
					if (original.qualified && original.ai_rankings) {
						return <AIRankingReasons reasons={original.ai_rankings} />;
					}
					return <ul>
						{original.rejection_reasons?.map((reason) => <li key={reason}>{reason}</li>)}
					</ul>;
				},
			}),
		] : []),
		columnHelper.accessor((row) => row.stats?.average_word_count?.toFixed(0), {
			header: 'Average Word Count',
			id: 'avg_word_count',
			size: 10,
		}),
		columnHelper.accessor((row) => {
			return Object.entries(row.newstex_categories || {})
				.filter(([, value]) => value > 10)
				.map(([key]) => key)
				.join(', ');
		}, {
			header: 'Possible Categories',
			id: 'categories',
			size: 20,
		}),
		columnHelper.accessor('last_post_date', {
			header: 'Last Post Date',
			size: 20,
		}),
		columnHelper.accessor((row) => {
			return {
				twitter: row.twitter,
				facebook: row.facebook,
				linkedin: row.linkedin,
			};
		}, {
			header: 'Social Media',
			id: 'social',
			size: 20,
			cell: ({ getValue }) => {
				const value = getValue();
				if (!value) {
					return '';
				}
				return <div className="social-media">
					{value.twitter && <a
						className="twitter ms-1"
						href={`https://twitter.com/${value.twitter}`}
						target="_blank"
						rel="noreferrer"
					>
						<FontAwesomeIcon icon={faTwitter} />
					</a>}
					{value.facebook && <a
						className="facebook ms-1"
						href={value.facebook}
						target="_blank"
						rel="noreferrer"
					>
						<FontAwesomeIcon icon={faFacebook} />
					</a>}
					{value.linkedin && <a
						className="linkedin ms-1"
						href={value.linkedin}
						target="_blank"
						rel="noreferrer"
					>
						<FontAwesomeIcon icon={faLinkedin} />
					</a>}
				</div>;
			},
		}),
		columnHelper.accessor('email', {
			header: 'Email',
		}),
	], [showScores]);

	const exportToCSV = () => {
		analytics?.trackEvent('Export Qualified Leads', {}, items.length);
		dataTableRef.current?.exportToCSV<LeadData>('Qualified Leads', {
			sync_status: (item) => item.sync_status || '',
			hubspot_id: (item) => item.hubspot_id || '',
			qualified: (item) => (item.qualified ? 'Yes' : 'No'),
			url: (item) => item.url || '',
			feed_url: (item) => item.feed_url || '',
			name: (item) => item.name || '',
			max_ai_score: (item) => String(item.max_ai_score || '0'),
			score_reason: (item) => item.rejection_reasons?.join(', ') || '',
			avg_word_count: (item) => String(item.stats?.average_word_count?.toFixed(0) || '0'),
			categories: (item) => Object.entries(item.newstex_categories || {})
				.filter(([, value]) => value > 10)
				.map(([key]) => key)
				.join(', '),
			last_post_date: (item) => item.last_post_date || '',
			hubspot: (item) => item.hubspot_records?.map((record) => `${record.name} (${record.hs_object_id || record.id})`).join(', ') || '',
			twitter: (item) => item.twitter || '',
			facebook: (item) => item.facebook || '',
			linkedin: (item) => item.linkedin || '',
			email: (item) => item.email || '',
		});
	};

	return (
		<>
			<Row className="mt-3 mb-3">
				<Col>
					<Button
						onClick={exportToCSV}
						disabled={running}
						variant="outline-secondary"
					>
						<FontAwesomeIcon icon={faDownload} /> Export to CSV
					</Button>
				</Col>
				<Col>
					{running ? (
						<Button
							onClick={(e) => {
								e.preventDefault();
								pause();
							}}
							disabled={abortController.signal.aborted}
							variant="warning"
							className="ms-2 float-end"
						>
							<FontAwesomeIcon icon={faPause} /> Pause
						</Button>
					) : (
						<Button
							onClick={async (e) => {
								e.preventDefault();
								if (await confirmDialog('Are you sure you want to clear the data?')) {
									clearData();
								}
							}}
							variant="danger"
							className="ms-2 float-end"
						>
							<FontAwesomeIcon icon={faTrash} /> Clear
						</Button>
					)}

					<QualificationButtons
						items={items}
						running={running}
						syncAll={syncAll}
						runQualification={runQualification}
						nextPage={nextPage}
					/>
				</Col>
			</Row>
			<Row className={`mb-3 ${className}`}>
				<Col>
					<DataTable
						className="qualify-leads"
						ref={dataTableRef}
						columns={columns}
						items={items.toSorted((a, b) => {
							// Handle blank sync_status - move to bottom
							if (!a.sync_status && b.sync_status) return 1;
							if (a.sync_status && !b.sync_status) return -1;

							// Both are blank, maintain original order
							if (!a.sync_status && !b.sync_status) return 0;

							// Move non-updated status to top
							if (a.sync_status !== 'Updated' && b.sync_status === 'Updated') return -1;
							if (a.sync_status === 'Updated' && b.sync_status !== 'Updated') return 1;

							// Both are same category, maintain original order
							return 0;
						})}
						sortable={false}
						tableRowClassNameHelper={({ original }) => {
							let rowClassName = original.sync_status ? `sync-status-${kebabCase(original.sync_status)}` : '';
							if (original.qualified != null) {
								rowClassName += original.qualified ? ' qualified' : ' not-qualified';
							}
							return rowClassName;
						}}
						striped
					/>
				</Col>
			</Row>
		</>
	);
};
