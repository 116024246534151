import { faDashboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useUserInfo } from '~/providers/user-info';

export interface ViewDashboardFilters {
	size: 'sm' | 'lg';
	id: string;
	tab?: string;
	publication?: string;
	content?: string;
	category?: string;
}

export default function ViewDashboardButton({ size, id, ...filters }: ViewDashboardFilters) {
	const userInfo = useUserInfo();
	if (!userInfo?.auth_groups?.includes('admin')) {
		return null;
	}
	const navigate = useNavigate();

	const routeToDashboard = () => {
		const queryParams = new URLSearchParams(filters);
		navigate(`/reports/${id}?${queryParams.toString()}`);
	};

	return (
		<Button size={size} variant="outline-secondary" onClick={routeToDashboard}>
			<FontAwesomeIcon icon={faDashboard} /> Dashboard
		</Button>
	);
}
