import { Alert, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface NotFoundProps {
	type: string;
	id?: string;
	message?: string;
}

export function NotFound({ type, id, message }: NotFoundProps) {
	return (
		<Container fluid>
			<div className="title-wrapper pt-30">
				<div className="row align-items-center">
					<div className="col-md-6">
						<div className="title">
							<h2>Not Found</h2>
						</div>
					</div>
				</div>
			</div>
			<hr />
			<Alert variant="warning">
				<Alert.Heading>404 Not Found</Alert.Heading>
				<p>
					{message || `The requested ${type}${id ? ` (${id})` : ''} could not be found.`}
				</p>
				<hr />
				<p className="mb-0">
					<Link to="/">Return to Dashboard</Link>
				</p>
			</Alert>
		</Container>
	);
}
