/*
 * Array Utilities
 */

/**
 * Returns an array of numbers from start to end, inclusive
 */
export function range(start: number, end: number): number[] {
	return Array.from({ length: end - start + 1 }, (_, i) => start + i);
}

/**
 * Returns true if the two arrays are equal, optionally ignoring order
 */
export function arrayEqual(arr1: any[], arr2: any[], ignoreOrder: boolean = false): boolean {
	if (arr1.length !== arr2.length) {
		return false;
	}

	if (ignoreOrder) {
		arr1 = [...arr1].sort();
		arr2 = [...arr2].sort();
	}

	for (let i = 0; i < arr1.length; i++) {
		if (arr1[i] !== arr2[i]) {
			return false;
		}
	}

	return true;
}
