import {
	faCode,
	faEllipsisV,
	faNewspaper,
	faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Story } from '@newstex/types/story';
import { useState } from 'react';
import { Dropdown, Form, Modal } from 'react-bootstrap';
import { useAPI } from '~/providers/api-provider';

import Preloader from '../Preloader';

export default function StoryActions({ story }: { story: Story }) {
	const [showXHTML, setShowXHTML] = useState(false);
	const [loading, setLoading] = useState(false);
	const [storyXHTML, setStoryXHTML] = useState<string>('');
	const api = useAPI();

	return (
		<>
			<div className="text-end">
				<Dropdown align="end">
					<Dropdown.Toggle variant="header-more">
						<FontAwesomeIcon icon={faEllipsisV} />
					</Dropdown.Toggle>

					<Dropdown.Menu className="text-sm">
						<Dropdown.Item href={`/stories/${story.__id__}`}>
							<FontAwesomeIcon
								icon={faNewspaper}
								className="me-2 opacity-5"
							/>
						View Story
						</Dropdown.Item>
						<Dropdown.Item onClick={() => {
							setLoading(true);
							setShowXHTML(true);
							api.fetchWithAuthRaw(`resources/Story/${story.__id__}/content.xhtml`).then((res) => {
								res.text().then((text) => {
									setStoryXHTML(text);
									setLoading(false);
								});
							}).catch((e) => {
								console.error(e);
								setLoading(false);
								setStoryXHTML(`ERROR: Could not load XHTML: ${e.message}}`);
							});
						}}>
							<FontAwesomeIcon
								icon={faCode}
								className="me-2 opacity-5"
							/>
						View XHTML
						</Dropdown.Item>
						<Dropdown.Item href="#!">
							<FontAwesomeIcon
								icon={faTrashAlt}
								className="me-2 opacity-5"
							/>
						Remove Story
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</div>
			<Modal
				size="xl"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={showXHTML}
				onHide={() => {
					setShowXHTML(false);
				}}
			>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">
						{story.__id__}.xhtml
					</Modal.Title>
				</Modal.Header>
				{loading
					? (
						<Modal.Body className="d-flex justify-content-center pt-5 pb-5" hidden={!loading}>
							<span className="visually-hidden">Loading...</span>
							<Preloader type="three-bounce" variant="success" />
						</Modal.Body>
					) : (
						<Modal.Body hidden={loading} className="p-0 m-0">
							<Form.Control
								as="textarea"
								value={storyXHTML}
								rows={40}
								readOnly
								style={{
									margin: 0,
									resize: 'none',
								}}
							/>
						</Modal.Body>
					)
				}
			</Modal>
		</>

	);
}
