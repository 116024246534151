import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { EarningsReportByClientAndPublication } from '@newstex/types/revenue';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import DataTable, { DataTableMethods } from '../DataTable';
import { LoadMoreButton } from '../load-more-button';

const CSV_EXPORT_CONFIG: Record<string, string | ((item: any) => string)> = {
	'Account ID': 'accountID',
	'Publisher Name': 'publisherName',
	'Publication Newstex ID': 'publicationNewstexID',
	'Publication Name': 'publicationName',
	Distributor: 'client',
	Period: 'period',
	Earnings: 'earnings',
};

export function RevenueTable(
	{
		revenue,
		searchText,
		hasMore,
		onLoadMore,
	}: {
		revenue: EarningsReportByClientAndPublication[],
		searchText?: string,
		hasMore?: boolean,
		onLoadMore?: () => void,
	},
) {
	const dataTableRef = useRef<DataTableMethods>(null);
	const columnHelper = createColumnHelper<EarningsReportByClientAndPublication>();
	const columns = useMemo(() => [
		columnHelper.accessor('accountID', {
			header: 'Account ID',
			enableSorting: true,
		}),
		columnHelper.accessor('publisherName', {
			header: 'Publisher Name',
			enableSorting: true,
			cell: ({ getValue, row: { original }}) => {
				const publisherName = getValue();
				if (!publisherName) {
					return '';
				}

				if (original.publisherNewscoreID) {
					return <Link to={`/publishers/${original.publisherNewscoreID}`}>{publisherName}</Link>;
				}
				return publisherName;
			},
		}),
		columnHelper.accessor('publicationNewstexID', {
			header: 'Publication Newstex ID',
			enableSorting: true,
		}),
		columnHelper.accessor('publicationName', {
			header: 'Publication Name',
			enableSorting: true,
			cell: ({ getValue, row: { original }}) => {
				const publicationName = getValue();
				if (!publicationName) {
					return '';
				}

				if (original.publicationNewscoreID) {
					return <Link to={`/publications/${original.publicationNewscoreID}`}>{publicationName}</Link>;
				}
				return publicationName;
			},
		}),
		columnHelper.accessor('client', {
			header: 'Distributor',
			enableSorting: true,
		}),
		columnHelper.accessor('period', {
			header: 'Period',
			enableSorting: true,
			cell: ({ getValue }) => {
				const period = getValue();
				if (!period) {
					return '';
				}
				return new Date(period).toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
			},
		}),
		columnHelper.accessor('earnings', {
			header: 'Earnings',
			enableSorting: true,
			cell: ({ getValue }) => {
				const totalEarnings = getValue();
				if (totalEarnings && typeof totalEarnings === 'number') {
					return totalEarnings.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
				}
				return '$0.00';
			},
		}),
	], [revenue]);

	return (<>
		<DataTable
			columns={columns}
			items={revenue || []}
			ref={dataTableRef}
			searchText={searchText}
			striped
			sortable
		/>
		<LoadMoreButton show={hasMore} onClick={onLoadMore} />
		<div className="float-end pb-2">
			<Button variant="outline-secondary" size="sm" onClick={() => {
				dataTableRef.current?.exportToCSV('Revenue', CSV_EXPORT_CONFIG);
			}}>
				<FontAwesomeIcon icon={faDownload} /> CSV ({revenue.length})
			</Button>
		</div>
	</>);
}
