import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button } from 'react-bootstrap';

import { CreateAlertModal } from './create-alert-modal';
import { hasPermission } from './require-permissions';

export function CreateAlertButton() {
	if (!hasPermission(['admin'])) {
		return null;
	}

	const [showCreateModal, setShowCreateModal] = useState(false);

	return (
		<>
			{showCreateModal && (
				<CreateAlertModal
					hide={() => {
						setShowCreateModal(false);
					}}
				/>
			)}

			<Button
				variant="outline-secondary"
				onClick={() => setShowCreateModal(true)}
				disabled={showCreateModal}
			>
				<FontAwesomeIcon icon={faPlus} />&nbsp;Create Alert
			</Button>
		</>
	);
}
