import { useEffect, useRef, useState } from 'react';
import {
	Button,
	Col,
	Container,
	Form,
	InputGroup,
	Row,
} from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import { InfoIcon } from './info-icon';

function TextInputFilter({ title, name, loading }: { title: string, name: string, loading?: boolean }) {
	const [searchParams, setSearchParams] = useSearchParams();

	return (
		<Row>
			<Col>
				<InputGroup className="mb-3">
					<InputGroup.Text>
						{title}
					</InputGroup.Text>
					<Form.Control
						placeholder={title}
						aria-label={title}
						aria-describedby={`${title}-filter`}
						disabled={loading}
						value={searchParams.get(name) || ''}
						onChange={(event) => {
							setSearchParams((params) => {
								if (event.target.value) {
									params.set(name, event.target.value);
								} else {
									params.delete(name);
								}
								return params;
							});
						}}
					/>
				</InputGroup>
			</Col>
		</Row>
	);
}

/**
 * Advanced Search Filters
 */
export function AdvancedSearchFilters({
	refineHook,
	refreshHook,
	loading = false,
	searchAsYouType = true,
	disableSearch = false,
}: {
	refineHook?: (query: string) => void;
	refreshHook?: () => Promise<void>;
	loading?: boolean;
	searchAsYouType?: boolean;
	disableSearch?: boolean;
}) {
	const formRef = useRef<HTMLFormElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const [searchParams, setSearchParams] = useSearchParams();
	const [inputValue, setInputValue] = useState(searchParams.get('q') || '');

	function refine(query: string) {
		if (!disableSearch) {
			setSearchParams((params) => {
				if (query) {
					params.set('q', query);
				} else {
					params.delete('q');
				}
				return params;
			});
		}
		refineHook?.(query);
	}

	function setQuery(newQuery: string) {
		setInputValue(newQuery);

		if (inputRef.current) {
			inputRef.current.value = newQuery;
		}

		if (searchAsYouType) {
			refine(newQuery);
		}
	}

	function onReset() {
		setQuery('');

		if (!searchAsYouType) {
			refine('');
		}
	}

	function onChange(event: React.ChangeEvent<HTMLInputElement>) {
		setInputValue(event.currentTarget.value);
		if (searchAsYouType) {
			refine(event.currentTarget.value);
		}
	}

	function onSubmit(event: React.KeyboardEvent<any>) {
		if (!searchAsYouType) {
			refine(inputValue);
		}
	}

	useEffect(() => {
		if (disableSearch) {
			if (inputValue) {
				setQuery('');
			}
			return;
		}
		const q = searchParams.get('q') || '';
		if (q !== inputValue) {
			setQuery(q);
		}
	}, [searchParams, inputRef.current, disableSearch]);

	return (
		<Container fluid>
			<Form ref={formRef}>
				<Row>
					<Col>
						<InputGroup className="mb-3">
							<InputGroup.Text style={{
								padding: 0,
							}}>
								<Form.Select
									aria-label="Index Name"
									style={{
										backgroundColor: 'transparent',
										border: 'none',
									}}
									onChange={(event) => {
										setSearchParams((params) => {
											if (event.target.value) {
												params.set('indexName', event.target.value);
											} else {
												params.delete('indexName');
											}
											return params;
										});
									}}
									value={searchParams.get('indexName') || 'Content'}
								>
									<option value="Content">Content</option>
									<option value="Feed">Feed</option>
									<option value="Story">Story</option>
									<option value="Category">Category</option>
									<option value="Delivery">Delivery</option>
									<option value="Product">Product</option>
								</Form.Select>
							</InputGroup.Text>
							<Form.Control
								placeholder={disableSearch ? 'Search Disabled' : 'Search Query'}
								aria-label="Search Query"
								aria-describedby="basic-addon1"
								autoFocus
								ref={inputRef}
								disabled={disableSearch || loading}
								onChange={onChange}
								onKeyDown={(event) => {
									if (event.key === 'Enter') {
										return onSubmit(event);
									}

									if (event.key === 'Escape') {
										return onReset();
									}
								}}
							/>
							<InputGroup.Text>
								<InfoIcon title="Search Query">
									<div>The query text to search for in the collection.</div>
									<div>
										Use <code>*</code> as the search string to return all documents.
										This is typically useful when used in conjunction with <code>filter_by</code>.
									</div>
									<div>
										For example, to return all documents that match a filter, use:
										<code>q=*&filter_by=num_employees:10</code>.
									</div>
									<div>
										To exclude words in your query explicitly, prefix the word with the
										<code>-</code> operator, e.g. <code>q: 'electric car -tesla'</code>.
									</div>

								</InfoIcon>
							</InputGroup.Text>
						</InputGroup>
					</Col>
				</Row>
				<TextInputFilter title="Filter By" name="filter_by" loading={loading} />
				<TextInputFilter title="Group By" name="group_by" loading={loading} />
				<TextInputFilter title="Include Fields" name="include_fields" loading={loading} />
				<Row>
					<Col>
						<center className="mb-2">
							<Button
								variant="outline-danger"
								size="sm"
								disabled={!searchParams.toString().length}
								onClick={() => {
									setSearchParams({});
									formRef.current?.reset();
								}}
							>Clear Filters</Button>
							{refreshHook && (
								<Button
									variant="outline-secondary"
									size="sm"
									className="ms-2"
									onClick={() => {
										refreshHook?.();
									}}
								>Refresh Results</Button>
							)}
						</center>
					</Col>
				</Row>
			</Form>
		</Container>
	);
}
