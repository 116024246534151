import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useAuth } from '~/providers/auth';

interface AssumeUserRoleModalProps {
	show: boolean;
	onHide: () => void;
}

const AssumeUserRoleModal: React.FC<AssumeUserRoleModalProps> = ({ show, onHide }) => {
	const [email, setEmail] = useState('');
	const auth = useAuth();

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		// Handle assume user role logic here
		console.log('Assuming user role for:', email);
		auth?.assumeUser?.(email);
		onHide();
	};

	return (
		<Modal show={show} onHide={onHide}>
			<Modal.Header closeButton>
				<Modal.Title>Assume User Role</Modal.Title>
			</Modal.Header>
			<Form onSubmit={handleSubmit}>
				<Modal.Body>
					<Form.Group className="mb-3" controlId="formEmail">
						<Form.Label>User Email</Form.Label>
						<Form.Control
							type="email"
							placeholder="Enter user email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							required
						/>
						<Form.Text className="text-muted">
							Enter the email address of the user to assume.
						</Form.Text>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={onHide}>
						Cancel
					</Button>
					<Button variant="primary" type="submit">
						Assume Role
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default AssumeUserRoleModal;
