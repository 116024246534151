import { Alert, Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import PageHeader from '~/components/PageHeader';

import { HubSpotCreateLeadPage } from './create-lead';

type HubSpotAction = 'create-lead' | undefined;

export function HubSpotToolsPage() {
	const { id } = useParams<{ id: HubSpotAction }>();

	return (
		id === 'create-lead' ? (
			<HubSpotCreateLeadPage />
		)
			: <Container fluid>
				<PageHeader title="HubSpot Tools" />
				<Alert variant="danger">
					Invalid action
				</Alert>
			</Container>
	);
}
