/* eslint-disable no-restricted-globals */
/**
 * Search Context Provider
 */
import { SearchClient } from '@newstex/search';
import React, {
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';

import { useUserInfo } from './user-info';

export interface SearchContextType {
	searchClient?: SearchClient;
	pending: boolean;
	setPending?: React.Dispatch<React.SetStateAction<boolean>>;
	query: string;
	setQuery?: React.Dispatch<React.SetStateAction<string>> | ((query: string) => void);
	filterParams: Record<string, string[]>;
	setFilterParams?:
	React.Dispatch<React.SetStateAction<Record<string, string[]>>>
	| ((params: Record<string, string[]>) => void);
	page: number;
	setPage: React.Dispatch<React.SetStateAction<number>> | ((page: number) => void);
	clear: () => void;
}

const SearchContext = createContext<SearchContextType>({
	pending: false,
	query: '',
	page: 1,
	setPage: () => {},
	filterParams: {},
	clear: () => {},
});

export function SearchProvider(props: { children: React.ReactNode }) {
	const [query, setQuery] = useState('');
	const [page, setPage] = useState(1);
	const [pending, setPending] = useState(false);
	const [searchClient, setSearchClient] = useState<SearchClient | undefined>(undefined);
	const [filterParams, setFilterParams] = useState<Record<string, string[]>>({});
	const userInfo = useUserInfo();
	useEffect(() => {
		if (userInfo?.search) {
			setSearchClient(new SearchClient(userInfo.search));
		}
	}, [userInfo]);

	useEffect(() => {
		if (document.location.search) {
			const search = new URLSearchParams(document.location.search);
			setQuery(search.get('query') || '');
			setPage(parseInt(search.get('page') || '1', 10));
		}
	}, [document.location.search]);

	return (
		<SearchContext.Provider
			value={{
				searchClient,
				pending,
				setPending,
				query,
				filterParams,
				setFilterParams,
				setQuery: (q: string) => {
					if (history) history.pushState({ query: 1 }, '', `?page=${page}&query=${encodeURIComponent(q)}`);
					return setQuery(q);
				},
				page,
				setPage: (p: number) => {
					if (history) history.pushState({ page: p }, '', `?page=${p}&query=${encodeURIComponent(query)}`);
					return setPage(p);
				},
				clear: () => {
					setPage(1);
					setQuery('');
				},
			}}
		>
			{props.children}
		</SearchContext.Provider>
	);
}

export function getFilterString(filters?: Record<string, string[]>) {
	if (!filters) {
		return;
	}
	return Object.entries(filters).map(([key, values]) => `(${values.map((value) => `${key}:"${value}"`).join(' || ')})`).join(' && ');
}

export function useSearch() {
	return useContext(SearchContext);
}
