import type { Product as ProductType } from '@newstex/types/product';

import type { CollectionFieldSchmaForObject } from './types';

export const Product: CollectionFieldSchmaForObject<ProductType> = [
	{
		name: 'name',
		type: 'string',
		store: true,
		defaultSearchOrder: 10,
	},
	{
		name: 'group',
		type: 'string',
		store: true,
		defaultSearchOrder: 20,
		facet: true,
		optional: true,
	},
	{
		name: 'username',
		type: 'string',
		store: true,
		defaultSearchOrder: 1,
		optional: true,
	},
	{
		name: 'kill_alert_emails',
		type: 'string[]',
		store: true,
		defaultSearchOrder: 2,
		optional: true,
	},
];
