import type { NewsletterSubscription } from '@newstex/types/newsletter-subscription';
import type { Results } from '@newstex/types/results';
import { title as titleCase } from 'case';
import Fuse from 'fuse.js';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import LoadingSpinner from '~/components/LoadingSpinner';
import { PageTitle } from '~/components/page-title';
import Preloader from '~/components/Preloader';
import { SearchBox } from '~/components/search-box';
import { SimpleFacetMenu } from '~/components/simple-facet-menu';
import { NewsletterSubscriptionsTable } from '~/components/tables/newsletter-subscriptions-table';
import { useAPI } from '~/providers/api-provider';

export const NewsletterSearchPage = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const api = useAPI();
	const [loading, setLoading] = useState<boolean>(false);
	const [nextToken, setNextToken] = useState<string>();
	const [allSubscriptions, setAllSubscriptions] = useState<NewsletterSubscription[]>([]);
	const [subscriptionCounts, setSubscriptionCounts] = useState<Record<string, number>>({});
	const [subscriptions, setSubscriptions] = useState<NewsletterSubscription[]>([]);

	async function loadMore(token?: string, refresh = false) {
		const resp = await api.fetchWithAuth<Results<NewsletterSubscription>>(
			`resources/NewsletterSubscription?limit=100&nextToken=${token || nextToken}`,
			refresh ? { cache: 'reload' } : undefined,
		);
		if (resp?.items?.length) {
			setAllSubscriptions((prev) => [
				...prev,
				...resp.items,
			]);
		}
		setNextToken(resp?.nextToken);
	}

	// Set Search Counts
	useEffect(() => {
		if (!nextToken && allSubscriptions.length) {
			const counts = allSubscriptions.reduce((acc, curr) => {
				if (curr.topic) {
					acc[curr.topic] = (acc[curr.topic] || 0) + 1;
				}
				return acc;
			}, {} as Record<string, number>);
			setSubscriptionCounts(counts);
		} else {
			setSubscriptionCounts({});
		}
	}, [allSubscriptions.length, nextToken]);

	// Filter Results
	useEffect(() => {
		if (!searchParams.get('topic') && !searchParams.get('status') && !searchParams.get('q')) {
			setSubscriptions(allSubscriptions);
		} else {
			const topicFilter = searchParams.get('topic');
			const statusFilter = searchParams.get('status');
			const filteredSubscriptions = allSubscriptions.filter((s) => {
				if (topicFilter && s.topic !== topicFilter) {
					return false;
				}

				if (statusFilter && s.status !== statusFilter) {
					return false;
				}
				return true;
			});
			if (searchParams.get('q')) {
				const searcher = new Fuse(filteredSubscriptions, {
					shouldSort: true,
					threshold: 0.6,
					location: 0,
					distance: 100,
					minMatchCharLength: 1,
					keys: [
						'name',
						'email',
						'topic',
					],
				});
				setSubscriptions(searcher
					.search(searchParams.get('q') || '')
					.map((result) => result.item));
			} else {
				setSubscriptions(filteredSubscriptions);
			}
		}
	}, [allSubscriptions, searchParams]);

	const fetchData = async (refresh = false) => {
		setLoading(true);
		const resp = await api.fetchWithAuth<Results<NewsletterSubscription>>(
			'resources/NewsletterSubscription?limit=100',
			refresh ? { cache: 'reload' } : undefined,
		);
		setAllSubscriptions(resp?.items || []);
		setNextToken(resp?.nextToken);
		if (resp?.nextToken) {
			await loadMore(resp.nextToken, refresh);
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchData();
	}, [api]);

	return (
		<Container fluid>
			<PageTitle title="Newsletter Subscriptions" />
			<Row>
				<Col>
					<SearchBox
						refreshHook={() => {
							return fetchData(true);
						}}
					/>
				</Col>
			</Row>
			{loading && <Preloader type="three-bounce" variant="success" />}
			<hr/>
			<Row>
				<Col md={2} xxl={1}>
					<SimpleFacetMenu
						title="Topic"
						prop="topic"
						options={[
							{ label: 'All', value: '' },
							...Object.entries(subscriptionCounts).map(([topic, count]) => ({
								label: titleCase(topic),
								value: topic,
								count,
							})),
						]}
					/>
					<SimpleFacetMenu
						title="Status"
						prop="status"
						options={[
							{ label: 'All', value: '' },
							{ label: 'Confirmed', value: 'confirmed' },
							{ label: 'Pending', value: 'pending' },
							{ label: 'Unsubscribed', value: 'unsubscribed' },
						]}
					/>
				</Col>
				<Col md={10} xxl={11}>
					{loading
						? <LoadingSpinner loading={loading} />
						: <NewsletterSubscriptionsTable
							subscriptions={subscriptions || []}
							hasMore={Boolean(nextToken?.length)}
							onLoadMore={() => loadMore(nextToken)}
						/>
					}
				</Col>
			</Row>
		</Container>
	);
};
