import { type Alert } from '@newstex/types/alert';
import { useRef, useState } from 'react';
import {
	Alert as BSAlert,
	Button,
	Container,
	Form,
	Modal,
	Spinner,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAPI } from '~/providers/api-provider';

import { AlertForm, type AvailableCollection } from './editors/alert-form';

export interface CreateAlertModalProps {
	hide: (created?: boolean) => void;
}

export function CreateAlertModal({ hide }: CreateAlertModalProps) {
	const [isSaving, setIsSaving] = useState(false);
	const [error, setError] = useState('');
	const formRef = useRef<HTMLFormElement>(null);

	const api = useAPI();
	const navigate = useNavigate();

	const saveAlert = () => {
		if (formRef.current) {
			const formData = new FormData(formRef.current);
			const name = formData.get('name')?.toString();
			const collection = formData.get('collection')?.toString() as AvailableCollection;

			if (!name?.trim()) {
				setError('Name is required');
				return;
			}

			if (!collection) {
				setError('Collection is required');
				return;
			}

			setIsSaving(true);
			const alertData: Partial<Alert> = {
				$type: 'Alert',
				name: name.trim(),
				collection,
				search: {
					q: formData.get('search')?.toString() || '*',
					filter_by: formData.get('search_filters')?.toString() || '',
				},
				schedule: formData.get('schedule')?.toString() || '* * * * *',
				check_field: formData.get('check_field')?.toString() || 'found',
				expected: {},
			};

			if (formData.get('description')) {
				alertData.description = formData.get('description')?.toString().trim();
			}

			if (formData.get('min')) {
				alertData.expected!.min = Number(formData.get('min'));
			}

			if (formData.get('max')) {
				alertData.expected!.max = Number(formData.get('max'));
			}

			if (formData.get('diff')) {
				alertData.expected!.diff = Number(formData.get('diff'));
			}

			const emails = formData.getAll('emails');
			if (emails?.length) {
				alertData.emails = emails.map((email) => email.toString().trim()).filter(Boolean);
			}

			console.log('Saving', alertData);
			toast.info('Creating Alert...');
			api.fetchWithAuth('resources/Alert', {
				method: 'POST',
				body: JSON.stringify(alertData),
			}).then((resp) => {
				console.log('Saved', resp);
				toast.success('Alert created', {
					onClick: () => {
						navigate(`/alerts/${resp.$id}`);
					},
				});
				setIsSaving(false);
				hide(true);
				if (resp?.$id) {
					navigate(`/alerts/${resp.$id}`);
				}
			}).catch((e) => {
				console.error(e);
				toast.error(`Alert creation failed: ${String(e)}`);
				setError(String(e));
				setIsSaving(false);
			});
		}
	};

	return (
		<Modal
			size="lg"
			show={true}
			onHide={() => hide(false)}
		>
			<Modal.Header closeButton>
				<Modal.Title>Create Alert</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{error && <BSAlert variant="danger">{error}</BSAlert>}
				<Form ref={formRef}>
					<Container fluid>
						<AlertForm disabled={isSaving} />
					</Container>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				{isSaving && <Spinner animation="border" size="sm" />}
				<Button variant="secondary" onClick={() => hide(false)}>Cancel</Button>
				<Button
					variant="primary"
					onClick={() => saveAlert()}
					disabled={isSaving}
				>
					Create Alert
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
