export function getChatSuggestions(currentPath: string): string[] {
	switch (currentPath) {
		case '/feeds':
			return [
				'How many active feeds are there?',
				'Show me a pie chart of feeds by priority.',
				'How many feeds are on Standby?',
			];
		case '/publications':
			return [
				'How many active publications are there?',
				'Show me a pie chart of publications by category.',
				'What publications mention Crypto Currencies?',
			];
		default:
			return [
				'Start a new Knowledge Base article.',
				'How many stories did StockPulse produce today?',
				'What are the top trending topics in financial news?',
			];
	}
}
