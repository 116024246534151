import { IconDefinition, faTable } from '@fortawesome/free-solid-svg-icons';
import { convertToDate, now } from '@newstex/core/date';
import type { SearchHit, SearchIndexName, SearchResults } from '@newstex/search';
import { Publication } from '@newstex/types/index';
import { title as titleCase } from 'case';
import omit from 'object.omit';
import {
	type JSXElementConstructor,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Button,
	ButtonGroup,
	Col,
	Container,
	Row,
	Table,
} from 'react-bootstrap';
import { Loader } from 'react-bootstrap-typeahead';
import { Link, useSearchParams } from 'react-router-dom';
import { useTypesenseSearch } from '~/hooks/useTypesenseSearch';
import { useSearch } from '~/providers/search';

import { ActiveFilterBadge } from './active-filter-badge';
import { DebouncedSearchBox } from './DebouncedSearchBox';
import { DisplayModeButton } from './display-mode-button';
import LoadingSpinner from './LoadingSpinner';
import { PageTitle } from './page-title';
import { PropertyDisplayValue } from './property-display-value';
import { SearchCountTimeline } from './search-count-timeline';
import { SimpleFacetMenu, SimpleFacetMenuOption } from './simple-facet-menu';

interface SearchFacetConfigBase {
	attribute: string;
	title: string;
	options?: SimpleFacetMenuOption[];
	type?: string;
	showMore?: boolean;
	sortBy?: string[] | ((a: any, b: any) => number);
	searchable?: boolean;
}

export interface SearchFacetConfigList extends SearchFacetConfigBase {
	type?: 'List';
}

export interface SearchFacetConfigMenu extends SearchFacetConfigBase {
	type: 'Menu';
}

export interface SearchFacetConfigDate extends SearchFacetConfigBase {
	type: 'Date';
}

export type SearchFacetConfig = SearchFacetConfigList | SearchFacetConfigMenu | SearchFacetConfigDate;

const today = now();

function getFacetFilterString(key: string, values: string[], negated: boolean = false) {
	// Split comma-separated values
	const valueArray = values.flatMap((v) => v.split(','));

	// Boolean Values
	if (valueArray[0] === 'true' || valueArray[0] === 'false') {
		return `${key}:${negated ? '!=' : '='}${valueArray[0]}`;
	}

	// Date/Received At handling
	if (key === 'date' || key === 'received_at') {
		if (valueArray.length === 2) {
			return `${key}:${negated ? '!' : ''}=[${valueArray[0]}...${valueArray[1]}]`;
		}

		if (valueArray.length === 1) {
			if (valueArray[0].startsWith('[')) {
				return `${key}:${negated ? '!' : ''}${valueArray[0]}`;
			}
			return `${key}:${negated ? '<' : '>='}${valueArray[0]}`;
		}
	}

	// Segment handling
	if (key === 'segment') {
		if (valueArray.length === 2) {
			return `${key}:${negated ? '!' : ''}=[${valueArray[0]}...${valueArray[1]}]`;
		}

		if (valueArray.length === 1) {
			return `${key}:${negated ? '!=' : '='}${valueArray[0]}`;
		}
	}

	return `${key}:${negated ? '!' : ''}=[${valueArray.map((value) => `\`${value}\``).join(',')}]`;
}

function getFilterString(filters?: Record<string, string[]>) {
	if (!filters) {
		return;
	}
	return Object.entries(filters)
		.filter(([key]) => key !== 'sort_by' && key !== 'page' && key !== 'mode')
		.map(([key, values]) => {
			const isNegated = key.startsWith('!');
			const actualKey = isNegated ? key.slice(1) : key;
			return getFacetFilterString(actualKey, values, isNegated);
		})
		.join(' && ');
}

interface SearchFacetProps {
	facet: SearchFacetConfig;
	results?: SearchResults<any>;
}

function SearchFacet({ facet, results }: SearchFacetProps) {
	if (facet.type === 'Date') {
		return <SimpleFacetMenu
			key={facet.attribute}
			title={facet.title}
			prop={facet.attribute}
			options={[
				{ value: '', label: 'All time' },
				{ value: `${today.add(-1, 'day').unix()}`, label: 'Last 24 hours' },
				{ value: `${today.add(-7, 'day').unix()}`, label: 'Last 7 days' },
				{ value: `${today.add(-30, 'day').unix()}`, label: 'Last 30 days' },
			]}
			supportNegation={true}
		/>;
	}
	// Get any negated values from search params to ensure they show up in options
	const [searchParams] = useSearchParams();
	const negatedValue = searchParams.get(`!${facet.attribute}`);
	const searchResult = useMemo(() => {
		const result = results?.facets?.[facet.attribute]?.values;
		if (negatedValue && result) {
			// If we have a negated value but it's not in the facet results,
			// add it to the options with count of 0 to keep it visible
			if (!result[negatedValue]) {
				result[negatedValue] = 0;
			}
		}
		return result;
	}, [results?.facets?.[facet.attribute]?.values, negatedValue]);

	return <SimpleFacetMenu
		key={facet.attribute}
		title={facet.title}
		prop={facet.attribute}
		searchResult={searchResult}
		options={facet.options}
		searchable={facet.searchable}
		supportNegation={true}
	/>;
}

export interface GroupByOption {
	id: string;
	icon: IconDefinition;
	label?: string;
	sort?: string;
}

export interface SearchWithFacetsArguments<T = any> {
	indexName: SearchIndexName;
	title: string;
	actions?: React.ReactNode;
	extraActions?: React.ReactNode | ((results?: SearchResults<T>) => React.ReactNode);
	queryBy?: string[];
	info?: string;
	defaultSort?: string;
	resultsTable: JSXElementConstructor<{
		items: SearchHit<T>[],
		searchText?: string,
		hasMore?: boolean,
		onLoadMore?: () => void,
	}>;
	groupTable?: JSXElementConstructor<{
		groupedHits: SearchResults<T>['grouped_hits'],
	}>;
	facets: SearchFacetConfig[];
	filters?: Record<string, string[]>;
	sortBy?: string[];
	/**
	 * Facet Key => Facet Name
	 */
	facetStats?: Record<string, string>;
	/**
	 * Facet to use for a sparkline timeline facet
	 */
	timelineFacet?: string;

	/**
	 * Optional include_fields to pass to the search client
	 */
	includeFields?: string;
	/**
	 * Fallback search function if there are no results
	 */
	fallbackSearch?: (q: string) => Promise<T[]>;

	/**
	 * Grouping options
	 */
	groupByOptions?: GroupByOption[];
}

function FacetStats(props: Pick<SearchWithFacetsArguments<any>, 'facetStats'> & { results?: SearchResults<any> }) {
	return (
		<>
			{Object.entries(props.facetStats || {}).map(([key, name]) => (
				<span className="facet-stat ms-2" key={`facet-stat${key}`}>
					<b>{name}</b>:&nbsp;
					{convertToDate(props.results?.facets?.[key]?.stats?.min).format('YYYY-MM-DD')}
					<i> to </i>
					{convertToDate(props.results?.facets?.[key]?.stats?.max).format('YYYY-MM-DD')}
				</span>
			))}
		</>
	);
}

function NoResults({
	fallbackSearch,
	title,
	loading,
}: {
	fallbackSearch?: (q: string) => Promise<any[]>,
	title?: string,
	loading?: boolean,
}) {
	const [searchParams] = useSearchParams();
	const [fallbackSearchPerformed, setFallbackSearchPerformed] = useState(false);

	return (
		<div className="text-muted p-4">
			{loading ? <LoadingSpinner loading={loading} /> : (
				<center>
					<div>
						No results for <i>{searchParams.get('q') || title || ''}</i>
					</div>
					{!fallbackSearchPerformed && fallbackSearch && searchParams.get('q') && (
						<Button variant="outline-secondary" className="mt-2" onClick={() => {
							setFallbackSearchPerformed(true);
							fallbackSearch(searchParams.get('q')!);
						}}>Perform Deep Search</Button>
					)}
				</center>
			)}
		</div>
	);
}

function SearchHitsTable<T = any>({
	resultsTable: ResultsTableComponent,
	groupTable: GroupTableComponent,
	results,
	hasMore,
	onLoadMore,
	loading,
}: {
	resultsTable: JSXElementConstructor<{
		items: SearchHit<T>[],
		searchText?: string,
		hasMore?: boolean,
		onLoadMore?: () => void,
		loading?: boolean,
	}>,
	groupTable?: JSXElementConstructor<{
		groupedHits: SearchResults<T>['grouped_hits'],
	}>,
	results: SearchResults<T>,
	hasMore?: boolean,
	onLoadMore?: () => void,
	loading?: boolean,
}) {
	const [searchParams] = useSearchParams();
	if (!results?.hits) {
		if (loading) {
			return <LoadingSpinner loading={loading} />;
		}

		if (results?.grouped_hits) {
			if (GroupTableComponent) {
				return <GroupTableComponent
					groupedHits={results.grouped_hits}
				/>;
			}

			return (
				<Table>
					<thead>
						<tr>
							<th>{titleCase(searchParams.get('group_by') || 'group')}</th>
							<th>Count</th>
						</tr>
					</thead>
					<tbody>
						{results.grouped_hits.map((group) => (
							<tr key={group.group_key.join('-')}>
								<td>{<PropertyDisplayValue propName={searchParams.get('group_by') || 'group'} propValue={group.group_key[0]} />}</td>
								<td><Link to={`?${new URLSearchParams({
									...omit(Object.fromEntries(searchParams.entries()), ['group_by', 'sort_by', 'page']),
									[searchParams.get('group_by') || 'group']: group.group_key[0],
								})}`}>{group.found}</Link></td>
							</tr>
						))}
					</tbody>
				</Table>
			);
		}

		return <></>;
	}
	return <ResultsTableComponent
		items={results.hits}
		loading={loading}
		hasMore={hasMore}
		onLoadMore={onLoadMore}
	/>;
}

/**
 * Displays the total number of results and search time
 */
const Stats = memo(({ results }: { results?: SearchResults<any> }) => (
	<small>
		found {results?.found?.toLocaleString() || '0'} results
		{results?.search_time_ms && ` in ${results.search_time_ms}ms`}
	</small>
));

/**
 * Displays active filters that are not part of the facet menu
 * Handles special cases like publication lookups
 */
const ActiveFilters = memo(({
	facetAttributes,
}: {
	facetAttributes: Set<string>;
}) => {
	const search = useSearch();
	const [searchParams] = useSearchParams();
	const [activeFilters, setActiveFilters] = useState<{
		key: string;
		value: string;
		label?: string;
	}[]>([]);

	// Look up publication names and update filter labels
	const updateFilterLabels = useCallback(async () => {
		const currentFilters: typeof activeFilters = [];

		for (const [key, value] of searchParams.entries()) {
			// Skip non-filter params and facet filters
			if (
				key === 'q'
				|| key === 'mode'
				|| key === 'sort_by'
				|| key === 'page'
				|| key === 'group_by'
				|| facetAttributes.has(key.replace('!', ''))
			) {
				continue;
			}

			// Handle publication lookups
			if (key.replace('!', '') === 'publication') {
				const resp = await search.searchClient?.multiSearch<[Publication]>([{
					indexName: 'Publication',
					query: '',
					filter_by: `id:[${value}]`,
				}]);

				if (resp?.[0]?.hits?.length) {
					for (const hit of resp[0].hits) {
						currentFilters.push({
							key,
							value: hit.id,
							label: hit.name,
						});
					}
				}
				continue;
			}

			// Handle regular filters
			for (const v of value.split(',')) {
				currentFilters.push({ key, value: v });
			}
		}
		setActiveFilters(currentFilters);
	}, [searchParams, facetAttributes, search.searchClient]);

	useEffect(() => {
		updateFilterLabels();
	}, [updateFilterLabels]);

	if (!activeFilters.length) return null;

	return (
		<div className="mb-3">
			<small className="text-muted me-2">Active Filters:</small>
			{activeFilters.map(({ key, value, label }) => (
				<ActiveFilterBadge
					key={`${key}-${value}`}
					filterKey={key}
					value={value}
					label={label}
				/>
			))}
		</div>
	);
});

/**
 * Header component showing title, stats, and actions
 */
const SearchHeader = memo(({
	title,
	info,
	results,
	actions,
	facetStats,
}: {
	title: string;
	info?: string;
	results?: SearchResults<any>;
	actions?: React.ReactNode;
	facetStats?: Record<string, string>;
}) => (
	<PageTitle title={title} info={info}>
		<Row>
			<Col>
				<Stats results={results}/>
			</Col>
			{actions && (
				<Col className="text-end mb-2">
					{actions}
				</Col>
			)}
			{facetStats && (
				<Col className="text-end">
					<FacetStats facetStats={facetStats} results={results} />
				</Col>
			)}
		</Row>
	</PageTitle>
));

/**
 * Displays the facet menu for filtering results
 */
const SearchFacets = memo(({
	facets,
	results,
}: {
	facets: SearchFacetConfig[];
	results?: SearchResults<any>;
}) => (
	<Col md={4} xxl={2}>
		{facets.map((facet) => (
			<SearchFacet
				key={`search-facet-${facet.attribute}`}
				facet={facet}
				results={results}
			/>
		))}
	</Col>
));

/**
 * Displays the search results with optional grouping controls
 */
const SearchResultsDisplay = memo(({
	resultsTable: ResultsTableComponent,
	groupTable: GroupTableComponent,
	results,
	hasMore,
	onLoadMore,
	loading,
	groupByOptions,
	extraActions,
}: {
	resultsTable: JSXElementConstructor<any>;
	groupTable?: JSXElementConstructor<any>;
	results?: SearchResults<any>;
	hasMore?: boolean;
	onLoadMore?: () => void;
	loading?: boolean;
	groupByOptions?: GroupByOption[];
	extraActions?: React.ReactNode | ((results?: SearchResults<any>) => React.ReactNode);
}) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const renderExtraActions = () => {
		if (typeof extraActions === 'function') {
			return extraActions(results);
		}
		return extraActions;
	};

	const handleGroupChange = useCallback((groupId?: string, sortBy?: string) => {
		setSearchParams((params) => {
			if (groupId) {
				params.set('group_by', groupId);
				params.set('sort_by', sortBy || '_group_found:desc');
			} else {
				params.delete('mode');
				params.delete('sort_by');
				params.delete('group_by');
			}
			return params;
		});
	}, [setSearchParams]);

	if (!results?.hits && !results?.grouped_hits) {
		return loading ? <LoadingSpinner loading={loading} /> : null;
	}

	return (
		<Col>
			{groupByOptions && (
				<Row className="text-end mb-2">
					{extraActions && (
						<Col className="text-start">
							<span className="me-2">{renderExtraActions()}</span>
						</Col>
					)}
					<Col className="text-end">
						<ButtonGroup>
							<DisplayModeButton
								mode="table"
								icon={faTable}
								onClick={() => handleGroupChange()}
								default={!searchParams.get('group_by')}
							/>
							{groupByOptions.map((option) => (
								<DisplayModeButton
									key={option.id}
									mode={option.id}
									searchKey="group_by"
									icon={option.icon}
									onClick={() => handleGroupChange(option.id, option.sort)}
								/>
							))}
						</ButtonGroup>
					</Col>
				</Row>
			)}
			<SearchHitsTable
				resultsTable={ResultsTableComponent}
				groupTable={GroupTableComponent}
				results={results}
				hasMore={hasMore}
				onLoadMore={onLoadMore}
				loading={loading}
			/>
		</Col>
	);
});

/**
 * Main search component with faceted filtering
 * Provides a full-featured search interface with:
 * - Debounced search input
 * - Faceted filtering
 * - Result grouping
 * - Timeline visualization
 * - Export capabilities
 */
export function SearchWithFacets<T = any>(props: SearchWithFacetsArguments<T>) {
	const search = useSearch();
	const {
		loading,
		results,
		hasMore,
		doSearch,
		loadMore,
	} = useTypesenseSearch<T>({
		indexName: props.indexName,
		queryBy: props.queryBy,
		facets: props.facets.map((f) => f.attribute),
		includeFields: props.includeFields,
		sortBy: props.sortBy,
		defaultSort: props.defaultSort,
		timelineFacet: props.timelineFacet,
	});

	// Memoize facet attributes for efficient filtering
	const facetAttributes = useMemo(
		() => new Set(props.facets.map((f) => f.attribute)),
		[props.facets],
	);

	if (!search?.searchClient) {
		return <Loader />;
	}

	return (
		<Container fluid>
			<SearchHeader
				title={props.title}
				info={props.info}
				results={results}
				actions={props.actions}
				facetStats={props.facetStats}
			/>
			<Row>
				<Col>
					<DebouncedSearchBox
						refreshHook={async () => doSearch(true)}
					/>
					<ActiveFilters facetAttributes={facetAttributes} />
				</Col>
			</Row>
			{props.timelineFacet && (
				<Row>
					<Col>
						<SearchCountTimeline facetName={props.timelineFacet} />
					</Col>
				</Row>
			)}
			<hr/>
			<Row>
				<SearchFacets facets={props.facets} results={results} />
				<SearchResultsDisplay
					extraActions={props.extraActions}
					resultsTable={props.resultsTable}
					groupTable={props.groupTable}
					results={results}
					hasMore={hasMore}
					onLoadMore={loadMore}
					loading={loading}
					groupByOptions={props.groupByOptions}
				/>
			</Row>
		</Container>
	);
}
