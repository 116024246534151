export function getFacetFilterString(key: string, values: string[], negated: boolean = false) {
	// Split comma-separated values
	const valueArray = values.flatMap((v) => v.split(','));

	// Boolean Values
	if (valueArray[0] === 'true' || valueArray[0] === 'false') {
		return `${key}:${negated ? '!=' : '='}${valueArray[0]}`;
	}

	// Date/Received At handling
	if (key === 'date' || key === 'received_at') {
		if (valueArray.length === 2) {
			return `${key}:${negated ? '!' : ''}=[${valueArray[0]}...${valueArray[1]}]`;
		}

		if (valueArray.length === 1) {
			if (valueArray[0].startsWith('[')) {
				return `${key}:${negated ? '!' : ''}${valueArray[0]}`;
			}
			return `${key}:${negated ? '<' : '>='}${valueArray[0]}`;
		}
	}

	// Segment handling
	if (key === 'segment') {
		if (valueArray.length === 2) {
			return `${key}:${negated ? '!' : ''}=[${valueArray[0]}...${valueArray[1]}]`;
		}

		if (valueArray.length === 1) {
			return `${key}:${negated ? '!=' : '='}${valueArray[0]}`;
		}
	}

	return `${key}:${negated ? '!' : ''}=[${valueArray.map((value) => `\`${value}\``).join(',')}]`;
}

export function getFilterString(filters?: Record<string, string[]>) {
	if (!filters) return;

	return Object.entries(filters)
		.filter(([key]) => key !== 'sort_by' && key !== 'page' && key !== 'mode')
		.map(([key, values]) => {
			const isNegated = key.startsWith('!');
			const actualKey = isNegated ? key.slice(1) : key;
			return getFacetFilterString(actualKey, values, isNegated);
		})
		.join(' && ');
}
