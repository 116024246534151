import { UnixTimestamp } from './dates';
import { ErrorResponse } from './error';
import { HubSpotCompany } from './hubspot/company';
import { StoryReference } from './story';

/**
 * Represents a media item attached to a feed (Attachment, Video, etc)
 */
export interface FeedMedia {
	title?: string;
	content?: {
		url?: string;
		type?: string;
		width?: string;
		height?: string;
	};
	thumbnail?: {
		url?: string;
		type?: string;
		width?: string;
		height?: string;
	};
	description?: string;
}
/**
 * represents a single story item
 */
export interface FeedItem {
	title?: string;
	date?: UnixTimestamp;
	updated?: UnixTimestamp;
	link?: string;
	guid?: string;
	author?: string;
	summary?: string;
	description?: string;
	fulltext?: string;
	categories?: string[];
	tickers?: string[];
	language?: string;
	media?: FeedMedia[];
	/**
	 * If true, this is the story for the page you are currently on
	 */
	is_current_story?: boolean;
}
/**
 * Find or classify a feed
 *
 * Requires either a `url` or `feed_url` (or both)
 */
export interface DetectFeedRequest {
	/**
	 * Unique ID for this request
	 * @calculated
	 */
	id?: string;

	/**
	 * Optional URL of the page to run detection on
	 */
	url?: string;

	/**
	 * Optional Feed URL
	 */
	feed_url?: string;

	/**
	 * Optional ID of the Hubspot record
	 *
	 * If provided, and classify is set to true, hubspot will be updated with classification results
	 */
	hubspot_id?: string;

	/**
	 * Set to "true" to run the classification engine
	 */
	classify?: boolean;

	/**
	 * Optional meta tag data, can be passed in from an extension
	 * in order to better classify a feed
	 */
	meta?: Record<string, string>;

	/**
	 * Optional LD JSON metadata, which can also be passed in from an extension,
	 * or returned from the response by parsing any application/ld+json tags
	 */
	ld_meta?: any[];

	/**
	 * Optional HTML content, which can be passed in from an extension
	 */
	html_content?: string;

	/**
	 * Optional S3 location where the response parts are stored
	 */
	s3?: {
		bucket: string;
		prefix: string;
	};

	/**
	 * Allow skipping robots lookup
	 */
	bypass_robots?: boolean;

	/**
	 * Allow limiting the number of articles to return
	 */
	limit?: number;
}

export interface DetectedStats {
	total_articles?: number;
	total_words?: number;
	average_word_count?: number;
	full_text_articles?: number;
	qualified_articles?: number;
}
export interface DetectedArticle extends FeedItem {
	headline_score?: number;
	headline_scores?: { [client: string]: number },
	newstex_categories?: { [id: string]: number };
	stats?: {
		character_count: number,
		line_count: number,
		word_count: number,
	};
	is_full_text?: boolean;
}

export interface DetectFeedResponse extends DetectFeedRequest {
	// POSSIBLE feed URLs, in order of preference
	possible_feed_urls?: string[];

	/**
	 * Feed or website "title" or name
	 */
	name?: string;
	/**
	 * Feed or website "description"
	 */
	description?: string;
	authors?: string[];
	articles?: DetectedArticle[];
	categories?: string[];
	last_post_date?: string;
	first_post_date?: string;
	raw_headers?: { [k: string]: string };
	headline_score?: number;
	headline_scores?: { [client: string]: number },
	newstex_categories?: { [id: string]: number };
	publications?: any[];
	stats?: DetectedStats;
	qualified?: boolean;
	rejection_reasons?: string[];
	/**
	 * Top-level <language> tag in a feed
	 */
	language?: string;
	/**
	 * Top-level <pubDate> (last published date) in a feed
	 */
	pubDate?: UnixTimestamp;
	/**
	 * "<link>" tag in a feed
	 */
	link?: string;

	// Who would like this publication?
	suggested_clients?: string[];

	// Social
	twitter?: string;
	facebook?: string;
	linkedin?: string;
	instagram?: string;
	youtube?: string;

	// Errors
	feed_error?: ErrorResponse;

	// HubSpot Search Results
	hubspot_records?: (Partial<HubSpotCompany> & { id: string })[];
	related_hubspot_records?: (Partial<HubSpotCompany> & { id: string })[];

	// NewsCore matching record
	newscore_id?: string;
}

export interface DetectedFeedWithArticleReferences extends Omit<DetectFeedResponse, 'articles'> {
	/**
	 * Articles are S3 references
	 */
	articles?: StoryReference[];
}

export function getMatchedCategories(detectResp: DetectFeedResponse): string[] {
	let matchedCategories: any = [];
	if (detectResp?.newstex_categories) {
		matchedCategories = Object.entries(detectResp.newstex_categories).filter(
			([, score]) => score > 10,
		).map(([id]) => id);
	} else if (detectResp.articles?.length > 0) {
		for (const article of detectResp.articles) {
			if (article.newstex_categories) {
				for (const [id, score] of Object.entries(article.newstex_categories)) {
					if (score > 10 && !matchedCategories.includes(id)) {
						matchedCategories.push(id);
					}
				}
			}
		}
	}
	return matchedCategories;
}
