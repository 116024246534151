export function getObjectType(item: any) {
	if (!item) {
		return item;
	}
	return item.__model__ || item.__type__ || item.$type;
}

export function getObjectID(item: any) {
	if (!item) {
		return item;
	}
	return item.__id__ || item.$id || item.id;
}
