import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type Delivery } from '@newstex/types/delivery';
import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { PropertyDisplayValue } from '../property-display-value';

export const DeliveryCard: React.FC<{
	item?: Delivery;
}> = ({
	item,
}) => {
	if (!item) {
		return <></>;
	}

	return (
		<Card className="delivery h-100">
			<Card.Body>
				<Card.Title className="">
					<div>
						<Link to={`/delivery/${item.$id}`}>
							{item.name}
						</Link>
					</div>
					<h6 className="text-muted">
						{item.$id}
					</h6>
				</Card.Title>
				<div>
					<div className="mb-2">
						<strong>Type:</strong> {item.type}
					</div>
					<div className="mb-2">
						<strong>Status:</strong> <PropertyDisplayValue propName="status" propValue={item.status} />
					</div>
					<div className="mb-2">
						<strong>Recipient:</strong> {item.recipient_name || item.recipient}
					</div>
					<div className="description">
						{item.description}
					</div>
				</div>
			</Card.Body>
			<Card.Footer>
				<div className="mb-2">
					<strong>Last Delivery:</strong> <PropertyDisplayValue propName="last_delivery" propValue={item.last_delivery} />
				</div>
				{item.url && (
					<Link className="text-muted" to={item.url} target="_blank">
						{item.url} <FontAwesomeIcon icon={faExternalLinkAlt} />
					</Link>
				)}
			</Card.Footer>
		</Card>
	);
};
