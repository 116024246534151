import type { UnixTimestamp } from './dates';
import type { ReferenceString, TypedObjectName } from './reference';
import type { User } from './user';

/**
 * Shared properties between both old and new style objects
 */
interface SharedObjectBase {
	/** Name
	 * @newscrunch
	 *
	 * |NewsCrunch Type |NewsCrunch ID |Display Name |
	 * |----------------|--------------|-------------|
	 * |Blog            |_name_        |Name         |
	 * |Publisher       |_name_        |Name         |
	 * |Account         |_name_        |Name         |
	 */
	name?: string;
	/**
	 * Sortable Name
	 * @calculated
	 * @newscrunch
	 *
	 * |NewsCrunch Type |NewsCrunch ID   |Display Name    |
	 * |----------------|----------------|----------------|
	 * |Blog            |_name_sortable_ |Name (Sortable) |
	 * |Publisher       |_name_sortable_ |Name (Sortable) |
	 * |Account         |_name_sortable_ |Name (Sortable) |
	 */
	sortable_name?: string;

	/**
	 * Deleted
	 *
	 * Deleted records are hidden from Search, and will eventually be PURGED
	 * @see purge_by
	 */
	deleted?: boolean;

	/**
	 * Date to purge the record (DynamoDB TTL)
	 */
	purge_by?: UnixTimestamp;

	/**
	 * Created At
	 * @calcualted
	 */
	created_at?: UnixTimestamp;
	/**
	 * Created By
	 * @calculated
	 */
	created_by?: ReferenceString<User>;
	/**
	 * Modified At
	 * @calculated
	 */
	modified_at?: UnixTimestamp;
	/**
	 * Modified By
	 * @calculated
	 */
	modified_by?: ReferenceString<User>;

	/**
	 * Change ID, sync'd to the History record when changes are made
	 */
	$transaction_id?: string;

	/**
	 * Change comment, sync'd to the History record when changes are made
	 */
	$comment?: string;
}

/**
 * Old style object (like Story)
 */
export interface LegacyBaseObject extends SharedObjectBase {
	__type__: string;
	__id__?: string;
}
/**
 * New style object (Stored in individual tables)
 */
export interface BaseObject extends SharedObjectBase {
	$type: string;
	$id?: string;
}

export type TypedObject = BaseObject | LegacyBaseObject;

/**
 * Check if an object is of a specific type
 * @param typeName Type of object to check for
 * @param obj
 * @returns True if `obj` is a `typeName
 */
export function isTypeOf<T extends TypedObject>(typeName: TypedObjectName<T>, obj: any): obj is T {
	return obj.__type__ === typeName || obj.$type === typeName;
}

export function isBaseObjectType(obj: any): obj is BaseObject {
	return '$type' in obj;
}

export function isLegacyBaseObjectType(obj: any): obj is LegacyBaseObject {
	return '__type__' in obj;
}

export function getTypeName<T extends TypedObject>(obj: T): TypedObjectName<T> {
	if (isBaseObjectType(obj)) {
		return obj.$type as TypedObjectName<T>;
	}

	if (isLegacyBaseObjectType(obj)) {
		return obj.__type__ as TypedObjectName<T>;
	}

	throw new Error('Invalid object');
}
