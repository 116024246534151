import { faHubspot } from '@fortawesome/free-brands-svg-icons';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { QualificationScores } from '@newstex/ai/qualify';
import { DetectFeedResponse } from '@newstex/types/detect-feed';
import { HubSpotCompany } from '@newstex/types/hubspot/company';
import { KnowledgeBase } from '@newstex/types/rag';
import { useEffect, useState } from 'react';
import {
	Alert,
	Button,
	Card,
	Col,
	Container,
	Form,
	Row,
} from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { ProvenanceEditor } from '~/components/editors/provenance';
import { HubSpotLink } from '~/components/hubspot-link';
import PageHeader from '~/components/PageHeader';
import { SelectKnowledgeBaseArticle } from '~/components/select-knowledge-base-article';
import { useAI } from '~/providers/ai-provider';
import { useAPI } from '~/providers/api-provider';
import { useConfirm } from '~/providers/confirm';
import { useHubSpot } from '~/providers/hubspot-provider';
import { useUserInfo } from '~/providers/user-info';

export function HubSpotCreateLeadPage() {
	const [selectedKnowledgeBase, setSelectedKnowledgeBase] = useState<KnowledgeBase | null>(null);
	const [searchParams, setSearchParams] = useSearchParams();
	const [error, setError] = useState<string | null>(null);
	const [success, setSuccess] = useState<string | null>(null);
	const [loadingMessage, setLoadingMessage] = useState<string | null>(null);
	const [loading, setLoading] = useState(false);
	const hubspot = useHubSpot();
	const [leadData, setLeadData] = useState<HubSpotCompany | undefined>();
	const [detectedInfo, setDetectedInfo] = useState<DetectFeedResponse | undefined>();
	const [aiQualificationData, setAiQualificationData] = useState<QualificationScores | null>(null);
	const userInfo = useUserInfo();

	const [updates, setUpdates] = useState<Partial<HubSpotCompany>>({
		newscore_tag: `${userInfo?.initials}-${new Date().toISOString().split('T')[0]}`,
		provenance: 'NewsCore',
	});
	const api = useAPI();
	const ai = useAI();
	const { confirmDialog } = useConfirm();

	const handleUpdateParam = (name: string, value: string) => {
		setSearchParams({ ...Object.fromEntries(searchParams), [name]: value });
	};

	const getLeadData = () => ({
		name: searchParams.get('name') || '',
		url: searchParams.get('url') || '',
		feed_url: searchParams.get('feed_url') || '',
	});

	const handleQualifyLead = async () => {
		if (!selectedKnowledgeBase || !detectedInfo) {
			return;
		}
		setLoading(true);
		setLoadingMessage('Qualifying lead...');
		const lead = getLeadData();
		console.log('Qualifying lead', { lead, detectedInfo, criteria: selectedKnowledgeBase.answer });
		// Run the qualification criteria against the article
		const resp = await ai.qualifySite({
			detectResp: detectedInfo,
			criteria: selectedKnowledgeBase.answer!,
		});
		console.log('AI Qualification', resp);
		setAiQualificationData(resp);
		setLoading(false);
	};

	const handleCreateLead = async () => {
		const name = searchParams.get('name');
		const url = searchParams.get('url');
		const feedUrl = searchParams.get('feed_url');

		if (!name || !url) {
			setError('Missing required parameters: name and url');
			return;
		}

		try {
			setLoading(true);
			setLoadingMessage('Creating lead...');
			setError(null);
			setSuccess(null);
			console.log('Creating lead', { updates });

			const resp = await hubspot.createCompany({
				lead: {
					...detectedInfo,
					name,
					url,
					feed_url: feedUrl || detectedInfo?.feed_url,
				},
				provenance: updates.provenance || 'NewsCore',
				tag: updates.newscore_tag || undefined,
				aiRankings: aiQualificationData,
			});
			setLeadData(resp);
			if (resp.hs_object_id) {
				setSuccess('Lead successfully created in HubSpot');
			} else {
				setError('Failed to create lead');
			}
		} catch (err) {
			setError(`Failed to create lead: ${err instanceof Error ? err.message : 'Unknown error'}`);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!searchParams.get('url')) {
			return;
		}
		setLeadData(undefined);
		setLoadingMessage(null);
		setAiQualificationData(null);
		setDetectedInfo(undefined);
		const timeout = setTimeout(() => {
			setLoading(true);
			api.detect({
				url: searchParams.get('url') || '',
				feed_url: searchParams.get('feed_url') || '',
				classify: true,
			}).then(setDetectedInfo).catch(() => {
				setError('Failed to detect feed');
			}).finally(() => {
				setLoading(false);
			});
		}, 1000);
		return () => clearTimeout(timeout);
	}, [hubspot, searchParams.get('url'), searchParams.get('feed_url')]);

	return (
		<Container className="row-padding">
			<PageHeader title="Create HubSpot Lead" />
			{loading && (
				<Alert className="text-center" variant="info">
					<FontAwesomeIcon icon={faSpinner} spin className="me-2" />
					{loadingMessage || 'Detecting feed...'}
					<FontAwesomeIcon icon={faSpinner} spin className="ms-2" />
				</Alert>
			)}
			{leadData && (
				<Alert className="text-center" variant="success">
					Lead successfully created in HubSpot
					<center>
						<HubSpotLink item={{
							$type: 'HubSpotCompany',
							hubspot_id: leadData.hs_object_id,
						}} />
					</center>
				</Alert>
			)}
			{detectedInfo?.hubspot_records && detectedInfo.hubspot_records.length > 0 && (<>
				<Alert className="text-center" variant="danger">
					<h2>An Existing HubSpot Company has been detected</h2>
					<center>
						{detectedInfo.hubspot_records.map((record) => (
							<div key={record.hs_object_id}>
								<HubSpotLink item={{
									$type: 'HubSpotCompany',
									hubspot_id: record.hs_object_id,
								}}>
									{record.name} &nbsp;
									<FontAwesomeIcon icon={faHubspot} />
								</HubSpotLink>
							</div>
						))}
					</center>
				</Alert>
			</>)}
			{!leadData && (<>
				<Row>
					<Col>
						<SelectKnowledgeBaseArticle
							category="Qualification"
							value={selectedKnowledgeBase}
							onSelect={setSelectedKnowledgeBase}
							label="Search for AI Qualification Criteria"
							disabled={loading || !detectedInfo?.articles?.length}
						/>
						{!loading && !detectedInfo?.articles?.length && <small className="text-danger">No articles identified</small>}
					</Col>
				</Row>

				<Row>
					<Col>
						<Form.Group controlId="name">
							<Form.Label>Name</Form.Label>
							<Form.Control type="text" name="name" value={searchParams.get('name') || ''} onChange={(e) => handleUpdateParam('name', e.target.value)} />
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Group controlId="url">
							<Form.Label>URL</Form.Label>
							<Form.Control type="text" name="url" value={searchParams.get('url') || ''} onChange={(e) => handleUpdateParam('url', e.target.value)} />
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Group controlId="feed_url">
							<Form.Label>Feed URL</Form.Label>
							<Form.Control type="text" name="feed_url" value={searchParams.get('feed_url') || ''} onChange={(e) => handleUpdateParam('feed_url', e.target.value)} />
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Group controlId="newscore_tag">
							<Form.Label>NewsCore Tag</Form.Label>
							<Form.Control type="text" name="newscore_tag" value={updates.newscore_tag} onChange={(e) => {
								setUpdates({ ...updates, newscore_tag: e.target.value });
							}} />
						</Form.Group>
					</Col>
					<Col>
						<ProvenanceEditor updates={updates} setUpdates={setUpdates} />
					</Col>
				</Row>
				{aiQualificationData && (
					<Row>
						{Object.entries(aiQualificationData).map(([key, value]) => (
							<Col key={`ai-qualification-${key}`} md={6} xl={4}>
								<Card className={`${value.score > 0.5 ? 'success' : 'danger'}`} style={{ minHeight: '250px' }}>
									<Card.Header>{key}</Card.Header>
									<Card.Body>{value.reason}</Card.Body>
									<Card.Footer>
										{value.score}
									</Card.Footer>
								</Card>
							</Col>
						))}
					</Row>
				)}
				<Row>
					<Col className="text-center">
						<Button
							disabled={loading || !!detectedInfo?.hubspot_id || !selectedKnowledgeBase || !!aiQualificationData}
							variant="info"
							type="submit"
							name="action"
							value="qualify"
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								handleQualifyLead();
							}}
						>Qualify Lead</Button>
						<Button
							disabled={loading || Boolean(detectedInfo?.hubspot_id)}
							variant="success"
							type="submit"
							name="action"
							value="create"
							onClick={async (e) => {
								e.preventDefault();
								e.stopPropagation();
								if (await confirmDialog({
									title: 'Create Lead?',
									message: 'Are you sure you want to create a new HubSpot Lead?',
									details: <>
										<div>This will create a new company in HubSpot for the URL <i>{searchParams.get('url')}</i></div>
										{!aiQualificationData && <div className="fw-bold pt-2 text-center text-danger">It will not include AI Qualification Criteria</div>}
										{detectedInfo?.hubspot_id && <div className="fw-bold pt-2 text-center text-danger">This may create a duplicate company</div>}
										{updates.provenance && <div className="pt-2 text-center">Provenance: {updates.provenance}</div>}
										{updates.newscore_tag && <div className="pt-2 text-center">NewsCore Tag: {updates.newscore_tag}</div>}
									</>,
								})) {
									handleCreateLead();
								}
							}}
						>Create Lead</Button>
					</Col>
				</Row>
			</>)}
		</Container>
	);
}
