import { faHistory } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getObjectID, getObjectType } from '@newstex/core/reference';
import type { HistoryRecord } from '@newstex/types/history';
import { convertReferenceToString } from '@newstex/types/reference';
import type { Results } from '@newstex/types/results';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import { useAPI } from '~/providers/api-provider';

import { DateTime } from './date-time';
import { PropertyDisplayValue } from './property-display-value';
import UserLink from './user/user-link';

interface PropertyHistoryButtonProps {
	propName?: string;
	item?: Record<string, any>;
}

export function PropertyHistoryButton(props: PropertyHistoryButtonProps) {
	const [visible, setVisible] = useState<boolean>(false);
	const [historyRecords, setHistoryRecords] = useState<HistoryRecord<any, any>[]>([]);
	const api = useAPI();

	useEffect(() => {
		const fetchData = async () => {
			if (visible && props?.item) {
				const itemType = getObjectType(props.item)?.toLowerCase();
				const itemId = getObjectID(props.item);
				const resp = await api.fetchWithAuth<Results<HistoryRecord<any, any>>>(`resources/${itemType}/${itemId}/history?property=${props.propName}`);
				setHistoryRecords(resp?.items);
			}
		};
		fetchData();
	}, [visible, props]);

	return (
		<>
			<button
				className="btn btn-link"
				onClick={() => setVisible(true)}
			>
				<FontAwesomeIcon icon={faHistory} size="lg" />
			</button>
			{createPortal(
				<Modal show={visible} onHide={() => setVisible(false)} fullscreen={true}>
					<Modal.Header>
						<Modal.Title>
							{props.item?.name} - {props.propName}
						</Modal.Title>
						<button
							className="btn-close"
							aria-label="Close"
							data-bs-dismiss="modal"
							onClick={() => setVisible(false)}
						/>
					</Modal.Header>
					<Modal.Body>
						<table className="table table-striped">
							<thead>
								<tr>
									<th>Date</th>
									<th>Old Value</th>
									<th>New Value</th>
									<th>Transaction ID</th>
									<th>Comment</th>
									<th>User</th>
								</tr>
							</thead>
							<tbody className="hoverable">
								{historyRecords?.map((record: HistoryRecord<any, any>) => (
									<tr key={record.transaction_id}>
										<td>
											<DateTime ts={record.ts} />
										</td>
										<td>
											<PropertyDisplayValue propName={record.property as string} propValue={record.old_value} />
										</td>
										<td>
											<PropertyDisplayValue propName={record.property as string} propValue={record.new_value} />
										</td>
										<td>{record.transaction_id}</td>
										<td>{record.comment}</td>
										<td>
											{record.user && <UserLink id={convertReferenceToString(record.user)} />}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</Modal.Body>
				</Modal>,
				document.body,
			)}
		</>
	);
}
