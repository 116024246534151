import { Content } from '@newstex/types/content';
import { Results } from '@newstex/types/results';
import React, { useEffect, useState } from 'react';
import { useAPI } from '~/providers/api-provider';

import BarChart, { BarChartProps } from '../charts/BarChart';
import LoadingSpinner from '../LoadingSpinner';

interface StoryStatsProps {
	content?: Content;
	contents?: Content[];
	defaultPeriod?: 'day' | 'week' | 'month';
}

/**
 * Story Stats widget for a given publisher or publication
 */
export const StoryStats: React.FC<StoryStatsProps> = ({ content, contents, defaultPeriod = 'week' }) => {
	const [storyStats, setStoryStats] = useState<Pick<BarChartProps, 'datasets' | 'labels'>>();
	const [loading, setLoading] = useState<boolean>(true);
	const [period, setPeriod] = useState<'day' | 'week' | 'month'>(defaultPeriod);
	const api = useAPI();

	useEffect(() => {
		let cancelled = false;
		setStoryStats(undefined);
		const loadStoryStats = async (nextToken?: string) => {
			if (cancelled) {
				return;
			}
			setLoading(true);
			let since = '30d';
			if (period === 'day') {
				since = '30d';
			} else if (period === 'week') {
				since = '90d';
			} else if (period === 'month') {
				// 3 Months
				since = '120d';
			}
			const searchParams = new URLSearchParams({
				since,
				timeGroup: period,
			});
			let groupBy = 'recipient';
			const publicationNames: { [key: string]: string } = {};

			if (contents) {
				for (const publication of contents) {
					if (publication?.newstex_id) {
						searchParams.append('newstex_id', publication.newstex_id);
						if (publication.name) {
							publicationNames[String(publication.newstex_id)] = `[${publication.newstex_id}] ${publication.name}`;
						}
					}
				}
				groupBy = 'content';
				searchParams.append('group_by', 'content');
			} else if (content?.newstex_id) {
				searchParams.append('newstex_id', content.newstex_id);
				if (nextToken) {
					searchParams.append('NextToken', nextToken);
				}
			}

			const storyStatsResp = await api.fetchWithAuth<Results<any>>(`stats/story-counts?${searchParams}`);
			if (cancelled) {
				return;
			}

			if (storyStatsResp.nextToken) {
				setTimeout(loadStoryStats, 1000, storyStatsResp.nextToken);
			}

			if (storyStatsResp.items?.length) {
				let labels: BarChartProps['labels'] = [];
				const datasets: BarChartProps['datasets'] = [];
				// Get all unique days first
				for (const item of storyStatsResp.items) {
					if (item.day && !labels.some((label) => label.name === item.day.split(' ')[0])) {
						const date = new Date(item.day);
						labels.push({
							name: item.day.split(' ')[0],
							backgroundColor: (period === 'day' && (date.getDay() === 0 || date.getDay() === 6)) ? 'rgba(0,0,0,0.1)' : undefined,
						});
					}
				}
				// Sort them by oldest first
				labels = labels.sort((a, b) => {
					const dateA = new Date(a.name);
					const dateB = new Date(b.name);
					return dateA.getTime() - dateB.getTime();
				});

				// Then create the datasets, making sure there is a value for each day
				for (const item of storyStatsResp.items) {
					let label = item[groupBy] || 'Received';
					if (groupBy === 'content' && publicationNames[label]) {
						label = publicationNames[label];
					}
					let dataset = datasets.find((d) => d.label === label);
					if (!dataset) {
						// Populate initially with all zeros
						dataset = {
							label,
							data: labels.map(() => 0),
						};
						if (!item[groupBy]) {
							dataset.type = 'line';
							dataset.borderWidth = 2;
							dataset.color = 'green';
							// Insert to the FRONT of the list so this appears on top of the barcharts
							datasets.unshift(dataset);
						} else {
							// Insert everything else to the END of the list so it appears below the
							// line (received)
							datasets.push(dataset);
						}
					}

					if (item.story_count) {
						const labelIndex = labels.findIndex((l) => l.name === item.day.split(' ')[0]);
						dataset.data[labelIndex] = parseInt(item.story_count, 10);
					}
				}

				// Add a "total" line if the groupBy is "content"
				if (groupBy === 'content') {
					const data: number[] = [];
					for (const label of labels) {
						let total = 0;
						for (const dataset of datasets) {
							total += dataset.data[labels.indexOf(label)];
						}
						data.push(total);
					}
					datasets.unshift({
						label: 'Total',
						type: 'line',
						borderWidth: 2,
						color: 'green',
						data,
					});
				}

				if (!cancelled) {
					setStoryStats({
						labels,
						datasets: datasets.slice(0, 10),
					});
				}
			}
			setLoading(false);
		};

		loadStoryStats();
		return () => {
			cancelled = true;
		};
	}, [content, contents, api, period]);

	return (
		<>
			{loading && (
				<LoadingSpinner
					hideTitle={true}
					loading={loading}
				/>
			)}
			{!loading && storyStats && (
				<BarChart
					setPeriod={setPeriod}
					period={period}
					{...storyStats}
				/>
			)}
			{!loading && !storyStats && (
				<center>No story stats</center>
			)}
		</>
	);
};
