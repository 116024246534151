import React from 'react';

export default function PageHeader({
	title,
	info,
}: {
	title: string | React.ReactNode,
	info?: string | React.ReactNode,
}) {
	return (
		<div className="page-header">
			<h1 className="page-heading">{title}</h1>
			{info && <p className="page-info">{info}</p>}
		</div>
	);
}
