import {
	faDownload,
	faPersonDigging,
	faSave,
	faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HotTable, HotTableClass } from '@handsontable/react';
import { camel } from 'case';
import Handsontable from 'handsontable';
import { ColumnSettings } from 'handsontable/settings';
import HyperFormula from 'hyperformula';
import { useRef, useState } from 'react';
import {
	Alert,
	Button,
	Col,
	Container,
	Row,
} from 'react-bootstrap';
import PageHeader from '~/components/PageHeader';

/**
 * Upload and process matching reports
 */
export function NewscrunchMatchingReportPage() {
	const hotRef = useRef<HotTableClass | null>(null);
	const [loading, setLoading] = useState(false);
	const [headers, setHeaders] = useState<ColumnSettings[] | undefined>(undefined);
	const [data, setData] = useState<any[]>([
		['', '', '', ''],
		['', '', '', ''],
		['', '', '', ''],
		['', '', '', ''],
		['', '', '', ''],
	]);

	const exportToCSV = () => {
		const hot = hotRef.current?.hotInstance;
		if (hot) {
			const exportPlugin = hot.getPlugin('exportFile');
			exportPlugin.downloadFile('csv', {
				bom: false,
				columnDelimiter: ',',
				columnHeaders: true,
				fileExtension: 'csv',
				filename: `NewsCrunch-${new Date().toISOString().split('T')[0].split('-').join('')}`,
				mimeType: 'text/csv',
				rowDelimiter: '\r\n',
			});
		}
	};

	const analyzeData = (d: any[]) => {
		console.log('Analyzing data', d);
	};

	const submitReport = async () => {
		setLoading(true);
		const hot = hotRef.current?.hotInstance;
		if (hot) {
			const hotData = hot.getSourceData();
			// If we don't have headers yet, set them based on the first row
			if (!headers?.length && hotData?.length) {
				const headerValues = hotData[0].map((col: string) => ({
					data: camel(col),
					title: col,
				}));
				console.log('headerValues', headerValues);
				setHeaders(headerValues);
				console.log('data', hotData.slice(1));
				const outputData: any[] = [];
				for (const row of hotData.slice(1)) {
					const outputRow: any = {};
					for (const [index, col] of row.entries()) {
						if (headerValues[index]?.data) {
							outputRow[headerValues[index].data] = col;
						}
					}
					outputData.push(outputRow);
				}
				console.log('outputData', outputData);
				analyzeData(outputData);
				setData(outputData);
			} else if (hotData.length) {
				analyzeData(hotData);
			}
		}
		setLoading(false);
	};

	return (
		<Container fluid>
			<PageHeader title="Newscrunch" />
			<Alert variant="warning" className="text-center">
				<FontAwesomeIcon icon={faPersonDigging} />&nbsp;
				This is the beta version of Newscrunch. DO NOT use this for clients.
			</Alert>
			<Row>
				<Col>
					<HotTable
						id="newscrunch-hot-table"
						undo
						filters
						dropdownMenu
						formulas={{
							engine: HyperFormula,
						}}
						data={data}
						ref={hotRef}
						colHeaders
						rowHeaders
						columns={headers}
						height="auto"
						licenseKey="non-commercial-and-evaluation"
						stretchH="all"
						autoWrapRow
						autoWrapCol
						persistentState
						minSpareRows={1}
						minSpareCols={0}
						columnSorting
						manualColumnMove
						contextMenu={{
							items: {
								row_above: {
									name: 'Insert row above',
								},
								row_below: {
									name: 'Insert row below',
								},
								separator1: Handsontable.plugins.ContextMenu.SEPARATOR,
								col_left: {
									name: 'Insert column left',
								},
								col_right: {
									name: 'Insert column right',
								},
								remove_col: {
									name: 'Remove column',
								},
								separator2: Handsontable.plugins.ContextMenu.SEPARATOR,
								undo: {
									name: 'Undo',
								},
								redo: {
									name: 'Redo',
								},
							},
						}}
						afterCreateCol={(index, amount) => {
							console.log('afterCreateCol', index, amount);
						}}
					/>
				</Col>
			</Row>
			<Row className="mt-3 mb-3">
				<Col>
					<Button
						onClick={exportToCSV}
						variant="outline-secondary"
						disabled={loading}
					>
						<FontAwesomeIcon icon={faDownload} /> Export to CSV
					</Button>
				</Col>
				<Col>
					<div className="float-end">
						<Button
							onClick={() => {
								setHeaders(undefined);
								setData([
									['', '', '', ''],
									['', '', '', ''],
									['', '', '', ''],
									['', '', '', ''],
									['', '', '', ''],
								]);
								const hot = hotRef.current?.hotInstance;
								if (hot) {
									hot.clear();
								}
							}}
							variant="outline-danger"
							className="ms-2"
							disabled={loading}
						>
							<FontAwesomeIcon icon={faTrash} /> Clear
						</Button>
						<Button
							onClick={submitReport}
							variant="outline-success"
							className="ms-2"
							disabled={loading}
						>
							<FontAwesomeIcon icon={faSave} /> Submit Report
						</Button>
					</div>
				</Col>
			</Row>
		</Container>
	);
}
