import { now } from '@newstex/core/date';
import { convertHtmlToMarkdown } from '@newstex/core/markdown';
import { Story } from '@newstex/types/story';

import { AgentContext } from './agent';
import { GenerateStoryAgent, StoryGenerationResources } from './generate-story-agent';

export interface SECFilingsAgentContext extends Omit<AgentContext, 'resources'> {
	resources: Required<Pick<StoryGenerationResources, 'searchStories' | 'getStoryFile'>>;
}

const SEC_SUMMARY_PROMPT = `You are the subsystem for a professional content writer for Newstex,
specializing in SEC filings.

You provide a summary of a specific SEC filing, focusing on details of the transaction.

For example, if the filing is a Form 4, you would provide a summary of the transaction,
including the number of shares bought or sold, the price per share, and the total value of the transaction.

When possible, include details of transactions, such as "CEO Adam Smith acquired 100,000 additional shares of common stock at $100 per share."

**RETURN NO MORE THAN 500 CHARACTERS**

You should only provide the summary of the transaction, and nothing else.`;

const SEC_PROMPT = `You are a professional content writer for Newstex, specializing in creating well-structured, informative articles about SEC filings.

Your task is to analyze SEC filings and create a comprehensive summary blog post.

Guidelines:
- Write in a professional, journalistic style
- Use clear, factual language
- Break content into logical sections by company
- Include relevant categories based on filing types
- Provide an informative but concise excerpt
- Format content using Markdown
- Include links to sources when referencing filings
- Name companies with significant filings in the headline
- Include ticker symbols next to company names
- Link company names to their Yahoo Finance pages
- Analyze the overall market health based on filing patterns
- Always use ISO format (YYYY-MM-DD) for dates
- Always include "SEC Filings" as the primary provider category
- Do not include any terms suggesting insider trading
- When possible, include details of transactions, such as "CEO Adam Smith acquired 100,000 additional shares of common stock at $100 per share."

Focus on:
- Most significant filings (8-K, 13G, 13D, 4, etc.)
- Companies with multiple or important filings
- Notable market trends or patterns
- Unusual or significant disclosures
- Key details of transactions (e.g. share count, price, etc.)

Remember to:
- Cite original filings using the "permalink" field ex: [{story.headline}]({story.permalink})
- Include total filing counts
- Highlight major points of interest
- Draw meaningful conclusions
- Format all dates as YYYY-MM-DD`;

// A pattern to match invalid/restricted terms like insider trading
const INSIDER_PATTERN = /insider(?:\s+(?:trad(?:e|ing|er|es)|deal(?:s|ing|er)?|transaction)s?)/i;

export class SECFilingsAgent extends GenerateStoryAgent {
	constructor(context: SECFilingsAgentContext) {
		super(context);
		this.setPrompt(SEC_PROMPT);

		// Replace the default story steps with SEC-specific steps
		this.steps = [
			{
				id: 'searching',
				label: 'Search',
				description: 'Searching for SEC Filings...',
			},
			{
				id: 'summarizing',
				label: 'Summarize',
				description: 'Summarizing SEC Filings...',
			},
			{
				id: 'generating',
				label: 'Generate',
				description: 'Generating draft story...',
			},
			{
				id: 'polishing',
				label: 'Polish',
				description: 'Polishing content...',
			},
			{
				id: 'cleaning',
				label: 'Clean',
				description: 'Cleaning sensitive terms...',
			},
		];
	}

	/**
	 * Extract the details of transactions from a given SEC filing
	 * @param story - The SEC filing to extract details from
	 * @returns A string containing the details of transactions
	 */
	async extractFilingDetails(story: Story): Promise<string> {
		// Fetch the HTML content of the filing
		const htmlContent = await this.resources.getStoryFile(story, 'content.html');
		if (!htmlContent) {
			this.logger.error('Failed to fetch HTML content for SEC filing', { story });
			return story.excerpt || '';
		}

		// Convert HTML to Markdown
		const markdownContent = convertHtmlToMarkdown(htmlContent);

		const prompt = [
			`Filing Type: ${story.provider_categories?.join(', ')}`,
			`Filing Date: ${new Date(story.date * 1000).toISOString().split('T')[0]}`,
			'',
			'Please analyze the following SEC filing and provide a concise summary:',
			'',
			markdownContent,
		].join('\n');

		const response = await this.generateText(SEC_SUMMARY_PROMPT, [{
			role: 'user',
			content: prompt,
		}]).catch((err) => {
			this.logger.error('Error generating SEC filing summary', { error: err });
			throw err;
		});
		return response;
	}

	/**
	 * Generate a summary of SEC filings for a given time period
	 */
	async generateFilingsSummary(options: {
		startDate?: Date;
		endDate?: Date;
		maxFilings?: number;
	} = {}): Promise<Partial<Story> | undefined> {
		const {
			maxFilings = 1000,
			endDate = now().startOf('day').toDate(),
			startDate = now().day() === 1
				? now().startOf('day').add(-3, 'day').toDate() // On Monday, go back to Friday
				: now().startOf('day').add(-1, 'day').toDate(), // Otherwise just yesterday
		} = options;

		this.updateProgress('searching', `Searching for SEC Filings from ${startDate.toISOString().split('T')[0]} to ${endDate.toISOString().split('T')[0]}`);

		// Search for SEC filings
		const stories: Story[] = [];
		let hasMore = true;
		let page = 1;
		let totalFilings = 0;
		let facetCounts: Record<string, { values?: Record<string, number> }> | undefined;

		while (hasMore && stories.length < maxFilings) {
			const resp = await (this.resources as Required<Pick<StoryGenerationResources, 'searchStories'>>).searchStories({
				query: '*',
				query_by: ['headline'],
				per_page: 100,
				page,
				sort_by: '_eval([ (provider_categories:4):3, (provider_categories:"8-K"):2, (provider_categories:"SC 13G"):1 ]):DESC',
				filter_by: `content:10009208 && date:[${Math.floor(startDate.getTime() / 1000)}..${Math.floor(endDate.getTime() / 1000)}]`,
				...(facetCounts ? {} : { facet_by: ['provider_categories'] }),
			});

			if (resp.found && resp.found > totalFilings) {
				totalFilings = resp.found;
			}

			if (resp.facets && !facetCounts) {
				facetCounts = resp.facets;
			}

			stories.push(...resp.hits);
			hasMore = resp.hits.length === 100;
			page++;
		}

		if (totalFilings === 0 || !facetCounts?.provider_categories) {
			return undefined;
		}

		this.updateProgress('summarizing', `Found ${stories.length} total SEC filings`);
		// For the top 25 stories, extract the details of transactions
		let totalSummaries = 0;
		for (const story of stories.slice(0, 25)) {
			this.updateProgress('summarizing', `(${++totalSummaries}/25) Extracting transaction details for ${story.headline}`);
			this.logger.debug('Extracting transaction details', { story });
			const transactionDetails = await this.extractFilingDetails(story);
			story.excerpt = transactionDetails;
			this.logger.debug('Extracted transaction details', {
				transactionDetails,
			});
		}

		this.updateProgress('generating', `Generating story for ${stories.length} total SEC filings`);

		// Generate the story
		let story = await this.generateStory([{
			role: 'user',
			content: [
				`This story is for ${now().set('date', startDate.getDate()).format('MMM D, YYYY')}. Do not refer to the publication date as "Today" or "Yesterday".`,
				`There were a total of ${totalFilings} SEC Filings.`,
				'The most relevant SEC Filings are detailed below in JSONL format:',
				'-------------------------------',
				'```jsonl',
				...stories.slice(0, 25).map((s) => JSON.stringify({
					id: s.__id__ || `story-${Date.now()}`,
					headline: s.headline,
					excerpt: `${s.excerpt?.slice(0, 500)} [View Filing](${s.permalink})`,
					permalink: s.permalink,
					date: s.date,
				})),
				'```',
				'-------------------------------',
				'Counts by Filing Type:',
				...Object.entries(facetCounts.provider_categories.values || {})
					.map(([category, count]) => `- ${category}: ${count}`),
				'',
				'-------------------------------',
				'Be sure to summarize the SEC Filings included above, and ONLY the ones listed above.',
				'Include links to the original filings using the "permalink" field.',
				'Your final response should be a complete story in Markdown format following the guidelines specified above.',
				'**DO NOT HALLUCINATE**',
			].join('\n'),
		}]);

		// Sanity check to make sure the story doesn't contain any insider trading related terms
		if (!story?.headline || !story?.excerpt || !story?.html_content) {
			this.logger.info({
				message: 'Generated story is missing required fields',
				story,
			});
			throw new Error('Generated story is missing required fields');
		}

		// Sanity check to make sure the story doesn't contain any insider trading related terms
		if (
			story.headline.match(INSIDER_PATTERN)
			|| story.excerpt.match(INSIDER_PATTERN)
			|| story.html_content.match(INSIDER_PATTERN)
		) {
			this.updateProgress('cleaning', 'Generated story contains insider trading related terms');
			this.logger.info({
				message: 'Generated story contains insider trading related terms',
				story,
			});
			// Run another polishing action, with specific instructions to remove insider trading related terms
			story = await this.cleanStory(story, 'Remove any terms suggesting insider trading, transactions, or deals');
		}

		// If it still contains insider trading related terms, log an error, and replace
		// those terms with "insider activity"
		if (
			story.headline.match(INSIDER_PATTERN)
			|| story.excerpt.match(INSIDER_PATTERN)
			|| story.html_content.match(INSIDER_PATTERN)
		) {
			this.logger.error({
				message: 'Failed to remove insider trading related terms',
				story,
			});
			for (const field of ['headline', 'excerpt', 'html_content']) {
				story[field] = story[field].replace(INSIDER_PATTERN, 'insider activity');
			}
		}

		// Make sure the categories are an array
		if (!Array.isArray(story.provider_categories)) {
			story.provider_categories = [];
		}

		// Make sure the categories include SEC Filing
		if (!story.provider_categories.includes('SEC Filings')) {
			story.provider_categories.push('SEC Filings');
		}

		// Remove "Insider Trading" category patterns
		story.provider_categories = story.provider_categories.filter((category) => !category.match(INSIDER_PATTERN));

		return story;
	}
}
