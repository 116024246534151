// Products/:id
import type { Product } from '@newstex/types/product';
import { useEffect, useState } from 'react';
import { Card, Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import EditButton from '~/components/edit-button';
import LoadingSpinner from '~/components/LoadingSpinner';
import { RefreshButton } from '~/components/refresh-button';
import { RequirePermissions } from '~/components/require-permissions';
import { PropertiesTable } from '~/components/tables/properties-table';
import ViewStoriesButton from '~/components/view-stories-button';
import { useAPI } from '~/providers/api-provider';

export function ProductPage() {
	const params = useParams();
	const api = useAPI();
	const [product, setProduct] = useState<Product>();
	const [loading, setLoading] = useState(true);

	const fetchData = async (refresh = false) => {
		setLoading(true);
		const resp = await api.fetchWithAuth(
			`resources/Product/${params.id}`,
			refresh ? { cache: 'reload' } : undefined,
		);
		setProduct(resp.items?.[0]);
		setLoading(false);
	};

	useEffect(() => {
		fetchData();
	}, [params]);

	return (
		<Container fluid>
			<div className="title-wrapper pt-30">
				<div className="row align-items-center">
					<div className="col-md-6">
						<div className="title">
							<h2>
								{product?.name}
							</h2>
							<h6 className="text-muted">
								{product?.group}
							</h6>
						</div>
					</div>
					<div className="col-md-6 text-end">
						<div className="breadcrumb-wrapper">
							<nav aria-label="breadcrumb" className="mb-0">
								<ol className="breadcrumb">
									<li className="breadcrumb-item active">
										<a>Product</a>
									</li>
								</ol>
							</nav>
							<div className="float-end">
								<RequirePermissions permissions={['admin']}>
									<span className="ps-1">
										<EditButton
											item={{
												...product,
												$type: 'Product',
											}}
											size="sm"
											refreshHandler={() => fetchData(true)}
										/>
									</span>
								</RequirePermissions>
								<span className="ps-1">
									{product && <ViewStoriesButton product={product.$id} size="sm" />}
								</span>
								<span className="ps-1">
									<RefreshButton size="sm" refreshHandler={() => {
										return fetchData(true);
									}} />
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<hr />

			<div className="row">
				<div className="col-12">
					<Card className="product">
						<Card.Body>
							<LoadingSpinner loading={loading} />
							<PropertiesTable item={product} />
						</Card.Body>
					</Card>
				</div>
			</div>
		</Container>
	);
}
