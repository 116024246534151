import { Category } from '@newstex/types/category';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface CategoryCardProps {
	item?: Category;
	hitCount?: number;
}

export function CategoryCard(props: CategoryCardProps) {
	if (!props.item) {
		return <></>;
	}
	return (
		<Card className="category">
			<Card.Body>
				<Card.Title>
					<Link to={`/categories/${props.item.code}`}>
						{props.item.name} [{props.item.code}]
						{props.hitCount && (
							<>
								&nbsp;
								<span className="badge text-bg-secondary">{props.hitCount}</span>
								&nbsp;
							</>
						)}
					</Link>
					<h6 className="text-muted mb-3">Category</h6>
				</Card.Title>
				<div>
					{props.item.description}
				</div>
			</Card.Body>
		</Card>
	);
}
