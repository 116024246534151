import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export interface Breadcrumb {
	label: string;
	href?: string;
}

export const PageTitle: FC<{
	title: string,
	info?: string,
	count?: number,
	breadcrumbs?: Breadcrumb[],
	children?: React.ReactNode,
}> = (props) => {
	return (
		<>
			<div className="title-wrapper pt-30">
				<div className="row align-items-center">
					<div className="col-md-6">
						<div className="title">
							<h2>
								{props.title}
								{props.info && (
									<OverlayTrigger
										placement="bottom"
										overlay={
											<Tooltip>
												{props.info}
											</Tooltip>
										}
									>
										<FontAwesomeIcon
											className="ms-2 text-muted"
											size="2xs"
											icon={faInfoCircle}
											title={props.info}
										/>
									</OverlayTrigger>
								)}
								{props.count && (
									<Badge pill bg="secondary" className="ms-2">{props.count.toLocaleString('en')}</Badge>
								)}
							</h2>
						</div>
					</div>
					<div className="col-md-6">
						<div className="breadcrumb-wrapper">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									{props.breadcrumbs?.map((breadcrumb, index) => (
										<li className="breadcrumb-item" key={index}>
											{breadcrumb.href ? (
												<Link to={breadcrumb.href}>{breadcrumb.label}</Link>
											) : (
												breadcrumb.label
											)}
										</li>
									))}
									<li className="breadcrumb-item active" aria-current="page">
										{props.title}
									</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
				{props.children}
			</div>
		</>
	);
};
