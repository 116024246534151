import type { AccountInfoResponse } from '@newstex/types/responses/info';
/**
 * User Info (/info) Provider
 */
import {
	createContext,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { useAPI } from './api-provider';
import { useAuth } from './auth';
import { useCacheStore } from '~/stores/cache-store';

export interface UserInfo extends AccountInfoResponse {
	refresh: () => Promise<UserInfo | null>;
	initials?: string;
}
const UserInfoContext = createContext<UserInfo | null>(null);

export function UserInfoProvider(props: React.PropsWithChildren<{}>) {
	const [data, setData] = useState<AccountInfoResponse | null>(null);
	const [error, setError] = useState<Error | null>(null);
	const { fetchWithAuth } = useAPI();
	const { user, runAs } = useAuth();
	const refreshLock = useRef<Promise<UserInfo | null> | null>(null);
	const toastId = useRef<string | number | null>(null);
	const cacheStore = useCacheStore();

	const refresh = async () => {
		// If there's already a refresh in progress, return that promise
		if (refreshLock.current) {
			return refreshLock.current;
		}

		// Create new refresh promise
		refreshLock.current = (async () => {
			try {
				// Show loading toast only on manual refreshes (not initial load)
				// or when credentials have expired
				if (data || error?.message?.toLowerCase().includes('expired')) {
					if (toastId.current) {
						toast.update(toastId.current, {
							render: 'Refreshing credentials...',
							type: 'info',
							isLoading: true,
							autoClose: false,
							toastId: 'refresh-credentials',
						});
					} else {
						toastId.current = toast.loading('Refreshing credentials...', {
							toastId: 'refresh-credentials',
						});
					}
				}

				const response = await fetchWithAuth<AccountInfoResponse>('info', {
					cache: 'reload',
				});

				setError(null);
				setData(response);
				cacheStore.setItem('userInfo', response);

				if (toastId.current) {
					toast.update(toastId.current, {
						render: 'Credentials refreshed successfully',
						type: 'success',
						isLoading: false,
						autoClose: 2000,
					});
					// Ensure the toast is properly cleaned up
					setTimeout(() => {
						if (toastId.current) {
							toast.dismiss(toastId.current);
							toastId.current = null;
						}
					}, 2000);
				}

				return {
					...response,
					refresh,
					initials: response.name?.split(' ').map((n) => n[0]).join('').toUpperCase(),
				};
			} catch (e: any) {
				setError(e);
				if (toastId.current) {
					toast.update(toastId.current, {
						render: `Failed to refresh credentials: ${e.message}`,
						type: 'error',
						isLoading: false,
						autoClose: 5000,
					});
					// Ensure the toast is properly cleaned up
					setTimeout(() => {
						if (toastId.current) {
							toast.dismiss(toastId.current);
							toastId.current = null;
						}
					}, 5000);
				}
				return null;
			} finally {
				refreshLock.current = null;
			}
		})();

		return refreshLock.current;
	};

	useEffect(() => {
		refresh();
	}, [user, runAs]);

	return (
		<UserInfoContext.Provider value={data ? {
			...data,
			initials: data.name?.split(' ').map((n) => n[0]).join('').toUpperCase(),
			refresh,
		} : null}>{
				error?.message ? <Modal
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={true}
					id="user-info-error-modal"
				>
					<Modal.Header>
						<Modal.Title id="contained-modal-title-vcenter">
						Fatal Error
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className="d-flex justify-content-center pt-5 pb-5">
						{window.location.hostname === 'admin-dev.newstex.com' && (
							<div className="alert alert-warning mb-3">
								You are currently on the development environment. Consider switching to <a href="https://admin.newstex.com">production</a>.
							</div>
						)}
						{error.message}<br /><br />
					Try refreshing the page. If the problem persists, contact support.
					</Modal.Body>
					<Modal.Footer>
						<Button variant="danger" onClick={() => {
							localStorage.clear();
							cacheStore.clear();
							window.location.reload();
						}}>
							Clear Cache and Reload
						</Button>
						<Button variant="secondary" onClick={() => window.location.reload()}>Refresh</Button>
					</Modal.Footer>
				</Modal> : props.children
			}</UserInfoContext.Provider>
	);
}

export function useUserInfo() {
	const result = useContext(UserInfoContext);
	if (result === undefined) {
		throw new Error('useUserInfo must be used within a UserInfoProvider');
	}
	return result;
}
