import { Feed as FeedType } from '@newstex/types/feed';

import type { CollectionFieldSchmaForObject } from './types';

export const Feed: CollectionFieldSchmaForObject<FeedType> = [
	{
		name: 'auth_type',
		type: 'string',
		store: true,
		facet: true,
		optional: true,
	},
	{
		name: 'content',
		type: 'string',
		store: true,
		facet: true,
		optional: true,
		defaultSearchOrder: 10,
		reference: 'Publication.id',
	},
	{
		name: 'last_read',
		type: 'int64',
		range_index: true,
		store: true,
		facet: true,
		optional: true,
		sort: true,
	},
	{
		name: 'next_read',
		type: 'int64',
		range_index: true,
		store: true,
		facet: true,
		optional: true,
		sort: true,
	},
	{
		name: 'priority',
		type: 'string',
		store: true,
		facet: true,
		optional: true,
		sort: true,
	},
	{
		name: 'segment',
		type: 'int32',
		store: true,
		facet: true,
		optional: true,
	},
	{
		name: 'status',
		type: 'string',
		store: true,
		facet: true,
		optional: true,
		sort: true,
	},
	{
		name: 'type',
		type: 'string',
		store: true,
		facet: true,
		optional: true,
	},
	{
		name: 'name',
		type: 'string',
		store: true,
		sort: true,
		defaultSearchOrder: 1,
	},
	{
		name: 'feed_uri',
		type: 'string',
		store: true,
		defaultSearchOrder: 0,
		optional: true,
	},
	{
		name: 'yt_id',
		type: 'string',
		store: true,
		optional: true,
	},
	{
		name: 'user_id',
		type: 'string',
		store: true,
		optional: true,
	},
	{
		name: 'last_story_count',
		type: 'int32',
		sort: true,
		store: true,
		optional: true,
	},
	{
		name: 'last_received_date',
		type: 'int64',
		sort: true,
		range_index: true,
		store: true,
		optional: true,
	},
	{
		name: 'last_post_date',
		type: 'int64',
		sort: true,
		range_index: true,
		store: true,
		optional: true,
	},
	{
		name: 'last_error.time',
		type: 'int64',
		store: true,
		optional: true,
		sort: true,
	},
];
