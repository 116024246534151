export interface ErrorResponse {
	$type: 'Error';
	message?: string;
	details?: string;
	code?: string;
	statusCode?: number;
	retryable?: boolean;
	aws_request_id?: string;
}

export function isErrorType(obj: any): obj is ErrorResponse {
	return obj?.$type === 'Error';
}

export class ValidationError extends Error {
	public readonly code = 'VALIDATION_ERROR';
	public readonly statusCode = 400;
	public readonly retryable = false;

	constructor(
		message: string,
		public obj?: any,
		public missingFields?: string[],
	) {
		super(message);
		this.name = 'ValidationError';
		this.obj = obj;
		this.missingFields = missingFields;
	}
}
