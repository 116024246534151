import { getHubSpotFieldName } from '@newstex/ai/hubspot';
import { QualificationScores } from '@newstex/ai/qualify';
import { AbortError, AccessDeniedError, UserError } from '@newstex/core/errors';
import { sleep } from '@newstex/core/sleep';
import { type TypedObject, getTypeName } from '@newstex/types/base';
import type { DetectFeedResponse } from '@newstex/types/detect-feed';
import { HubSpotCompany } from '@newstex/types/hubspot';
import { createCompanyFromDetect } from '@newstex/types/hubspot/create-company-from-detect';
import { convertReferenceToString } from '@newstex/types/reference';
import type { GetHubSpotRecordsRequest } from '@newstex/types/requests/get-hubspot-records';
import type { GetMetricStatsRequest } from '@newstex/types/requests/get-metric-stats';
import type { GetHubSpotRecordsResponse } from '@newstex/types/responses/get-hubspot-records';
import type { GetMetricStatsResponse } from '@newstex/types/responses/get-metric-stats';
import { createContext, useContext } from 'react';
import { toast } from 'react-toastify';

import type { AnalyticsContextType } from './analytics-provider';
import { Logout, UserType, useAuth } from './auth';

const DEFAULT_MAX_ATTEMPTS = 5;
const MAX_CONCURRENT_REQUESTS = 5;
const requestQueue: (() => void)[] = [];
const activeRequests: Promise<any>[] = [];

export interface RequestInitWithMaxAttempts extends RequestInit {
	maxAttempts?: number;
}

export interface APIContextType {
	fetchWithAuthRaw: (path: string, options?: RequestInitWithMaxAttempts) => Promise<Response>;
	fetchWithAuth: <T = any>(path: string, options?: RequestInitWithMaxAttempts) => Promise<T>;
	getMetricStats: (
		args: GetMetricStatsRequest,
		options?: RequestInitWithMaxAttempts,
	) => Promise<GetMetricStatsResponse>;
	getHubSpotRecords: (
		args: GetHubSpotRecordsRequest & {
			abortController?: AbortController,
			nextToken?: string,
		},
		options?: RequestInitWithMaxAttempts,
	) => Promise<GetHubSpotRecordsResponse>;
	syncLeadToHubSpot: (
		item: LeadData,
		formData: Partial<Record<keyof HubSpotCompany, any>>,
		analytics?: AnalyticsContextType | null,
	) => Promise<void>;
	updateItem: <T extends TypedObject>(
		item: T,
		updates: Partial<T>,
		options?: RequestInitWithMaxAttempts,
	) => Promise<T>;
	createItem: <T extends TypedObject>(item: T, options?: RequestInitWithMaxAttempts) => Promise<T>;
	getItem: <T extends TypedObject>(item: T, options?: RequestInitWithMaxAttempts) => Promise<T>;
}

export interface LeadData extends DetectFeedResponse {
	id: string;
	running?: boolean;
	sync_status?: 'Waiting' | 'Checking' | 'Syncing' | 'Updated' | 'Created' | 'Failed' | 'Ready';
	ai_rankings?: QualificationScores;
	max_ai_score?: number;
	email?: string;
}

const CACHED_REQUESTS: Record<string, Promise<any>> = {};
const CACHE_TIMEOUT = 1000 * 60 * 5; // 5 minutes

// Create a Context for the API functions
const APIContext = createContext<APIContextType | undefined>(undefined);

// Create a Provider Component
export function APIProvider({
	apiBaseUrl,
	children,
	getUserFunction,
}: React.PropsWithChildren<{ apiBaseUrl?: string, getUserFunction?: () => {
	user: UserType;
	runAs?: string;
}}>) {
	const { user, runAs } = getUserFunction ? getUserFunction() : useAuth();

	// Create a fetch function that includes the Authorization header
	const doFetchWithAuthRaw = async (
		path: string,
		options?: RequestInitWithMaxAttempts,
		attempt = 0,
		toastId?: string | number,
	): Promise<Response> => {
		// Allow using "Google" type for google auth via the extension
		const authHeader = [
			user?.type === 'google' ? 'Google' : 'Bearer',
			user?.sessionToken,
			runAs || '',
		].join(' ').trim();

		const url = `${apiBaseUrl || import.meta.env.VITE_API_URL}/${path}`;
		const response = await fetch(url, {
			...options,
			headers: {
				...options?.headers,
				Authorization: authHeader,
			},
		}).catch((e) => {
			if (e.name === 'AbortError') {
				console.log('AbortError', e);
				return {
					ok: false,
					status: 600,
					statusText: 'Aborted',
				} as Response;
			}
			console.log('API Error', e);
			return {
				ok: false,
				status: 500,
				statusText: e.message || e.msg || String(e),
			} as Response;
		});

		if (!response.ok) {
			console.warn(response);

			// Forbidden (Access Denied but we know who the user is)
			if (response.status === 403) {
				throw new AccessDeniedError();
			}

			// TODO: Make this show a warning rather than just forcably logging them out or throwing an
			// error that's not seen anywhere by the user.
			if (response.status === 403 || response.status === 401) {
				Logout();
			}

			if (response.status === 600) {
				throw new AbortError();
			}

			// Allow retrying the request
			if (
				response.status >= 500
					&& response.status < 600
					&& attempt < (options?.maxAttempts || DEFAULT_MAX_ATTEMPTS)
			) {
				const errMessage = `Temporary API Error: ${response.statusText || response.status}${
					' '
				}[Attempt ${(attempt || 0) + 1}/${(options?.maxAttempts || DEFAULT_MAX_ATTEMPTS) + 1}]`;
				if (toastId) {
					toast.update(toastId, {
						render: <>
							<div>{errMessage}</div>
							<small>${path.slice(0, 100)}</small>
						</>,
						isLoading: true,
					});
				} else {
					toastId = toast.error(<>
						<div>{errMessage}</div>
						<small>${path.slice(0, 100)}</small>
					</>, {
						toastId: 'api-error-temporary',
					});
				}

				await sleep(500 * attempt);
				return doFetchWithAuthRaw(path, options, attempt + 1);
			}
			let errResponse: any;
			let errMessage = response.statusText || response.status;
			try {
				errResponse = await response.json();
				if (errResponse.message) {
					errMessage = errResponse.message;
				}
			} catch (e) {
				console.error('Could not parse error message', e);
			}

			if (errResponse?.statusCode && errResponse?.__type__ === 'Error') {
				throw new UserError(errResponse);
			}

			throw new Error(`API request failed: ${errMessage}`);
		}

		if (toastId) {
			toast.dismiss(toastId);
		}

		return response;
	};

	const fetchWithAuthRaw = async (
		path: string,
		options?: RequestInitWithMaxAttempts,
		attempt = 0,
		toastId?: string | number,
	): Promise<Response> => {
		// Add queue handling
		const maxConcurrentRequests = options?.maxAttempts || MAX_CONCURRENT_REQUESTS;
		if (activeRequests.length >= maxConcurrentRequests) {
			console.log('Queueing request', path);
			await new Promise<void>((resolve) => {
				requestQueue.push(resolve);
			});
			console.log('Request processed', path);
		}
		const promise = doFetchWithAuthRaw(path, options, attempt, toastId);
		activeRequests.push(promise);
		promise.finally(() => {
			activeRequests.splice(activeRequests.indexOf(promise), 1);
			if (requestQueue.length > 0) {
				const next = requestQueue.shift();
				next?.();
			}
		});
		return promise;
	};

	const fetchWithAuth = async <T = any>(path: string, options?: RequestInitWithMaxAttempts) => {
		if (!options) {
			if (CACHED_REQUESTS[path] == null) {
				CACHED_REQUESTS[path] = fetchWithAuthRaw(path, options).then((response) => {
					setTimeout(() => {
						delete CACHED_REQUESTS[path];
					}, CACHE_TIMEOUT);
					return response.json();
				});
			}
			return CACHED_REQUESTS[path];
		}
		const response = await fetchWithAuthRaw(path, options);
		return response.json() as Promise<T>;
	};

	const getMetricStats = async (
		args: GetMetricStatsRequest,
		options?: RequestInitWithMaxAttempts,
	) => {
		const params: Record<string, string> = {
			namespace: args.namespace,
			metricName: args.metricName,
		};
		if (args.startTime) {
			params.startTime = args.startTime;
		}

		if (args.endTime) {
			params.endTime = args.endTime;
		}

		if (args.dimensions) {
			params.dimensions = JSON.stringify(args.dimensions);
		}

		if (args.period) {
			params.period = args.period;
		}

		const url = `metrics/stats?${new URLSearchParams(params).toString()}`;
		return fetchWithAuth<GetMetricStatsResponse>(url, options);
	};

	const updateItem = async <T extends TypedObject>(
		item: T,
		updates: Partial<T>,
		options?: RequestInitWithMaxAttempts,
	) => {
		const url = `resources/${getTypeName(item)}/${convertReferenceToString(item)}`;
		return fetchWithAuth<T>(url, {
			...(options || {}),
			method: 'PATCH',
			body: JSON.stringify(updates),
		});
	};

	const createItem = async <T extends TypedObject>(
		item: T,
		options?: RequestInitWithMaxAttempts,
	) => {
		const url = `resources/${getTypeName(item)}`;
		return fetchWithAuth<T>(url, {
			...(options || {}),
			method: 'POST',
			body: JSON.stringify(item),
		});
	};

	const getItem = async <T extends TypedObject>(item: T, options?: RequestInitWithMaxAttempts) => {
		const url = `resources/${getTypeName(item)}/${convertReferenceToString(item)}`;
		const resp = await fetchWithAuth<T>(url, options);
		if (resp.items) {
			return resp.items[0];
		}
		return null;
	};

	const getHubSpotRecords = async (
		args: GetHubSpotRecordsRequest & {
			abortController?: AbortController,
			nextToken?: string,
		},
		options?: RequestInitWithMaxAttempts,
	) => {
		const url = `hubspot/records/${args.type}/${args.id}${args.nextToken ? `?nextToken=${args.nextToken}` : ''}`;
		const output: GetHubSpotRecordsResponse = {
			$type: 'Results',
			items: [],
		};

		if (args.abortController?.signal.aborted) {
			throw new AbortError();
		}

		const resp = await fetchWithAuth<GetHubSpotRecordsResponse>(url, options);
		if (args.abortController?.signal.aborted) {
			throw new AbortError();
		}

		if (resp.list) {
			output.list = resp.list;
		}

		if (resp.items) {
			output.items.push(...resp.items);
		}

		// Include nextToken in response so caller can handle pagination
		if (resp.nextToken) {
			output.nextToken = resp.nextToken;
		}

		return output;
	};

	const syncLeadToHubSpot = async (
		lead: LeadData,
		formData: Partial<Record<keyof HubSpotCompany, any>>,
		analytics?: AnalyticsContextType | null,
	) => {
		try {
			const matchedCategories: string[] = [];
			for (const [category, score] of Object.entries(lead.newstex_categories ?? {})) {
				if (score >= 10) {
					matchedCategories.push(category);
				}
			}
			// Include a $note (which gets added as a Note object in HubSpot)
			const note = lead.qualified ? Object.entries(lead.ai_rankings ?? {})
				.map(([client, score]) => `<b>${client}</b>: <i>${score.score}</i> - ${score.reason}`)
				.join('<br/>') : lead.rejection_reasons?.join('<br/>');

			// Update existing records
			if (lead.hubspot_records?.length) {
				const hsRecord = lead.hubspot_records[0];
				const hsObjectID = hsRecord.hs_object_id;
				analytics?.trackEvent('Update HubSpot Company', {
					id: hsObjectID,
					url: lead.url,
					tag: formData.newscore_tag,
					provenance: formData.provenance,
				});
				const metadataSources: any[] = (hsRecord.metadata_sources as any) || ['NewsCore'];
				if (!metadataSources.includes('NewsCore')) {
					metadataSources.push('NewsCore');
				}
				const hsCompany: Partial<HubSpotCompany & { $note: string }> = {
					ai_check_date: new Date().toISOString().split('T')[0],
					ai_qualified: lead.qualified ? 'true' : 'false',
					ai_headline_score: lead.headline_score,
					ai_story_categories: matchedCategories?.join(';') || '' as any,
					ai_rejection_reason: lead.rejection_reasons?.join(';') || '',
					ai_qualification_score: Math.max(...(Object.values(lead.ai_rankings ?? {}).map((r) => r.score) || [0])),
					// Don't update existing provenances
					// provenance: formData.provenance,
					// But DO update the tag
					newscore_tag: formData.newscore_tag,
					// And update the metadata_sources
					metadata_sources: metadataSources,
					// Include a $note (which gets added as a Note object in HubSpot)
					$note: note,
				};

				if (formData.ai_qualification_score != null) {
					hsCompany.ai_qualification_score = formData.ai_qualification_score;
				}

				if (lead.ai_rankings) {
					for (const [client, score] of Object.entries(lead.ai_rankings)) {
						const fieldName = getHubSpotFieldName(client);
						if (fieldName) {
							hsCompany[fieldName] = score.score;
						}
					}
				}
				await updateItem<any>({
					$type: 'HubSpotCompany',
					$id: hsObjectID,
				}, hsCompany);
			} else {
				// Or create a new one
				const hsCompany = createCompanyFromDetect(lead);
				hsCompany.ai_check_date = new Date().toISOString().split('T')[0];
				hsCompany.ai_qualified = lead.qualified ? 'true' : 'false';
				hsCompany.ai_headline_score = lead.headline_score;
				hsCompany.ai_story_categories = matchedCategories?.join(';') || '' as any;
				hsCompany.ai_rejection_reason = lead.rejection_reasons?.join(';') || '';
				if (lead.ai_rankings) {
					for (const [client, score] of Object.entries(lead.ai_rankings)) {
						const fieldName = getHubSpotFieldName(client);
						if (fieldName) {
							hsCompany[fieldName] = score.score;
						}
					}
					hsCompany.ai_qualification_score = Math.max(
						...(Object.values(lead.ai_rankings ?? {})
							.map((r) => r.score) || [0]),
					);
				}
				const hsResp = await createItem<any>({
					$type: 'HubSpotCompany',
					...hsCompany,
					articles: lead.articles,
					provenance: formData.provenance,
					newscore_tag: formData.newscore_tag,
					// Include a $note (which gets added as a Note object in HubSpot)
					$note: note,
				});
				analytics?.trackEvent('Create HubSpot Company', {
					id: hsResp?.hs_object_id,
					url: lead.url,
					tag: formData.newscore_tag,
					provenance: formData.provenance,
				});
				const hsObjectID = hsResp?.hs_object_id || hsResp?.id;
				if (hsObjectID) {
					lead.hubspot_records = [hsResp];
				} else {
					console.error('Failed to create HubSpot Company', lead.url, hsResp);
				}
			}
		} catch (e: any) {
			console.error(e);
		}
	};

	// Provide the fetch function
	return (
		<APIContext.Provider value={{
			fetchWithAuthRaw,
			fetchWithAuth,
			getMetricStats,
			getHubSpotRecords,
			updateItem,
			createItem,
			getItem,
			syncLeadToHubSpot,
		}}>
			{children}
		</APIContext.Provider>
	);
}

// Create a custom hook to use the API context
export function useAPI() {
	const context = useContext(APIContext);
	if (context === undefined) {
		throw new Error('useAPI must be used within a APIProvider');
	}
	return context;
}
