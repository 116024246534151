import { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import type { ModalFields } from '~/commands/type';
import { useAPI } from '~/providers/api-provider';

export interface ModalDataEditorProps {
	modalData: ModalFields | null;
	setModalData: (data: ModalFields | null) => void;
	onClose?: () => void;
}

export default function ModalDataEditor({
	modalData,
	setModalData,
	onClose,
}: ModalDataEditorProps) {
	const [modalLoading, setModalLoading] = useState(false);
	const [modalValues, setModalValues] = useState<Record<string, string> | null>(null);
	const api = useAPI();

	return (
		<Modal show={modalData !== null} onHide={() => {
			setModalData(null);
			setModalValues(null);
			onClose?.();
		}} className="commandbar modal-xl">
			<Modal.Header>
				<Modal.Title>
					{modalData?.title}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{modalData?.fields.map((field) => (
					<Form.Group key={field.id}>
						<Form.Label>{field.title}</Form.Label>
						<Form.Control
							disabled={modalLoading}
							placeholder={field.placeholder}
							aria-label={field.placeholder}
							onChange={(e) => setModalValues({
								...modalValues,
								[field.id]: e.currentTarget.value,
							})}
						/>
						{field.help && <Form.Text>{field.help}</Form.Text>}
					</Form.Group>
				))}
			</Modal.Body>
			<Modal.Footer className="d-flex justify-content-between">
				<Button disabled={modalLoading} variant="danger" className="float-left" onClick={() => setModalData(null)}>Cancel</Button>
				<Button disabled={modalLoading} variant="success" onClick={(e) => {
					setModalLoading(true);
					const toastID = toast.loading(`Running ${modalData?.title}`);
					api.fetchWithAuth(`/scripts/${modalData?.id}`, {
						method: 'POST',
						body: JSON.stringify(modalValues),
					}).then((actionResp) => {
						setModalLoading(false);
						setModalValues(null);
						setModalData(null);
						toast.update(toastID, {
							render: <>
								<b>Finished:</b> <i>{modalData?.title}</i>
								<div className="text-muted">
									{actionResp.message}
								</div>
							</>,
							type: 'success',
							isLoading: false,
							autoClose: 30000,
						});
					});
				}}>Submit</Button>
			</Modal.Footer>
		</Modal>
	);
}
