import { Feed } from '@newstex/types/feed';
import { SearchFacetConfig, SearchWithFacets } from '~/components/search-with-facets';
import { FeedsTable } from '~/components/tables/feeds-table';

export const FeedFacetConfig: SearchFacetConfig[] = [
	{
		attribute: 'status',
		title: 'Status',
		options: [
			{ label: 'All', value: '' },
			{ label: 'Active', value: 'Active' },
			{ label: 'Inactive', value: 'Inactive' },
			{ label: 'Standby', value: 'Standby' },
			{ label: 'Archived', value: 'Archived' },
		],
	},
	{
		attribute: 'priority',
		title: 'Priority',
		options: [
			{ label: 'All', value: '' },
			{ label: 'High', value: 'high' },
			{ label: 'Standard', value: 'standard' },
			{ label: 'Low', value: 'low' },
		],
	},
	{
		attribute: 'segment',
		title: 'Segment',
		options: [
			{ label: 'All', value: '' },
			{ label: 'Prod', value: '-1' },
			{ label: 'Dev', value: '-2' },
			{ label: 'Chrome Extension', value: '-3' },
		],
	},
];

export const FeedSearchPage = () => {
	const fallbackSearch = async (q: string): Promise<Feed[]> => {
		console.log('Fallback Search', q);
		return [];
	};

	return (
		<SearchWithFacets
			indexName="Feed"
			title="Feeds"
			info="Feeds are searchable with various facets."
			defaultSort="next_read:desc"
			resultsTable={FeedsTable}
			fallbackSearch={fallbackSearch}
			facets={FeedFacetConfig}
		/>
	);
};
