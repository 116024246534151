/**
 * Org Type options
 */
export const ORG_TYPES = [
	'Independent',
	'Corporate',
	'Traditional News',
	'Government',
];

/**
 * Language codes and names
 */
export const LANGUAGES = {
	en: 'English',
	fr: 'French',
	de: 'German',
	ru: 'Russian',
	es: 'Spanish, Castilian',
	aa: 'Afar',
	ab: 'Abkhazian',
	af: 'Afrikaans',
	ak: 'Akan',
	sq: 'Albanian',
	am: 'Amharic',
	ar: 'Arabic',
	an: 'Aragonese',
	hy: 'Armenian',
	as: 'Assamese',
	av: 'Avaric',
	ae: 'Avestan',
	ay: 'Aymara',
	az: 'Azerbaijani',
	ba: 'Bashkir',
	bm: 'Bambara',
	eu: 'Basque',
	be: 'Belarusian',
	bn: 'Bengali',
	bh: 'Bihari languages',
	bi: 'Bislama',
	bs: 'Bosnian',
	br: 'Breton',
	bg: 'Bulgarian',
	my: 'Burmese',
	ca: 'Catalan, Valencian',
	ch: 'Chamorro',
	ce: 'Chechen',
	zh: 'Chinese',
	'Church Slavonic': 'Church Slavic, Old Slavonic',
	cv: 'Chuvash',
	kw: 'Cornish',
	co: 'Corsican',
	cr: 'Cree',
	cs: 'Czech',
	da: 'Danish',
	dv: 'Divehi, Dhivehi, Maldivian',
	nl: 'Dutch, Flemish',
	dz: 'Dzongkha',
	eo: 'Esperanto',
	et: 'Estonian',
	ee: 'Ewe',
	fo: 'Faroese',
	fj: 'Fijian',
	fi: 'Finnish',
	fy: 'Western Frisian',
	ff: 'Fulah',
	ka: 'Georgian',
	gd: 'Gaelic, Scottish Gaelic',
	ga: 'Irish',
	gl: 'Galician',
	gv: 'Manx',
	el: 'Greek, Modern (1453-)',
	gn: 'Guarani',
	gu: 'Gujarati',
	ht: 'Haitian, Haitian Creole',
	ha: 'Hausa',
	he: 'Hebrew',
	hz: 'Herero',
	hi: 'Hindi',
	ho: 'Hiri Motu',
	hr: 'Croatian',
	hu: 'Hungarian',
	ig: 'Igbo',
	is: 'Icelandic',
	io: 'Ido',
	ii: 'Sichuan Yi, Nuosu',
	iu: 'Inuktitut',
	ie: 'Interlingue, Occidental',
	ia: 'Interlingua (International Auxiliary Language Association)',
	id: 'Indonesian',
	ik: 'Inupiaq',
	it: 'Italian',
	jv: 'Javanese',
	ja: 'Japanese',
	kl: 'Kalaallisut, Greenlandic',
	kn: 'Kannada',
	ks: 'Kashmiri',
	kr: 'Kanuri',
	kk: 'Kazakh',
	km: 'Central Khmer',
	ki: 'Kikuyu, Gikuyu',
	rw: 'Kinyarwanda',
	ky: 'Kirghiz, Kyrgyz',
	kv: 'Komi',
	kg: 'Kongo',
	ko: 'Korean',
	kj: 'Kuanyama, Kwanyama',
	ku: 'Kurdish',
	lo: 'Lao',
	la: 'Latin',
	lv: 'Latvian',
	li: 'Limburgan, Limburger, Limburgish',
	ln: 'Lingala',
	lt: 'Lithuanian',
	lb: 'Luxembourgish, Letzeburgesch',
	lu: 'Luba-Katanga',
	lg: 'Ganda',
	mk: 'Macedonian',
	mh: 'Marshallese',
	ml: 'Malayalam',
	mi: 'Maori',
	mr: 'Marathi',
	ms: 'Malay',
	mg: 'Malagasy',
	mt: 'Maltese',
	mn: 'Mongolian',
	na: 'Nauru',
	nv: 'Navajo, Navaho',
	nr: 'Ndebele, South, South Ndebele',
	nd: 'Ndebele, North, North Ndebele',
	ng: 'Ndonga',
	ne: 'Nepali',
	nn: 'Norwegian Nynorsk, Nynorsk, Norwegian',
	nb: 'Bokmål, Norwegian, Norwegian Bokmål',
	no: 'Norwegian',
	ny: 'Chichewa, Chewa, Nyanja',
	oc: 'Occitan (post 1500), Provençal',
	oj: 'Ojibwa',
	or: 'Oriya',
	om: 'Oromo',
	os: 'Ossetian, Ossetic',
	pa: 'Panjabi, Punjabi',
	fa: 'Persian',
	pi: 'Pali',
	pl: 'Polish',
	pt: 'Portuguese',
	ps: 'Pushto, Pashto',
	qu: 'Quechua',
	rm: 'Romansh',
	ro: 'Romanian, Moldavian, Moldovan',
	rn: 'Rundi',
	sg: 'Sango',
	sa: 'Sanskrit',
	si: 'Sinhala, Sinhalese',
	sk: 'Slovak',
	sl: 'Slovenian',
	se: 'Northern Sami',
	sm: 'Samoan',
	sn: 'Shona',
	sd: 'Sindhi',
	so: 'Somali',
	st: 'Sotho, Southern',
	sc: 'Sardinian',
	sr: 'Serbian',
	ss: 'Swati',
	su: 'Sundanese',
	sw: 'Swahili',
	sv: 'Swedish',
	ty: 'Tahitian',
	ta: 'Tamil',
	tt: 'Tatar',
	te: 'Telugu',
	tg: 'Tajik',
	tl: 'Tagalog',
	th: 'Thai',
	bo: 'Tibetan',
	ti: 'Tigrinya',
	to: 'Tonga (Tonga Islands)',
	tn: 'Tswana',
	ts: 'Tsonga',
	tk: 'Turkmen',
	tr: 'Turkish',
	tw: 'Twi',
	ug: 'Uighur, Uyghur',
	uk: 'Ukrainian',
	ur: 'Urdu',
	uz: 'Uzbek',
	ve: 'Venda',
	vi: 'Vietnamese',
	vo: 'Volapük',
	cy: 'Welsh',
	wa: 'Walloon',
	wo: 'Wolof',
	xh: 'Xhosa',
	yi: 'Yiddish',
	yo: 'Yoruba',
	za: 'Zhuang, Chuang',
	zu: 'Zulu',
};

/**
 * Country codes and names
 */
export const COUNTRIES = {
	USA: 'United States',
	AFG: 'Afghanistan',
	ALA: 'Åland Islands',
	ALB: 'Albania',
	DZA: 'Algeria',
	ASM: 'American Samoa',
	AND: 'Andorra',
	AGO: 'Angola',
	AIA: 'Anguilla',
	ATA: 'Antarctica',
	ATG: 'Antigua and Barbuda',
	ARG: 'Argentina',
	ARM: 'Armenia',
	ABW: 'Aruba',
	AUS: 'Australia',
	AUT: 'Austria',
	AZE: 'Azerbaijan',
	BHS: 'Bahamas',
	BHR: 'Bahrain',
	BGD: 'Bangladesh',
	BRB: 'Barbados',
	BLR: 'Belarus',
	BEL: 'Belgium',
	BLZ: 'Belize',
	BEN: 'Benin',
	BMU: 'Bermuda',
	BTN: 'Bhutan',
	BOL: 'Bolivia - Plurinational State of',
	BES: 'Bonaire - Sint Eustatius and Saba',
	BIH: 'Bosnia and Herzegovina',
	BWA: 'Botswana',
	BVT: 'Bouvet Island',
	BRA: 'Brazil',
	IOT: 'British Indian Ocean Territory',
	BRN: 'Brunei Darussalam',
	BGR: 'Bulgaria',
	BFA: 'Burkina Faso',
	BDI: 'Burundi',
	KHM: 'Cambodia',
	CMR: 'Cameroon',
	CAN: 'Canada',
	CPV: 'Cape Verde',
	CYM: 'Cayman Islands',
	CAF: 'Central African Republic',
	TCD: 'Chad',
	CHL: 'Chile',
	CHN: 'China',
	CXR: 'Christmas Island',
	CCK: 'Cocos (Keeling) Islands',
	COL: 'Colombia',
	COM: 'Comoros',
	COG: 'Congo',
	COD: 'Congo - the Democratic Republic of the',
	COK: 'Cook Islands',
	CRI: 'Costa Rica',
	CIV: "Côte d'Ivoire",
	HRV: 'Croatia',
	CUB: 'Cuba',
	CUW: 'Curaçao',
	CYP: 'Cyprus',
	CZE: 'Czech Republic',
	DNK: 'Denmark',
	DJI: 'Djibouti',
	DMA: 'Dominica',
	DOM: 'Dominican Republic',
	ECU: 'Ecuador',
	EGY: 'Egypt',
	SLV: 'El Salvador',
	GNQ: 'Equatorial Guinea',
	ERI: 'Eritrea',
	EST: 'Estonia',
	ETH: 'Ethiopia',
	FLK: 'Falkland Islands (Malvinas)',
	FRO: 'Faroe Islands',
	FJI: 'Fiji',
	FIN: 'Finland',
	FRA: 'France',
	GUF: 'French Guiana',
	PYF: 'French Polynesia',
	ATF: 'French Southern Territories',
	GAB: 'Gabon',
	GMB: 'Gambia',
	GEO: 'Georgia',
	DEU: 'Germany',
	GHA: 'Ghana',
	GIB: 'Gibraltar',
	GRC: 'Greece',
	GRL: 'Greenland',
	GRD: 'Grenada',
	GLP: 'Guadeloupe',
	GUM: 'Guam',
	GTM: 'Guatemala',
	GGY: 'Guernsey',
	GIN: 'Guinea',
	GNB: 'Guinea-Bissau',
	GUY: 'Guyana',
	HTI: 'Haiti',
	HMD: 'Heard Island and McDonald Islands',
	VAT: 'Holy See (Vatican City State)',
	HND: 'Honduras',
	HKG: 'Hong Kong',
	HUN: 'Hungary',
	ISL: 'Iceland',
	IND: 'India',
	IDN: 'Indonesia',
	IRN: 'Iran - Islamic Republic of',
	IRQ: 'Iraq',
	IRL: 'Ireland',
	IMN: 'Isle of Man',
	ISR: 'Israel',
	ITA: 'Italy',
	JAM: 'Jamaica',
	JPN: 'Japan',
	JEY: 'Jersey',
	JOR: 'Jordan',
	KAZ: 'Kazakhstan',
	KEN: 'Kenya',
	KIR: 'Kiribati',
	PRK: "Korea - Democratic People's Republic of",
	KOR: 'Korea - Republic of',
	KWT: 'Kuwait',
	KGZ: 'Kyrgyzstan',
	LAO: "Lao People's Democratic Republic",
	LVA: 'Latvia',
	LBN: 'Lebanon',
	LSO: 'Lesotho',
	LBR: 'Liberia',
	LBY: 'Libya',
	LIE: 'Liechtenstein',
	LTU: 'Lithuania',
	LUX: 'Luxembourg',
	MAC: 'Macao',
	MKD: 'Macedonia - the former Yugoslav Republic of',
	MDG: 'Madagascar',
	MWI: 'Malawi',
	MYS: 'Malaysia',
	MDV: 'Maldives',
	MLI: 'Mali',
	MLT: 'Malta',
	MHL: 'Marshall Islands',
	MTQ: 'Martinique',
	MRT: 'Mauritania',
	MUS: 'Mauritius',
	MYT: 'Mayotte',
	MEX: 'Mexico',
	FSM: 'Micronesia - Federated States of',
	MDA: 'Moldova - Republic of',
	MCO: 'Monaco',
	MNG: 'Mongolia',
	MNE: 'Montenegro',
	MSR: 'Montserrat',
	MAR: 'Morocco',
	MOZ: 'Mozambique',
	MMR: 'Myanmar',
	NAM: 'Namibia',
	NRU: 'Nauru',
	NPL: 'Nepal',
	NLD: 'Netherlands',
	NCL: 'New Caledonia',
	NZL: 'New Zealand',
	NIC: 'Nicaragua',
	NER: 'Niger',
	NGA: 'Nigeria',
	NIU: 'Niue',
	NFK: 'Norfolk Island',
	MNP: 'Northern Mariana Islands',
	NOR: 'Norway',
	OMN: 'Oman',
	PAK: 'Pakistan',
	PLW: 'Palau',
	PSE: 'Palestine - State of',
	PAN: 'Panama',
	PNG: 'Papua New Guinea',
	PRY: 'Paraguay',
	PER: 'Peru',
	PHL: 'Philippines',
	PCN: 'Pitcairn',
	POL: 'Poland',
	PRT: 'Portugal',
	PRI: 'Puerto Rico',
	QAT: 'Qatar',
	REU: 'Réunion',
	ROU: 'Romania',
	RUS: 'Russian Federation',
	RWA: 'Rwanda',
	BLM: 'Saint Barthélemy',
	SHN: 'Saint Helena - Ascension and Tristan da Cunha',
	KNA: 'Saint Kitts and Nevis',
	LCA: 'Saint Lucia',
	MAF: 'Saint Martin (French part)',
	SPM: 'Saint Pierre and Miquelon',
	VCT: 'Saint Vincent and the Grenadines',
	WSM: 'Samoa',
	SMR: 'San Marino',
	STP: 'Sao Tome and Principe',
	SAU: 'Saudi Arabia',
	SEN: 'Senegal',
	SRB: 'Serbia',
	SYC: 'Seychelles',
	SLE: 'Sierra Leone',
	SGP: 'Singapore',
	SXM: 'Sint Maarten (Dutch part)',
	SVK: 'Slovakia',
	SVN: 'Slovenia',
	SLB: 'Solomon Islands',
	SOM: 'Somalia',
	ZAF: 'South Africa',
	SGS: 'South Georgia and the South Sandwich Islands',
	SSD: 'South Sudan',
	ESP: 'Spain',
	LKA: 'Sri Lanka',
	SDN: 'Sudan',
	SUR: 'Suriname',
	SJM: 'Svalbard and Jan Mayen',
	SWZ: 'Swaziland',
	SWE: 'Sweden',
	CHE: 'Switzerland',
	SYR: 'Syrian Arab Republic',
	TWN: 'Taiwan - Province of China',
	TJK: 'Tajikistan',
	TZA: 'Tanzania - United Republic of',
	THA: 'Thailand',
	TLS: 'Timor-Leste',
	TGO: 'Togo',
	TKL: 'Tokelau',
	TON: 'Tonga',
	TTO: 'Trinidad and Tobago',
	TUN: 'Tunisia',
	TUR: 'Turkey',
	TKM: 'Turkmenistan',
	TCA: 'Turks and Caicos Islands',
	TUV: 'Tuvalu',
	UGA: 'Uganda',
	UKR: 'Ukraine',
	ARE: 'United Arab Emirates',
	GBR: 'United Kingdom',
	UMI: 'United States Minor Outlying Islands',
	URY: 'Uruguay',
	UZB: 'Uzbekistan',
	VUT: 'Vanuatu',
	VEN: 'Venezuela - Bolivarian Republic of',
	VNM: 'Viet Nam',
	VGB: 'Virgin Islands - British',
	VIR: 'Virgin Islands - U.S.',
	WLF: 'Wallis and Futuna',
	ESH: 'Western Sahara',
	YEM: 'Yemen',
	ZMB: 'Zambia',
	ZWE: 'Zimbabwe',
	INT: 'International',
	XXK: 'Kosovo',
};
