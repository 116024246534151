import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Publication } from '@newstex/types/content';
import { useState } from 'react';
import { Button } from 'react-bootstrap';

import { RedeliverModal } from './redeliver-modal';

export interface RedeliverButtonProps {
	item: Publication;
	disabled?: boolean;
	size?: 'sm' | 'lg';
	onRedeliver?: () => void;
}

export function RedeliverButton({
	disabled, size, onRedeliver, item,
}: RedeliverButtonProps) {
	const [showModal, setShowModal] = useState(false);

	return (
		<>
			{showModal && (
				<RedeliverModal
					pub={item}
					hide={(redelivered) => {
						setShowModal(false);
						if (redelivered) {
							onRedeliver?.();
						}
					}}
				/>
			)}

			<Button
				size={size || 'sm'}
				variant="outline-secondary"
				disabled={disabled}
				title="Redeliver"
				onClick={() => setShowModal(true)}
			>
				<FontAwesomeIcon icon={faRedo} />&nbsp;Redeliver
			</Button>
		</>
	);
}
