import { faSave } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { unixNow } from '@newstex/core/date';
import type { Publication } from '@newstex/types/content';
import { useRef } from 'react';
import {
	Alert,
	Button,
	Form,
	Modal,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useAPI } from '~/providers/api-provider';
import { useUserInfo } from '~/providers/user-info';

interface CreateEditorialNoteModalProps {
	publication: Partial<Publication>;
	show: boolean;
	onHide: () => void;
	onSave?: () => void;
}

export function CreateEditorialNoteModal({
	publication, show, onHide, onSave,
}: CreateEditorialNoteModalProps) {
	const formRef = useRef<HTMLFormElement>(null);
	const api = useAPI();
	const userInfo = useUserInfo();

	const savePublication = async () => {
		const notes = (formRef.current?.elements.namedItem('last_review_comment') as HTMLTextAreaElement)?.value;
		const toastId = toast.loading('Updating publication...', {
			autoClose: false,
			closeButton: true,
		});

		try {
			await api.updateItem<Publication>({
				$type: 'Publication',
				$id: publication.$id,
			}, {
				last_editorial_review: unixNow(),
				last_review_comment: notes || '',
				reviewed_by: userInfo?.$id,
			});

			onHide();
			if (onSave) {
				onSave();
			}

			setTimeout(() => {
				toast.update(toastId, {
					render: 'Publication updated',
					closeButton: true,
					type: 'success',
					isLoading: false,
					autoClose: 5000,
				});
			}, 2000);
		} catch (e) {
			console.error(e);
			toast.update(toastId, {
				render: 'Error updating publication',
				closeButton: true,
				type: 'error',
				isLoading: false,
				autoClose: 5000,
			});
		}
	};

	return (
		<Modal show={show} onHide={onHide} size="xl">
			<Modal.Header closeButton>
				<Modal.Title>Add Editorial Note - {publication.name}
					{publication.newstex_id && <small className="text-muted ps-1">[{publication.newstex_id}]</small>}
				</Modal.Title>
			</Modal.Header>
			<Form ref={formRef} onSubmit={(e) => {
				e.preventDefault();
				savePublication();
			}}>
				<Modal.Body>
					{!publication.$id && (
						<Alert variant="warning">
							Publication ID not found.
							<code>{JSON.stringify(publication, null, 2)}</code>
						</Alert>
					)}
					<Form.Group controlId="lastReviewComment">
						<Form.Label className="fw-bold">Notes</Form.Label>
						<Form.Control
							as="textarea"
							placeholder="Enter any notes about this review"
							name="last_review_comment"
							rows={3}
							defaultValue={publication.last_review_comment || ''}
							onKeyDown={(e) => {
								if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
									savePublication();
									e.preventDefault();
									return false;
								}
							}}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer className="d-flex justify-content-end">

					<Button variant="success" type="submit" disabled={!publication.$id}>
						<FontAwesomeIcon icon={faSave} /> Save
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
}
