import { title as titleCase } from 'case';
import { Col } from 'react-bootstrap';

import { getDisplayName } from './property-display-name';
import { PropertyDisplayValue } from './property-display-value';

export function PropertyDisplay({
	propKey, propName, propValue, typeName,
}: {
	propKey: string,
	propName?: string,
	propValue: any,
	typeName?: string,
}) {
	return (<>
		<Col className="property-name" md={4}>{propName || (typeName ? getDisplayName(propKey as any, typeName as any) : titleCase(propKey))}</Col>
		<Col className={typeName ? `property-value ${typeName.toLowerCase()}-${propKey}-value` : 'property-value'} md={8}>
			<PropertyDisplayValue propValue={propValue} propName={propKey} typeName={typeName} />
		</Col>
	</>);
}
