/**
 * Calculates the dot product of two vectors
 */
function dotProduct(v1: number[], v2: number[]): number {
	return v1.reduce((sum, v1i, i) => sum + v1i * v2[i], 0);
}

/**
 * Calculates the magnitude (length) of a vector
 */
function magnitude(v: number[]): number {
	return Math.sqrt(dotProduct(v, v));
}

/**
 * Calculates the cosine similarity between two vectors
 * Returns a value between -1 and 1, where:
 * 1 means the vectors are identical
 * 0 means the vectors are perpendicular
 * -1 means the vectors are opposite
 *
 * @param v1 - First vector
 * @param v2 - Second vector of same length as v1
 * @returns Cosine similarity score
 */
export function cosineSimilarity(v1: number[], v2: number[]): number {
	if (v1.length !== v2.length) {
		throw new Error('Vectors must have same length');
	}

	const dot = dotProduct(v1, v2);
	const mag1 = magnitude(v1);
	const mag2 = magnitude(v2);

	if (mag1 === 0 || mag2 === 0) {
		return 0;
	}

	return dot / (mag1 * mag2);
}
