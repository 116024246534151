import { faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export interface ViewStoriesFilters {
	size: 'sm' | 'lg';
	onClick?: () => void;
	publication?: string;
	content?: string;
	category?: string;
	product?: string;
}

export default function ViewStoriesButton({ size, onClick, ...filters }: ViewStoriesFilters) {
	const navigate = useNavigate();

	const routeToStories = () => {
		if (filters.publication && !filters.content && !filters.category) {
			navigate(`/content/${filters.publication}/stories`);
			return;
		}

		const filterQueryParameters = new URLSearchParams();
		if (filters.content) {
			filterQueryParameters.set('content', filters.content);
		}

		if (filters.category) {
			filterQueryParameters.set('categories', filters.category);
		}

		if (filters.product) {
			filterQueryParameters.set('products', filters.product);
		}
		navigate(`/stories?${filterQueryParameters.toString()}`);
		onClick?.();
	};

	return (
		<Button size={size} variant="outline-secondary" onClick={routeToStories}>
			<FontAwesomeIcon icon={faNewspaper} /> View Stories
		</Button>
	);
}
