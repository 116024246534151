import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import type { AIChatContextType } from '~/providers/ai-chat-provider';
import type { AIContextType } from '~/providers/ai-provider';
import type { SearchContextType } from '~/providers/search';

export interface ModalFields {
	icon?: FontAwesomeIconProps['icon'];
	id: string;
	title: string;
	fields: ModalField[];
}

export interface ModalField {
	title: string;
	id: string;
	icon?: FontAwesomeIconProps['icon'];
	placeholder?: string;
	help?: string;
}

export interface Command {
	id: string;
	name: string;
	icon?: FontAwesomeIconProps['icon'];
	description?: string;
	handler: (args: {
		searchContext?: SearchContextType,
		setModalData: (data: ModalFields | null) => void,
		navigate: (path: string) => void,
		ai: AIContextType,
		aiChat: AIChatContextType,
	}) => void;
}
export function isCommand(command: any): command is Command {
	return typeof command === 'object'
		&& typeof command.id === 'string'
		&& typeof command.handler === 'function';
}
