import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useUserInfo } from '~/providers/user-info';

import { HubSpotForm } from './hubspot-form';

interface CreatePublicationHubSpotModalProps {
	show: boolean;
	onClose: (success?: boolean) => void;
}

export function CreatePublicationHubSpotModal({
	show,
	onClose,
}: CreatePublicationHubSpotModalProps) {
	const userInfo = useUserInfo();

	const defaultValues = {
		'TICKET.name_of_the_person_submitting_this_form': userInfo?.name || '',
		email: userInfo?.email || '',
	};

	return (
		<Modal show={show} onHide={() => onClose(false)} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>Create New Publication</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<HubSpotForm
					portalId="20613241"
					formId="cded4afa-ee51-4392-82e3-9665764c5ecd"
					defaultValues={defaultValues}
					onSubmit={() => {
						toast.success('Publication request submitted successfully');
						onClose(true);
					}}
				/>
			</Modal.Body>
			<Modal.Footer className="justify-content-start">
				<small className="text-muted">
					By submitting this form, you agree to your information being processed by HubSpot in accordance with their{' '}
					<a href="https://legal.hubspot.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
					{' '}and{' '}
					<a href="https://legal.hubspot.com/terms-of-service" target="_blank" rel="noopener noreferrer">Terms of Service</a>.
				</small>
			</Modal.Footer>
		</Modal>
	);
}
