import { GetDashboardEmbedUrlCommandOutput } from '@aws-sdk/client-quicksight';
import { IconName } from '@fortawesome/fontawesome-common-types';
import {
	DashboardExperience,
	EmbeddingContext,
	Parameter,
	createEmbeddingContext,
} from 'amazon-quicksight-embedding-sdk';
import { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import LoadingSpinner from '~/components/LoadingSpinner';
import { PageTitle } from '~/components/page-title';
import { useAPI } from '~/providers/api-provider';

export interface IReport {
	id: string;
	name: string;
	description: string;
	icon: IconName;
	embed_url?: string;
	sheets?: Record<string, string>;
}

export const REPORTS: IReport[] = [
	{
		id: 'a4a25c65-9616-4538-bcfa-e4dee8c665b5',
		name: 'Story Counts',
		description: 'Story Deliveries, Monthly and Weekly Received counts, Lexis counts, StockPulse counts, Controversial story counts, and links for stories received',
		icon: 'file-spreadsheet',
		sheets: {
			'Weekly Received': 'a4a25c65-9616-4538-bcfa-e4dee8c665b5_ef51f22f-778a-4df4-8d57-a7092a90cb22',
			'Story Counts': 'a4a25c65-9616-4538-bcfa-e4dee8c665b5_40cce810-dcd7-4ef0-a92d-4de1519143e1',
		},
	},
	{
		id: '2443b6d1-87de-43b7-9c09-5e16f1684bb5',
		name: 'Blog QA',
		description: 'Lapsed Publications',
		icon: 'trophy-star',
	},
	{
		id: '73579460-2229-4ac2-877a-a436a25193f7',
		name: 'Publication Churn',
		description: 'Total publications added, archived, and integrated.',
		icon: 'chart-line-down',
	},
	{
		id: '88060157-d3c7-4456-b39a-8bfaf25030c6',
		name: 'DMARC Report',
		description: 'This report shows the DMARC report for all emails sent through Newstex Domains',
		icon: 'envelope-circle-check',
	},
];

function EmbedDashboard({ embedUrl, id, report }: { embedUrl?: string; id: string, report?: IReport }) {
	const [dashboard, setDashboard] = useState<DashboardExperience | undefined>();
	const [ctx, setContext] = useState<EmbeddingContext | undefined>();
	const containerRef = useRef<HTMLDivElement>(null);
	const location = useLocation();

	const setTab = async (tabName: string, db?: DashboardExperience) => {
		if (!db) {
			db = dashboard;
		}

		if (dashboard) {
			try {
				const sheets = await dashboard.getSheets();
				for (const sheet of sheets) {
					if (sheet.Name === tabName || sheet.SheetId === tabName) {
						console.log('Mapped', tabName, sheet);
						dashboard.setSelectedSheetId(sheet.SheetId);
					}
				}
			} catch (e) {
				console.error('SHEET ERROR', e);
				setTimeout(setTab, 500, tabName, db);
			}
		} else {
			console.error('Tried to set tab when there was no dashboard');
		}
	};

	useEffect(() => {
		if (dashboard && id) {
			dashboard.navigateToDashboard(id);
			return;
		}

		if (embedUrl && id) {
			const fetchData = async () => {
				console.log('Embedding Dashboard', {
					embedUrl,
					id,
				});
				let context = ctx;

				if (!context) {
					context = await createEmbeddingContext();
					setContext(context);
				}

				const parameters: Parameter[] = [];
				let initialSheetId: string = '';
				if (location?.search?.length) {
					const queryParams = new URLSearchParams(location.search);
					for (const [paramName, paramValue] of queryParams.entries()) {
						if (paramValue) {
							if (paramName === 'tabId') {
								initialSheetId = paramValue;
							} else if (paramName === 'tab') {
								initialSheetId = report?.sheets?.[paramValue] || '';
							} else {
								if (typeof paramValue === 'string') {
									parameters.push({
										Name: paramName,
										Values: [paramValue],
									});
								} else if (Array.isArray(paramValue)) {
									parameters.push({
										Name: paramName,
										Values: paramValue,
									});
								}
								console.log({
									paramName,
									paramValue,
								});
							}
						}
					}
				}
				const db = await context.embedDashboard(
					{
						url: embedUrl,
						container: containerRef?.current || '#dashboard-container',
						height: '1800px',
						width: '100%',
					},
					{
						locale: 'en-US',
						sheetOptions: {
							singleSheet: false,
							emitSizeChangedEventOnSheetChange: false,
							initialSheetId,
						},
						toolbarOptions: {
							export: false,
							undoRedo: false,
							reset: false,
						},
						attributionOptions: {
							overlayContent: false,
						},
						parameters,
					},
				);

				console.log('Created Embedding Experience', db);

				db.addEventListener('ERROR_OCCURRED', (payload) => {
					console.log('QS DashboardERROR', payload);
				});

				setDashboard(db);
			};
			fetchData();
		}
	}, [embedUrl, id, location?.search]);

	/**
	 * Set the sheet according to the "tab" query string parameter
	 */
	useEffect(() => {
		if (dashboard && location?.search) {
			const params = new URLSearchParams(location.search);
			const tab = params.get('tab');
			if (tab) {
				setTab(tab);
			}
		}
	}, [dashboard, location?.search]);

	return <div id="dashboard-container" ref={containerRef} />;
}

export const ReportPage = () => {
	const params = useParams();
	const api = useAPI();
	const [loading, setLoading] = useState(true);
	const [report, setReport] = useState<IReport | undefined>();
	useEffect(() => {
		const abortController = new AbortController(); // Step 1
		const signal = abortController.signal;

		const fetchData = async () => {
			try {
				const myReport = REPORTS.find((r) => r.id === params.id);
				setReport(myReport);
				if (myReport) {
					const resp = await api.fetchWithAuth<GetDashboardEmbedUrlCommandOutput>(`reports/${params.id}`, {
						signal,
					});
					if (resp?.EmbedUrl) {
						setReport({
							...myReport,
							embed_url: resp.EmbedUrl,
						});
					}
				}
				setLoading(false);
			} catch (error: any) {
				if (error?.name !== 'AbortError') {
					console.error('Fetch aborted or another error occurred', error);
				}
			}
		};
		fetchData();

		return () => {
			console.log('Cleanup Report', params.id);
			abortController.abort();
			setReport(undefined);
		};
	}, [params.id]);

	return (
		<Container fluid key={`report-page-${params.id}`}>
			<PageTitle
				title={report?.name || 'Report'}
				breadcrumbs={[
					{
						label: 'Reports',
						href: '/reports',
					},
				]}
			/>
			<LoadingSpinner loading={loading} />
			{ !loading ? <EmbedDashboard embedUrl={report?.embed_url} id={params.id || 'no-id'} report={report} /> : null }
		</Container>
	);
};
