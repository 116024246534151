import { faHistory } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getObjectID, getObjectType } from '@newstex/core/reference';
import type { HistoryRecord } from '@newstex/types/history';
import { convertReferenceToString } from '@newstex/types/reference';
import type { Results } from '@newstex/types/results';
import React, { useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useAPI } from '~/providers/api-provider';

import { DateTime } from './date-time';
import { PropertyDisplayValue } from './property-display-value';
import UserLink from './user/user-link';

interface ObjectHistoryButtonProps {
	item?: Record<string, any>;
	size?: number;
}

export const ObjectHistoryButton: React.FC<ObjectHistoryButtonProps> = (props) => {
	const [visible, setVisible] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	const [historyRecords, setHistoryRecords] = useState<HistoryRecord<any, any>[]>([]);
	const api = useAPI();

	useEffect(() => {
		const fetchData = async () => {
			if (visible && props?.item) {
				const itemType = getObjectType(props.item)?.toLowerCase();
				const itemId = getObjectID(props.item);
				setLoading(true);
				const resp = await api.fetchWithAuth<Results<HistoryRecord<any, any>>>(`resources/${itemType}/${itemId}/history`);
				setHistoryRecords(resp?.items);
				setLoading(false);
			}
		};
		fetchData();
	}, [visible, props?.item]);

	return (
		<>
			<Button variant='link' onClick={() => setVisible(true)}>
				<FontAwesomeIcon icon={faHistory} />
			</Button>
			{visible && (
				<Modal show={visible} onHide={() => setVisible(false)} fullscreen={true}>
					<Modal.Header>
						<Modal.Title>{props.item?.name}</Modal.Title>
						<Button
							variant="secondary"
							className="btn-close"
							aria-label="Close"
							data-bs-dismiss="modal"
							onClick={() => setVisible(false)}
						/>
					</Modal.Header>
					<Modal.Body>
						{loading ? (
							<Spinner animation="border" role="status">
								<span className="visually-hidden">Loading...</span>
							</Spinner>
						) : (
							<table className="table table-striped">
								<thead>
									<tr>
										<th>Date</th>
										<th>Property Name</th>
										<th>Old Value</th>
										<th>New Value</th>
										<th>Transaction ID</th>
										<th>Comment</th>
										<th>User</th>
									</tr>
								</thead>
								<tbody className="hoverable">
									{historyRecords.map((record) => (
										<tr key={String(record.property)}>
											<td>
												<DateTime ts={record.ts} />
											</td>
											<td>{String(record.property)}</td>
											<td>
												<PropertyDisplayValue propName={String(record.property)} propValue={record.old_value} />
											</td>
											<td>
												<PropertyDisplayValue propName={String(record.property)} propValue={record.new_value} />
											</td>
											<td>{record.transaction_id}</td>
											<td>{record.comment}</td>
											<td>
												{record.user && <UserLink id={convertReferenceToString(record.user)} />}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						)}
					</Modal.Body>
				</Modal>
			)}
		</>
	);
};
