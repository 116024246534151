/* eslint-disable no-nested-ternary */
import { faBug, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { faLock, faLockOpen } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatSchedule } from '@newstex/core/schedule';
import { AlertStatus } from '@newstex/types/alert';
import { Publication } from '@newstex/types/content';
import { FeedPriority } from '@newstex/types/feed-priority';
import { title as titleCase } from 'case';
import { parseExpression } from 'cron-parser';
import {
	Button,
	ListGroup,
	OverlayTrigger,
	Table,
	Tooltip,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { AlertBadge } from './alert-badge';
import { CategoryLink } from './category-link';
import { ContentRecordLink } from './content-record-link';
import { DateTime } from './date-time';
import { ProductLink } from './product-link';
import { ProductStatusList } from './product-status';
import UserLink from './user/user-link';

interface PropertyDisplayValueProps {
	propName?: string;
	propValue?: any;
	className?: string;
	maxLength?: number;
	short?: boolean;
	typeName?: string;
}

export const SegmentName: React.FC<{ id: number }> = ({ id }) => {
	switch (id) {
		case -3: return <OverlayTrigger
			placement="right"
			overlay={<Tooltip>
				<h4>Chrome Extension</h4>
				<p>This feed runs via the Chrome Extension from staff member's machines.
					It can not run on AWS Servers due to IP restrictions.</p>
			</Tooltip>}
		><span className="bg-danger-subtle text-danger-emphasis rounded-1 px-1">Chrome Extension</span></OverlayTrigger>;
		case -2: return <span className="bg-warning-subtle text-warning-emphasis rounded-1 px-1">Dev</span>;
		case -1: return <span className="bg-success-subtle text-secondary-emphasis rounded-1 px-1">Prod</span>;
		default: return <span>{id}</span>;
	}
};

export const PropertyDisplayValue: React.FC<PropertyDisplayValueProps> = (props) => {
	if (props.propValue === undefined) {
		return <i className="text-muted">[Not Set]</i>;
	}

	if (props.propValue === null) {
		return <i className="text-muted">[Null]</i>;
	}

	if (props.propValue === true) {
		if (props.propName === 'closed_sites_only') {
			return <>Yes <FontAwesomeIcon className="text-danger" icon={faLock} /></>;
		}
		return 'Yes';
	}

	if (props.propValue === false) {
		if (props.propName === 'closed_sites_only') {
			return <>No <FontAwesomeIcon icon={faLockOpen} className="text-muted" /></>;
		}
		return 'No';
	}

	if (Array.isArray(props.propValue)) {
		return (
			<ul>
				{props.propValue.map((item: any, i) => (
					<li key={`pli-${props.propName}-${i}`}>
						<PropertyDisplayValue propName={props.propName} propValue={item} />
					</li>
				))}
			</ul>
		);
	}

	if (props.propName === 'status' && props.typeName === 'Alert') {
		return <AlertBadge status={props.propValue || AlertStatus.NO_DATA} />;
	}

	switch (props.propName) {
		case 'parent':
		case 'publication':

			return <ContentRecordLink
				id={props.propValue}
				typeName={props.propName === 'publication' ? 'Publication' : 'Publisher'}
			/>;
		case 'hs_object_id':
			return (
				<Link target="_blank" to={`https://app.hubspot.com/contacts/20613241/record/0-2/${props.propValue}`} className={props.className}>
					{props.propValue} <FontAwesomeIcon icon={faExternalLinkAlt} />
				</Link>
			);
		case 'url':
		case 'permalink':
		case 'website':
		case 'url_history':
		case 'feed_uri':
		case 'feed_url':
		case 'alt_urls':
		case 'home_url':
		case 'xml_feed':
			return (
				<Link target="_blank" to={props.propValue} className={props.className}>
					{(props.maxLength && props.propValue && props.propValue.length > props.maxLength)
						? `${props.propValue?.slice(0, props.maxLength)}...`
						: props.propValue
					}&nbsp;<FontAwesomeIcon icon={faExternalLinkAlt} />
				</Link>
			);
		case 'blocked_content':
			return (
				<Table striped bordered variant='danger'>
					<tbody>
						{Object.entries(props.propValue as Publication['blocked_content'] || {}).map(([key, value]) => (
							<tr key={key} style={{ padding: '0px' }}>
								<th style={{
									width: `${key.length}em`, textAlign: 'right', paddingRight: '5px', paddingTop: '0px',
								}}>{key}</th>
								<td style={{ paddingTop: '0px', paddingLeft: '5px' }}>{value?.join(', ')}</td>
							</tr>
						))}
					</tbody>
				</Table>
			);
		case 'product_status':
			return <ProductStatusList value={props.propValue} />;
		case 'product':
		case 'products':
			return <ProductLink product={props.propValue} />;
		// Dates
		case 'activated':
		case 'created_at':
		case 'received_at':
		case 'date':
		case 'modified_at':
		case 'sys_modstamp':
		case 'last_read':
		case 'next_read':
		case 'last_delivery':
		case 'last_run':
			return <DateTime ts={props.propValue} />;
		case 'last_post_date':
		case 'last_received_date':
		case 'last_applied':
		case 'last_checked':
		case 'last_editorial_review':
			return <DateTime ts={props.propValue} dateOnly/>;
		// Frequency
		case 'check_period':
		case 'polling_delay':
			if (typeof props.propValue === 'number') {
				if (props.propValue > 86400) {
					return <>{Math.round(props.propValue / 86400)} days</>;
				}

				if (props.propValue > 3600) {
					return <>{Math.round(props.propValue / 3600)} hours</>;
				}

				if (props.propValue > 60) {
					return <>{Math.round(props.propValue / 60)} minutes</>;
				}
				return <>{props.propValue} seconds</>;
			}
			return <span title={props.propValue}>{props.propValue}</span>;
		case 'schedule':
			if (typeof props.propValue === 'string') {
				return <>
					<span>{titleCase(formatSchedule(props.propValue))}</span>
					{!props.short && (
						<div className="text-muted mt-2">
							<ListGroup>
								<ListGroup.Item className="bg-light">
									<b>Next 5 Scheduled Run Times</b>
								</ListGroup.Item>
								{(() => {
									try {
										const interval = parseExpression(props.propValue);
										return Array.from({ length: 5 }, () => interval.next().toDate()).map((date, i) => (
											<ListGroup.Item key={i} className="py-1">
												<DateTime ts={date.getTime()} />
											</ListGroup.Item>
										));
									} catch (e) {
										return null;
									}
								})()}
							</ListGroup>
						</div>
					)}
				</>;
			}
			return <span title={props.propValue}>{props.propValue}</span>;
		// Enums
		case 'content_status':
			return <span title={props.propValue}>{props.propValue}</span>;
		case 'feed_priority':
			return <span title={props.propValue}>{FeedPriority[props.propValue] || props.propValue}</span>;
		case 'last_error':
			if (typeof props.propValue === 'object') {
				if (props.propValue.error) {
					return (
						<pre
							className="p-1 bg-danger-subtle text-danger-emphasis border rounded-2 border-danger"
						>{props.propValue.error}</pre>
					);
				}
				return (
					<pre>{JSON.stringify(props.propValue, null, 2)}</pre>
				);
			}
			return <span title={props.propValue}>{props.propValue}</span>;
		// Date-only fields (no time associated)
		case 'archive_date':
		case 'deletion_date':
		case 'integration_completion_date':
			return <DateTime ts={props.propValue} dateOnly />;
		case 'password':
		case 'passwd':
		case 'private_key':
			return <span title={props.propValue}><i>****</i></span>;
		case 'stats':
			return (
				<Table size="sm" style={{ maxWidth: '200px' }}>
					<tbody>
						<tr className={
							props.propValue?.sentiment_score < -1
								? 'table-danger'
								: props.propValue?.sentiment_score > 1
									? 'table-success'
									: ''
						}>
							<th className="text-end">Sentiment</th>
							<td>{props.propValue?.sentiment_score}</td>
						</tr>
						<tr>
							<th className="text-end">Words</th>
							<td>{props.propValue?.word_count}</td>
						</tr>
						<tr>
							<th className="text-end">Lines</th>
							<td>{props.propValue?.line_count}</td>
						</tr>
						<tr>
							<th className="text-end">Characters</th>
							<td>{props.propValue?.character_count}</td>
						</tr>
					</tbody>
				</Table>
			);
		case 'story_count':
			return <span title={String(props.propValue)}>{parseInt(props.propValue, 10).toLocaleString()}</span>;
		case 'error_task_url':
			return <Button variant="outline-secondary" size="sm" onClick={() => {
				window.open(props.propValue, '_blank');
			}}><FontAwesomeIcon icon={faBug} /></Button>;
		case 'modified_by':
		case 'created_by':
		case 'reviewed_by':
			return <UserLink id={props.propValue} />;
		case 'categories':
		case 'newstex_categories':
			return <CategoryLink category={props.propValue} />;
		case 'segment':
			return <SegmentName id={props.propValue} />;
		case 'search.q':
		case 'search.filter_by':
		case 'check_field':
			return <code title={props.propValue}>{props.propValue}</code>;
		case 'expected':
			if (typeof props.propValue === 'object') {
				return (
					<>
						{props.propValue.diff && (
							<OverlayTrigger placement="right" overlay={<Tooltip>Must be within {props.propValue.diff}% of the average for the previous average</Tooltip>}>
								<span>&plusmn;{props.propValue.diff}%</span>
							</OverlayTrigger>
						)}

						{props.propValue.min && (
							<>
								{props.propValue.diff && <> AND </>}
								&gt;={props.propValue.min}
							</>
						)}

						{props.propValue.max && (
							<>
								{props.propValue.min && <> to </>}
								&lt;={props.propValue.max}
							</>
						)}
					</>
				);
			}
			return <span title={props.propValue}>{String(props.propValue)}</span>;
		case 'last_values':
			if (typeof props.propValue === 'object') {
				return (
					<span>
						<b><DateTime ts={props.propValue.timestamp} /></b> {Intl.NumberFormat().format(props.propValue.value)}
					</span>
				);
			}

			if (typeof props.propValue === 'number') {
				return <b>{Intl.NumberFormat().format(props.propValue)}</b>;
			}

			return <b>{JSON.stringify(props.propValue)}</b>;
		default:
			if (typeof props.propValue === 'object') {
				return <pre>{JSON.stringify(props.propValue, null, 2)}</pre>;
			}
			return <span title={props.propValue}>{String(props.propValue)}</span>;
	}
};
