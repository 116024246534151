import { faRobot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { QualificationResult } from '@newstex/ai/agents/qualification-agent';
import type { Publication, Results } from '@newstex/types';
import { useState } from 'react';
import {
	Alert,
	Button,
	Card,
	Col,
	Row,
	Spinner,
} from 'react-bootstrap';

import { useAI } from '../providers/ai-provider';
import { useAPI } from '~/providers/api-provider';
import { KnowledgeBase } from '@newstex/types/rag';
import { RequirePermissions } from './require-permissions';

interface Props {
	/**
	 * The publication to qualify
	 */
	publication: Publication;
}

export function AIQualificationResults({ publication }: Props) {
	const api = useAPI();
	const ai = useAI();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string>();
	const [results, setResults] = useState<QualificationResult>();

	const generateResults = async () => {
		if (!publication.$id) {
			setError('Publication ID is required');
			return;
		}

		setLoading(true);
		setError(undefined);
		try {
			// Get the qualification criteria from the knowledge base
			const response = await api.fetchWithAuth<Results<KnowledgeBase>>('resources/RAG/QUALIFY-ALL');
			if (!response.items.length) {
				throw new Error('No qualification criteria found');
			}
			const results = await ai.qualifyPublicationWithAgent(publication.$id, response.items[0].answer!);
			setResults(results);
		} catch (err) {
			setError(err instanceof Error ? err.message : 'An error occurred while generating qualification results');
		} finally {
			setLoading(false);
		}
	};

	return (
		<div>
			{!results && !loading && (
				<RequirePermissions permissions={['admin']}>
					<div className="text-center p-4">
						<Button
							variant="outline-success"
							onClick={generateResults}
							disabled={loading}
						>
							<FontAwesomeIcon icon={faRobot} className="me-2" />
							Generate AI Qualification Results
						</Button>
					</div>
				</RequirePermissions>
			)}

			{loading && (
				<div className="text-center p-4">
					<Spinner animation="border" role="status">
						<span className="visually-hidden">Loading...</span>
					</Spinner>
					<p className="mt-2">Generating qualification results...</p>
				</div>
			)}

			{error && (
				<Alert variant="danger">
					{error}
				</Alert>
			)}

			{results && (
				<div>
					<Row>
						<Col md={8}>
							<Card>
								<Card.Header>
									<h5 className="mb-0">Client Qualification Scores</h5>
								</Card.Header>
								<Card.Body>
									<div className="table-responsive">
										<table className="table">
											<thead>
												<tr>
													<th>Client</th>
													<th>Score</th>
													<th>Reason</th>
												</tr>
											</thead>
											<tbody>
												{Object.entries(results.scores)
													.sort(([, a], [, b]) => b.score - a.score)
													.map(([client, score]: [string, QualificationResult['scores'][string]]) => (
														<tr key={client}>
															<td>{client}</td>
															<td>
																<span className={`badge bg-${score.score > 2 ? 'success' : score.score > 0 ? 'warning' : 'danger'}`}>
																	{score.score}
																</span>
															</td>
															<td>{score.reason}</td>
														</tr>
													))}
											</tbody>
										</table>
									</div>
								</Card.Body>
							</Card>
						</Col>
						<Col md={4}>
							<Card>
								<Card.Header>
									<h5 className="mb-0">Matched Criteria</h5>
								</Card.Header>
								<Card.Body>
									<ul className="list-unstyled mb-0">
										{results.matched_criteria.map((criteria: string) => (
											<li key={criteria} className="mb-2">
												<FontAwesomeIcon icon={faRobot} className="me-2 text-success" />
												{criteria}
											</li>
										))}
									</ul>
								</Card.Body>
							</Card>

							<Card className="mt-3">
								<Card.Header>
									<h5 className="mb-0">Improvement Recommendations</h5>
								</Card.Header>
								<Card.Body>
									<ul className="list-unstyled mb-0">
										{results.improvements.map((improvement: string) => (
											<li key={improvement} className="mb-2">
												<FontAwesomeIcon icon={faRobot} className="me-2 text-primary" />
												{improvement}
											</li>
										))}
									</ul>
								</Card.Body>
							</Card>

							<div className="text-center mt-3">
								<Button
									variant="outline-primary"
									size="sm"
									onClick={generateResults}
									disabled={loading}
								>
									<FontAwesomeIcon icon={faRobot} className="me-2" />
									Regenerate Results
								</Button>
							</div>
						</Col>
					</Row>
				</div>
			)}
		</div>
	);
}
