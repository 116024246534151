import { Button } from 'react-bootstrap';

interface LoadMoreButtonProps {
	show?: boolean;
	onClick?: () => void;
}

export function LoadMoreButton({ show = true, onClick }: LoadMoreButtonProps) {
	if (!show || !onClick) {
		return <div className="pt-2" />;
	}
	return (
		<div className="pt-2">
			<center>
				<Button
					variant="outline-primary"
					size="sm"
					onClick={onClick}
				>
					Load More
				</Button>
			</center>
		</div>
	);
}
