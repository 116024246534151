/**
 * Adapted from highlight-words-core
 *
 * @see https://www.npmjs.com/package/highlight-words-core
 * @see https://github.com/bvaughn/highlight-words-core/blob/master/src/utils.js
 */
export interface Chunk {
	highlight: boolean;
	start: number;
	end: number;
}

interface FindChunksArgs {
	autoEscape?: boolean;
	caseSensitive?: boolean;
	searchWords: (string | RegExp)[];
	textToHighlight: string;
	findChunks?: (args: FindChunksArgs) => Chunk[];
}

interface CombineChunksArgs {
	chunks: Chunk[];
}

interface FillInChunksArgs {
	chunksToHighlight: Chunk[];
	totalLength: number;
}

/**
 * Takes an array of {start:number, end:number} objects and combines chunks that overlap into single chunks.
 * @return {start:number, end:number}[]
 */
export function combineChunks({ chunks }: CombineChunksArgs): Chunk[] {
	chunks = chunks
		.sort((first, second) => first.start - second.start)
		.reduce((processedChunks, nextChunk) => {
			if (processedChunks.length === 0) {
				return [nextChunk];
			}
			const prevChunk = processedChunks.pop()!;
			if (nextChunk.start <= prevChunk.end) {
				const endIndex = Math.max(prevChunk.end, nextChunk.end);
				processedChunks.push({ highlight: false, start: prevChunk.start, end: endIndex });
			} else {
				processedChunks.push(prevChunk, nextChunk);
			}
			return processedChunks;
		}, [] as Chunk[]);

	return chunks;
}

/**
 * Examine text for any matches.
 * If we find matches, add them to the returned array as a "chunk" object ({start:number, end:number}).
 * @return {start:number, end:number}[]
 */
export function defaultFindChunks({
	autoEscape,
	caseSensitive,
	searchWords,
	textToHighlight,
}: FindChunksArgs): Chunk[] {
	return searchWords
		.filter((searchWord) => searchWord) // Remove empty words
		.reduce((chunks, searchWord) => {
			if (autoEscape && typeof searchWord === 'string') {
				searchWord = escapeRegExpFn(searchWord);
			}

			const regex = new RegExp(searchWord, caseSensitive ? 'g' : 'gi');

			let match;
			while ((match = regex.exec(textToHighlight))) {
				const start = match.index;
				const end = regex.lastIndex;
				// We do not return zero-length matches
				if (end > start) {
					chunks.push({ highlight: false, start, end });
				}

				// Prevent browsers like Firefox from getting stuck in an infinite loop
				// See http://www.regexguru.com/2008/04/watch-out-for-zero-length-matches/
				if (match.index === regex.lastIndex) {
					regex.lastIndex++;
				}
			}

			return chunks;
		}, [] as Chunk[]);
}

/**
 * Given a set of chunks to highlight, create an additional set of chunks
 * to represent the bits of text between the highlighted text.
 * @param chunksToHighlight {start:number, end:number}[]
 * @param totalLength number
 * @return {start:number, end:number, highlight:boolean}[]
 */
export const fillInChunks = ({
	chunksToHighlight,
	totalLength,
}: FillInChunksArgs): Chunk[] => {
	const allChunks: Chunk[] = [];
	const append = (start: number, end: number, highlight: boolean) => {
		if (end - start > 0) {
			allChunks.push({
				start,
				end,
				highlight,
			});
		}
	};

	if (chunksToHighlight.length === 0) {
		append(0, totalLength, false);
	} else {
		let lastIndex = 0;
		for (const chunk of chunksToHighlight) {
			append(lastIndex, chunk.start, false);
			append(chunk.start, chunk.end, true);
			lastIndex = chunk.end;
		}
		append(lastIndex, totalLength, false);
	}
	return allChunks;
};

function escapeRegExpFn(string: string): string {
	return string.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
}

/**
 * Creates an array of chunk objects representing both highlightable and non-highlightable pieces of
 * text that match each search word.
 *
 * @return Array of "chunks" (where a Chunk is { start:number, end:number, highlight:boolean })
 */
export function findAll({
	autoEscape,
	caseSensitive = false,
	findChunks = defaultFindChunks,
	searchWords,
	textToHighlight,
}: FindChunksArgs): Chunk[] {
	return fillInChunks({
		chunksToHighlight: combineChunks({
			chunks: findChunks({
				autoEscape,
				caseSensitive,
				searchWords,
				textToHighlight,
			}),
		}),
		totalLength: textToHighlight ? textToHighlight.length : 0,
	});
}
