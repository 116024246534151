import type { Category } from '@newstex/types/category';
import { SearchFacetConfig, SearchWithFacets } from '~/components/search-with-facets';
import { CategoriesTable } from '~/components/tables/categories-table';

export const CategoryFacetConfig: SearchFacetConfig[] = [
	{
		attribute: 'type',
		title: 'Type',
	},
];

export const CategorySearchPage = () => {
	const fallbackSearch = async (q: string): Promise<Category[]> => {
		console.log('Fallback Search', q);
		return [];
	};

	return (
		<SearchWithFacets
			indexName="Category"
			title="Categories"
			info="Categories are searchable with various facets."
			defaultSort="story_count:desc"
			resultsTable={CategoriesTable}
			fallbackSearch={fallbackSearch}
			facets={CategoryFacetConfig}
		/>
	);
};
