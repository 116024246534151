import { Command } from './index';

export const AIChatCommand: Command = {
	id: 'ai-chat',
	name: 'AI Chat',
	description: 'Open AI Chat',
	icon: ['fad', 'message-bot'],
	handler: ({ aiChat }) => {
		aiChat.show();
	},
};
