/**
 * Dayjs bootstrapping, since it requires a "plugin" to support local formatting
 */
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const ISO_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(advancedFormat);
dayjs.extend(timezone);

// Default to Eastern time
dayjs.tz.setDefault('America/New_York');

export { dayjs };

export function now() {
	return dayjs();
}
export function unixNow() {
	return dayjs().unix();
}
export function fromUnix(unix: number) {
	return dayjs.unix(unix);
}
export function formatUTCDateFromUnix(unix: number, format = ISO_DATE_FORMAT) {
	return fromUnix(unix).utc().format(format);
}
export function utcFormat(format: string, date?: dayjs.ConfigType) {
	return dayjs(date).utc().format(format);
}
export function nowFormat(format: string, date?: dayjs.ConfigType) {
	return dayjs(date).format(format);
}

export function convertToDate(val: string | number | Date | dayjs.Dayjs): dayjs.Dayjs {
	if (typeof val === 'number') {
		// A number that is exactly 8 digits is probably YYYYMMDD
		if (val > 19000000 && val < 29999999) {
			return dayjs(String(val), 'YYYYMMDD');
		}

		// A number under 10 billion is probably a unix timestamp
		if (val < 10000000000) {
			return fromUnix(val);
		}
	}
	return dayjs(val);
}

export function formatDateForHubSpot(val: any) {
	if (typeof val === 'number') {
		val = formatUTCDateFromUnix(val);
	} else if (typeof val === 'string' && val.includes('T')) {
		val = val.split('T')[0];
	} else if (val.toISOString) {
		val = val.toISOString().split('T')[0];
	}
	return val;
}
