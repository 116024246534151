import { faHubspot } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TypedObject } from '@newstex/types/base';
import React from 'react';
import { Link } from 'react-router-dom';

interface HubSpotLinkProps {
	id?: string | number;
	item?: TypedObject & { hubspot_id?: string | number; };
	size?: number;
	color?: string;
	children?: React.ReactNode;
}

export const HubSpotLink: React.FC<HubSpotLinkProps> = (props) => {
	const hubspotId = props.id || props.item?.hubspot_id;
	return (
		<>
			{hubspotId && (
				<Link target="_blank" to={`https://app.hubspot.com/contacts/20613241/record/0-2/${hubspotId}`}>
					{props.children || <FontAwesomeIcon icon={faHubspot} />}
				</Link>
			)}
		</>
	);
};
