import { useState } from 'react';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

interface GroupSelectionActionsProps {
	selectedGroups: string[];
	groupKey: string;
	onClearSelection: () => void;
	onSelectAll: () => void;
}

export function GroupSelectionActions({
	selectedGroups,
	groupKey,
	onClearSelection,
	onSelectAll,
}: GroupSelectionActionsProps) {
	const [showConfirm, setShowConfirm] = useState(false);
	const [action, setAction] = useState<'include' | 'exclude'>();
	const [searchParams, setSearchParams] = useSearchParams();

	const handleAction = (type: 'include' | 'exclude') => {
		setAction(type);
		setShowConfirm(true);
	};

	const confirmAction = () => {
		setSearchParams((prev) => {
			if (action === 'exclude') {
				// Get existing excluded items
				const existingExcludes = prev.get(`!${groupKey}`)?.split(',') || [];
				// Combine with new selections, removing duplicates
				const allExcludes = [...new Set([...existingExcludes, ...selectedGroups])];
				prev.set(`!${groupKey}`, allExcludes.join(','));
				// Clear the positive filter if it exists
				prev.delete(groupKey);
			} else {
				// For include, we replace the filter entirely
				prev.set(groupKey, selectedGroups.join(','));
				// Clear any negated filters
				prev.delete(`!${groupKey}`);
			}
			return prev;
		});
		onClearSelection();
		setShowConfirm(false);
	};

	if (selectedGroups.length === 0) {
		return (
			<Dropdown>
				<Dropdown.Toggle variant="outline-secondary" size="sm"/>
				<Dropdown.Menu>
					<Dropdown.Item onClick={onSelectAll}>Select All</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		);
	}

	return (
		<>
			<Dropdown>
				<Dropdown.Toggle variant="outline-secondary" size="sm">
					{selectedGroups.length} Selected
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Item onClick={onSelectAll}>Select All</Dropdown.Item>
					<Dropdown.Item onClick={onClearSelection}>Clear Selection</Dropdown.Item>
					<Dropdown.Divider />
					<Dropdown.Item
						onClick={() => handleAction('include')}
						className="text-primary"
					>
						Include Only Selected
					</Dropdown.Item>
					<Dropdown.Item
						onClick={() => handleAction('exclude')}
						className="text-danger"
					>
						Exclude Selected
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>

			<Modal show={showConfirm} onHide={() => setShowConfirm(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Confirm {action === 'exclude' ? 'Exclusion' : 'Inclusion'}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Are you sure you want to {action === 'exclude' ? 'exclude' : 'include only'} the selected {selectedGroups.length} items?
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowConfirm(false)}>
						Cancel
					</Button>
					<Button
						variant={action === 'exclude' ? 'danger' : 'primary'}
						onClick={confirmAction}
					>
						Confirm
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
