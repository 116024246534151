import { Content } from '@newstex/types/content';
import { Story } from '@newstex/types/story';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import { useState } from 'react';
import {
	Alert,
	Button,
	Form,
	Modal,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { convertToDate } from '~/lib/utils';
import { useAPI } from '~/providers/api-provider';

interface ExportStoriesModalProps {
	content: Content;
	show: boolean;
	onClose: () => void;
}

const SUPPORTED_CSV_TYPES = ['number', 'string', 'boolean', 'null', 'undefined'];

export function ExportStoriesModal({
	content, show, onClose,
}: ExportStoriesModalProps) {
	const [startDate, setStartDate] = useState<string>('');
	const [endDate, setEndDate] = useState<string>('');
	const [error, setError] = useState<string | null>(null);
	const [exporting, setExporting] = useState(false);
	const [totalStories, setTotalStories] = useState(0);
	const api = useAPI();

	const handleExport = async () => {
		if (!content?.$id) {
			setError('Content not found');
			return;
		}

		if (!startDate || !endDate) {
			setError('Please select both start and end dates');
			return;
		}

		setExporting(true);
		setError(null);
		setTotalStories(0);

		try {
			const stories: Story[] = [];
			let nextToken: string | undefined;

			do {
				const searchParams = new URLSearchParams({
					publication: content.$id,
					sort: 'desc',
					start_date: startDate,
					end_date: endDate,
				});

				if (nextToken) {
					searchParams.set('nextToken', nextToken);
				}

				const resp = await api.fetchWithAuth(`resources/Story?${searchParams}`);
				stories.push(...resp.items);
				nextToken = resp.nextToken;
				setTotalStories((total) => total + resp.items.length);
			} while (nextToken);

			const csvConfig = mkConfig({
				filename: `stories_export_${content.newstex_id || content.$id}_${startDate}_${endDate}`,
				fieldSeparator: ',',
				decimalSeparator: '.',
				quoteCharacter: '"',
				quoteStrings: true,
				useKeysAsHeaders: true,
			});

			const dataToExport = stories.map((story) => {
				const exportData: Record<string, any> = {
					'Newstex ID': story.__id__,
					'Publication Name': content.name,
					Headline: story.headline,
					'External ID': story.external_id,
					Permalink: story.permalink,
					Source: story.source,
					'Published Timestamp': story.date,
					'Published Date': story.date ? convertToDate(story.date).toISOString() : '',
					'Received At': story.received_at,
					'Provider Categories': story.provider_categories?.join(';'),
					Categories: story.categories?.join(';'),
					Excerpt: story.excerpt,
				};

				// Convert any non-supported types to strings
				for (const [key, value] of Object.entries(exportData)) {
					if (!SUPPORTED_CSV_TYPES.includes(typeof value)) {
						exportData[key] = String(value);
					}
				}

				return exportData;
			});

			// Generate the CSV string
			const csv = generateCsv(csvConfig)(dataToExport);

			// Trigger the download
			download(csvConfig)(csv);

			toast.success('Stories exported successfully');
			onClose();
		} catch (err) {
			console.error('Error exporting stories:', err);
			setError('Failed to export stories. Please try again.');
		} finally {
			setExporting(false);
		}
	};

	return (
		<Modal show={show} onHide={onClose}>
			<Modal.Header closeButton>
				<Modal.Title>Export Stories for {content.name}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{error && <Alert className="text-center mb-3" variant="danger">{error}</Alert>}
				{totalStories > 0 && <Alert className="text-center mb-3" variant="info">{totalStories} stories loaded</Alert>}
				<Form>
					<Form.Group className="mb-3">
						<Form.Label>Start Date</Form.Label>
						<Form.Control
							type="date"
							value={startDate}
							onChange={(e) => setStartDate(e.target.value)}
							required
						/>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>End Date</Form.Label>
						<Form.Control
							type="date"
							value={endDate}
							onChange={(e) => setEndDate(e.target.value)}
							required
						/>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={onClose}>
					Cancel
				</Button>
				<Button variant="primary" onClick={handleExport} disabled={exporting}>
					{exporting ? 'Exporting...' : 'Export Stories'}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
