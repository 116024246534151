import { version } from '@newstex/core/version';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';

import { App } from './app';

console.log('Build Mode:', import.meta.env.VITE_BUILD_MODE);

Sentry.init({
	dsn: 'https://77de0fd19e02744a16b530ea1c073242@o4505953416511488.ingest.us.sentry.io/4508342411198464',
	release: version,
	environment: import.meta.env.VITE_ENVIRONMENT,
	// Disable when running in "dev" mode (local build)
	enabled: import.meta.env.VITE_BUILD_MODE !== 'dev',
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
	// Tracing
	tracesSampleRate: 1.0, // Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: [
		'localhost',
		/^https:\/\/(api|app)(-dev)?\.newstex\.com/,
	],
	// Session Replay
	// 10% session sample rate
	replaysSessionSampleRate: 0.1,
	// 100% session sample rate on error
	replaysOnErrorSampleRate: 1.0,
});

createRoot(document.getElementById('root')!).render(<App />);
