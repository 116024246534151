import { faHubspot } from '@fortawesome/free-brands-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AbortError } from '@newstex/core/errors';
import { HubSpotCompany } from '@newstex/types/hubspot';
import { KnowledgeBase } from '@newstex/types/rag';
import { GetHubSpotRecordsResponse } from '@newstex/types/responses/get-hubspot-records';
import { useEffect, useRef } from 'react';
import {
	Alert,
	Col,
	Container,
	Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MermaidGraph } from '~/components/mermaid-graph';
import PageHeader from '~/components/PageHeader';
import { AISummaryStats } from '~/components/qualify/ai-summary-stats';
import { LeadQualificationTable } from '~/components/qualify/lead-qualification-table';
import { LeadSubmissionForm } from '~/components/qualify/lead-submission-form';
import { sleep } from '~/lib/utils';
import { useAI } from '~/providers/ai-provider';
import { useAnalytics } from '~/providers/analytics-provider';
import { LeadData, useAPI } from '~/providers/api-provider';
import { useAWS } from '~/providers/aws-provider';
import { useSearch } from '~/providers/search';
import { useQualificationStore } from '~/stores/qualification-store';
import { useQualificationStatsStore } from '~/stores/qualification-stats-store';

function cleanURL(url: string) {
	let trimmedUrl = url?.trim();
	if (!trimmedUrl) {
		return;
	}

	if (!trimmedUrl.match(/^https?:\/\//i)) {
		trimmedUrl = `https://${trimmedUrl}`;
	}
	return trimmedUrl;
}

const DEFAULT_FLOW_DIAGRAM = `graph TD
	A[URL Input] --> C[Technical<br>Qualification]
	C --> D{Approve<br>Sync}
	D -->|Yes| E[HubSpot]
`;
const DEFAULT_FLOW_DIAGRAM_AUTO_SYNC = `graph TD
	A[URL Input] --> C[Technical<br>Qualification]
	C --> D[HubSpot]
`;

const AI_FLOW_DIAGRAM = `graph TD
	A[URL Input] --> B{Technical<br>Qualification}
	B -->|Yes| C[AI Review]
	C --> D{Approve<br>Sync}
	B -->|No| D
	D -->|Yes| E[HubSpot]
`;
const AI_FLOW_DIAGRAM_AUTO_SYNC = `graph TD
	A[URL Input] --> B{Technical<br>Qualification}
	B -->|Yes| C[AI Review]
	C --> D[HubSpot]
	B -->|No| D
`;

function FlowDiagramSelector({
	knowledgeBaseArticle,
	autoSyncToHubSpot,
}: {
	knowledgeBaseArticle: KnowledgeBase | null;
	autoSyncToHubSpot: boolean;
}) {
	if (autoSyncToHubSpot) {
		return (
			<MermaidGraph
				className="text-center"
				chart={knowledgeBaseArticle ? AI_FLOW_DIAGRAM_AUTO_SYNC : DEFAULT_FLOW_DIAGRAM_AUTO_SYNC}
			/>
		);
	}

	return (
		<MermaidGraph
			className="text-center"
			chart={knowledgeBaseArticle ? AI_FLOW_DIAGRAM : DEFAULT_FLOW_DIAGRAM}
		/>
	);
}

export function QualifyLeadsPage() {
	const store = useQualificationStore();
	const stats = useQualificationStatsStore();
	const abortController = useRef<AbortController>(new AbortController());
	const api = useAPI();
	const { qualifySite } = useAI();
	const aws = useAWS();
	const analytics = useAnalytics();
	const { searchClient } = useSearch();

	const pauseAllRunningItems = async () => {
		abortController.current?.abort();
		store.setItems(Object.fromEntries(Object.entries(store.items).map(([id, item]) => [id, {
			...item,
			running: false,
			sync_status: item.sync_status === 'Checking' ? undefined : item.sync_status,
		}])));
		await sleep(1000);
		store.setRunning(false);
		store.setSyncing(false);
		store.setSynced(false);
		abortController.current = new AbortController();
	};

	const syncItem = async (item: LeadData, syncData?: Partial<HubSpotCompany>) => {
		item.sync_status = 'Syncing';
		store.updateItem(item);
		await api.syncLeadToHubSpot(item, syncData || {}, analytics);
		if (item.hubspot_records?.length) {
			item.sync_status = 'Updated';
			console.log('Updating HubSpot record', {
				item,
			});
			stats.incrementUpdatedCount();
			store.updateItem(item);
		} else {
			item.sync_status = 'Created';
			console.log('Creating HubSpot record', item);
			stats.incrementCreatedCount();
			store.updateItem(item);
		}
		stats.addItem(item);
	};

	const processItem = async (item: LeadData, id: string, article?: KnowledgeBase | null) => {
		if (abortController.current.signal.aborted) {
			console.log('Cancelled');
			return;
		}

		// Ensure we're marked as running
		store.setRunning(true);

		// Check first if we have a cached result
		const cachedKey = `qualify:${item.url}:${article?.$id || 'ALL'}`;
		if (item.hubspot_id) {
			const cached = await store.getCachedResult(cachedKey);
			if (cached) {
				console.log('Using cached result', cached);
				store.updateItem(cached);
				if (store.autoSyncToHubSpot) {
					await syncItem(cached);
				}
				return;
			}
		}

		const currentItem = store.items[item.id];
		if (currentItem) {
			if (currentItem.sync_status != null && currentItem.sync_status !== 'Waiting') {
				console.log('Already processing', currentItem);
				return;
			}
			currentItem.sync_status = 'Checking';
			currentItem.running = true;
			store.updateItem(currentItem);
		}

		try {
			const detectResp = await aws.detect({
				url: item.url,
				classify: true,
			});
			if (abortController.current.signal.aborted) {
				console.log('Cancelled', {
					item,
					detectResp,
				});
				return;
			}

			const result: LeadData = {
				...(currentItem || {}),
				sync_status: 'Checking',
				url: item.url,
				qualified: false,
				...(detectResp || {}),
				id: item.id,
			};

			if (!result.qualified) {
				if (!result.rejection_reasons?.length) {
					console.log('Technical qualification failed', {
						item,
						result,
						detectResp,
					});
					result.rejection_reasons = ['Technical qualification failed'];
					if (detectResp?.name) {
						result.rejection_reasons.push(detectResp.name);
					}
				}

				if (result.max_ai_score == null) {
					result.max_ai_score = 0;
				}
			}

			console.log('Result', result);
			store.updateItem(result);

			// Submitted hubspot ID takes priority
			if (item.hubspot_id) {
				result.hubspot_id = item.hubspot_id;
				if (item.hubspot_records?.length) {
					result.hubspot_records = item.hubspot_records;
				}
			}

			if (!result.qualified && !result.feed_url && !result.rejection_reasons?.length) {
				result.rejection_reasons = ['No RSS feed found'];
			}
			console.log('Checking', {
				qualified: result.qualified,
				knowledgeBaseArticle: article?.title,
			});

			// If it's qualified and we have qualification criteria, add the article score to the result
			if (result.qualified && article) {
				console.log('Qualifying', item, article.title);

				// Run the qualification criteria against the article
				const resp = await qualifySite({
					detectResp: result,
					criteria: article.answer!,
				});
				console.log('AI Qualification', item, resp);
				result.ai_rankings = resp;
				result.max_ai_score = Math.max(...Object.values(resp).map((r) => r.score));
				analytics?.trackEvent('Qualified Lead', {
					id,
					criteria: article.title,
					url: item.url,
					qualified: result.qualified,
					score: result.max_ai_score,
				});
				store.updateItem(result);
			} else if (article) {
				result.max_ai_score = 0;
				analytics?.trackEvent('Unqualified Lead', {
					id,
					criteria: article.title,
					url: item.url,
					score: 0,
					qualified: result.qualified,
				});
				store.updateItem(result);
			}

			console.log(item, result);
			if (store.autoSyncToHubSpot) {
				const syncData: Partial<HubSpotCompany> = { };

				if (result.max_ai_score != null) {
					syncData.ai_qualification_score = result.max_ai_score;
				} else if (store.knowledgeBaseArticle && !result.ai_rankings) {
					syncData.ai_qualification_score = 0;
				}

				await syncItem(result, syncData);
			} else {
				result.running = false;
				result.sync_status = 'Ready';
				store.updateItem(result);
			}

			// Cache the result if we have a hubspot ID
			if (cachedKey) {
				store.cacheResult(cachedKey, result);
			}
		} catch (e: any) {
			if (e instanceof AbortError) {
				console.log('Cancelled', item);
				if (currentItem) {
					currentItem.running = false;
					delete currentItem.sync_status;
					store.updateItem(currentItem);
				}
				return;
			}

			if (String(e).includes('ExpiredTokenException')) {
				console.log('Expired', {
					item,
					e,
				});
				if (currentItem) {
					currentItem.running = false;
					delete currentItem.sync_status;
					store.updateItem(currentItem);
					store.setRunning(false);
					store.setSyncing(false);
				}
				await sleep(500);
				// Mark everything as paused
				setTimeout(() => {
					pauseAllRunningItems();
				}, 500);
				return;
			}

			if (String(e).includes('Lambda client not initialized') || String(e).includes('No AWS credentials found')) {
				await sleep(500);
				// Mark everything as paused
				setTimeout(() => {
					pauseAllRunningItems();
				}, 500);
				return;
			}

			if (currentItem) {
				currentItem.sync_status = 'Failed';
				currentItem.running = false;
				currentItem.qualified = false;
				currentItem.max_ai_score = 0;
				currentItem.rejection_reasons = [`Error: ${e instanceof Error ? e.message : 'Unknown error'}`];
				store.updateItem(currentItem);
			}

			const errorResult: LeadData = {
				...item,
				qualified: false,
				sync_status: 'Failed',
				rejection_reasons: [`Error: ${e instanceof Error ? e.message : 'Unknown error'}`],
			};

			if (store.knowledgeBaseArticle) {
				errorResult.ai_rankings = {};
			}

			// Only sync full failures if we have a HubSpot ID
			if (store.autoSyncToHubSpot && errorResult.hubspot_records?.length) {
				console.log('Syncing failed lead', errorResult);
				await syncItem(errorResult, store.knowledgeBaseArticle ? {
					ai_qualification_score: 0,
				} : {});
			}
		}
	};

	const processItems = async () => {
		// Set running state immediately
		store.setRunning(true);

		const id = crypto.randomUUID();
		try {
			for (const item of Object.values(store.items)) {
				if (abortController.current.signal.aborted) {
					console.log('Cancelled');
					break;
				}
				console.log('Processing item', {
					item,
					id,
					knowledgeBaseArticle: store.knowledgeBaseArticle?.title,
				});
				await processItem(item, id, store.knowledgeBaseArticle);
			}
		} finally {
			// Always ensure we clear running state when done
			if (!abortController.current.signal.aborted) {
				store.setRunning(false);
			}
		}
	};

	const runQualification = () => {
		// Don't start if already running
		if (store.running) {
			console.log('Already running qualification');
			return;
		}

		console.log('Starting qualification');
		abortController.current = new AbortController();
		store.setRunning(true);
		return processItems().catch((e) => {
			console.error('Error in qualification:', e);
			store.setRunning(false);
			throw e;
		});
	};

	const readHubSpotList = async (id: string) => {
		const validItems: LeadData[] = [];
		const toastId = toast.loading('Fetching HubSpot records...');
		const hubspotRecords = await api.getHubSpotRecords({
			id,
			type: 'list',
			abortController: abortController.current,
			nextToken: store.nextToken,
		}).catch(() => {
			toast.update(toastId, {
				render: 'Error fetching HubSpot records',
				closeButton: true,
				type: 'error',
				isLoading: false,
				autoClose: 5000,
			});
			return null;
		});

		if (hubspotRecords && !abortController.current.signal.aborted) {
			store.setNextToken(hubspotRecords.nextToken);
			stats.setTotalItems(hubspotRecords.items.length);
			toast.update(toastId, {
				render: `Loaded ${hubspotRecords.items.length} HubSpot records`,
				closeButton: true,
				type: 'success',
				isLoading: false,
				autoClose: 5000,
			});
			if (hubspotRecords.list) {
				store.setHubSpotList(hubspotRecords.list);
			}

			if (hubspotRecords?.items?.length) {
				for (const hubspotItem of hubspotRecords.items) {
					if (hubspotItem.website) {
						const cleanedUrl = cleanURL(hubspotItem.website);
						if (cleanedUrl) {
							validItems.push({
								id: String(hubspotItem.hs_object_id || crypto.randomUUID()),
								url: cleanedUrl,
								feed_url: hubspotItem.content_feed_url ? cleanURL(hubspotItem.content_feed_url) : undefined,
								hubspot_id: String(hubspotItem.hs_object_id),
								hubspot_records: [{
									...hubspotItem,
									id: String(hubspotItem.hs_object_id || ''),
								}],
							});
						} else {
							stats.addSkippedItem({
								id: String(hubspotItem.hs_object_id || ''),
								url: hubspotItem.website,
								hubspot_id: String(hubspotItem.hs_object_id),
								hubspot_records: [{
									...hubspotItem,
									id: String(hubspotItem.hs_object_id || ''),
								}],
							});
						}
					} else {
						console.log('Skipping', hubspotItem);
						stats.addSkippedItem({
							id: String(hubspotItem.hs_object_id || ''),
							url: hubspotItem.website,
							hubspot_id: String(hubspotItem.hs_object_id),
							hubspot_records: [{
								...hubspotItem,
								id: String(hubspotItem.hs_object_id || ''),
							}],
						});
					}
				}
			}
		}
		return validItems;
	};

	const submitURLs = async (submittedUrls?: string[], article?: KnowledgeBase | null, mode?: 'hubspot' | 'list') => {
		analytics?.trackEvent('Qualify Leads', {
			name: article?.title || 'None',
		}, submittedUrls?.length || 1);

		// Don't start if already running
		if (store.running) {
			console.log('Already running qualification');
			return;
		}

		abortController.current = new AbortController();
		store.setRunning(true);

		try {
			store.setSyncing(false);
			store.setSynced(false);
			store.setKnowledgeBaseArticle(article || null);
			const validItems: LeadData[] = [];
			if (submittedUrls) {
				for (const url of submittedUrls) {
					const trimmedUrl = cleanURL(url);
					// Ignore blank and comment lines
					if (!trimmedUrl || trimmedUrl.startsWith('#')) {
						continue;
					}

					const hubspotMatch = trimmedUrl.match(/^https?:\/\/app\.hubspot\.com\/contacts\/(\d+)\/(objectLists|lists)\/(\d+)\/filters/);
					if (hubspotMatch) {
						console.log('HubSpot List ID:', hubspotMatch[3]);
						store.setHubSpotListID(hubspotMatch[3]);
						store.setNextToken(undefined);
						const hubspotListItems = await readHubSpotList(hubspotMatch[3]);
						if (hubspotListItems) {
							validItems.push(...hubspotListItems);
						}
					} else if (mode === 'hubspot') {
						toast.error('Invalid HubSpot List URL');
					} else if (trimmedUrl.includes(';')) {
						// Allow a semicolon to specify a URL and a HubSpot ID
						const [validUrl, hubspotID] = trimmedUrl.split(';');
						validItems.push({
							id: hubspotID.trim() || crypto.randomUUID(),
							url: validUrl.trim(),
							hubspot_id: hubspotID.trim(),
						});
					} else {
						validItems.push({
							id: crypto.randomUUID(),
							url: trimmedUrl,
						});
					}
				}
			} else {
				for (const item of Object.values(store.items)) {
					validItems.push({
						...item,
						sync_status: 'Waiting',
					});
				}
			}

			// Check to see if any of these items exist in HubSpot already
			if (searchClient) {
				for (const item of validItems) {
					if (!item.hubspot_id && item.url) {
						const hubspotSearchResults = await searchClient.search<HubSpotCompany>({
							indexName: 'HubSpot',
							query: `"${item.url.replace(/^https?:\/\/(www\.)?/, '').replace(/\/$/, '')}"`,
							num_typos: 0,
							query_by: [
								'website',
								'domain',
								'email',
								'content_feed_url',
								'alt_website_url_1',
								'alt_website_url_2',
								'alt_website_url_3',
								'alt_website_url_4',
								'related_company_domains',
								'related_website_urls',
							],
						});
						if (hubspotSearchResults.hits.length) {
							console.log('Found HubSpot Matches', hubspotSearchResults.hits);
							for (const hit of hubspotSearchResults.hits) {
								if (hit.hs_object_id) {
									item.hubspot_id = String(hit.hs_object_id);
									if (!item.feed_url && hit.content_feed_url) {
										item.feed_url = hit.content_feed_url;
									}
									break;
								}
							}
						}
					}
				}
			}

			// Make sure all items have a unique ID
			for (const item of validItems) {
				if (!item.id) {
					if (item.hubspot_id) {
						item.id = item.hubspot_id;
					} else {
						item.id = crypto.randomUUID();
					}
				}
			}

			if (!abortController.current.signal.aborted) {
				store.setItems(validItems.reduce((acc, item) => {
					acc[item.id] = item;
					return acc;
				}, {} as Record<string, LeadData>));
				store.setShouldRunQualification(true);
			}
		} finally {
			// Always ensure we clear running state when done with initial setup
			if (!abortController.current.signal.aborted) {
				store.setRunning(false);
			}
		}
	};

	const processNextPage = async () => {
		if (!store.hubSpotListID || !store.nextToken) {
			return;
		}
		const validItems = await readHubSpotList(store.hubSpotListID);
		// Make sure all items have a unique ID
		for (const item of validItems) {
			if (!item.id) {
				if (item.hubspot_id) {
					item.id = item.hubspot_id;
				} else {
					item.id = crypto.randomUUID();
				}
			}
		}

		if (!abortController.current.signal.aborted) {
			store.setItems(validItems.reduce((acc, item) => {
				acc[item.id] = item;
				return acc;
			}, {} as Record<string, LeadData>));
			store.setShouldRunQualification(true);
		}
	};

	useEffect(() => {
		if (store.shouldRunQualification && !store.running && Object.values(store.items).length > 0) {
			runQualification();
			store.setShouldRunQualification(false);
		}
	}, [store.shouldRunQualification, store.items]);

	useEffect(() => {
		// Initialize state when component mounts
		store.initializeState();

		return () => {
			abortController.current.abort();
		};
	}, []);

	return (
		<Container fluid>
			<PageHeader title={<>
				Qualify Leads
				{store.hubSpotList?.name && <small>
					<> - </>
					<a
						href={`https://app.hubspot.com/contacts/20613241/objectLists/${store.hubSpotList.id}/filters`}
						target="_blank" rel="noreferrer"
					>
						{store.hubSpotList.name}
					</a>
				</small>}
			</>} />
			<Row className="mb-3">
				<Col>
					<Container fluid>
						{store.knowledgeBaseArticle && (store.running || Object.values(store.items).length > 0) && (
							<Row className="mb-3">
								<Col>
									<Alert variant="info">
										Selected criteria:&nbsp;
										<Link to={`/docs/kb/${store.knowledgeBaseArticle.$id}`} target="_blank">
											{store.knowledgeBaseArticle.title}
										</Link>
									</Alert>
								</Col>
							</Row>
						)}
						{store.running && (
							<Alert className="text-center" variant="info">
								<FontAwesomeIcon icon={faSpinner} spin className="me-2" />
								Qualifying leads. PLEASE DO NOT CLOSE THIS PAGE.
								&nbsp;[{Object
									.values(store.items)
									.filter((lead) => lead.qualified != null)
									.length} / {Object.values(store.items).length || 100}]
								<FontAwesomeIcon icon={faSpinner} spin className="ms-2" />
								<br/>
								{Object.values(store.items).length > 0
									? ` ${Math.round((Object.values(store.items).filter((lead) => lead.qualified != null).length / Object.values(store.items).length) * 100)}% complete`
									: ' Starting...'
								}
							</Alert>
						)}
						{store.syncing && (
							<Alert className="text-center" variant="info">
								<FontAwesomeIcon icon={faHubspot} spin className="me-2" />
								Syncing selected leads to HubSpot. PLEASE DO NOT CLOSE THIS PAGE.
								<FontAwesomeIcon icon={faHubspot} spin className="ms-2" />
							</Alert>
						)}
						{store.synced && (
							<Alert className="text-center" variant="success">
								Selected leads have been synced to HubSpot.
							</Alert>
						)}
						{!store.running && !Object.values(store.items).length && (
							<LeadSubmissionForm
								onSubmit={(urls, article, mode) => {
									console.log('onSubmit', urls, article, mode);
									submitURLs(urls, article, mode);
								}}
								selectedKnowledgeBase={store.knowledgeBaseArticle}
								autoSyncToHubSpot={store.autoSyncToHubSpot}
								onSelectKnowledgeBase={store.setKnowledgeBaseArticle}
								onSelectAutoSyncToHubSpot={store.setAutoSyncToHubSpot}
							/>
						)}
						{stats.totalItems > 0 && (
							<AISummaryStats />
						)}
						{Object.values(store.items).length > 0 && (
							<LeadQualificationTable
								className={store.synced ? 'opacity-50' : ''}
								items={Object.values(store.items)}
								running={store.running}
								abortController={abortController.current}
								showScores={store.knowledgeBaseArticle != null}
								nextPage={store.nextToken ? processNextPage : undefined}
								runQualification={runQualification}
								pause={async () => {
									abortController.current.abort();
									await sleep(1000);
									store.setRunning(false);
									store.setSyncing(false);
									store.setSynced(false);
								}}
								clearData={() => {
									abortController.current.abort();
									store.reset();
									stats.reset();
									window.location.reload();
								}}
								syncAll={async () => {
									store.setSyncing(true);
									for (const item of Object.values(store.items)) {
										await syncItem(item, {
											ai_qualification_score: 0,
										});
									}
									store.setSyncing(false);
									store.setSynced(true);
								}}
								syncLead={(lead: LeadData) => {
									syncItem(lead, {});
								}}
							/>
						)}
					</Container>
				</Col>
				{!Object.values(store.items).length && (
					<Col lg={2} md={12}>
						<FlowDiagramSelector
							knowledgeBaseArticle={store.knowledgeBaseArticle}
							autoSyncToHubSpot={store.autoSyncToHubSpot}
						/>
					</Col>
				)}
			</Row>
		</Container>
	);
}
