/**
 * Create Publication Button
 *
 * @see https://app.asana.com/0/214270922471/1207889058997289/f
 */
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Content } from '@newstex/types/content';
import { useRef, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';

import CreatePublicationModal from './create-publication-modal';
import { hasPermission } from './require-permissions';

export interface CreatePublicationButtonProps {
	parent: Content,
}

export default function CreatePublicationButton({ parent }: CreatePublicationButtonProps) {
	if (!hasPermission(['admin'])) {
		return <></>;
	}
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [pubURL, setPubURL] = useState('');
	const formRef = useRef<HTMLFormElement>(null);

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!showCreateModal) {
			const form = e.currentTarget;
			const url = (form.elements.namedItem('url') as HTMLInputElement).value;
			if (url?.length) {
				console.log('Creating Publication', url, parent.$id);
				setPubURL(url);
				setShowCreateModal(true);
			}
		}
	};

	return (
		<>
			{showCreateModal && <CreatePublicationModal
				parent={parent}
				pubURL={pubURL}
				hide={(created) => {
					setShowCreateModal(false);
					if (created) {
						setPubURL('');
						formRef.current?.reset();
					}
				}}
			/>}

			<Form ref={formRef} onSubmit={handleSubmit}>
				<Form.Group className="mb-3 mt-3">
					<InputGroup>
						<Form.Control
							type="url"
							name="url"
							placeholder="https://..."
							aria-label="Publication URL"
							disabled={showCreateModal}
						/>
						<Button variant="outline-secondary" type="submit" disabled={showCreateModal}>
							<FontAwesomeIcon icon={faPlus} />&nbsp;Add Publication
						</Button>
					</InputGroup>
				</Form.Group>
			</Form>
		</>
	);
}
