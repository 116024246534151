import type { SearchClient, SearchIndexName } from '@newstex/search/client';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface SearchLinkButtonProps {
	search: Omit<Parameters<SearchClient['search']>[0], 'indexName'>;
	indexName: SearchIndexName;
	size?: 'sm' | 'lg';
	variant?: string;
}

export function SearchLinkButton({
	search, indexName, size, variant = 'primary',
}: SearchLinkButtonProps) {
	const buildSearchUrl = () => {
		const params = new URLSearchParams();
		params.set('indexName', indexName);
		params.set('q', search.q || '*');

		for (const [key, value] of Object.entries(search)) {
			if (key !== 'indexName' && key !== 'q') {
				params.set(key, value.toString());
			}
		}
		return `/search?${params.toString()}`;
	};

	return (
		<Button
			as={Link as any}
			to={buildSearchUrl()}
			size={size}
			variant={variant}
		>
			View Search
		</Button>
	);
}
