import type { Delivery } from '@newstex/types/delivery';
import type { Results } from '@newstex/types/results';
import Fuse from 'fuse.js';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { CreateDeliveryButton } from '~/components/create-delivery-button';
import LoadingSpinner from '~/components/LoadingSpinner';
import { PageTitle } from '~/components/page-title';
import Preloader from '~/components/Preloader';
import { SearchBox } from '~/components/search-box';
import { SimpleFacetMenu } from '~/components/simple-facet-menu';
import { DeliveryStats } from '~/components/story/delivery-stats';
import { DeliveriesTable } from '~/components/tables/deliveries-table';
import { useAPI } from '~/providers/api-provider';

export const DeliverySearchPage = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const api = useAPI();
	const [loading, setLoading] = useState<boolean>(false);
	const [deliveries, setDeliveries] = useState<Delivery[]>([]);
	const [allDeliveries, setAllDeliveries] = useState<Delivery[]>([]);

	async function loadMore(token?: string, refresh = false) {
		const resp = await api.fetchWithAuth<Results<Delivery>>(
			`resources/Delivery?limit=100&nextToken=${token}`,
			refresh ? { cache: 'reload' } : undefined,
		);
		if (resp?.items?.length) {
			setAllDeliveries((prev) => [
				...prev,
				...resp.items,
			]);
		}
	}
	const fetchData = async (refresh = false) => {
		setLoading(true);
		const resp = await api.fetchWithAuth<Results<Delivery>>(
			'resources/Delivery?limit=100',
			refresh ? { cache: 'reload' } : undefined,
		);
		setAllDeliveries(resp?.items || []);
		if (resp?.nextToken) {
			await loadMore(resp.nextToken, refresh);
		}
		setLoading(false);
	};

	// Filter Results
	useEffect(() => {
		const typeFilter = searchParams.get('type');
		const statusFilter = searchParams.get('status');
		const envFilter = searchParams.get('env');
		const filteredDeliveries = allDeliveries.filter((d) => {
			if (typeFilter && d.type !== typeFilter) {
				return false;
			}

			if (statusFilter && d.status !== statusFilter) {
				return false;
			}

			if (envFilter) {
				if (envFilter === 'prod' && !d.queue_name?.startsWith('prod-')) {
					return false;
				}

				if (envFilter === 'dev' && !d.queue_name?.startsWith('dev-')) {
					return false;
				}

				if (envFilter === 'custom' && (d.queue_name?.startsWith('prod-') || d.queue_name?.startsWith('dev-'))) {
					return false;
				}
			}
			return true;
		});
		if (searchParams.get('q')) {
			const searcher = new Fuse(filteredDeliveries, {
				shouldSort: true,
				threshold: 0.6,
				location: 0,
				distance: 100,
				minMatchCharLength: 1,
				keys: [
					'name',
					'username',
					'hostname',
					'type',
				],
			});
			setDeliveries(searcher
				.search(searchParams.get('q') || '')
				.map((result) => result.item));
		} else {
			setDeliveries(filteredDeliveries);
		}
	}, [allDeliveries, searchParams]);

	useEffect(() => {
		fetchData();
	}, [api]);

	return (
		<Container fluid>
			<PageTitle title="Deliveries" />
			<Row>
				<Col>
					<SearchBox
						extraButtons={<CreateDeliveryButton />}
						refreshHook={() => {
							return fetchData(true);
						}}
					/>
				</Col>
			</Row>
			{loading && <Preloader type="three-bounce" variant="success" /> }
			<DeliveryStats />
			<hr/>
			<Row>
				<Col md={2} xxl={1}>
					<SimpleFacetMenu
						title="Status"
						prop="status"
						options={[
							{ label: 'All', value: '', count: deliveries?.length },
							{ label: 'Active', value: 'Active', count: deliveries?.filter((d) => d.status === 'Active').length },
							{ label: 'Inactive', value: 'Inactive', count: deliveries?.filter((d) => d.status === 'Inactive').length },
							{ label: 'Standby', value: 'Standby', count: deliveries?.filter((d) => d.status === 'Standby').length },
							{ label: 'Archived', value: 'Archived', count: deliveries?.filter((d) => d.status === 'Archived').length },
						]}
					/>
					<SimpleFacetMenu
						title="Type"
						prop="type"
						options={[
							{ label: 'All', value: '', count: deliveries?.length },
							{ label: 'SFTP', value: 'SFTP', count: deliveries?.filter((d) => d.type === 'SFTP').length },
							{ label: 'HTTP', value: 'HTTP', count: deliveries?.filter((d) => d.type === 'HTTP').length },
							{ label: 'S3', value: 'S3', count: deliveries?.filter((d) => d.type === 'S3').length },
							{ label: 'FTP', value: 'FTP', count: deliveries?.filter((d) => d.type === 'FTP').length },
						]}
					/>
					<SimpleFacetMenu
						title="Environment"
						prop="env"
						options={[
							{ label: 'All', value: '', count: deliveries?.length },
							{ label: 'Production', value: 'prod', count: deliveries?.filter((d) => d.queue_name?.startsWith('prod-')).length },
							{ label: 'Development', value: 'dev', count: deliveries?.filter((d) => d.queue_name?.startsWith('dev-')).length },
							{ label: 'Custom', value: 'custom', count: deliveries?.filter((d) => !d.queue_name?.startsWith('prod-') && !d.queue_name?.startsWith('dev-')).length },
						]}
					/>
				</Col>
				<Col md={10} xxl={11}>
					{loading
						? <LoadingSpinner loading={loading} />
						: <DeliveriesTable
							deliveries={deliveries || []}
							hasMore={false}
						/>
					}
				</Col>
			</Row>
		</Container>
	);
};
