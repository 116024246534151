import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Product } from '@newstex/types/product';
import type { Results } from '@newstex/types/results';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAPI } from '~/providers/api-provider';

interface ProductLinkProps {
	product?: string | Product;
}

export const ProductLink: React.FC<ProductLinkProps> = ({ product }) => {
	const [productData, setProductData] = useState<Product | null>(null);
	const api = useAPI();

	useEffect(() => {
		const fetchData = async () => {
			if (typeof product === 'string') {
				if (product.match(/^\d+$/)) {
					throw new Error(`Invalid product ID: ${product}`);
				}
				const resp = await api.fetchWithAuth<Results<Product>>(`resources/Product/${product}`);
				setProductData(resp.items?.[0]);
			}
		};

		if (typeof product === 'string') {
			fetchData();
		} else if (product && typeof product === 'object') {
			setProductData(product);
		}
	}, [product]);

	if (!product) {
		return null;
	}

	if (typeof product === 'object') {
		return (
			<Link to={`/products/${product.$id}`}>
				{product.name || product.$id}
			</Link>
		);
	}

	return (
		<Link to={`/products/${product}`}>
			{productData ? (
				productData.name || product
			) : (
				<FontAwesomeIcon icon={faSpinner} spin />
			)}
		</Link>
	);
};
